import styled from "styled-components";

export const BufferBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: ${({ theme }) => theme.palette.secondaryDarkBlue};
`;

interface ILine {
  first?: boolean;
  videoDuration: string;
  left: number;
}

export const Line = styled.div<ILine>`
  width: 1px;
  height: 50%;
  background: ${({ theme }) => theme.palette.secondaryDarkBlue};
  position: absolute;
  left: ${({ left }) => `${left}px`};
  font-size: 0.6em;
  ${(props) => {
    return `
      :after{
        content: "${props.first ? "0" : props.videoDuration}";
        width: 1px;
        height: 10px;
        position: absolute;
        left:${props.first ? `${props.left}px` : "-2em"};
        bottom: -1em; 
      }
    `;
  }}
`;
