import { FC } from "react";

import { SharedComponents, Theme } from "@shared";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as LoadingModalLib from "./components";
import { ILoadingModal } from "./types";

export const LoadingModal: FC<ILoadingModal> = ({ isOpen = false, title }) => {
  const {
    palette: { primaryBlue },
    spacing: { md },
  } = Theme.useStyledTheme();
  return (
    <LoadingModalLib.Wrapper open={isOpen}>
      <CircularProgress color={"primary"} />
      <SharedComponents.Text
        margin={`${md}px 0`}
        text={title}
        color={primaryBlue}
        type={"h2"}
      />
      <SharedComponents.Text
        text={"Give us a few seconds"}
        type={"paragraph"}
      />
    </LoadingModalLib.Wrapper>
  );
};
