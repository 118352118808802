import styled from "styled-components";
import {
  HOVER_BUTTONS_CLASS_NAME,
  REACT_PLAYER_CLASS_NAME,
} from "../constants";

export const CellWrapper = styled.div`
  position: relative;

  box-shadow: 0 8px 24px rgba(128, 139, 159, 0.2);
  cursor: pointer;
  .${HOVER_BUTTONS_CLASS_NAME} {
    display: none;
  }

  :hover {
    .${HOVER_BUTTONS_CLASS_NAME} {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .${REACT_PLAYER_CLASS_NAME} {
      -webkit-filter: brightness(60%);
    }
  }

  transition: all 0.2s ease-in-out;
`;
