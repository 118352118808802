import * as React from "react";

export function IconSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45.264 39.44a24.781 24.781 0 1 0-5.326 5.33h-.003c.114.153.236.298.373.439l14.678 14.678a3.813 3.813 0 1 0 5.395-5.39L45.703 39.817a3.831 3.831 0 0 0-.439-.381v.004Zm.984-14.655a20.968 20.968 0 1 1-41.936 0 20.968 20.968 0 0 1 41.936 0Z"
        fill="#808B9F"
      />
    </svg>
  );
}
