import { Field } from "formik";
import styled from "styled-components";

import { IStyledField } from "../types";

export const StyledField = styled(Field)<IStyledField>`
  width: 100%;
  background: transparent;
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ theme }) => `${theme.spacing.sm}px`};
  border: ${({ theme, hasFocus }) =>
    `1px solid ${hasFocus ? theme.palette.primaryBlue : theme.palette.gray}`};
  padding: ${({ theme }) => `${theme.spacing.sm}px ${theme.spacing.md}px`};
  resize: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.219rem;

  ::placeholder {
    color: ${({ theme }) => `1px solid ${theme.palette.gray}`};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${({ theme }) => `1px solid ${theme.palette.gray}`};
  }

  ::-ms-input-placeholder {
    color: ${({ theme }) => `1px solid ${theme.palette.gray}`};
  }
`;
