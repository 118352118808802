export const formatDuration = (time: number): string => {
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_MINUTES = 60;
  const hours = (time - (time % SECONDS_IN_HOUR)) / SECONDS_IN_HOUR;
  const minutes =
    (time - hours * SECONDS_IN_HOUR - (time % SECONDS_IN_MINUTES)) /
    SECONDS_IN_MINUTES;
  const seconds = time - hours * SECONDS_IN_HOUR - minutes * SECONDS_IN_MINUTES;
  if (hours > 0) {
    return `${hours} hours ${minutes} minutes ${seconds.toFixed(1)} seconds`;
  }
  if (minutes > 0) {
    return `${minutes} minutes ${seconds.toFixed(1)} seconds`;
  }
  return `${seconds.toFixed(1)} seconds`;
};
