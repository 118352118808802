import React from "react";

import { IStepperSteps } from "../types";

export const Steps = ({ children, currentStep }: IStepperSteps) => {
  return (
    <>
      {React.Children.map(children, (child, index) => {
        return index + 1 === currentStep ? child : null;
      })}
    </>
  );
};
