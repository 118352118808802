import { ULWrapper } from "../../../pages/add_project/components/ul_wrapper";

export const FormExample = () => {
  return (
    <ULWrapper>
      Looking for example consent forms?{" "}
      <a
        rel="noreferrer"
        target="_blank"
        href="https://branduniversity.pg.com/pages/?wpiuid=135784"
      >
        Click here
      </a>
    </ULWrapper>
  );
};
