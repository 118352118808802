import styled from "styled-components";
import { LIST_ITEM_HEIGHT } from "../../../constants";

export const ItemWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: ${LIST_ITEM_HEIGHT}px;
  align-items: center;
`;
