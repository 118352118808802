import styled from "styled-components";

interface IAbsoluteContainer {
  height: number;
}

export const AbsoluteContainer = styled.div<IAbsoluteContainer>`
  position: absolute;
  height: ${({ height }) => `${height}px`};
  width: fit-content;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;
