import { Types } from "@shared";

export { useLoadingStatus } from "./useLoadingStatus";
export { useQueryStatus } from "./useQueryStatus";
export { useTop5Tags } from "./useTop5Tags";
export { lerp, range, invlerp, clamp } from "./interpolate";
export { onKeyDown } from "./onKeyDown";

export const parseTaxonomyToView = (selectedProject: Types.IProject) => {
  return selectedProject.taxonomy;
};
export const parseTagsToView = (selectedProject: Types.IProjectDetails) => {
  return (
    selectedProject.tags
      ?.slice(0, 5)
      .map((item) => `#${item.name}`)
      .join(", ") + "..."
  );
};

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTES = 60;

export function formatSeconds(value: number) {
  const result = Math.floor(value);
  const hh =
    Math.floor(result / 3600) < 10
      ? "0" + Math.floor(result / 3600)
      : Math.floor(result / 3600);
  const mm =
    Math.floor((result / 60) % 60) < 10
      ? "0" + Math.floor((result / 60) % 60)
      : Math.floor((result / 60) % 60);
  const ss =
    Math.floor(result % 60) < 10
      ? "0" + Math.floor(result % 60)
      : Math.floor(result % 60);
  return `${hh}:${mm}:${ss}`;
}

export const formatTimeShort = (time: number): string => {
  const hours = (time - (time % SECONDS_IN_HOUR)) / SECONDS_IN_HOUR;
  const minutes =
    (time - hours * SECONDS_IN_HOUR - (time % SECONDS_IN_MINUTES)) /
    SECONDS_IN_MINUTES;
  const seconds = time - hours * SECONDS_IN_HOUR - minutes * SECONDS_IN_MINUTES;
  const fHours = hours >= 10 ? hours : `0${hours}`;
  const fMinutes = minutes >= 10 ? minutes : `0${minutes}`;
  const parsedSeconds =
    seconds >= 10 ? seconds.toFixed(2) : `0${seconds.toFixed(2)}`;
  const formattedSeconds = parsedSeconds.split(".")[0];
  if (hours === 0) {
    return `${fMinutes}:${formattedSeconds}`;
  }
  return `${fHours}:${fMinutes}:${formattedSeconds}`;
};

export const formatTime = (time: number): string => {
  const hours = (time - (time % SECONDS_IN_HOUR)) / SECONDS_IN_HOUR;
  const minutes =
    (time - hours * SECONDS_IN_HOUR - (time % SECONDS_IN_MINUTES)) /
    SECONDS_IN_MINUTES;
  const seconds = time - hours * SECONDS_IN_HOUR - minutes * SECONDS_IN_MINUTES;
  const fHours = hours >= 10 ? hours : `0${hours}`;
  const fMinutes = minutes >= 10 ? minutes : `0${minutes}`;
  const parsedSeconds =
    seconds >= 10 ? seconds.toFixed(2) : `0${seconds.toFixed(2)}`;
  const formattedSeconds = parsedSeconds.split(".").join(":");
  // if (hours === 0) {
  //   return `${fMinutes}:${formattedSeconds}`;
  // }
  return `${fHours}:${fMinutes}:${formattedSeconds}`;
};

export const timeValidator = (value: string, duration: number): boolean => {
  const data = value.split(":");
  if (data.length !== 4) return false;
  if (!data[0] || !data[1] || !data[2] || !data[3]) {
    return false;
  }
  if (
    isNaN(parseInt(data[0])) ||
    isNaN(parseInt(data[1])) ||
    isNaN(parseFloat(data[2])) ||
    isNaN(parseFloat(data[3]))
  ) {
    return false;
  }
  const result =
    parseInt(data[0]) * SECONDS_IN_HOUR +
    parseInt(data[1]) * SECONDS_IN_MINUTES +
    parseFloat(data[2]) +
    parseFloat(data[3]) / 100;
  return result < duration && result >= 0;
};
export const deFormat = (value: string): number => {
  const data = value.split(":");
  if (!data[0] || !data[1] || !data[2] || !data[3]) {
    return 0;
  }
  const result =
    parseInt(data[0]) * SECONDS_IN_HOUR +
    parseInt(data[1]) * SECONDS_IN_MINUTES +
    parseFloat(data[2]) +
    parseFloat(data[3]) / 100;
  return result;
};
export const translateClipDurationToWidth = (
  timelineWidth: number,
  videoDuration: number,
  start: number,
  end: number
): number => {
  const diff = end - start;
  return (timelineWidth / videoDuration) * diff;
};
export const translateMarkerTimeToLeft = (
  timelineWidth: number,
  duration: number,
  markerTime: number
): number => {
  return (timelineWidth / duration) * markerTime;
};

export function captureVideoFrame(
  // link to HTMLVideoElement
  htmlVideoTag?: any,
  format = "jpeg",
  quality = 0.12
) {
  if (!htmlVideoTag) {
    return { blob: "", dataUri: "", format: "" };
  }

  try {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = htmlVideoTag.videoWidth;
    canvas.height = htmlVideoTag.videoHeight;
    canvas.getContext("2d")?.drawImage(htmlVideoTag, 0, 0);
    const dataUri = canvas.toDataURL("image/" + format, quality);
    const data = dataUri.split(",")[1];
    const mimeType = dataUri.split(";")[0].slice(5);

    const bytes = window.atob(data);
    const buf = new ArrayBuffer(bytes.length);
    let arr = new Uint8Array(buf);

    for (let i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: mimeType });
    return { blob, dataUri, format };
  } catch (e) {
    console.error("captureVideoFrame error::::", e);
  }
  return { blob: "", dataUri: "", format: "" };
}

export const formatSize = (size: number) => {
  switch (true) {
    case size < 1000:
      return `${size} Bytes`;
    case size >= 1000 && size < 1000000:
      return `${(size / 1000).toFixed(1)} Kb`;
    case size >= 1000000 && size < 1000000000:
      return `${(size / 1000000).toFixed(1)} Mb`;
    default:
      return "";
  }
};
