import { makeObservable, observable, ObservableMap } from "mobx";
import { ToastOptions } from "react-toastify";
import axios from "axios";

import { Types } from "@shared";

export class BucketClient {
  loadings: Types.ObservableProgressMap;
  errors: ObservableMap<string, string>;
  constructor() {
    this.loadings = observable.map<string, Types.IProgress>();
    this.errors = observable.map<string, string>();
    makeObservable(this, { loadings: true, errors: true });
  }

  putToBucket = async (
    input: Types.IPutToS3Params,
    showToast: (msg: string, options?: ToastOptions) => void
  ) => {
    await axios
      .request({
        method: "put",
        url: input.path,
        data: input.body,
        onUploadProgress: (p) => {
          const progress = (p.loaded / p.total) * 100;
          this.loadings.set(input.id, {
            id: input.id,
            progress,
          });
        },
      })
      .catch((e) => {
        showToast(e, { type: "error" });
        this.onError(e, input.id);
      });
  };

  onError = (error: string, id: string) => {
    this.errors.set(id, error);
  };
}
