import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { observer } from "mobx-react";
import { Theme } from "@shared";
import { ITSInput } from "../types";
import { InputWrapper } from "./ts.input.wrapper";
import { InputField } from "./ts.input.field";
import { IconWrapper } from "./ts.input.iconWrapper";
import { IconPlus } from "./ts.icon.plus";

export const Input: FC<ITSInput> = observer(({ placeholder, type }) => {
  const theme = Theme.useStyledTheme();
  const [value, setValue] = useState<string>("");
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length < 32) {
        setValue(e.target.value);
      }
    },
    [setValue]
  );
  const { setFieldValue, values, submitForm } = useFormikContext<any>();
  const onAdd = useCallback(async () => {
    if (value === "") return;
    const isExisting = values.tags.find(
      (element: string) => element.toLowerCase() === value.toLowerCase().trim()
    );
    if (!isExisting) {
      setFieldValue("tags", [...values.tags, value.trim()]);
      await submitForm();
      setValue("");
    } else {
      setValue("");
    }
  }, [value, values.tags, setFieldValue, submitForm]);

  const fill = useMemo(
    () => (value.length ? theme.palette.primaryBlue : theme.palette.gray),
    [theme.palette.gray, theme.palette.primaryBlue, value.length]
  );

  return (
    <InputWrapper>
      <InputField
        borderColor={theme.palette.primaryBlue}
        borderRadius={theme.spacing.sm}
        paddingHorizontal={theme.spacing.md}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
      />
      <IconWrapper onClick={onAdd} right={theme.spacing.md}>
        <IconPlus
          fill={fill}
          width={`${theme.spacing.md}px`}
          height={`${theme.spacing.md}px`}
        />
      </IconWrapper>
    </InputWrapper>
  );
});
