import { observer } from "mobx-react";

import { Types, SharedComponents, Theme, Types as SharedTypes } from "@shared";
import { IconButton } from "@material-ui/core";
import { useState } from "react";

interface IProjectFile {
  item: Types.IFile;
  onDelete: (id: string, name: string) => void;
  hasAccess: boolean;
}

export const ProjectFile = observer(
  ({ item, onDelete, hasAccess }: IProjectFile) => {
    const {
      spacing: { sm, md },
    } = Theme.useStyledTheme();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <SharedComponents.Modal
          isOpen={open}
          cancelText={"No"}
          onCancel={handleClose}
          onConfirm={() => {
            handleClose();
            onDelete(item.id, item?.title || "");
          }}
          confirmText={"Yes"}
          type={SharedTypes.ModalTypes.DEFAULT}
          title={"Do you want to delete this file ?"}
        />
        <SharedComponents.Row>
          <SharedComponents.Icons.IconProjectFile
            width={"18px"}
            height={"24px"}
          />
          <SharedComponents.HorizontalBox width={md} />
          <SharedComponents.Redirect href={item?.rawPath}>
            <SharedComponents.Column justifyContent={"space-between"}>
              <SharedComponents.Text
                noSelection
                text={item.name}
                type={"button"}
              />
              <SharedComponents.VerticalBox height={md} />
              <SharedComponents.Row>
                <SharedComponents.Text
                  noSelection
                  text={`${item.ownerEmail}:`}
                  type={"xSmall"}
                />
                <SharedComponents.HorizontalBox width={sm} />
                <SharedComponents.Text
                  noSelection
                  text={item.createdAt}
                  type={"xSmall"}
                />
              </SharedComponents.Row>
            </SharedComponents.Column>
          </SharedComponents.Redirect>
          <SharedComponents.HorizontalBox width={md} />
          {hasAccess && (
            <IconButton onClick={handleClickOpen}>
              <SharedComponents.Icons.IconDelete />
            </IconButton>
          )}
        </SharedComponents.Row>
      </>
    );
  }
);
