import { FC, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { computed } from "mobx";

import { Types, VideoAnalyticsStore } from "@videoAnalytics";
import {
  SharedComponents,
  Utils,
  SharedStore,
  Types as SharedTypes,
} from "@shared";

import { ICreateClipValues, IFormInner } from "./types";
import { CREATE_CLIP_STEPS } from "./mock";
import * as Pages from "./pages";

import { validationSchema } from "./formik_data";

const FormInner: FC<IFormInner> = observer(({ projectId, onSubmit }) => {
  const {
    data: { successes },
  } = VideoAnalyticsStore;
  const {
    data: { setRequestReset },
  } = SharedStore;

  const navigate = useNavigate();
  const isSuccess = Utils.useQueryStatus(
    successes,
    Types.Endpoints.UPDATE_CLIPS_VIDEO
  );

  const onBack = useCallback(() => {
    setRequestReset(Types.Endpoints.UPDATE_CLIPS_VIDEO);
    navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}`);
  }, [navigate]);

  return (
    <>
      <SharedComponents.Modal
        Icon={SharedComponents.Icons.IconSuccess}
        type={SharedTypes.ModalTypes.SUCCESS}
        isOpen={isSuccess}
        onConfirm={onBack}
        confirmText={"GO TO PROJECT PAGE"}
        title={"The summary has been updated"}
      />
      <SharedComponents.Stepper
        paddingRight={124}
        title={"Create summary"}
        steps={CREATE_CLIP_STEPS}
      >
        <Pages.SummaryDetailsPage projectId={projectId} />
        <Pages.ChooseVideosPage projectId={projectId} />
        <Pages.CreateClipsPage />
        <Pages.ChangeClipsOrder projectId={projectId} />
      </SharedComponents.Stepper>
    </>
  );
});

function compare(a: any, b: any) {
  if (a.precedence < b.precedence) {
    return -1;
  }
  if (a.precedence > b.precedence) {
    return 1;
  }
  return 0;
}

export const EditSummary = observer(() => {
  const { projectId, videoId } = useParams();

  const fProjectId = useMemo(() => projectId || "", [projectId]);
  const fVideoId = useMemo(() => videoId || "", [videoId]);

  const {
    getVideoClips,
    data: {
      setRequestReset,
      loadings,
      errors,
      videoClips,
      selectedProject,
      videosDetails,
    },
    getVideosDetails,
    getProject,
    updateClipsVideo,
  } = VideoAnalyticsStore;

  const projectVideosIds = computed(() =>
    selectedProject?.videos.map((el) => el.id)
  ).get();

  const hasVideoClipsLoading = Utils.useQueryStatus(
    loadings,
    SharedTypes.Endpoints.VIDEO_CLIPS
  );
  const hasVideosDetailsLoading = Utils.useQueryStatus(
    loadings,
    Types.Endpoints.VIDEOS_DETAILS
  );
  const hasVideoClipsError = Utils.useQueryStatus(
    errors,
    SharedTypes.Endpoints.VIDEO_CLIPS
  );
  const hasVideosDetailsError = Utils.useQueryStatus(
    errors,
    Types.Endpoints.VIDEOS_DETAILS
  );

  const onSubmit = (values: ICreateClipValues) => {
    const input = {
      id: videoId || "",
      thumbnail: (values.clips[0] && values.clips[0].dataUri) || "",
      summary: values.summary,
      name: values.name,
      clips: values.clips
        .map((el) => ({
          fileId: el.fileId,
          startTime: Utils.formatTime(el.from) + "0",
          endTime: Utils.formatTime(el.to) + "0",
          thumbnail: el.dataUri,
        }))
        .filter((c) => projectVideosIds?.includes(c.fileId)),
    };
    updateClipsVideo(input);
  };

  useEffect(() => {
    getVideoClips(fVideoId);
    getVideosDetails(fProjectId);
    getProject(fProjectId);
    return () => {
      setRequestReset(Types.Endpoints.UPDATE_CLIPS_VIDEO);
      setRequestReset(Types.Endpoints.VIDEOS_DETAILS);
      setRequestReset(Types.Endpoints.VIDEO_DETAILS);
      setRequestReset(Types.Endpoints.PROJECT_DETAILS);
    };
  }, [fProjectId, fVideoId]);

  if (hasVideoClipsLoading || hasVideosDetailsLoading) {
    return (
      <SharedComponents.Column
        height={"100vh"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <SharedComponents.LoadingIndicator />
      </SharedComponents.Column>
    );
  }

  if (hasVideosDetailsError || hasVideoClipsError || !videoClips) {
    return (
      <SharedComponents.ErrorIndicator
        title={"Error while getting video clip details"}
        onReload={() => getVideoClips(projectId || "")}
      />
    );
  }

  const initialValues = {
    name: videoClips.name,
    summary: videoClips.summary,
    search: "",
    chosenVideos: videoClips.videos.map((video) => {
      const item = videosDetails?.find((el) => el.id === video.id);
      return {
        active: true,
        createdAt: item?.createdAt,
        disabledAt: item?.disabledAt,
        id: video.id,
        name: video.name,
        ownerEmail: item?.ownerEmail,
        processedAt: item?.processedAt,
        processedPath: item?.processedPath,
        projectName: item?.projectName,
        rawPath: item?.rawPath,
        status: "SUCCEEDED",
        summary: "",
        thumbnail: item?.thumbnail,
      };
    }),
    clips: videoClips.videos
      .reduce((acc: any, curr) => {
        const clips = curr.clips.map((clip, index) => {
          return {
            dataUri: clip.thumbnail,
            id: clip.startTime + clip.endTime + index,
            from: Utils.deFormat(clip.startTime.slice(0, -1)),
            to: Utils.deFormat(clip.endTime.slice(0, -1)),
            videoName: curr.name,
            clipName: `${curr.name
              .split(" ")
              .slice(0, 4)
              .join(" ")}: ${clip.startTime.slice(0, -4)}-${clip.endTime.slice(
              0,
              -4
            )}`,
            fileId: curr.id,
            precedence: clip.precedence,
          };
        });
        return [...acc, ...clips];
      }, [])
      .sort(compare),
    timelines: [
      videoClips.videos.map((el) => ({
        id: el.id,
        time: 0,
        positionX: 0,
      })),
    ],
  };

  return (
    <SharedComponents.Screen>
      <SharedComponents.FormikLib.Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <FormInner projectId={projectId || ""} onSubmit={onSubmit} />
      </SharedComponents.FormikLib.Form>
    </SharedComponents.Screen>
  );
});
