import { SVGProps } from "react";

export const IconTextarea = ({
  fill = "#808B9F",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.914a2 2 0 0 0-1.414.586l-2 2V2a1 1 0 0 1 1-1h12Zm-12-1a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.914 12H14.5a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-12Z"
      fill={fill}
    />
    <path
      d="M5.5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      fill={fill}
    />
  </svg>
);
