import styled from "styled-components";
import { TOPBAR_SIZE } from "../../../../constants";
import { IMenuItemWrapper } from "../../types";

export const MenuItemWrapper = styled.div<IMenuItemWrapper>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.md}px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.palette.secondaryDarkBlue : ""};
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${TOPBAR_SIZE}px;
  border-left: 2px solid transparent;
  ${({ isActive, theme }) =>
    isActive && `border-left: 2px solid ${theme.palette.primaryWhite}`}

  &:hover {
    background: ${({ theme }) => theme.palette.secondaryDarkBlue};
  }
`;
