import { SVGProps } from "react";

export const IconArrowDown = ({
  fill = "#000",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.846 0a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708l3.146 3.147V.5a.5.5 0 0 1 .5-.5Z"
      fill={fill}
    />
  </svg>
);
