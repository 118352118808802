import * as React from "react";

export function IconWords(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.942 12.468a.906.906 0 10-1.758.439l2.718 10.875a.907.907 0 001.753.018L11.5 17.04l1.845 6.76a.906.906 0 001.753-.017l2.719-10.875a.906.906 0 00-1.759-.439l-1.877 7.511-1.807-6.625a.906.906 0 00-1.748 0L8.82 19.981 6.942 12.47v-.002z"
        fill="#808B9F"
      />
      <path
        d="M22.375 25.375V8.156L14.219 0H4.25A3.625 3.625 0 00.625 3.625v21.75A3.625 3.625 0 004.25 29h14.5a3.625 3.625 0 003.625-3.625zM14.219 5.437a2.719 2.719 0 002.719 2.72h3.625v17.218a1.812 1.812 0 01-1.813 1.813H4.25a1.813 1.813 0 01-1.813-1.813V3.625A1.812 1.812 0 014.25 1.812h9.969v3.625z"
        fill="#808B9F"
      />
    </svg>
  );
}
