import { FC } from "react";

import * as PCLib from "./lib";
import { IPlaylistCard } from "./types";

import src from "./assets/tumbnail.jpg";

import { SharedComponents, Theme } from "@shared";

export const PlaylistCard: FC<IPlaylistCard> = ({ numberOfVideos, title }) => {
  const theme = Theme.useStyledTheme();
  return (
    <PCLib.Container>
      <PCLib.Img src={src} />
      <PCLib.InfoWrapper>
        <PCLib.TitleWrapper>
          <SharedComponents.Text type={"h2"} text={"Project1"} />
          <PCLib.VideoCounterWrapper>
            <SharedComponents.Icons.IconCopy
              style={{ marginRight: `${theme.spacing.sm}px` }}
            />
            <SharedComponents.Text type={"xSmall"} text={"Videos:"} />
            <SharedComponents.Text
              type={"xSmall"}
              text={numberOfVideos}
              color={theme.palette.primaryBlue}
            />
          </PCLib.VideoCounterWrapper>
        </PCLib.TitleWrapper>
        <SharedComponents.Text type={"paragraph"} text={"morris.bm@pg.com"} />
        <SharedComponents.Text
          type={"paragraph"}
          text={"24/12/2020 5:13:02 PM"}
        />
        <SharedComponents.Link
          to={`/playlists/${title}`}
          // justifyContent={"flex-end"}
        >
          <SharedComponents.Text
            type={"h3"}
            text={"View full playlist"}
            color={theme.palette.primaryBlue}
          />
          <SharedComponents.Icons.IconArrowRight />
        </SharedComponents.Link>
      </PCLib.InfoWrapper>
    </PCLib.Container>
  );
};
