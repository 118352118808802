import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as _ from "lodash";

import {
  SharedComponents,
  Theme,
  Pages,
  Types as SharedTypes,
  Types,
  SharedStore,
} from "@shared";
import { VideoAnalyticsStore } from "@videoAnalytics";

import * as AddProjectComponents from "../../components";

import * as PDLib from "./components";

interface IProjectDetailsPage {
  projectId: string;
}

export const ProjectDetailsPage = observer(
  ({ projectId }: IProjectDetailsPage) => {
    const [isBackModalOpen, setBackModalOpen] = useState(false);
    const {
      spacing: { sm, lg },
    } = Theme.useStyledTheme();

    const {
      searchUsers,
      data: { userEmails, selectedProject },
    } = VideoAnalyticsStore;
    const {
      data: { loadings },
    } = SharedStore;

    const navigate = useNavigate();
    const { currentStep, setCurrentStep } =
      SharedComponents.useStepperContext();
    const onBack = useCallback(() => {
      navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}`);
    }, [navigate]);
    const onBackHandler = useCallback(() => {
      setBackModalOpen(true);
    }, [setBackModalOpen]);
    const onNextHandler = useCallback(
      () => setCurrentStep(currentStep + 1),
      [setCurrentStep, currentStep]
    );

    const debouncedSearch = _.debounce(
      (text: string) => searchUsers(text),
      500
    );
    return (
      <Pages.AddProjectStepTemplate
        fields={["name", "summary", "cmk"]}
        nextTitle="Next"
        onPrev={onBackHandler}
        onNext={onNextHandler}
      >
        <SharedComponents.Box>
          <AddProjectComponents.BackModal
            isOpen={isBackModalOpen}
            setOpen={setBackModalOpen}
            onBack={onBack}
            modalText={
              "As you click the back button, the completed fields will be lost\n" +
              "Do you want to continue?"
            }
          />
          <SharedComponents.VerticalBox height={44} />
          <SharedComponents.Text text={"Project owner*"} type={"h3SemiBold"} />
          <SharedComponents.VerticalBox height={sm} />
          <SharedComponents.Text
            text={selectedProject?.ownerEmail}
            type={"h3"}
          />
          <SharedComponents.VerticalBox height={lg} />
          <SharedComponents.FormikLib.ValidatableField
            endpoint={Types.Endpoints.PROJECT_EXISTS}
            loadings={loadings}
            field="name"
            title="Project name*"
            placeholder={"Enter a project name"}
          />
          <SharedComponents.VerticalBox height={lg} />
          <SharedComponents.FormikLib.Field
            placeholder={"Enter a project summary"}
            field="summary"
            title="Project summary*"
            component="textarea"
            height={88}
          />
          <SharedComponents.VerticalBox height={lg} />
          <PDLib.Calendars disabled={false} />
          <SharedComponents.VerticalBox height={lg} />
          <SharedComponents.FormikLib.Field
            placeholder={"Enter a Internal Project Number"}
            field="cmk"
            title="CLP/Study # Associated with the Project*"
            subtitle={
              "If there is no CLP/Study # associated with your video project, please enter “0”"
            }
          />
          <SharedComponents.VerticalBox height={lg} />
          <SharedComponents.FormikLib.Autocomplete
            title="Project members"
            subtitle="Automatic notification is not enabled yet. Please notify your team members about their participation in the project"
            inputField="email"
            optionsField="members"
            onChange={debouncedSearch}
            options={userEmails}
          />
        </SharedComponents.Box>
      </Pages.AddProjectStepTemplate>
    );
  }
);
