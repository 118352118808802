import { useCallback, useMemo } from "react";
import { useField } from "formik";

import { SharedComponents, Pages, Types as SharedTypes } from "@shared";
import { Types } from "@videoAnalytics";

import { OVERSCAN_COUNT, LIST_ITEM_HEIGHT } from "./constants";
import * as CreateClipsComponents from "./components";
import { CreateClipsContext } from "./context";

export const CreateClipsPage = () => {
  const { setCurrentStep, currentStep } = SharedComponents.useStepperContext();
  const [input, ,] = useField<Array<SharedTypes.IProjectVideo>>("chosenVideos");

  const onPrev = useCallback(
    () => setCurrentStep(currentStep - 1),
    [currentStep, setCurrentStep]
  );
  const onNext = useCallback(
    () => setCurrentStep(currentStep + 1),
    [currentStep, setCurrentStep]
  );

  const value: Types.ICreateClipsContextValues = useMemo(
    () => ({
      overscanCount: OVERSCAN_COUNT,
      listItemHeight: LIST_ITEM_HEIGHT,
      timelineHeight: 48,
    }),
    []
  );

  return (
    <CreateClipsContext.Provider value={value}>
      <Pages.AddProjectStepTemplate
        onPrev={onPrev}
        onNext={onNext}
        fields={["clips"]}
      >
        <SharedComponents.Box
          width={"100%"}
          style={{ height: input.value.length * LIST_ITEM_HEIGHT }}
        >
          {input.value.map((item, index: number) => (
            <CreateClipsComponents.ListItem
              index={index}
              key={item.id}
              item={item}
            />
          ))}
        </SharedComponents.Box>
      </Pages.AddProjectStepTemplate>
    </CreateClipsContext.Provider>
  );
};
