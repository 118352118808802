import styled from "styled-components";
import { IDot } from "../types";

export const Dot = styled.div<IDot>`
  width: ${({ theme }) => theme.spacing.sm}px;
  height: ${({ theme }) => theme.spacing.sm}px;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing.sm}px;
  background: ${({ color }) => color};
`;
