import { createContext, RefObject, useContext } from "react";
import { List } from "react-virtualized";
import ReactPlayer from "react-player";

interface IValue {
  playerRef: RefObject<ReactPlayer>;
  listWrapperRef: RefObject<List>;
  startTime?: string;
}

export const PlayerContext = createContext<IValue | null>(null);

export const usePlayerContext = () => {
  const context = useContext<IValue | null>(PlayerContext);

  if (!context) {
    throw new Error("Player context is not defined");
  }

  return context;
};
