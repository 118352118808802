import * as React from "react";

export function IconFiles(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 0H8C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4C2.93913 4 1.92172 4.42143 1.17157 5.17157C0.421427 5.92172 0 6.93913 0 8V28C0 29.0609 0.421427 30.0783 1.17157 30.8284C1.92172 31.5786 2.93913 32 4 32H18C19.0609 32 20.0783 31.5786 20.8284 30.8284C21.5786 30.0783 22 29.0609 22 28C23.0609 28 24.0783 27.5786 24.8284 26.8284C25.5786 26.0783 26 25.0609 26 24V4C26 2.93913 25.5786 1.92172 24.8284 1.17157C24.0783 0.421427 23.0609 0 22 0V0ZM22 26V8C22 6.93913 21.5786 5.92172 20.8284 5.17157C20.0783 4.42143 19.0609 4 18 4H6C6 3.46957 6.21071 2.96086 6.58579 2.58579C6.96086 2.21071 7.46957 2 8 2H22C22.5304 2 23.0391 2.21071 23.4142 2.58579C23.7893 2.96086 24 3.46957 24 4V24C24 24.5304 23.7893 25.0391 23.4142 25.4142C23.0391 25.7893 22.5304 26 22 26ZM2 8C2 7.46957 2.21071 6.96086 2.58579 6.58579C2.96086 6.21071 3.46957 6 4 6H18C18.5304 6 19.0391 6.21071 19.4142 6.58579C19.7893 6.96086 20 7.46957 20 8V28C20 28.5304 19.7893 29.0391 19.4142 29.4142C19.0391 29.7893 18.5304 30 18 30H4C3.46957 30 2.96086 29.7893 2.58579 29.4142C2.21071 29.0391 2 28.5304 2 28V8Z"
        fill="#808B9F"
      />
    </svg>
  );
}
