import { RefObject, useEffect } from "react";
import { List } from "react-virtualized";
import { observer } from "mobx-react";
import { VideoAnalyticsStore } from "@videoAnalytics";
import { computed } from "mobx";

interface IRefresher {
  listWrapperRef: RefObject<List>;
}

export const Refresher = observer(({ listWrapperRef }: IRefresher) => {
  const {
    data: { videoTranscript, videoTranscriptPlayerProgress },
  } = VideoAnalyticsStore;

  const videoTranscriptTimelines = computed(() =>
    videoTranscript.map((el) => ({
      start: el.startTime,
      end: el.endTime,
    }))
  ).get();

  useEffect(() => {
    if (videoTranscript.length > 0 && listWrapperRef.current) {
      if (
        videoTranscriptPlayerProgress >
        parseFloat(videoTranscript[videoTranscript.length - 1].endTime)
      ) {
        const position = listWrapperRef.current?.getOffsetForRow({
          index: videoTranscript.length - 1,
          alignment: "start",
        });
        listWrapperRef.current?.scrollToPosition(position);
      } else {
        const index = videoTranscriptTimelines.findIndex(
          (el) =>
            videoTranscriptPlayerProgress > parseFloat(el.start) &&
            videoTranscriptPlayerProgress < parseFloat(el.end)
        );
        if (index !== -1) {
          const offset = listWrapperRef.current?.getOffsetForRow({
            index,
            alignment: "start",
          });
          listWrapperRef.current?.scrollToPosition(offset);
        }
      }
    }
  }, [listWrapperRef, videoTranscript.length, videoTranscriptPlayerProgress]);
  return null;
});
