import * as React from "react";
import { Theme } from "@shared";

export function IconClose({ fill, ...rest }: React.SVGProps<SVGSVGElement>) {
  const {
    palette: { gray },
  } = Theme.useStyledTheme();
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.167848 0.167848C0.22092 0.114643 0.283966 0.0724297 0.353377 0.0436275C0.422788 0.0148252 0.497199 0 0.572348 0C0.647497 0 0.721909 0.0148252 0.791319 0.0436275C0.86073 0.0724297 0.923777 0.114643 0.976848 0.167848L4.00031 3.19245L7.02377 0.167848C7.07689 0.114729 7.13996 0.0725919 7.20936 0.0438438C7.27876 0.0150957 7.35315 0.000299128 7.42827 0.000299128C7.5034 0.000299128 7.57778 0.0150957 7.64719 0.0438438C7.71659 0.0725919 7.77965 0.114729 7.83277 0.167848C7.88589 0.220968 7.92803 0.28403 7.95678 0.353434C7.98553 0.422839 8.00032 0.497226 8.00032 0.572348C8.00032 0.647471 7.98553 0.721858 7.95678 0.791262C7.92803 0.860666 7.88589 0.923728 7.83277 0.976848L4.80817 4.00031L7.83277 7.02377C7.88589 7.07689 7.92803 7.13996 7.95678 7.20936C7.98553 7.27876 8.00032 7.35315 8.00032 7.42827C8.00032 7.5034 7.98553 7.57778 7.95678 7.64719C7.92803 7.71659 7.88589 7.77965 7.83277 7.83277C7.77965 7.88589 7.71659 7.92803 7.64719 7.95678C7.57778 7.98553 7.5034 8.00032 7.42827 8.00032C7.35315 8.00032 7.27876 7.98553 7.20936 7.95678C7.13996 7.92803 7.07689 7.88589 7.02377 7.83277L4.00031 4.80817L0.976848 7.83277C0.923728 7.88589 0.860666 7.92803 0.791262 7.95678C0.721858 7.98553 0.647471 8.00032 0.572348 8.00032C0.497226 8.00032 0.422839 7.98553 0.353434 7.95678C0.28403 7.92803 0.220968 7.88589 0.167848 7.83277C0.114729 7.77965 0.0725919 7.71659 0.0438438 7.64719C0.0150957 7.57778 0.000299128 7.5034 0.000299128 7.42827C0.000299128 7.35315 0.0150957 7.27876 0.0438438 7.20936C0.0725919 7.13996 0.114729 7.07689 0.167848 7.02377L3.19245 4.00031L0.167848 0.976848C0.114643 0.923777 0.0724297 0.86073 0.0436275 0.791319C0.0148252 0.721909 0 0.647497 0 0.572348C0 0.497199 0.0148252 0.422788 0.0436275 0.353377C0.0724297 0.283966 0.114643 0.22092 0.167848 0.167848Z"
        fill={fill || gray}
      />
    </svg>
  );
}
