import { FC } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import * as VLLib from "./lib";
import { IVirtualizedList } from "./types";

export const VirtualizedList: FC<IVirtualizedList> = ({
  itemHeight,
  overscanCount,
  listItem,
  layout,
  data,
}) => {
  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <VLLib.VlList
          itemData={data}
          className="List"
          height={height}
          itemCount={data.length}
          itemSize={itemHeight}
          width={width}
          overscanCount={overscanCount}
          layout={layout}
        >
          {listItem}
        </VLLib.VlList>
      )}
    </AutoSizer>
  );
};
