import { useRef } from "react";
import {
  CellMeasurer,
  CellMeasurerCache,
  createMasonryCellPositioner,
  Masonry,
  MasonryCellProps,
} from "react-virtualized";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import { useField } from "formik";

import { SharedComponents, Theme } from "@shared";

import { IChosenVideo } from "../../../../types";

import * as CVLib from "./lib";

const COLUMN_WIDTH = 192;
const SPACER = 16;
const cache = new CellMeasurerCache({
  defaultHeight: 180,
  defaultWidth: COLUMN_WIDTH,
  fixedWidth: true,
  fixedHeight: true,
});
const cellPositioner = createMasonryCellPositioner({
  cellMeasurerCache: cache,
  columnCount: 2,
  columnWidth: COLUMN_WIDTH,
  spacer: SPACER,
});
export const ChosenVideos = () => {
  const {
    spacing: { md },
  } = Theme.useStyledTheme();
  const [input, ,] = useField<Array<IChosenVideo>>("chosenVideos");
  const ref = useRef<Masonry>(null);

  const cellRenderer = ({ index, key, parent, style }: MasonryCellProps) => {
    const item = input.value[index] || undefined;
    const wrapperStyle = {
      ...style,
      paddingBottom: "8px",
      background: "transparent",
    };
    if (!item) {
      return <div key={key} />;
    }
    return (
      <div style={wrapperStyle} key={key}>
        <CellMeasurer cache={cache} index={index} parent={parent}>
          <CVLib.Cell item={item} />
        </CellMeasurer>
      </div>
    );
  };
  const onResize = () => {
    cellPositioner.reset({
      columnCount: 2,
      columnWidth: COLUMN_WIDTH,
      spacer: SPACER,
    });
    cache.clearAll();
    ref.current?.clearCellPositions();
  };
  return (
    <CVLib.Wrapper>
      <SharedComponents.Text text={"Chosen videos"} type={"h3SemiBold"} />
      <SharedComponents.VerticalBox height={md} />
      <AutoSizer disableHeight onResize={onResize}>
        {() => (
          <Masonry
            autoHeight={false}
            ref={ref}
            height={520}
            width={400}
            cellCount={input.value.length}
            cellMeasurerCache={cache}
            cellPositioner={cellPositioner}
            cellRenderer={cellRenderer}
          />
        )}
      </AutoSizer>
    </CVLib.Wrapper>
  );
};
