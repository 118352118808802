import { ObservableMap } from "mobx";
import { Theme } from "@shared";
import AWSAppSyncClient from "aws-appsync";

export interface ITranscript {
  endTimeMs: number;
  language: string;
  startTimeMs: number;
  text: string;
}

export interface IPieChartItem {
  name: string;
  value: number;
  color: string;
  count?: number;
}

export interface ITranscripts {
  fullTranscript: string;
  transcripts: ITranscript;
}

export interface ISurvey {
  title: string;
  date: string;
}

export enum ProjectType {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export enum FilesType {
  FILES = "files",
  VIDEOS = "videos",
}

export enum ModalTypes {
  DEFAULT = 0,
  SUCCESS = 1,
  ERROR = 2,
}

export enum ButtonType {
  CUSTOM,
  OUTLINED,
  FILLED,
  DISABLED,
}

export interface IFile {
  id: string;
  temporaryId?: string;
  fileUrl?: string;
  name: string;
  slug: string;
  projectId: string;
  body?: File;
  ownerEmail?: string;
  path?: string;
  title?: string;
  thumbnail?: string;
  projectName?: string;
  duration?: number;
  createdAt?: string;
  updatedAt?: string;
  disabledAt?: string;
  processedAt?: Date;
  type?: string;
  status?: string;
  active?: boolean;
  // tags: Array<string>;
  fileSize?: string | number;
  rawPath?: string;
}

export type FileType = typeof FilesType.FILES | typeof FilesType.VIDEOS;

export enum Endpoints {
  COST_CENTER_EXIST = "costCenterExist",
  FULL_SEARCH = "fullSearch",
  MESSAGES = "messages",
  PROJECT_DETAILS = "projectDetails",
  PROJECT_EXISTS = "projectExist",
  PROJECT_FORM_METADATA = "projectFormMetadata",
  USER_GLOBAL_ROLE = "userGlobalRole",
  PROJECTS = "projects",
  MORE_PROJECTS = "moreProjects",
  SEARCH_USERS = "searchUsers",
  USER_PROJECTS = "userProjects",
  VIDEO_DETAILS = "videoDetails",
  MORE_USER_PROJECTS = "moreUserProjects",
  USER_ROLE = "userRole",
  VIDEO_EXISTS = "videoExists",
  CREATE_CLIPS_VIDEO = "createClipsVideo",
  CREATE_FILE = "createFile",
  CREATE_PROJECT = "createProject",
  CREATE_VIDEO = "createVideo",
  DELETE_FILE = "deleteFile",
  DELETE_PROJECT = "deleteProject",
  DELETE_VIDEO = "deleteVideo",
  READ_MESSAGE = "readMessage",
  UPDATE_PROJECT = "updateProject",
  UPDATE_VIDEO = "updateVideo",
  GET_USER_INFO = "getUserInfo",
  USER_PROJECTS_STATS = "userProjectsStats",
  PROJECT_STATS = "projectsStats",
  VIDEO_CLIPS = "videoClips",
  UPDATE_CLIPS_VIDEO = "updateClipsVideo",
}

export type IEndpoint =
  | typeof Endpoints.COST_CENTER_EXIST
  | typeof Endpoints.FULL_SEARCH
  | typeof Endpoints.MESSAGES
  | typeof Endpoints.PROJECT_DETAILS
  | typeof Endpoints.PROJECT_EXISTS
  | typeof Endpoints.PROJECT_FORM_METADATA
  | typeof Endpoints.PROJECTS
  | typeof Endpoints.MORE_PROJECTS
  | typeof Endpoints.SEARCH_USERS
  | typeof Endpoints.USER_PROJECTS
  | typeof Endpoints.VIDEO_DETAILS
  | typeof Endpoints.MORE_USER_PROJECTS
  | typeof Endpoints.USER_ROLE
  | typeof Endpoints.VIDEO_EXISTS
  | typeof Endpoints.CREATE_CLIPS_VIDEO
  | typeof Endpoints.CREATE_FILE
  | typeof Endpoints.CREATE_PROJECT
  | typeof Endpoints.CREATE_VIDEO
  | typeof Endpoints.DELETE_FILE
  | typeof Endpoints.DELETE_PROJECT
  | typeof Endpoints.DELETE_VIDEO
  | typeof Endpoints.READ_MESSAGE
  | typeof Endpoints.UPDATE_PROJECT
  | typeof Endpoints.UPDATE_VIDEO
  | typeof Endpoints.GET_USER_INFO
  | typeof Endpoints.USER_PROJECTS_STATS
  | typeof Endpoints.PROJECT_STATS
  | typeof Endpoints.VIDEO_CLIPS
  | typeof Endpoints.UPDATE_CLIPS_VIDEO
  | typeof Endpoints.USER_GLOBAL_ROLE
  | string;

export enum ProjectStatuses {
  PENDING = "PENDING",
  CONFIGURED = "CONFIGURED",
}

export enum PATHS {
  INITIAL = "/",
  HOME = "/",
  VIDEO_ANALYTICS = "/video-analytics",
  SURVEYS = "/surveys",
  TUTORIAL = "/tutorial",
  CONTACT_US = "mailto:vap-support@groups.pg.com?Subject=VAP-Support-Request",
  VIDEO_ANALYTICS_PROJECT = "/video-analytics/project/:projectId",
  VIDEO_ANALYTICS_PROJECT_VIDEO = "/video-analytics/project/:projectId/video/:videoId",
  VIDEO_ANALYTICS_PROJECT_SUMMARY = "/video-analytics/project/:projectId/summary/:videoId",
  VIDEO_ANALYTICS_PROJECT_SUMMARY_EDIT = "/video-analytics/project/:projectId/summary/:videoId/edit",
  VIDEO_ANALYTICS_PROJECT_ADD = "/video-analytics/project/add",
  VIDEO_ANALYTICS_PROJECT_EDIT = "/video-analytics/project/:projectId/edit",
  VIDEO_ANALYTICS_VIDEO = "/video-analytics/project/:projectId/video/:videoId/:startTime",
  VIDEO_ANALYTICS_VIDEO_ONLY = "/video-analytics/video/:videoId",
  VIDEO_ANALYTICS_VIDEO_EDIT = "/video-analytics/project/:projectId/video/:videoId/edit",
  VIDEO_ANALYTICS_ADD_SUMMARY = "/video-analytics/project/:projectId/summary/add",
  VIDEO_ANALYTICS_ADD_VIDEO = "/video-analytics/project/:projectId/video/add",
  VIDEO_ANALYTICS_ADD_FILE = "/video-analytics/project/:projectId/file/add",
}

export interface ITag {
  id: string;
  name: string;
}

export type ProjectsStatus =
  | typeof ProjectStatuses.PENDING
  | typeof ProjectStatuses.CONFIGURED;

export enum UserRole {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  USER = "USER",
  NONE = "NONE",
}

export enum GlobalRole {
  UNDEFINED = "",
  NONE = "NONE",
  PG_USER = "PG_USER",
  PG_ADMIN = "PG_ADMIN",
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
  EBP_USER = "EBP_USER",
  NON_EMP_USER = "NON_EMP_USER",
}

export interface IAWSClientParams {
  awsClient: typeof AWSAppSyncClient;
}

export type GlobalRoleType =
  | typeof GlobalRole.UNDEFINED
  | typeof GlobalRole.NONE
  | typeof GlobalRole.PG_USER
  | typeof GlobalRole.PG_ADMIN
  | typeof GlobalRole.SYSTEM_ADMIN
  | typeof GlobalRole.EBP_USER
  | typeof GlobalRole.NON_EMP_USER;

export type IUserRole =
  | typeof UserRole.USER
  | typeof UserRole.OWNER
  | typeof UserRole.ADMIN
  | typeof UserRole.OWNER;

export enum ProjectVideoStatuses {
  PENDING = "PENDING",
  UPLOADED = "UPLOADED",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}

export type ProjectVideoStatus =
  | ProjectVideoStatuses.PENDING
  | ProjectVideoStatuses.UPLOADED
  | ProjectVideoStatuses.PROCESSING
  | ProjectVideoStatuses.FAILED
  | ProjectVideoStatuses.SUCCEEDED;

export interface IProjectVideo {
  active: boolean;
  createdAt: string;
  disabledAt: string;
  id: string;
  ownerEmail: string;
  processedAt: string;
  projectName: string;
  status: ProjectVideoStatus;
  processedPath: string;
  thumbnail: string;
  summary: string;
  name: string;
  updatedAt: string;
}

export enum BlurMode {
  BlurEyes = "BlurEyes",
  BlurEyesNoseMouth = "BlurEyesNoseMouth",
  BlurForeheadEyesNose = "BlurForeheadEyesNose",
  BlurFace = "BlurFace",
}

export interface IMember {
  id: string;
  email: string;
}

export interface IProject {
  active: boolean;
  id: string;
  description: string;
  name: string;
  ownerEmail: string;
  videosNumber: number;
  slug: string;
  status: ProjectsStatus;
  tags: Array<ITag>;
  taxonomy: string;
  updatedAt: string;
  videos: Array<IProjectVideo>;
  clp: string;
  costCenter: string;
  brands: Array<IMeta>;
  categories: Array<IMeta>;
  countries: Array<IMeta>;
  regions: Array<IMeta>;
  hasConsentFormSignedByParent: boolean;
  hasCostsAgreed: boolean;
  hasDelayedFSDSUpload: boolean;
  hasPii: boolean;
  hasSignedInformedConsent: boolean;
  hasVideoPersonsUnder18: boolean;
  blurMode: BlurMode;
  startDate: string;
  endDate: string;
  members: Array<IMember>;
}

export interface IElasticFilters {
  categories: Array<string>;
  countries: Array<string>;
  regions: Array<string>;
  brands: Array<string>;
}

export interface IProjectsStats {
  membersNumber: number;
  projectsNumber: number;
  videosNumber: number;
  totalLength: string;
}

export interface IProjectsResponse {
  projects: Array<IProject>;
}

export interface IProjectsStatsResponse {
  projectsStats: IProjectsStats;
}

export interface IProjectStats {
  filesNumber: number;
  summariesLength: string;
  summariesNumber: number;
  totalLength: string;
  videosLength: string;
  videosNumber: number;
}

export interface IMeta {
  id: string;
  name: string;
}

export interface IMember {
  email: string;
  id: string;
}

export interface ITransformation {
  description: string;
  id: string;
  name: string;
}

export interface IProjectFile {
  active: boolean;
  createdAt: string;
  disabledAt: string;
  fileUrl: string;
  ownerEmail: string;
  id: string;
  projectName: string;
  slug: string;
  status: ProjectsStatus;
  thumbnail: string;
  title: string;
}

export interface IEmotion {
  color: string;
  count: number;
  percent: number;
}

export interface IBasicEmotions {
  negative: IEmotion;
  neutral: IEmotion;
  positive: IEmotion;
}

export interface IDetailedEmotions extends IBasicEmotions {
  angry: IEmotion;
  calm: IEmotion;
  confused: IEmotion;
  disgusted: IEmotion;
  fear: IEmotion;
  happy: IEmotion;
  sad: IEmotion;
  surprised: IEmotion;
}

export interface IProjectEmotions {
  basic: IBasicEmotions;
  detailed: IDetailedEmotions;
}

export interface IProjectDetails {
  updatedAt: string;
  taxonomy: string;
  stats: IProjectStats;
  status: ProjectsStatus;
  FSDSUploadDate: string;
  active: boolean;
  clp: string;
  consentFormLink: string;
  costCenter: string;
  countries: Array<ICountry>;
  description: string;
  endDate: string;
  hasCostsAgreed: boolean;
  hasConsentFormSignedByParent: boolean;
  hasPii: boolean;
  hasSignedInformedConsent: boolean;
  hasVideoPersonsUnder18: boolean;
  id: string;
  hasDelayedFSDSUpload: boolean;
  name: string;
  ownerEmail: string;
  slug: string;
  startDate: string;
  videos: Array<IProjectVideo>;
  regions: Array<IMeta>;
  brands: Array<IMeta>;
  categories: Array<IMeta>;
  tags: Array<IMeta>;
  members: Array<IMember>;
  transformations: Array<ITransformation>;
  files: Array<IProjectFile>;
  emotions: IProjectEmotions;
  summaries: Array<IProjectVideo>;
}

export interface IVideoStats {
  totalWordsNumber: number;
  videoLength: string;
}

export interface ITranscriptEmotion {
  color: string;
  percent: number;
}

export interface ITranscriptEmotions {
  sentiment: string;
  sentimentScore: {
    mixed: ITranscriptEmotion;
    negative: ITranscriptEmotion;
    neutral: ITranscriptEmotion;
    positive: ITranscriptEmotion;
  };
}

export interface IVideoDetailsBasic {
  id: string;
  processedPath: string;
}

export interface IVideoDetails extends IVideoDetailsBasic {
  active: boolean;
  createdAt: string;
  disabledAt: string;
  fileUrl: string;
  ownerEmail: string;
  processedAt: string;
  projectName: string;
  slug: string;
  rawPath: string;
  emotions: IProjectEmotions;
  transcriptTranslation: string;
  stats: IVideoStats;
  status: ProjectVideoStatus;
  summary: string;
  tags: Array<IMeta>;
  thumbnail: string;
  title: string;
  transcriptEmotions: ITranscriptEmotions;
  transcripts: ITranscripts;
  updatedAt: string;
}

export interface IVideoDetailsResponse {
  videoDetails: IVideoDetails;
}

export interface IVideosDetailsResponse {
  videosDetails: Array<IVideoDetailsBasic>;
}

export interface IProjectDetailsResponse {
  projectDetails: IProjectDetails;
}

export interface IUserProjectsResponse {
  userProjects: Array<IProject>;
}

export interface IFullSearchResponse {
  fullSearch: {
    projects: Array<IProject>;
    stats: IProjectsStats;
  };
}
export interface IUserProjectStatsResponse {
  userProjectsStats: IProjectsStats;
}

export interface ISummaryClip {
  endTime: string;
  precedence: string;
  startTime: string;
  thumbnail: string;
}

export interface ISummaryVideo {
  id: string;
  title: string;
  videoUrl: string;
  clips: Array<ISummaryClip>;
}

export interface IVideoClips {
  projectId: string;
  summary: string;
  title: string;
  videos: Array<ISummaryVideo>;
}

export interface IVideoClipsResponse {
  videoClips: IVideoClips;
}

export enum EntityType {
  PROJECTS = "projects",
  FILES = "files",
}

export interface IS3File {
  meta: File;
  rawId: string;
  id: string;
  rawPath: string;
  projectId: string;
  duration?: number;
}

export type ObservableProgressMap = ObservableMap<string, IProgress>;

export interface IProgress {
  id: string;
  progress: number;
}

export interface IPutToS3Params {
  body: File;
  path: string;
  id: string;
  type: FilesType;
}

export interface IRouteIcons {
  inactiveIcon: React.FC;
  activeIcon: React.FC;
}

export interface INavbarSectionConfig {
  data: Array<IRoute>;
  title?: string;
}

export interface IRoute {
  path: string;
  element: JSX.Element;
  icons?: IRouteIcons;
  external?: boolean;
  name: string;
  breadcrumb?: string;
}

export type RotateType = 0 | 180;

export interface IDropdownItem {
  title: string;
  titleColor: string;
  fontType?: Theme.TypographyType;
  onClick: () => void;
}

export interface IDropdown {
  data: Array<IDropdownItem>;
}

export type IModuleType = "link" | "module";

export interface IModule {
  type: IModuleType;
  title: string;
  content: JSX.Element;
  src: string;
  path: string;
}

export interface ISection {
  title: string;
  data: Array<IModule>;
}

export interface IRouteIcons {
  inactiveIcon: React.FC;
  activeIcon: React.FC;
}

export interface IMeta {
  id: string;
  name: string;
}

export interface IBrand extends IMeta {
  parentId: string;
}

export interface ICategory extends IMeta {}

export interface IRegion extends IMeta {}

export interface ICountry extends IMeta {
  parentId: string;
}

export interface ITaxonomy {
  brands: Array<IBrand>;
  categories: Array<ICategory>;
  countries: Array<ICountry>;
  regions: Array<IRegion>;
}

export interface IAdvancedFiltersItem {
  name: string;
  data: Array<IMeta>;
  field: string;
}
