import { observer } from "mobx-react";

import { RefObject, useCallback, useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import { computed } from "mobx";

import { VideoAnalyticsStore } from "@videoAnalytics";
import { SharedComponents, Theme } from "@shared";

import { usePlayerContext } from "../../context";

interface ITranscriptPlayer {
  isRaw: boolean;
  hasAccess: boolean;
  height: number;
  playerRef: RefObject<ReactPlayer>;
}

export const TranscriptPlayer = observer(
  ({ isRaw, hasAccess, height, playerRef }: ITranscriptPlayer) => {
    const {
      data: { videoDetails, setVideoTranscriptPlayerProgress },
    } = VideoAnalyticsStore;

    const {
      palette: { text },
    } = Theme.useStyledTheme();

    // const speakers: Array<string> = computed(() => {
    //   if (!videoDetails) return [];
    //   return videoDetails?.transcripts?.emotions?.tracked?.parts?.reduce(
    //     (acc: Array<string>, curr) => {
    //       curr.sentences.forEach((el) => {
    //         if (el.speakerId !== null && !acc.includes(el.speakerId)) {
    //           acc.push(el.speakerId);
    //         }
    //       });
    //       return acc;
    //     },
    //     []
    //   );
    // }).get();

    // const speakersOutput: Array<Types.ITranscriptsOutputItem> = computed(() => {
    //   const parts = videoDetails?.transcripts?.emotions?.tracked?.parts;
    //   if (!parts || parts.length === 0 || !parts[0]) {
    //     return [];
    //   }
    //   const firstItem = parts[0].sentences[0];
    //   if (!firstItem || !firstItem.startTime || !firstItem.endTime) {
    //     return [];
    //   }
    //   const lastItem = parts[parts.length - 1].sentences[0];
    //   if (!lastItem || !lastItem.startTime || !lastItem.endTime) {
    //     return [];
    //   }
    //   const scale =
    //     parseFloat(firstItem.endTime) - parseFloat(firstItem.startTime);
    //   const totalTime = parseFloat(lastItem.endTime);
    //   let mappedParts: Map<
    //     string,
    //     {
    //       speakerId: string;
    //       timelines: Map<number, Types.ITranscriptsOutputTimelineItem>;
    //     }
    //   > = new Map();
    //   speakers.forEach((speakerId) =>
    //     mappedParts.set(speakerId, {
    //       speakerId,
    //       timelines: new Map<number, Types.ITranscriptsOutputTimelineItem>(),
    //     })
    //   );
    //   console.log(toJS(parts));
    //   parts.forEach((part) => {
    //     part.sentences.forEach((sentence) => {
    //       // console.log(toJS(sentence));
    //       if (sentence.speakerId === null) return;
    //       mappedParts
    //         .get(sentence.speakerId)
    //         ?.timelines.set(parseFloat(sentence.startTime), {
    //           startTime: parseFloat(sentence.startTime),
    //           endTime: parseFloat(sentence.endTime),
    //           emotion: parseFloat(
    //             (
    //               (sentence.sentiment.positive.percent -
    //                 sentence.sentiment.negative.percent) *
    //               100
    //             ).toPrecision(5)
    //           ),
    //           text: sentence.text,
    //         });
    //     });
    //   });
    //   const mappedPartsAsArray = [...mappedParts.values()];
    //
    //   let formattedOutput: Array<Types.ITranscriptsOutputItem> = [];
    //
    //   for (let i = 0; i < mappedPartsAsArray.length; i++) {
    //     const t: Array<Types.ITranscriptsOutputTimelineItem> = [];
    //     for (let j = 0; j < totalTime / scale; j++) {
    //       const emptyItem = {
    //         startTime: scale * j,
    //         endTime: scale * (j + 1),
    //         text: "",
    //         emotion: 0,
    //       };
    //       if (mappedPartsAsArray[i].timelines.has(scale * j)) {
    //         const item = mappedPartsAsArray[i].timelines.get(scale * j);
    //         if (item) {
    //           t.push(item);
    //         } else {
    //           t.push(emptyItem);
    //         }
    //       } else {
    //         t.push(emptyItem);
    //       }
    //     }
    //     formattedOutput.push({
    //       speakerId: mappedPartsAsArray[i].speakerId,
    //       timelines: t,
    //     });
    //   }
    //   return formattedOutput;
    // }).get();
    /**
     * Video player context
     */
    const { startTime } = usePlayerContext();
    /**
     * Video player seek to timestamp
     */
    const seekTo = useCallback(
      (amount: number) => {
        if (amount === 0) return;
        if (playerRef.current && playerRef.current.seekTo) {
          playerRef.current?.seekTo(amount, "seconds");
        }
      },
      [playerRef]
    );

    const url = computed(() => {
      if (hasAccess && videoDetails?.rawPath !== "" && isRaw) {
        return videoDetails?.rawPath;
      }
      return videoDetails?.processedPath || "";
    }).get();

    const isValidStartTime = useMemo(
      () => startTime !== undefined && !isNaN(parseFloat(startTime)),
      [startTime]
    );

    const [isPlaying, setPlaying] = useState(isValidStartTime);

    useEffect(() => {
      if (isValidStartTime) {
        startTime && seekTo(parseFloat(startTime));
        setPlaying(true);
      }
    }, [startTime]);

    return (
      <SharedComponents.Column padding={"0 8px 0 0"}>
        <SharedComponents.Box background={text} height={`${height}px`}>
          <div>
            <SharedComponents.Column>
              <ReactPlayer
                playing={isPlaying}
                config={{
                  file: {
                    attributes: {
                      onContextMenu: (e: any) => e.preventDefault(),
                      controlsList: "nodownload",
                    },
                  },
                }}
                width="100%"
                onPlay={() => {
                  setPlaying(true);
                }}
                onPause={() => {
                  setPlaying(false);
                }}
                controls={true}
                height={height}
                ref={playerRef}
                url={url}
                onProgress={(state) =>
                  setVideoTranscriptPlayerProgress(state.playedSeconds)
                }
              />
            </SharedComponents.Column>
            <SharedComponents.HorizontalBox width={16} />
          </div>
        </SharedComponents.Box>
        <SharedComponents.VerticalBox height={32} />
        {/*<SharedComponents.Box maxWidth={"600px"}>*/}
        {/*  {speakersOutput.map((speaker, index: number) => (*/}
        {/*    <TimelineBar*/}
        {/*      index={index}*/}
        {/*      seekTo={seekTo}*/}
        {/*      key={speaker.speakerId}*/}
        {/*      item={speaker}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</SharedComponents.Box>*/}
      </SharedComponents.Column>
    );
  }
);
