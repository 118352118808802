import { useField } from "formik";
import { FC, memo, useCallback } from "react";
import { SharedComponents, Utils } from "@shared";
import { useStyledTheme } from "../../../../theme";
import { Column, Row } from "../../../containers";
import { HorizontalBox } from "../../../horizontal_box";
import { VerticalBox } from "../../../vertical_box";
import { Text } from "../../../text";
import { IFormikRadioGroup } from "./types";

export const RadioGroup: FC<IFormikRadioGroup> = memo(({ field, title }) => {
  const {
    spacing: { sm },
    palette: { primaryRed, text, primaryBlue },
  } = useStyledTheme();
  const [input, meta, helpers] = useField(field);

  const onPositiveHandle = useCallback(() => helpers.setValue(true), [helpers]);
  const onNegativeHandle = useCallback(
    () => helpers.setValue(false),
    [helpers]
  );

  return (
    <Column>
      <Text text={title} type="h3SemiBold" />
      <VerticalBox height={sm} />
      <Row alignItems={"center"}>
        <input
          type="radio"
          value="Yes"
          // input.value: true|false|undefined
          checked={input.value}
          onKeyDown={Utils.onKeyDown(onPositiveHandle)}
          onChange={onPositiveHandle}
        />
        <HorizontalBox width={sm} />
        <Text
          text="Yes"
          type={input.value ? "button" : "small"}
          color={input.value ? primaryBlue : text}
          onKeyDown={onPositiveHandle}
        />
      </Row>
      <Row alignItems={"center"}>
        <input
          type="radio"
          value="No"
          // input.value: true|false|undefined
          checked={input.value === false}
          onChange={onNegativeHandle}
          onKeyDown={Utils.onKeyDown(onNegativeHandle)}
        />
        <HorizontalBox width={sm} />
        <Text
          text="No"
          type={input.value === false ? "button" : "small"}
          color={input.value === false ? primaryBlue : text}
          onKeyDown={onNegativeHandle}
        />
      </Row>
      {meta.error && meta.touched && (
        <SharedComponents.Text
          text={meta.error}
          type={"small"}
          color={primaryRed}
        />
      )}
    </Column>
  );
});
