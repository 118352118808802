import { SVGProps } from "react";

export const IconEmptySurvey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={442}
    height={208}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      opacity={0.2}
      x={24.5}
      y={24.5}
      width={393}
      height={39}
      rx={4.5}
      fill="#808B9F"
      stroke="#808B9F"
    />
    <rect
      x={48.5}
      y={80.5}
      width={23}
      height={23}
      rx={1.5}
      fill="#fff"
      stroke="#808B9F"
    />
    <path
      d="M68.406 86.22a.753.753 0 0 1 0 1.062l-10.5 10.5a.749.749 0 0 1-1.063 0l-5.25-5.25a.751.751 0 1 1 1.063-1.062l4.718 4.72 9.97-9.97a.75.75 0 0 1 1.062 0Z"
      fill="#808B9F"
    />
    <rect
      x={80.5}
      y={80.5}
      width={194}
      height={22}
      rx={4.5}
      fill="#F0F1F4"
      stroke="#F0F1F4"
    />
    <rect
      x={48.5}
      y={120.5}
      width={23}
      height={23}
      rx={1.5}
      fill="#fff"
      stroke="#808B9F"
    />
    <path
      d="M68.406 126.22a.759.759 0 0 1 .22.531.751.751 0 0 1-.22.531l-10.5 10.5a.747.747 0 0 1-1.062 0l-5.25-5.25a.748.748 0 0 1 0-1.062.754.754 0 0 1 1.062 0l4.718 4.721 9.97-9.971a.75.75 0 0 1 1.062 0Z"
      fill="#808B9F"
    />
    <rect
      x={80.5}
      y={120.5}
      width={303}
      height={22}
      rx={4.5}
      fill="#F0F1F4"
      stroke="#F0F1F4"
    />
    <rect
      x={48.5}
      y={160.5}
      width={23}
      height={23}
      rx={1.5}
      fill="#fff"
      stroke="#808B9F"
    />
    <path
      d="M68.406 166.22a.759.759 0 0 1 .22.531.751.751 0 0 1-.22.531l-10.5 10.5a.747.747 0 0 1-1.062 0l-5.25-5.25a.748.748 0 0 1 0-1.062.754.754 0 0 1 1.062 0l4.718 4.721 9.97-9.971a.75.75 0 0 1 1.062 0Z"
      fill="#808B9F"
    />
    <rect
      x={80.5}
      y={160.5}
      width={249}
      height={22}
      rx={4.5}
      fill="#F0F1F4"
      stroke="#F0F1F4"
    />
  </svg>
);
