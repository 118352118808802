import { useTheme } from "styled-components";
import { useLocation } from "react-router-dom";
import { FC, useEffect, useMemo, useState } from "react";
import { Types } from "@shared";
import { ITheme } from "@src/shared/theme";

import { Link, Redirect } from "../../../custom_link";
import { Text } from "../../../text";
import { INavMenuItem } from "../../types";
import { MenuItemWrapper } from "./navbar.menu.item.wrapper";

export const MenuItem: FC<INavMenuItem> = ({ item }) => {
  const [isRoutingActive, setRoutingActive] = useState<boolean>(false);
  const [hover, setHover] = useState(false);
  const { path, name, icons, external } = item;
  const theme = useTheme() as ITheme;
  const location = useLocation();
  const toConfig = { pathname: path, state: name };
  const matchLocation = useMemo(() => {
    if (location.pathname === Types.PATHS.VIDEO_ANALYTICS) {
      return (
        //  TO-DO: delete this logic after moving to module route map
        path === Types.PATHS.VIDEO_ANALYTICS || path === Types.PATHS.INITIAL
      );
    }
    return location.pathname === path;
  }, [location.pathname, path]);

  useEffect(() => {
    setRoutingActive(matchLocation);
  }, [location.pathname, matchLocation, path]);

  const renderLinkContent = useMemo(() => {
    return (
      icons && (
        <>
          {isRoutingActive || hover ? (
            <icons.activeIcon />
          ) : (
            <icons.inactiveIcon />
          )}
          <Text
            text={name}
            type={"xSmall"}
            textAlign={"center"}
            color={theme.palette.primaryWhite}
            margin={`${theme.spacing.sm}px 0 0 0`}
          />
        </>
      )
    );
  }, [icons, isRoutingActive, hover, name, theme]);

  if (!icons) {
    return (
      <MenuItemWrapper isActive={isRoutingActive}>
        <Link to={toConfig} padding="4px">
          <Text
            text={name}
            type={"xSmall"}
            textAlign={"center"}
            color={theme.palette.primaryWhite}
            margin={`${theme.spacing.sm}px 0 0 0`}
          />
        </Link>
      </MenuItemWrapper>
    );
  }

  const renderRedirectOutsiteLink = () => {
    return (
      <Redirect
        href={path}
        padding="4px"
        onMouseEnter={(_: any) => {
          setHover(true);
        }}
        onMouseLeave={(_: any) => {
          setHover(false);
        }}
      >
        {renderLinkContent}
      </Redirect>
    );
  };

  const renderCustomLink = () => {
    return (
      <Link
        to={toConfig}
        padding="4px"
        onMouseEnter={(e) => {
          setHover(true);
        }}
        onMouseLeave={(e) => {
          setHover(false);
        }}
      >
        {renderLinkContent}
      </Link>
    );
  };

  return (
    <MenuItemWrapper isActive={isRoutingActive}>
      {external ? renderRedirectOutsiteLink() : renderCustomLink()}
    </MenuItemWrapper>
  );
};
