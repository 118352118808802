import styled from "styled-components";

export const InterweaveWrapper = styled.div`
  font-size: 0.85rem;

  white-space: pre-wrap;

  span {
    white-space: pre-wrap;
  }

  em {
    font-weight: 600;
    font-style: normal;
    color: ${({ theme }) => theme.palette.primaryBlue};
  }
`;

export const InterweaveTitleWrapper = styled.div`
  font-size: 1rem;
  font-weight: 700;

  white-space: pre-wrap;

  span {
    white-space: pre-wrap;
  }

  em {
    font-style: normal;
    color: ${({ theme }) => theme.palette.primaryBlue};
  }
`;
