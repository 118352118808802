import { Dispatch, ReactElement, SetStateAction } from "react";
import { CircularProgress } from "@material-ui/core";
import { SharedComponents, Theme } from "@shared";
import {
  Pagination,
  PaginationContainer,
  PaginationSelect,
  PaginationSelectContainer,
} from "./styled";

export const Paginator = ({
  page,
  onChange,
  Component,
  totalCount,
  itemsPerPage,
  setItemsPerPage,
  hasLoading,
  pageCount,
}: {
  page: number;
  onChange: (_: any, p: number) => void;
  Component: ReactElement;
  totalCount: number;
  itemsPerPage: number;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  hasLoading: boolean;
  pageCount: number;
}) => {
  const {
    palette: { primaryBlue, gray },
  } = Theme.useStyledTheme();

  if (hasLoading) {
    return (
      <SharedComponents.Column alignItems={"center"} justifyContent={"center"}>
        <CircularProgress size={48} />
      </SharedComponents.Column>
    );
  }

  return (
    <>
      {Component}
      {totalCount > 0 && (
        <PaginationContainer>
          <PaginationSelectContainer>
            <SharedComponents.Row alignItems={"center"}>
              <SharedComponents.Text
                text={"Total:"}
                type={"xSmall"}
                color={gray}
              />
              <SharedComponents.HorizontalBox width={4} />
              <SharedComponents.Text
                color={primaryBlue}
                text={totalCount}
                type={"xSmallSemiBold"}
              />
              <SharedComponents.HorizontalBox width={16} />
              <SharedComponents.Text
                text={"Elements per page:"}
                type={"xSmall"}
                color={gray}
              />
              <SharedComponents.HorizontalBox width={4} />
              <PaginationSelect
                onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                value={itemsPerPage}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </PaginationSelect>
            </SharedComponents.Row>
          </PaginationSelectContainer>
          <SharedComponents.Row margin={"16px 0"} justifyContent={"flex-end"}>
            <Pagination count={pageCount} page={page} onChange={onChange} />
          </SharedComponents.Row>
        </PaginationContainer>
      )}
    </>
  );
};
