import { SharedComponents } from "@shared";

import * as LandingComponents from "./components";

export const Landing = () => {
  return (
    <LandingComponents.Center>
      <LandingComponents.Logo width={260} />
      <SharedComponents.Text
        text={"Checking user credentials..."}
        type={"h1"}
        color={"#003DA5"}
      />
    </LandingComponents.Center>
  );
};
