import { makeObservable } from "mobx";

import { GlobalRole, Endpoints } from "../../types";

import { sortAlphabetically } from "../utils";
import { BasicData } from "../basic";
import {
  IBrands,
  ICountries,
  IMetaItem,
  IProjectFormMetadata,
  IUser,
} from "../types";

export class Data extends BasicData {
  jwtToken: string = "";
  globalRole: GlobalRole = GlobalRole.UNDEFINED;
  userData: IUser | null;

  brands: Array<IBrands>;
  categories: Array<IMetaItem>;
  countries: Array<ICountries>;
  regions: Array<IMetaItem>;
  tags: Array<IMetaItem>;
  costCenters: Array<IMetaItem>;
  filtersValue: string[];

  constructor() {
    super();
    this.userData = null;
    this.brands = [];
    this.categories = [];
    this.countries = [];
    this.regions = [];
    this.tags = [];
    this.costCenters = [];
    this.filtersValue = [];
    makeObservable(this, {
      userData: true,
      brands: true,
      categories: true,
      countries: true,
      regions: true,
      tags: true,
      costCenters: true,
      filtersValue: true,
      globalRole: true,
    });
  }

  setGlobalRole = ({ role }: { role: GlobalRole }) => {
    this.globalRole = role;
  };

  setAccessToken = (jwtToken: string) => {
    this.jwtToken = jwtToken;
  };

  setFiltersValue = (value: string[]) => {
    this.filtersValue = value;
  };

  setUser = (response: IUser) => {
    this.userData = response;
  };

  setFormMetaData = ({
    projectFormMetadata: {
      regions,
      brands,
      categories,
      countries,
      tags,
      costCenters,
    },
  }: IProjectFormMetadata) => {
    this.regions = sortAlphabetically(regions);
    this.brands = sortAlphabetically(brands) as IBrands[];
    this.categories = sortAlphabetically(categories);
    this.countries = sortAlphabetically(countries) as ICountries[];
    this.tags = sortAlphabetically(tags);
    this.costCenters = sortAlphabetically(costCenters);
  };

  get hasInitialLoading() {
    return (
      this.loadings.get(Endpoints.GET_USER_INFO) ||
      this.loadings.get(Endpoints.USER_GLOBAL_ROLE)
    );
  }

  get hasInitialError() {
    return (
      this.errors.get(Endpoints.GET_USER_INFO) ||
      this.errors.get(Endpoints.USER_GLOBAL_ROLE)
    );
  }

  get hasAdminRole() {
    return (
      this.globalRole === GlobalRole.PG_ADMIN ||
      this.globalRole === GlobalRole.SYSTEM_ADMIN
    );
  }

  get hasUserRole() {
    return this.globalRole === GlobalRole.PG_USER;
  }

  get hasNonEmpRole() {
    return this.globalRole === GlobalRole.NON_EMP_USER;
  }

  get hasUndefinedRole() {
    return this.globalRole === GlobalRole.UNDEFINED;
  }

  get hasNoneRole() {
    return this.globalRole === GlobalRole.NONE;
  }

  get hasEBPRole() {
    return this.globalRole === GlobalRole.EBP_USER;
  }

  get hasDevMode() {
    return process.env.NODE_ENV === "development";
  }
}
