import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { ICustomLink } from "./types";

export const Link = styled(RouterLink)<ICustomLink>`
  text-decoration: none;
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: ${({ justifyContent = "center" }) => justifyContent};
  padding: ${({ padding = 0 }) => padding};
`;
