import styled from "styled-components";

interface ITimeline {
  height: number;
}

export const Timeline = styled.div<ITimeline>`
  height: ${({ height }) => `${height}px`};
  background: ${({ theme }) => theme.palette.timeline};
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 0;
  margin: 0;
`;
