import { FC } from "react";
import "react-toggle/style.css";

import * as CTLib from "./lib";
import { ICustomToggle } from "./types";

export const CustomToggle: FC<ICustomToggle> = ({
  toggleCallback,
  isToggled,
  label,
}) => {
  return (
    <CTLib.Label isToggled={isToggled}>
      {label}
      <CTLib.Toggler
        icons={false}
        onChange={() => toggleCallback(!isToggled)}
      />
    </CTLib.Label>
  );
};
