import { useFormikContext } from "formik";

import { SharedComponents } from "@shared";

import { IValues } from "../../../pages/add_project/types";

export const InformedConsentForm = () => {
  const { values } = useFormikContext<IValues>();
  return (
    <>
      <SharedComponents.FormikLib.RadioGroup
        field="hasVideoPersonsUnder18"
        title="Does your video contain persons under the age of 18?*"
      />
      {values.hasVideoPersonsUnder18 && (
        <SharedComponents.FormikLib.RadioGroup
          field="hasConsentFormSignedByParent"
          title="Is the informed consent form for the video containing persons under 18 signed by the parent/guardian?*"
        />
      )}
    </>
  );
};
