import styled from "styled-components";

import { IStepperInner } from "../types";

export const Inner = styled.div<IStepperInner>`
  width: 192px;
  height: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
`;
