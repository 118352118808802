import styled from "styled-components";

import { Utils, SharedComponents } from "@shared";

interface ITimelineMarkerWrapper {
  positionX: number;
  zIndex?: number;
}

interface ITimelineMarker extends ITimelineMarkerWrapper {
  progress: number;
}

const TimelineMarkerWrapper = styled.div<ITimelineMarkerWrapper>`
  position: absolute;
  z-index: ${({ zIndex = 1 }) => zIndex};
  top: -32px;
  left: ${({ positionX }) => `${positionX}px`};
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.palette.text}`};
  background: ${({ theme }) => theme.palette.primaryWhite};
`;

export const TimelineMarker = ({
  positionX,
  progress,
  zIndex,
}: ITimelineMarker) => {
  return (
    <TimelineMarkerWrapper zIndex={zIndex} positionX={positionX}>
      <SharedComponents.Text
        type={"xSmallMedium"}
        text={Utils.formatSeconds(progress)}
      />
    </TimelineMarkerWrapper>
  );
};
