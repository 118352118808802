import * as React from "react";

export function IconSuccess(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 37.5a17.5 17.5 0 110-35 17.5 17.5 0 010 35zm0 2.5a20 20 0 100-40 20 20 0 000 40z"
        fill="#00B140"
      />
      <path
        d="M27.423 12.425a.592.592 0 00-.05.055L18.69 23.543l-5.232-5.235a1.875 1.875 0 00-2.65 2.65l6.615 6.617a1.874 1.874 0 002.697-.05L30.1 15.05a1.875 1.875 0 00-2.677-2.625z"
        fill="#00B140"
      />
    </svg>
  );
}
