import ReactSelect from "react-select";
import styled from "styled-components";

import { SharedComponents, Theme } from "@shared";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Wrapper = styled(ReactSelect)`
  width: 100%;
  height: 40px;
`;

interface ISelect {
  title: string;
  subtitle?: string;
  placeholder?: string;
}

export const Select = ({ title, subtitle, placeholder = "" }: ISelect) => {
  const {
    spacing: { sm },
  } = Theme.useStyledTheme();
  return (
    <SharedComponents.Box>
      <SharedComponents.Text text={title} type={"h3SemiBold"} />
      {subtitle && <SharedComponents.Text text={subtitle} type={"xSmall"} />}
      <SharedComponents.VerticalBox height={sm} />
      <Wrapper options={options} placeholder={placeholder} />
      <SharedComponents.VerticalBox height={16} />
    </SharedComponents.Box>
  );
};
