import styled from "styled-components";

import { IField } from "../types";

export const Field = styled.textarea<IField>`
  width: 100%;
  height: ${({ height = 48 }) => `${height}px`};
  border: 1px solid black;
  padding: ${({ theme }) => `${theme.spacing.md}px`};
  margin-top: ${({ theme }) => `${theme.spacing.md}px`};
  border: ${({ theme, isEmpty }) =>
    `1px solid ${isEmpty ? theme.palette.gray : theme.palette.primaryBlue}`};
  border-radius: ${({ theme }) => `${theme.spacing.sm}px`};
  background: transparent;
  text-align: top;
  resize: none;
  outline: none;
`;
