import styled from "styled-components";

import { IInfoColumnWrapper } from "../types";

export const InfoColumnWrapper = styled.div<IInfoColumnWrapper>`
  display: flex;
  height: 100%;
  align-items: flex-end;

  justify-content: ${({ justifyContent }: IInfoColumnWrapper) =>
    justifyContent};
`;
