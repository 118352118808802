import * as React from "react";

export function IconSummaries(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={34}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.25 6.375a1.062 1.062 0 0 0 1.063 1.062h23.375a1.063 1.063 0 0 0 0-2.125H5.313A1.062 1.062 0 0 0 4.25 6.375Zm4.25-4.25a1.062 1.062 0 0 0 1.063 1.062h14.874a1.063 1.063 0 0 0 0-2.125H9.563A1.062 1.062 0 0 0 8.5 2.125Zm5.876 11.849a1.063 1.063 0 0 0-1.626.9V25.5a1.064 1.064 0 0 0 1.626.9l8.5-5.312a1.062 1.062 0 0 0 0-1.802l-8.5-5.312Z"
        fill="#808B9F"
      />
      <path
        d="M3.188 30.812A3.187 3.187 0 0 1 0 27.625V12.75a3.187 3.187 0 0 1 3.188-3.188h27.625A3.187 3.187 0 0 1 34 12.75v14.875a3.187 3.187 0 0 1-3.188 3.187H3.188Zm27.625-2.125a1.063 1.063 0 0 0 1.062-1.062V12.75a1.062 1.062 0 0 0-1.063-1.063H3.188a1.062 1.062 0 0 0-1.062 1.063v14.875a1.063 1.063 0 0 0 1.063 1.062h27.625Z"
        fill="#808B9F"
      />
    </svg>
  );
}
