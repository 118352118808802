import styled from "styled-components";

import { ITSWrapper } from "../types";

export const Wrapper = styled.div<ITSWrapper>`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: ${({ padding }: ITSWrapper) => `${padding}px 0 `};
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray};
`;
