import { FC, useMemo } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { SharedComponents, Theme, Utils } from "@shared";

import { IListItem } from "../types";

import { TLListItemWrapper } from "./tl.listitem.wrapper";

export const ListItem: FC<IListItem> = ({
  id,
  title,
  selectedData,
  setSelectedData,
  disabled,
  isElastic,
}) => {
  const {
    spacing: { sm },
    palette: { text, primaryBlue },
  } = Theme.useStyledTheme();

  const isChecked: boolean = useMemo(
    () => selectedData.includes(isElastic ? title : id),
    [selectedData, id, isElastic, title]
  );

  return (
    <TLListItemWrapper>
      <Checkbox
        onKeyDown={Utils.onKeyDown(() => {
          if (disabled) return;
          setSelectedData();
        })}
        checked={isChecked}
        color="primary"
        disabled={disabled}
        onChange={(e) => {
          e.stopPropagation();
          setSelectedData();
        }}
      />
      <SharedComponents.Text
        color={isChecked ? primaryBlue : text}
        text={title}
        onKeyDown={Utils.onKeyDown(() => {
          if (disabled) return;
          setSelectedData();
        })}
        type={isChecked ? "button" : "small"}
        margin={`0 ${sm}px`}
      />
    </TLListItemWrapper>
  );
};
