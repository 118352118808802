import styled from "styled-components";

import { ITSIconWrapper } from "../types";

export const IconWrapper = styled.div<ITSIconWrapper>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ right }: ITSIconWrapper) => `${right}px`};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
