import { observable } from "mobx";

import {Types} from '@shared'

export abstract class BasicData {
  /**
   * describe loadings states for all async requests
   * */
  loadings: Map<Types.IEndpoint, boolean>;
  /**
   * describe all completed async requests
   * */
  successes: Map<Types.IEndpoint, boolean>;
  /**
   * describe errors states for all requests
   * */
  errors: Map<Types.IEndpoint, boolean>;

  constructor() {
    this.loadings = observable.map<Types.IEndpoint, boolean>();
    this.successes = observable.map<Types.IEndpoint, boolean>();
    this.errors = observable.map<Types.IEndpoint, boolean>();
  }

  /**
   * handlers for setting loading/error/success request's status
   * */
  setRequestError = (key: Types.IEndpoint) => {
    this.errors.set(key, true);
    this.successes.set(key, false);
    this.loadings.set(key, false);
  };

  setRequestLoading = (key: Types.IEndpoint) => {
    this.loadings.set(key, true);
    this.errors.set(key, false);
    this.successes.set(key, false);
  };

  setRequestSuccess = (key: Types.IEndpoint) => {
    this.successes.set(key, true);
    this.loadings.set(key, false);
    this.errors.set(key, false);
  };

  setRequestReset = (key: Types.IEndpoint) => {
    this.successes.set(key, false);
    this.loadings.set(key, false);
    this.errors.set(key, false);
  };
}
