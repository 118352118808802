import { useParams } from "react-router-dom";
import { computed } from "mobx";

import {
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
  Types,
} from "@videoAnalytics";
import { SharedComponents, Pages, Utils } from "@shared";

import { validationSchema } from "./formik_data";
import * as LocalPages from "./pages";
import { useEffect } from "react";
import { observer } from "mobx-react";

export const EditVideo = observer(() => {
  /**
   * route params
   * */
  const { videoId, projectId } = useParams();
  /**
   * storage values
   * */
  const {
    data: { videoDetails, setRequestReset, loadings },
    getVideoDetails,
    clearVideoDetails,
  } = VideoAnalyticsStore;

  const hasVideoDetailsLoading = Utils.useQueryStatus(
    loadings,
    Types.Endpoints.VIDEO_DETAILS
  );

  const formValues = computed(() => ({
    id: videoId || videoDetails?.id,
    name: videoDetails?.name,
    tags: [],
  })).get();
  /**
   * side effects
   * */
  useEffect(() => {
    const init = async () => {
      await getVideoDetails(videoId || "");
    };
    init();
    return () => {
      clearVideoDetails();
      setRequestReset(Types.Endpoints.VIDEO_DETAILS);
    };
  }, []);

  return (
    <VideoAnalyticsComponents.FileRoleController
      hasExternalLoading={hasVideoDetailsLoading}
      fileId={videoId || ""}
    >
      <SharedComponents.Screen>
        <SharedComponents.FormikLib.Form
          validationSchema={validationSchema}
          initialValues={formValues}
          onSubmit={(_) => {}}
        >
          <Pages.AddProjectTemplate
            steps={["Video details"]}
            title={"Edit video"}
            paddingRight={64}
          >
            <LocalPages.VideoDetailsPage projectId={projectId || ""} />
            <div />
          </Pages.AddProjectTemplate>
        </SharedComponents.FormikLib.Form>
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.FileRoleController>
  );
});
