import { observer } from "mobx-react";
import { CircularProgress } from "@material-ui/core";

import { SharedComponents, Theme, SharedStore } from "@shared";

import { IconUserFilled } from "../../../../components/icons/icon.userFilled";
import { Text } from "../../../../components/text";

import { Wrapper } from "./topbar.userInfo.wrapper";

export const UserInfo = observer(() => {
  const {
    spacing: { md },
  } = Theme.useStyledTheme();
  const {
    data: { userData },
  } = SharedStore;

  if (!userData?.attributes?.email) {
    return (
      <Wrapper>
        <CircularProgress size={24} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Text
        // TO-DO: provide user nickname
        text={userData?.attributes?.email}
        type={"paragraph"}
      />
      {/*<SharedComponents.HorizontalBox width={md} />*/}
      {/*<Text*/}
      {/*  // TO-DO: provide user nickname*/}
      {/*  text={globalRole.toString()}*/}
      {/*  type={"button"}*/}
      {/*/>*/}
      <SharedComponents.HorizontalBox width={md} />
      <IconUserFilled />
      {/*<SharedComponents.HorizontalBox width={md} />*/}
      {/*<ArrowContainer rotate={rotate}>*/}
      {/*  <IconButton onClick={onClick}>*/}
      {/*    <SharedComponents.Icons.IconArrowDown*/}
      {/*      fill={hasOpen ? primaryBlue : gray}*/}
      {/*    />*/}
      {/*  </IconButton>*/}
      {/*</ArrowContainer> */}
    </Wrapper>
  );
});
