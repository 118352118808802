import { ChangeEvent, FC } from "react";
import { ICustomSearch } from "./types";
import * as CSLib from "./lib";

export const CustomSearch: FC<ICustomSearch> = ({
  placeholder,
  searchValue,
  setSearchValue,
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const inputProps = {
    endAdornment: searchValue ? (
      <CSLib.CSButtonClear clearSearchValue={setSearchValue} />
    ) : (
      <CSLib.IconSearch />
    ),
  };
  return (
    <CSLib.CustomTextField
      variant={"outlined"}
      size={"small"}
      label={placeholder}
      value={searchValue}
      InputProps={inputProps}
      name="search"
      onChange={handleChange}
    />
  );
};
