import CircularProgress from "@material-ui/core/CircularProgress";

import { SharedComponents, Theme } from "@shared";

import * as LILib from "./lib";

export const LoadingIndicator = () => {
  const {
    palette: { primaryBlue },
    spacing: { md },
  } = Theme.useStyledTheme();
  return (
    <LILib.Wrapper>
      <CircularProgress color={"primary"} />
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Text
        text={"Loading. Please wait..."}
        type={"h1"}
        color={primaryBlue}
      />
    </LILib.Wrapper>
  );
};
