import { FC, useCallback } from "react";
import { useFormikContext } from "formik";

import * as BGLib from "./lib";
import { IButtonsSection } from "./types";

export const ButtonsGroup: FC<IButtonsSection> = ({
  onNextHandler,
  onBackHandler,
  fields,
  titleNext,
  titleBack,
}) => {
  const { values, errors, setFieldError, setFieldTouched } =
    useFormikContext<any>();

  const validateFields = useCallback(
    (field: string) => {
      setFieldTouched(field);
      if (values[field] === "") {
        setFieldError(field, "This field is required");
      } else {
        setFieldError(field, "Invalid value");
      }
    },
    [values, setFieldError, setFieldTouched]
  );

  const hasErrors = useCallback(
    (fields: Array<string>): boolean => {
      for (let i = 0; i < fields.length; i++) {
        const value = values[fields[i]];
        if (value === "" || value === undefined) {
          return true;
        } else {
          if (errors[fields[i]]) {
            return true;
          }
        }
      }
      return false;
    },
    [errors, values]
  );

  const onNext = useCallback(() => {
    if (hasErrors(fields)) {
      for (let i = 0; i < fields.length; i++) {
        validateFields(fields[i]);
      }
    } else {
      onNextHandler();
    }
  }, [hasErrors, fields, validateFields, onNextHandler]);

  return (
    <BGLib.Buttons
      onNextHandler={onNext}
      onBackHandler={onBackHandler}
      titleNext={titleNext}
      titleBack={titleBack}
    />
  );
};
