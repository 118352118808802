import { FC, useCallback } from "react";
import { SharedComponents, Theme } from "@shared";
import { ITagsColumn } from "../types";
import { InfoColumnWrapper } from "./summary.infoColumn.wrapper";
import { Text } from "../../text";
import { Row, Column } from "../../containers/index";

export const TagsColumn: FC<ITagsColumn> = ({
  justifyContent = "space-between",
  width = "20",
  title,
  tags,
  Icon,
}) => {
  const { palette, spacing } = Theme.useStyledTheme();
  const renderItem = useCallback(
    (tag: string) => (
      <Text
        text={`#${tag}`}
        type={"h6"}
        color={palette.primaryBlue}
        margin={`0 0 0 ${spacing.sm}px`}
      />
    ),
    [palette.primaryBlue, spacing.sm]
  );
  return (
    <InfoColumnWrapper justifyContent={justifyContent} width={width}>
      <Icon width={`${spacing.lg}px`} height={`${spacing.lg}px`} />
      <Column>
        <SharedComponents.Text text={title} type="button" />
        <Row>{tags.map(renderItem)}</Row>
      </Column>
    </InfoColumnWrapper>
  );
};
