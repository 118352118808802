import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";

import * as Theme from "../../theme";
import { GlobalStyle } from "../global_style";

interface IMainContainer {
  children: ReactNode;
}

export const MainContainer = ({ children }: IMainContainer) => {
  return (
    <ThemeProvider theme={Theme.lightTheme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
