const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTES = 60;

export const formatTime = (time: number): string => {
  const hours = (time - (time % SECONDS_IN_HOUR)) / SECONDS_IN_HOUR;
  const minutes =
    (time - hours * SECONDS_IN_HOUR - (time % SECONDS_IN_MINUTES)) /
    SECONDS_IN_MINUTES;
  const seconds = time - hours * SECONDS_IN_HOUR - minutes * SECONDS_IN_MINUTES;
  const fHours = hours >= 10 ? hours : `0${hours}`;
  const fMinutes = minutes >= 10 ? minutes : `0${minutes}`;
  const parsedSeconds =
    seconds >= 10 ? seconds.toFixed(2) : `0${seconds.toFixed(2)}`;
  const formattedSeconds = parsedSeconds.split(".").join(":");
  return `${fHours}:${fMinutes}:${formattedSeconds}`;
};

export const timeValidator = (value: string, duration: number): boolean => {
  const data = value.split(":");
  if (data.length !== 4) return false;
  if (!data[0] || !data[1] || !data[2] || !data[3]) {
    return false;
  }
  if (
    isNaN(parseInt(data[0])) ||
    isNaN(parseInt(data[1])) ||
    isNaN(parseFloat(data[2])) ||
    isNaN(parseFloat(data[3]))
  ) {
    return false;
  }
  const result =
    parseInt(data[0]) * SECONDS_IN_HOUR +
    parseInt(data[1]) * SECONDS_IN_MINUTES +
    parseFloat(data[2]) +
    parseFloat(data[3]) / 100;
  return result < duration && result >= 0;
};
export const deFormat = (value: string): number => {
  const data = value.split(":");
  if (!data[0] || !data[1] || !data[2] || !data[3]) {
    return 0;
  }
  const result =
    parseInt(data[0]) * SECONDS_IN_HOUR +
    parseInt(data[1]) * SECONDS_IN_MINUTES +
    parseFloat(data[2]) +
    parseFloat(data[3]) / 100;
  return result;
};
export const translateClipDurationToWidth = (
  timelineWidth: number,
  videoDuration: number,
  start: number,
  end: number
): number => {
  const diff = end - start;
  return (timelineWidth / videoDuration) * diff;
};
export const translateMarkerTimeToLeft = (
  timelineWidth: number,
  duration: number,
  markerTime: number
): number => {
  return (timelineWidth / duration) * markerTime;
};
