import { observer } from "mobx-react";
import { SharedComponents, Theme, Types } from "@shared";

interface ISummary {
  data?: Types.IProjectsStats;
}

export const Summary = observer(({ data }: ISummary) => {
  const theme = Theme.useStyledTheme();

  if (!data) {
    return <></>;
  }

  return (
    <>
      <SharedComponents.Summary.Row>
        <SharedComponents.Summary.InfoColumn
          width="12"
          title="Projects"
          justifyContent="flex-start"
          value={data.projectsNumber}
          Icon={SharedComponents.Summary.IconProjects}
        />
        <SharedComponents.Summary.Border borderColor={theme.palette.gray} />
        <SharedComponents.Summary.InfoColumn
          width="12"
          title="Videos"
          justifyContent="center"
          value={data.videosNumber}
          Icon={SharedComponents.Summary.IconVideo}
        />
        <SharedComponents.Summary.Border borderColor={theme.palette.gray} />
        <SharedComponents.Summary.InfoColumn
          width="20"
          title="Project participants"
          justifyContent="center"
          value={data.membersNumber}
          Icon={SharedComponents.Summary.IconParticipants}
        />
        <SharedComponents.Summary.Border borderColor={theme.palette.gray} />
        <SharedComponents.Summary.InfoColumn
          width="24"
          title="Total videos duration"
          justifyContent="center"
          value={data.totalLength}
          Icon={SharedComponents.Summary.IconDuration}
        />
        <SharedComponents.Summary.Border borderColor={"transparent"} />
      </SharedComponents.Summary.Row>
      <SharedComponents.Box background={theme.palette.gray} height={"1px"} />
    </>
  );
});
