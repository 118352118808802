import { useField } from "formik";
import { v4 as uuidv4 } from "uuid";
import { useCallback } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { SharedComponents, Types as SharedTypes, Theme, Utils } from "@shared";

import { IClip, ITimelineItem } from "../../../../../../types";

import { usePlayerContext } from "../../context";

interface ITimelineActionButton {
  playerRef: React.RefObject<ReactPlayer>;
  item: SharedTypes.IProjectVideo;
}

const TimelineActionButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const TimelineActionButton = ({
  playerRef,
  item,
}: ITimelineActionButton) => {
  const {
    spacing: { sm },
  } = Theme.useStyledTheme();
  const { hasEditMode, setEditMode } = usePlayerContext();

  const [timelines, ,] = useField<Array<ITimelineItem>>("timelines");
  /**
   * Created clips from project video
   */
  const [clips, , clipsHelpers] = useField<Array<IClip>>("clips");
  /**
   * Field to describe if user select clip to edit
   */
  const [selectedClipId, , selectedClipIdHelpers] = useField<
    string | undefined
  >("selectedClipId");

  const onSubmit = useCallback(() => {
    const timelineItem: ITimelineItem | undefined = timelines.value.find(
      (t) => t.id === item.id
    );
    if (timelineItem) {
      /**
       * create basic data for new clip
       * **/
      const basicData = {
        dataUri: Utils.captureVideoFrame(
          playerRef!.current?.getInternalPlayer()
        ).dataUri,
        from: timelineItem.progressFrom,
        to: timelineItem.progressTo,
        videoName: item.name,
        clipName: `${item.name
          .split(" ")
          .slice(0, 4)
          .join(" ")}... ${Utils.formatSeconds(
          timelineItem.progressFrom
        )}-${Utils.formatSeconds(timelineItem.progressTo)}`,
        fileId: item.id,
      };
      /**
       * if user select video trying to edit existing clip
       * **/
      if (selectedClipId.value) {
        const newClip: IClip = {
          id: selectedClipId.value,
          ...basicData,
        };
        const index = clips.value.findIndex(
          (c) => c.id === selectedClipId.value
        );
        clipsHelpers.setValue([
          ...clips.value.slice(0, index),
          newClip,
          ...clips.value.slice(index + 1),
        ]);
      } else {
        /**
         * otherwise create new clip and adding it to the list
         * **/
        const newClip: IClip = {
          id: uuidv4(),
          ...basicData,
        };
        clipsHelpers.setValue([...clips.value, newClip]);
      }
      selectedClipIdHelpers.setValue(undefined);
      setEditMode(false);
    }
  }, [item, selectedClipIdHelpers, setEditMode, clipsHelpers, clips]);

  const onAddClipClick = useCallback(() => setEditMode(true), [setEditMode]);

  const onCancel = useCallback(() => {
    selectedClipIdHelpers.setValue(undefined);
    setEditMode(false);
  }, [setEditMode, selectedClipIdHelpers.setValue]);

  return (
    <TimelineActionButtonWrapper>
      {hasEditMode && (
        <SharedComponents.Row>
          <SharedComponents.Button
            onClick={onCancel}
            type={SharedTypes.ButtonType.OUTLINED}
            text={"CANCEL"}
          />
          <SharedComponents.HorizontalBox width={sm} />
          <SharedComponents.Button
            onClick={onSubmit}
            type={SharedTypes.ButtonType.FILLED}
            text={"SUBMIT"}
          />
        </SharedComponents.Row>
      )}
      {!hasEditMode && (
        <SharedComponents.Button
          onClick={onAddClipClick}
          type={SharedTypes.ButtonType.FILLED}
          text={"ADD CLIP"}
        />
      )}
    </TimelineActionButtonWrapper>
  );
};
