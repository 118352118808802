import { FC } from "react";
import { Formik, Form as FormikForm } from "formik";

import { IForm } from "./types";

export const Form: FC<IForm> = ({
  children,
  initialValues,
  onSubmit,
  validationSchema,
  enableReinitialize = true,
}) => {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <FormikForm onSubmit={handleSubmit}>{children}</FormikForm>
      )}
    </Formik>
  );
};
