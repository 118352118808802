import { useEffect } from "react";

import { SharedComponents } from "@shared";

import * as PDLib from "./components";
import { useField } from "formik";

interface ICalendars {
  disabled: boolean;
}

export const Calendars = ({ disabled }: ICalendars) => {
  const [startDateInput, ,] = useField("startDate");
  const [endDateInput, , endDateHelpers] = useField("endDate");
  useEffect(() => {
    if (startDateInput.value.getTime && endDateInput.value.getTime) {
      if (startDateInput.value.getTime() > endDateInput.value.getTime()) {
        endDateHelpers.setValue(startDateInput.value);
      }
    }
  }, [startDateInput, endDateInput, endDateHelpers]);
  return (
    <SharedComponents.FlexContainer justifyContent="space-between" padding={0}>
      <PDLib.Wrapper>
        <SharedComponents.FormikLib.Calendar
          title="Project start date*"
          field="startDate"
          disabled={disabled}
        />
      </PDLib.Wrapper>
      <PDLib.Wrapper>
        <SharedComponents.FormikLib.Calendar
          title="Project end date*"
          field="endDate"
        />
      </PDLib.Wrapper>
    </SharedComponents.FlexContainer>
  );
};
