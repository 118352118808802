import styled from "styled-components";

import { IRow } from "../types";

export const Row = styled.div<IRow>`
  display: flex;
  justify-content: ${({ justifyContent = "flex-start" }: IRow) =>
    justifyContent};
  align-items: center;
  margin: ${({ margin }: IRow) => margin};
`;
