import { computed } from "mobx";

import { Types } from "@shared";

export const useQueryStatus = (
  map: Map<Types.IEndpoint | string, boolean>,
  endpoint: string
): boolean => {
  const status = computed(() => map.get(endpoint)).get() || false;
  return status;
};
