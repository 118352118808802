import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  :hover {
    cursor: pointer;
    opacity: 0.75;
  }
`;
