import { SharedComponents, Theme } from "@shared";

interface IInfoTile {
  Icon: React.FC<any>;
  name: string;
  value: string | number;
}

export const InfoTile = ({ Icon, name, value }: IInfoTile) => {
  const {
    palette: { primaryBlue, gray },
  } = Theme.useStyledTheme();
  return (
    <SharedComponents.Row
      alignItems={"center"}
      flex={0}
      width={"fit-content"}
      height={"fit-content"}
    >
      <Icon fill={gray} width={"12px"} height={"12px"} />
      <SharedComponents.HorizontalBox width={4} />
      <SharedComponents.Text
        text={name}
        type={"xSmall"}
        whiteSpace={"nowrap"}
      />
      <SharedComponents.HorizontalBox width={4} />
      <SharedComponents.Text
        text={value}
        color={primaryBlue}
        type={"xSmallSemiBold"}
        whiteSpace={"nowrap"}
      />
    </SharedComponents.Row>
  );
};
