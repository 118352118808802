import { createGlobalStyle } from "styled-components";
import { ITheme } from "../../theme";
import { SCROLLBAR } from "../scrollbar";

export const GlobalStyle = createGlobalStyle`
  *{
  //
   font-family: Montserrat;
  //
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
  //
  ${() => SCROLLBAR}
  //
  }

  body {
    background: ${({ theme }: { theme: ITheme }) => theme.palette.background};
  }
  
  em {
    font-weight: 600;
    font-style: normal;
    color: ${({ theme }) => theme.palette.primaryBlue};
  }
`;
