export const WARNING_LIST: Array<string> = [
  "grt china",
  "macau",
  "taiwan",
  "china",
  "hong kong",
  "united states",
];

export const GRT_CHINA_LIST: Array<string> = [
  "grt china",
  "macau",
  "taiwan",
  "china",
  "hong kong",
];
