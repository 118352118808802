import styled from "styled-components";

import { IOptionsWrapper } from "../types";

export const OptionsWrapper = styled.div<IOptionsWrapper>`
  position: absolute;
  left: 0;
  bottom: ${({ hasError }) => (hasError ? "88px" : "64px")};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  width: 100%;
  max-height: 144px;
  min-height: 34px;
  background: ${({ theme }) => `${theme.palette.primaryWhite}`};
  border: ${({ theme }) => `1px solid ${theme.palette.gray}`};
  overflow-y: scroll;
  overflow-x: hidden;
`;
