export const queryFullSearch = /* GraphQL */ `
  query FullSearch($input: FullSearchInput!) {
    fullSearch(input: $input) {
      stats {
        membersNumber
        projectsNumber
        totalLength
        videosNumber
      }
      projects {
        videosNumber
        active
        id
        name
        ownerEmail
        slug
        status
        tags {
          id
          name
        }
        videos {
          active
          createdAt
          disabledAt
          id
          ownerEmail
          processedAt
          projectName
          summary
          thumbnail
          title
          updatedAt
          status
        }
        taxonomy
        updatedAt
      }
    }
  }
`;

export const projectFormMetaData = /* GraphQL */ `
  query MyQuery {
    projectFormMetadata {
      brands {
        id
        name
        parentId
      }
      categories {
        id
        name
      }
      countries {
        id
        name
        parentId
      }
      regions {
        id
        name
      }
    }
  }
`;

export const getVideosDetails = /* GraphQL */ `
  query videosDetails($input: VideoDetailsInput!) {
    videosDetails(input: $input) {
      id
      processedPath
    }
  }
`;

export const getVideoClips = /* GraphQL */ `
  query videoClips($input: VideoClipsInput!) {
    videoClips(input: $input) {
      projectId
      summary
      title
      videos {
        id
        title
        videoUrl
        clips {
          endTime
          precedence
          startTime
          thumbnail
        }
      }
    }
  }
`;

export const userRole = /* GraphQL */ `
  query userRole($input: UserEntityRoleInput!) {
    userRole(input: $input) {
      role
    }
  }
`;

export const costCenterExist = /* GraphQL */ `
  query CostCenterExist($id: String) {
    costCenterExist(id: $id)
  }
`;

export const userGlobalRole = /* GraphQL */ `
  query userGlobalRole($input: UserGlobalRoleInput!) {
    userGlobalRole(input: $input) {
      role
    }
  }
`;

export const projectExist = /* GraphQL */ `
  query projectExist($name: String!) {
    projectExist(name: $name)
  }
`;
