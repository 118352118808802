import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useField } from "formik";
import { computed } from "mobx";
import { CircularProgress } from "@material-ui/core";

import { SharedComponents, Types } from "@shared";

import { useStyledTheme } from "../../../../theme";

import { StyledField } from "./lib";

export interface IValidatableField {
  title: string;
  subtitle?: string;
  field: string;
  placeholder?: string;
  height?: number;
  endpoint: Types.IEndpoint;
  loadings: Map<Types.IEndpoint, boolean>;
}

export const ValidatableField: FC<IValidatableField> = observer(
  ({
    height = 44,
    field,
    title,
    subtitle = "",
    placeholder,
    endpoint,
    loadings,
  }) => {
    const {
      palette: { primaryRed },
      spacing: { sm },
    } = useStyledTheme();

    const [input, meta, helpers] = useField(field);

    const hasLoading = computed(() => loadings.get(endpoint)).get();

    const [hasFocus, setFocus] = useState(false);

    const onBlur = useCallback(async () => {
      setFocus(false);
    }, [setFocus]);

    const onFocus = useCallback(() => setFocus(true), [setFocus]);

    const hasFocusOrValue = useMemo(
      () => (input.value && input.value.length > 0) || hasFocus,
      [input, hasFocus]
    );

    useEffect(() => {
      if (input.value.length > 0 && !meta.touched) {
        helpers.setTouched(true, false);
      }
    }, [input.value, meta.touched]);

    return (
      <SharedComponents.Column
        flex={0}
        padding={0}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <SharedComponents.Text text={title} type="h3SemiBold" />
        {subtitle && <SharedComponents.Text text={subtitle} type="xSmall" />}
        <SharedComponents.VerticalBox height={sm} />
        <div style={{ position: "relative", width: "100%" }}>
          <StyledField
            hasfocus={hasFocusOrValue.toString()}
            onBlur={onBlur}
            onFocus={onFocus}
            height={height}
            placeholder={placeholder}
            name={field}
          />
          <div
            style={{
              position: "absolute",
              right: 16,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {hasLoading && <CircularProgress size={24} />}
          </div>
        </div>

        {meta.error && meta.touched && (
          <SharedComponents.Text
            text={meta.error}
            type={"small"}
            color={primaryRed}
          />
        )}
      </SharedComponents.Column>
    );
  }
);
