import styled from "styled-components";

import { INPUT_HEIGHT, INPUT_WIDTH } from "../constants";

export const InputWrapper = styled.div`
  width: ${INPUT_WIDTH};
  height: ${INPUT_HEIGHT};
  margin-right: ${({ theme }) => theme.spacing.md}px;
  background: transparent;
  position: relative;
`;
