import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  height: 100%;

  max-height: 360px;
`;
