import * as React from "react";

export function IconClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.168.168a.571.571 0 01.809 0L4 3.192 7.024.168a.572.572 0 01.809.809L4.808 4l3.025 3.024a.572.572 0 01-.81.809L4 4.808.977 7.833a.572.572 0 11-.81-.81L3.193 4 .168.977a.571.571 0 010-.81z"
        fill="#808B9F"
      />
    </svg>
  );
}
