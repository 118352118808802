import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { makeObservable } from "mobx";
import { Auth } from "aws-amplify";
import gql from "graphql-tag";
import localforage from "localforage";

import { Types } from "@shared";

import {
  projectFormMetaData,
  userGlobalRole,
  costCenterExist,
  projectExist,
} from "./queries";
import { IProjectFormMetadata, IUser } from "../types";

export class Client {
  client: AWSAppSyncClient<any>;
  jwtToken: string = "";
  constructor({ awsClient }: Types.IAWSClientParams) {
    this.client = new awsClient({
      url: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT || "",
      region: process.env.REACT_APP_COGNITO_REGION || "",
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => {
          await this.refreshToken();
          return this.jwtToken;
        },
      },
      offlineConfig: {
        keyPrefix: "shared",
        storage: localforage,
      },
    });
    makeObservable(this, { client: true, jwtToken: true });
  }

  private refreshToken = async () => {
    try {
      const accessToken = (await Auth.currentSession()).getAccessToken();
      this.setAccessToken(accessToken.getJwtToken());
    } catch (e) {
      console.error("Failed to refresh JWT token", e);
    }
  };

  setAccessToken = (jwtToken: string) => {
    this.jwtToken = jwtToken;
  };

  getUserInfo = async (): Promise<IUser> => {
    const user = await Auth.currentUserInfo();
    if (!user) {
      await Auth.federatedSignIn({
        customProvider: "Ping",
        customState: window.location.href,
      });
    }
    return user;
  };

  projectFormMetaData = async () => {
    const response = (await this.client.query({
      query: gql(projectFormMetaData),
      fetchPolicy: "network-only",
    })) as GraphQLResult<IProjectFormMetadata>;
    return response.data;
  };

  userGlobalRole = async (): Promise<
    | {
        userGlobalRole: { role: Types.GlobalRole };
      }
    | undefined
  > => {
    if (!this.jwtToken) {
      await this.refreshToken();
    }

    const response = (await this.client.query({
      query: gql(userGlobalRole),
      variables: { input: { accessToken: this.jwtToken } },
      fetchPolicy: "network-only",
    })) as GraphQLResult<{ userGlobalRole: { role: Types.GlobalRole } }>;
    return response.data;
  };

  costCenterExists = async (id: string) => {
    const response = (await this.client.query({
      query: gql(costCenterExist),
      variables: { id },
      fetchPolicy: "network-only",
    })) as GraphQLResult<{ costCenterExist: boolean }>;
    return response.data?.costCenterExist || false;
  };

  projectExist = async (name: string) => {
    const response = (await this.client.query({
      query: gql(projectExist),
      variables: { name },
      fetchPolicy: "network-only",
    })) as GraphQLResult<{ projectExist: boolean }>;
    return response.data;
  };
}
