import { useRef, useState } from "react";
import styled from "styled-components";

import { SharedComponents, Theme } from "@shared";

import { IDownloadItem } from "../../types";

export const DownloadItem = ({ name, path }: IDownloadItem) => {
  const {
    palette: { primaryBlue, text },
  } = Theme.useStyledTheme();

  const [hasHover, setHover] = useState<boolean>(false);

  const onMouseEnter = useRef(() => setHover(true));
  const onMouseLeave = useRef(() => setHover(false));

  return (
    <SharedComponents.Box>
      <Link href={path}>
        <DownloadItemWrapper
          onMouseEnter={onMouseEnter.current}
          onMouseLeave={onMouseLeave.current}
        >
          <SharedComponents.Text
            text={name.toUpperCase()}
            type={hasHover ? "button" : "paragraph"}
            color={hasHover ? primaryBlue : text}
          />
        </DownloadItemWrapper>
      </Link>
    </SharedComponents.Box>
  );
};

const Link = styled.a`
  text-decoration: none;
`;

const DownloadItemWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
    background-color: #f2f5fa;
  }
`;
