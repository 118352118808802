import { FC } from "react";
import { SharedComponents } from "@shared";
import { Dot } from "./analysis.legend.dot";
import { Wrapper } from "./analysis.legend.wrapper";
import { ILegend } from "../types";

export const Legend: FC<ILegend> = ({ item }) => {
  return (
    <Wrapper>
      <Dot color={item.color} />
      <SharedComponents.Text text={item.type.toUpperCase()} type={"xSmall"} />
    </Wrapper>
  );
};
