import { createContext, useContext } from "react";

import { Types } from "@videoAnalytics";

export const CreateClipsContext =
  createContext<Types.ICreateClipsContextValues | null>(null);

export const useCreateClipsContext = () => {
  const context = useContext(CreateClipsContext);

  if (!context) {
    throw new Error("Create clips context is not defined");
  }

  return context;
};
