import { SharedComponents } from "@shared";
import { ReactElement } from "react";

interface IAddProjectTemplate {
  paddingRight: number;
  title: string;
  steps: Array<string>;
  children: ReactElement[];
}

export const AddProjectTemplate = ({
  children,
  paddingRight,
  title,
  steps,
}: IAddProjectTemplate) => {
  return (
    <SharedComponents.Screen>
      <SharedComponents.Stepper
        title={title}
        paddingRight={paddingRight}
        steps={steps}
      >
        {children}
      </SharedComponents.Stepper>
    </SharedComponents.Screen>
  );
};
