import { observer } from "mobx-react";

import {
  SharedStore,
  SharedComponents,
  Types as SharedTypes,
  Utils,
} from "@shared";

interface IFileRoleController {
  children: React.ReactChildren | React.ReactChild;
}

export const AddProjectController = observer(
  ({ children }: IFileRoleController) => {
    const {
      data: {
        hasNonEmpRole,
        hasNoneRole,
        hasUndefinedRole,
        hasEBPRole,
        loadings,
      },
    } = SharedStore;

    const hasLoading = Utils.useQueryStatus(
      loadings,
      SharedTypes.Endpoints.USER_GLOBAL_ROLE
    );

    if (hasLoading) {
      return <SharedComponents.LoadingIndicator />;
    }

    if (hasUndefinedRole) {
      return <SharedComponents.WaitingIndicator />;
    }

    if (hasNoneRole || hasNonEmpRole || hasEBPRole) {
      return <SharedComponents.DeniedIndicator />;
    }

    return (
      <SharedComponents.Box width={"100%"}>{children}</SharedComponents.Box>
    );
  }
);
