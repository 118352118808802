import { useNavigate } from "react-router-dom";
import { useField, useFormikContext } from "formik";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { computed } from "mobx";

import { CircularProgress } from "@material-ui/core";
import {
  SharedComponents,
  Theme,
  SharedStore,
  Pages,
  Types as SharedTypes,
} from "@shared";
import { VideoAnalyticsStore, Types } from "@videoAnalytics";

import * as AddProjectComponents from "../../components";
import { formatDuration } from "../../utils";

import * as CostsEstimateComponents from "./components";

export const CostsEstimatePage = observer(
  ({ isEditMode }: { isEditMode: boolean }) => {
    /**
     * hooks
     * */
    const navigate = useNavigate();
    /**
     * context values
     * */
    const { values, submitForm } =
      useFormikContext<Types.ICreateProjectFormValues>();
    const {
      spacing: { sm, lg },
    } = Theme.useStyledTheme();
    const { currentStep, setCurrentStep } =
      SharedComponents.useStepperContext();
    /**
     * formik values
     * */
    const [videos] = useField<Array<Types.IVideo>>("videos");
    /**
     * storage values
     * */
    const {
      updateProject,
      data: { loadings, successes, selectedProject },
    } = VideoAnalyticsStore;

    const { initPlatform } = SharedStore;
    const totalVideosDuration = computed(() => {
      // length of videos uploaded during edit session
      const uploadedLength = videos.value.reduce(
        (acc: number, curr: Types.IVideo) => {
          if (curr?.videoLength) {
            const newAcc = curr.videoLength;
            return newAcc;
          }
          return acc;
        },
        0
      );

      if (!selectedProject) return uploadedLength;
      // length of existed project videos
      const existedLength =
        selectedProject?.videos?.reduce((acc: number, curr: Types.IVideo) => {
          if (curr?.videoLength) {
            const newAcc = curr.videoLength;
            return newAcc;
          }
          return acc;
        }, 0) / 1000;
      return uploadedLength + existedLength;
    }).get();

    const updateProjectMutationHasSuccess: boolean = computed(
      () => successes.get(Types.Endpoints.UPDATE_PROJECT) || false
    ).get();
    const updateProjectMutationHasLoading: boolean = computed(
      () => loadings.get(Types.Endpoints.UPDATE_PROJECT) || false
    ).get();

    /**
     * memoize values
     * */
    const onSuccess = useCallback(async () => {
      navigate(SharedTypes.PATHS.VIDEO_ANALYTICS);
      await initPlatform();
    }, [navigate, initPlatform]);

    const onBackHandler = useCallback(
      () => setCurrentStep(currentStep - 1),
      [setCurrentStep, currentStep]
    );

    const onNextHandler = useCallback(async () => {
      if (isEditMode) {
        await submitForm();
        return;
      }
      await updateProject(
        values.id,
        values.costCenter,
        values.name,
        values.tags,
        values.hasCostsAgreement,
        values.brands,
        values.countries,
        values.categories,
        values.regions,
        values.members
      );
    }, [updateProject, values]);

    return (
      <Pages.AddProjectStepTemplate
        fields={["hasCostsAgreement", "costCenter"]}
        nextTitle="Submit"
        onPrev={onBackHandler}
        onNext={onNextHandler}
      >
        <SharedComponents.Box>
          <AddProjectComponents.LoadingModal
            title={"The project is being updated"}
            isOpen={updateProjectMutationHasLoading}
          />
          <SharedComponents.Modal
            Icon={SharedComponents.Icons.IconSuccess}
            type={SharedTypes.ModalTypes.SUCCESS}
            isOpen={updateProjectMutationHasSuccess}
            onConfirm={onSuccess}
            confirmText={"GO TO PROJECT PAGE"}
            title={"The project has been updated"}
          />
          <SharedComponents.VerticalBox height={44} />
          <CostsEstimateComponents.CostItem
            title="Total Video Platform Analytics & Storage Cost Estimate:"
            subtitle={`$1.5 per minute (one time charge)`}
          />
          {!isEditMode && (
            <CostsEstimateComponents.CostItem
              title="Total Project Video Minute Estimate:"
              subtitle={formatDuration(totalVideosDuration)}
            />
          )}
          <CostsEstimateComponents.Separator />
          <SharedComponents.Text type="h3SemiBold" text="Project owner:*" />
          <SharedComponents.VerticalBox height={sm} />
          <SharedComponents.Text type="h3" text={selectedProject?.ownerEmail} />
          <SharedComponents.VerticalBox height={lg} />
          <CostsEstimateComponents.CostCenterInput />
          <SharedComponents.VerticalBox height={sm} />
          <SharedComponents.FormikLib.Checkbox
            title=""
            subtitle="I agree to the total cost estimate and for it to be charged to the cost center provided.  I understand any new/additional videos added to this project will be an incremental cost and will be charged to the same cost center."
            field="hasCostsAgreement"
          />
          {updateProjectMutationHasLoading && (
            <SharedComponents.Row
              justifyContent={"flex-end"}
              padding={`${sm}px`}
            >
              <CircularProgress />
            </SharedComponents.Row>
          )}
        </SharedComponents.Box>
      </Pages.AddProjectStepTemplate>
    );
  }
);
