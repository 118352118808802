import { SVGProps } from "react";

export const IconTextRecognition = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 1.5A1.5 1.5 0 0 1 22.5 3v12a1.5 1.5 0 0 1-1.5 1.5h-3.75a3 3 0 0 0-2.4 1.2L12 21.5l-2.85-3.8a3 3 0 0 0-2.4-1.2H3A1.5 1.5 0 0 1 1.5 15V3A1.5 1.5 0 0 1 3 1.5h18ZM3 0a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h3.75a1.5 1.5 0 0 1 1.2.6l2.85 3.8a1.5 1.5 0 0 0 2.4 0l2.85-3.8a1.5 1.5 0 0 1 1.2-.6H21a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3Z"
      fill="#fff"
    />
    <path
      d="M4.5 5.25a.75.75 0 0 1 .75-.75h13.5a.75.75 0 1 1 0 1.5H5.25a.75.75 0 0 1-.75-.75ZM4.5 9a.75.75 0 0 1 .75-.75h13.5a.75.75 0 1 1 0 1.5H5.25A.75.75 0 0 1 4.5 9Zm0 3.75a.75.75 0 0 1 .75-.75h7.5a.75.75 0 1 1 0 1.5h-7.5a.75.75 0 0 1-.75-.75Z"
      fill="#fff"
    />
  </svg>
);
