import * as React from "react";

export function IconTags(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.438 2.625v8.312l12.687 12.688 8.312-8.313L13.75 2.626H5.437zm-1.813 0A1.813 1.813 0 015.438.812h8.312c.48 0 .941.192 1.281.532L27.72 14.03a1.812 1.812 0 010 2.563l-8.313 8.312a1.812 1.812 0 01-2.562 0L4.156 12.22c-.34-.34-.53-.801-.531-1.282V2.625z"
        fill="#808B9F"
      />
      <path
        d="M9.969 8.063a.906.906 0 110-1.813.906.906 0 010 1.813zm0 1.812a2.719 2.719 0 100-5.438 2.719 2.719 0 000 5.438zm-8.156 1.968c0 .481.19.942.53 1.282L15.86 26.64l-.078.078a1.812 1.812 0 01-2.562 0L.53 14.03c-.34-.34-.53-.8-.531-1.281V4.437a1.813 1.813 0 011.813-1.812v9.218z"
        fill="#808B9F"
      />
    </svg>
  );
}
