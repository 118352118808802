import { lightTheme as theme } from "../../theme";
import { SCROLL_SIZE, SCROLL_BORDER_RADIUS } from "./constants";

export const SCROLLBAR = (function ScrollbarFactory() {
  return `
   //Gecko styles
   scrollbar-width: ${SCROLL_SIZE}px;
   scrollbar-height: ${SCROLL_SIZE}px;
  scrollbar-color:  ${theme.palette.gray};
 
//  Chromium styles
  &::-webkit-scrollbar {
    width: ${SCROLL_SIZE}px;
    height: ${SCROLL_SIZE}px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
     border-radius: ${SCROLL_BORDER_RADIUS}px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: ${SCROLL_BORDER_RADIUS}px;
    background: ${theme.palette.gray};
    :hover {
     background: ${theme.palette.primaryBlue};
     cursor: pointer;
    }
  }
`;
})();
