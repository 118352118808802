import styled from "styled-components";

export const ButtonContainer = styled.div`
  height: 44px;
  padding: 10px 80px;

  :hover {
    cursor: pointer;
  }
`;
