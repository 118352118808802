import { useCallback } from "react";
import { observer } from "mobx-react";
import { SharedComponents, Theme, Pages } from "@shared";
import * as ProjectTagsComponents from "./components";

export const ProjectTagsPage = observer(() => {
  const {
    spacing: { lg },
  } = Theme.useStyledTheme();
  const { currentStep, setCurrentStep } = SharedComponents.useStepperContext();
  const onBackHandler = useCallback(
    () => setCurrentStep(currentStep - 1),
    [setCurrentStep, currentStep]
  );
  const onNextHandler = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [setCurrentStep, currentStep]);

  return (
    <Pages.AddProjectStepTemplate
      fields={["tags", "brands", "regions", "countries", "categories"]}
      nextTitle="Next"
      onPrev={onBackHandler}
      onNext={onNextHandler}
    >
      <SharedComponents.Box>
        <SharedComponents.VerticalBox height={44} />
        <SharedComponents.Row justifyContent={"space-between"}>
          <SharedComponents.TaxonomyFilters isCreation />
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={lg} />
        <ProjectTagsComponents.CustomTags
          title="Custom tags"
          inputField="customTags"
          tagsField="tags"
        />
      </SharedComponents.Box>
    </Pages.AddProjectStepTemplate>
  );
});
