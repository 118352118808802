import { makeObservable, observable, autorun } from "mobx";
import { toast, ToastOptions } from "react-toastify";

type NotificationKey = string;

interface INotification {
  key: NotificationKey;
  msg: string;
  options?: ToastOptions;
}

export class Notificator {
  notifications: Array<INotification>;
  displayedNotifications: Array<NotificationKey>;

  constructor() {
    this.notifications = observable.array<INotification>();
    this.displayedNotifications = observable.array<NotificationKey>();
    autorun(() => {
      this.notifications.forEach((notification) => {
        if (this.displayedNotifications.includes(notification.key)) return;
        // Display snackbar using notistack
        toast.info(notification.msg, {
          position: toast.POSITION.TOP_RIGHT,
          type: notification.options?.type,
        });
        // Keep track of snackbars that we've displayed
        this.addToast(notification.key);
      });
    });
    makeObservable(this, { notifications: true, displayedNotifications: true });
  }

  showToast = (msg: string, options: ToastOptions = { type: "info" }) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random() + "",
      msg,
      options,
    });
  };

  showErrorToast = (msg: string, options: ToastOptions = { type: "info" }) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random() + "",
      msg,
      options: { ...options, type: "error" },
    });
  };

  showSuccessToast = (
    msg: string,
    options: ToastOptions = { type: "info" }
  ) => {
    this.notifications.push({
      key: new Date().getTime() + Math.random() + "",
      msg,
      options: { ...options, type: "success" },
    });
  };

  addToast = (key: string) => {
    this.displayedNotifications = [...this.displayedNotifications, key];
  };

  removeToast = (key: string) => {
    this.notifications = this.notifications.filter(
      (notification) => notification.key !== key
    );
  };
}
