import * as React from "react";

export function IconLogoSmall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 297 297"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M231.25,159.15a49.91,49.91,0,1,0-21,71.44l1.54,2.51-1.47,6,22.35,36.66,13.88-8.41-22.36-36.66-5.94-1.47-1.41-2.39A50.19,50.19,0,0,0,231.25,159.15Zm-78.52,48.58a42.43,42.43,0,1,1,58.33,13.89A42.66,42.66,0,0,1,152.73,207.73Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="logo_color_wihout_pg" data-name="logo color wihout pg">
        <g id="logo">
          <ellipse
            id="bg"
            cx="148.31"
            cy="148.85"
            rx="144.31"
            ry="144.81"
            fill="#003da5"
          />
          <g id="logo-2" data-name="logo">
            <g id="computer">
              <path
                id="bg-2"
                data-name="bg"
                d="M205.76,76.86H84.55C76.22,76.86,69.4,83,69.4,90.45V167.7c0,7.47,6.82,13.59,15.15,13.59H205.76c8.34,0,15.15-6.12,15.15-13.59V90.45C220.91,83,214.1,76.86,205.76,76.86Z"
                fill="#00a3e0"
                fillRule="evenodd"
              />
              <path
                id="play"
                d="M153.5,118.31c-7.68-4.71-16.37-9.48-19.76-11.49-11.27-6.68-10.21-.56-10.21,13.41v17.69c0,14-1.06,20.09,10.21,13.4,3.12-1.84,10.7-6,17.87-10.34C170.72,129.48,172.33,129.85,153.5,118.31Z"
                fill="#003da5"
                fillRule="evenodd"
              />
              <path
                id="border"
                d="M62.91,90.2a21,21,0,0,1,21-21.06H206A21,21,0,0,1,227,90.2v76.59a21,21,0,0,1-21,21.06H161.85l5.72,11.48h15.55a5.75,5.75,0,0,1,0,11.49H106.8a5.75,5.75,0,0,1,0-11.49h15.54l5.72-11.48H83.9a21,21,0,0,1-21-21.06Zm145,87.63H82A9.85,9.85,0,0,1,72.2,168V89A9.85,9.85,0,0,1,82,79.16H207.89A9.85,9.85,0,0,1,217.72,89V168A9.85,9.85,0,0,1,207.89,177.83Z"
                fill="#fff"
                fillRule="evenodd"
              />
            </g>
            <g id="search">
              <ellipse
                id="bg-3"
                data-name="bg"
                cx="188.68"
                cy="185.33"
                rx="42.81"
                ry="42.96"
                fill="#003da5"
              />
              <g id="barchart">
                <path
                  d="M223,201.12V169.61H204.45v49.45A37.28,37.28,0,0,0,209,216.6,38.4,38.4,0,0,0,223,201.12Z"
                  fill="#e87722"
                />
                <path
                  d="M173.53,219.11V192.9H155v8.33c.45.89.93,1.77,1.45,2.63A38.35,38.35,0,0,0,173.53,219.11Z"
                  fill="#fedb00"
                />
                <path
                  d="M198.26,221.24v-40H179.71v40A38.44,38.44,0,0,0,189,222.4,37.59,37.59,0,0,0,198.26,221.24Z"
                  fill="#f1b434"
                />
              </g>
              <g id="search-2" data-name="search">
                <g clipPath="url(#clip-path)">
                  <ellipse
                    id="bg-4"
                    data-name="bg"
                    cx="148"
                    cy="149.29"
                    rx="145"
                    ry="145.5"
                    fill="#fff"
                  />
                  <g id="barchart-2" data-name="barchart">
                    <path
                      d="M223.47,203.15v-31.5H204.58V221.1a37.56,37.56,0,0,0,4.65-2.46A38.58,38.58,0,0,0,223.47,203.15Z"
                      fill="#ec1c24"
                    />
                    <path
                      d="M173.1,221.14v-26.2H154.22v8.33c.45.89.95,1.77,1.47,2.63A38.74,38.74,0,0,0,173.1,221.14Z"
                      fill="#ec1c24"
                    />
                    <path
                      d="M198.28,223.28v-40H179.4v40a39.69,39.69,0,0,0,9.48,1.15A39,39,0,0,0,198.28,223.28Z"
                      fill="#ec1c24"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="color_borders" data-name="color borders">
            <path
              d="M147.66,293.79C67.89,293.79,3,229,3,149.29A143.18,143.18,0,0,1,31.69,62.9l5.31,4A136.61,136.61,0,0,0,9.63,149.29c0,76,61.92,137.88,138,137.88a137.84,137.84,0,0,0,72.17-20.33l3.47,5.64A144.45,144.45,0,0,1,147.66,293.79Z"
              fill="#f1b434"
            />
            <path
              d="M250.31,252l-4.64-4.7a137.16,137.16,0,0,0,40.73-98H293A143.74,143.74,0,0,1,250.31,252Z"
              fill="#e87722"
            />
            <path
              d="M286.19,141.06A138,138,0,0,0,43.06,59.32L38,55a144.59,144.59,0,0,1,254.81,85.68Z"
              fill="#fedb00"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
