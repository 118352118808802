import { useCallback, useMemo } from "react";
import { useField } from "formik";

import { SharedComponents, Theme, Pages } from "@shared";
import { ValuesType } from "@src/modules/video_analytics/pages/add_project/types";

import * as ConsentLib from "./lib";

export const ConsentPage = () => {
  const {
    spacing: { lg, sm },
  } = Theme.useStyledTheme();
  const { currentStep, setCurrentStep } = SharedComponents.useStepperContext();
  const [hasPii] = useField<boolean>("hasPii");

  const onBackHandler = useCallback(
    () => setCurrentStep(currentStep - 1),
    [setCurrentStep, currentStep]
  );

  const onNextHandler = useCallback(
    () => setCurrentStep(currentStep + 1),
    [setCurrentStep, currentStep]
  );

  const fieldsToValidate: Array<ValuesType> = useMemo(
    () =>
      hasPii.value
        ? [
            "hasPii",
            "hasSignedInformedConsent",
            "hasVideoPersonsUnder18",
            "hasConsentFormSignedByParent",
            "hasDelayedFSDSUpload",
            "consentFormLink",
          ]
        : ["hasPii"],
    [hasPii.value]
  );

  return (
    <Pages.AddProjectStepTemplate
      fields={fieldsToValidate}
      nextTitle="Next"
      onPrev={onBackHandler}
      onNext={onNextHandler}
    >
      <SharedComponents.Box>
        <SharedComponents.VerticalBox height={44} />
        <SharedComponents.FormikLib.RadioGroup
          field="hasPii"
          title="Does your Video contain PII (i.e. images of consumers, first/last name, email addresses, etc.)?*"
        />
        {hasPii.value && (
          <>
            <SharedComponents.VerticalBox height={sm} />
            <ConsentLib.PiiNote />
            <SharedComponents.VerticalBox height={lg} />
            <SharedComponents.FormikLib.RadioGroup
              field="hasSignedInformedConsent"
              title="Does your video have signed informed consent that permits the upload to the Video Analytics Platform?*"
            />
            <SharedComponents.VerticalBox height={lg} />
            <SharedComponents.FormikLib.Field
              title="Where are the informed consent forms for the videos related to this project stored?  (i.e. specific sharepoint location, supplier name and contact info, etc.)*"
              field="consentFormLink"
            />
            <SharedComponents.VerticalBox height={sm} />
            <ConsentLib.FormExample />
            <SharedComponents.VerticalBox height={lg} />
            <ConsentLib.InformedConsentForm />
          </>
        )}
        <SharedComponents.VerticalBox height={lg} />
        <ConsentLib.FSDSUploadSection />
      </SharedComponents.Box>
    </Pages.AddProjectStepTemplate>
  );
};
