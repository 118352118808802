import { SVGProps } from "react";

export const IconMultiplyChoice = ({
  fill = "#808B9F",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={0.5}
      width={15}
      height={15}
      rx={1.5}
      fill="#fff"
      stroke={fill}
    />
    <path
      d="M14.104 4.147a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 0 1 .708-.708l3.146 3.147 6.646-6.647a.5.5 0 0 1 .708 0Z"
      fill={fill}
    />
  </svg>
);
