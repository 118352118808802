export interface IGetValidationSchemaProps {
  nameVerificationCb: (name: string) => Promise<boolean>;
  hasEditMode: boolean;
  name?: string;
}

export enum Endpoints {
  COST_CENTER_EXIST = "costCenterExist",
  SEARCH_PROJECTS = "searchProjects",
  FULL_SEARCH = "fullSearch",
  MESSAGES = "messages",
  PROJECT_DETAILS = "projectDetails",
  PROJECT_EXISTS = "projectExist",
  PROJECT_FORM_METADATA = "projectFormMetadata",
  PROJECTS = "projects",
  MORE_PROJECTS = "moreProjects",
  SEARCH_USERS = "searchUsers",
  USER_PROJECTS = "userProjects",
  VIDEO_DETAILS = "videoDetails",
  MORE_USER_PROJECTS = "moreUserProjects",
  USER_ROLE = "userRole",
  VIDEO_EXISTS = "videoExists",
  VIDEOS_DETAILS = "videosDetails",
  CREATE_CLIPS_VIDEO = "createClipsVideo",
  CREATE_FILE = "createFile",
  CREATE_PROJECT = "createProject",
  CREATE_VIDEO = "createVideo",
  DELETE_FILE = "deleteFile",
  DELETE_PROJECT = "deleteProject",
  DELETE_VIDEO = "deleteVideo",
  READ_MESSAGE = "readMessage",
  UPDATE_PROJECT = "updateProject",
  UPDATE_VIDEO = "updateVideo",
  PROJECT_MEDIA = "projectMedia",
  SEARCH_VIDEO_TRANSCRIPT = "searchVideoTranscript",
  GET_USER_INFO = "getUserInfo",
  USER_PROJECTS_STATS = "userProjectsStats",
  PROJECT_STATS = "projectStats",
  PROJECTS_STATS = "projectsStats",
  VIDEO_CLIPS = "videoClips",
  VIDEO_TRANSCRIPTS = "videoTranscripts",
  UPDATE_CLIPS_VIDEO = "updateClipsVideo",
}

export interface IVideoDetailsBasic {
  id: string;
  processedPath: string;
}

export type IEndpoint =
  | typeof Endpoints.VIDEOS_DETAILS
  | typeof Endpoints.SEARCH_VIDEO_TRANSCRIPT
  | typeof Endpoints.COST_CENTER_EXIST
  | typeof Endpoints.FULL_SEARCH
  | typeof Endpoints.SEARCH_PROJECTS
  | typeof Endpoints.PROJECT_MEDIA
  | typeof Endpoints.MESSAGES
  | typeof Endpoints.PROJECT_DETAILS
  | typeof Endpoints.PROJECT_EXISTS
  | typeof Endpoints.PROJECT_FORM_METADATA
  | typeof Endpoints.PROJECTS
  | typeof Endpoints.MORE_PROJECTS
  | typeof Endpoints.SEARCH_USERS
  | typeof Endpoints.USER_PROJECTS
  | typeof Endpoints.VIDEO_DETAILS
  | typeof Endpoints.MORE_USER_PROJECTS
  | typeof Endpoints.USER_ROLE
  | typeof Endpoints.VIDEO_EXISTS
  | typeof Endpoints.CREATE_CLIPS_VIDEO
  | typeof Endpoints.CREATE_FILE
  | typeof Endpoints.CREATE_PROJECT
  | typeof Endpoints.CREATE_VIDEO
  | typeof Endpoints.DELETE_FILE
  | typeof Endpoints.DELETE_PROJECT
  | typeof Endpoints.DELETE_VIDEO
  | typeof Endpoints.READ_MESSAGE
  | typeof Endpoints.UPDATE_PROJECT
  | typeof Endpoints.UPDATE_VIDEO
  | typeof Endpoints.GET_USER_INFO
  | typeof Endpoints.USER_PROJECTS_STATS
  | typeof Endpoints.PROJECT_STATS
  | typeof Endpoints.PROJECTS_STATS
  | typeof Endpoints.VIDEO_CLIPS
  | typeof Endpoints.VIDEO_TRANSCRIPTS
  | typeof Endpoints.UPDATE_CLIPS_VIDEO;

//migration

export interface INameRef {
  id: string;
  name: string;
}

export interface IParentRef {
  id: string;
  name: string;
  parentId: string;
}

export interface IVideo {
  id: string;
  name?: string;
  projectName: string;
  ownerEmail: string;
  thumbnail?: string;
  summary?: string;
  active?: boolean;
  createdAt: string;
  updatedAt: string;
  disabledAt?: string;
  videoLength?: number;
  processedAt?: string;
  status: string;
}

export enum FilesType {
  FILES = "files",
  VIDEOS = "videos",
}

export interface IProject {
  id: string;
  ownerEmail: string;
  name: string;
  description?: string;
  updatedAt: string;
  active: boolean;
  tags: Array<INameRef>;
  taxonomy?: string;
  videos?: Array<IVideo>;
  videosNumber: number;
  status: string;
  hasPii: boolean;
}

export interface IProjectsInput {
  page: number;
}

export interface IProjectsOutput {
  projects: Array<IProject>;
}

export interface IUserProjectsInput {
  page: number;
}

export interface IUserProjectsOutput {
  userProjects: Array<IProject>;
}

export interface IUser {
  id: string;
  email: string;
}

export interface IProjectsStats {
  projectsNumber: number;
  membersNumber: number;
  videosNumber: number;
  totalLength: string;
}

export interface IUserProjectsStats extends IProjectsStats {}

export interface IFullSearchInput {
  text?: string;
  page?: number;
  brands?: Array<string>;
  categories?: Array<string>;
  regions?: Array<string>;
  countries?: string;
}

export interface IFullSearchOutput {
  fullSearch: {
    projects: Array<ISearchItem>;
    videos: Array<ISearchItem>;
  };
}

export interface ITransformation {
  id: string;
  name: string;
  description: string;
}

export interface IEmotion {
  color: string;
  percent: number;
  count: number;
}

export interface IBasicEmotions {
  negative: IEmotion;
  neutral: IEmotion;
  positive: IEmotion;
}

export interface IDetailedEmotions {
  angry: IEmotion;
  calm: IEmotion;
  confused: IEmotion;
  disgusted: IEmotion;
  fear: IEmotion;
  happy: IEmotion;
  sad: IEmotion;
  surprised: IEmotion;
}

export interface IProjectEmotions {
  basic?: IBasicEmotions;
  detailed?: IDetailedEmotions;
}

export interface IProjectStats {
  videosLength: string;
  videosNumber: number;
  summariesLength: string;
  summariesNumber: number;
  totalLength: string;
  filesNumber: number;
}

export interface IProjectDetails {
  id: string;
  ownerEmail: string;
  name: string;
  updatedAt: string;
  description: string;
  active: boolean;
  costCenter: string;
  consentFormLink: string;
  startDate: string;
  endDate: string;
  clp: string;
  brands: Array<IParentRef>;
  categories: Array<INameRef>;
  regions: Array<INameRef>;
  countries: Array<IParentRef>;
  tags: Array<INameRef>;
  members: Array<IUser>;
  videos: Array<IVideo>;
  summaries: Array<IVideo>;
  files: Array<IFile>;
  taxonomy?: string;
  transformations: Array<ITransformation>;
  status: string;
  hasPii: boolean;
  hasSignedInformedConsent: boolean;
  hasVideoPersonsUnder18?: boolean;
  hasConsentFormSignedByParent?: boolean;
  hasCostsAgreed: boolean;
  hasDelayedFSDSUpload: boolean;
  FSDSUploadDate?: string;
  stats: IProjectStats;
  emotions: IProjectEmotions;
}

export interface IProjectDetailsInput {
  id: string;
}

export interface IProjectDetailsOutput {
  projectDetails: IProjectDetails;
}

export interface IProjectsStatsOutput {
  projectsStats: IProjectsStats;
}

export interface IUserProjectsStatsOutput {
  userProjectsStats: IUserProjectsStats;
}

export interface ISearchProjectsInput {
  text?: string;
  page?: number;
  brands?: Array<string>;
  categories?: Array<string>;
  regions?: Array<string>;
  countries?: Array<string>;
  types?: Array<"VIDEO">;
}

export interface ISearchProjectsOutput {
  searchProjects: {
    projects: Array<IProject>;
    stats: IProjectsStats;
  };
}

export interface ISearchUsersInput {
  text: string;
}

export interface ISearchUsersOutput {
  searchUsers: Array<{ email: string }>;
}

export interface IUserRoleInput {
  entityId: string;
  entityType: string;
}

export interface IUserRoleOutput {
  userRole: {
    role: string;
  };
}

export interface ISummaryClip {
  endTime: string;
  precedence: string;
  startTime: string;
  thumbnail: string;
}

export interface ISummaryVideo {
  id: string;
  name: string;
  videoUrl: string;
  clips: Array<ISummaryClip>;
}

export enum BlurMode {
  BlurEyes = "BlurEyes",
  BlurEyesNoseMouth = "BlurEyesNoseMouth",
  BlurForeheadEyesNose = "BlurForeheadEyesNose",
  BlurFace = "BlurFace",
}

export interface IVideoClips {
  projectId: string;
  summary: string;
  name: string;
  videos: Array<ISummaryVideo>;
}

export interface IVideoClipsInput {
  id: string;
}

export interface IVideoClipsOutput {
  videoClips: IVideoClips;
}

export interface ICreateClipsContextValues {
  overscanCount: number;
  listItemHeight: number;
  timelineHeight: number;
}

export interface IVideoStats {
  videoLength: string;
  totalWordsNumber: number;
}

export interface ITranscriptEmotion {
  color: string;
  percent: number;
  count: number;
}

export interface ITranscriptSentence {
  endTime: string;
  startTime: string;
  translatedText: string;
  language: string;
  text: string;
  speakerId: string;
  sentiment: {
    negative: ITranscriptEmotion;
    neutral: ITranscriptEmotion;
    positive: ITranscriptEmotion;
  };
}

export interface IParsedEmotion {
  color: string;
  type: string;
  percent: number;
  count: number;
}

export enum ProjectVideoStatuses {
  PENDING = "PENDING",
  UPLOADED = "UPLOADED",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}

export interface ITranscriptsOutputTimelineItem {
  startTime: number;
  endTime: number;
  emotion: number;
  text: string;
}

export interface ITranscriptsOutputItem {
  speakerId: string;
  timelines: Array<ITranscriptsOutputTimelineItem>;
}

export interface IFaceEmotions {
  general: {
    basic: {
      positive: IEmotion;
      neutral: IEmotion;
      negative: IEmotion;
    };
    detailed: {
      happy: IEmotion;
      sad: IEmotion;
      angry: IEmotion;
      confused: IEmotion;
      disgusted: IEmotion;
      surprised: IEmotion;
      calm: IEmotion;
      fear: IEmotion;
    };
  };
  tracked: Array<{
    endTime: string;
    startTime: string;
    negative: ITranscriptEmotion;
    neutral: ITranscriptEmotion;
    positive: ITranscriptEmotion;
  }>;
}

export interface ITopWord {
  word: string;
  number: number;
}

export interface ISentence {
  speakerId: string;
  language: string;
  text: string;
  translatedText: string;
  startTime: string;
  endTime: string;
  sentiment: {
    mixed: ITranscriptEmotion;
    negative: ITranscriptEmotion;
    neutral: ITranscriptEmotion;
    positive: ITranscriptEmotion;
  };
}

export interface ISearchItem {
  id: string;
  projectId: string;
  name: string;
  results: Array<any>;
}

export interface IVideoDetails {
  id: string;
  name?: string;
  ownerEmail: string;
  rawPath?: string;
  processedPath?: string;
  downloadPath?: string;
  thumbnail?: string;
  summary?: string;
  projectName: string;
  projectHasPii?: boolean;
  tags?: Array<string>;
  createdAt: string;
  updatedAt: string;
  disabledAt: string;
  processedAt: string;

  status: string;
  active: boolean;
  stats: IVideoStats;
  emotions: {
    basic: Array<{
      endTime: string;
      startTime: string;
      negative: ITranscriptEmotion;
      neutral: ITranscriptEmotion;
      positive: ITranscriptEmotion;
    }>;
    detail: Array<{
      endTime: string;
      startTime: string;
      angry: ITranscriptEmotion;
      calm: ITranscriptEmotion;
      confused: ITranscriptEmotion;
      disgusted: ITranscriptEmotion;
      fear: ITranscriptEmotion;
      happy: ITranscriptEmotion;
      sad: ITranscriptEmotion;
      surprised: ITranscriptEmotion;
    }>;
  };
  transcripts: ITranscripts;
  outputFiles: Array<{
    rawPath: string;
    processedPath: string;
  }>;
}

export interface IFile {
  id: string;
  ownerEmail: string;
  fileUrl?: string;
  thumbnail?: string;
  projectName: string;
  name?: string;
  createdAt: string;
  updatedAt: string;
  disabledAt?: string;
  status: string;
  slug?: string;
  active?: boolean;
}

export interface IVideoDetailsInput {
  id: string;
}

export interface IVideoDetailsOutput {
  videoDetails: IVideoDetails;
}

export interface IVideoTranscriptsInput {
  id: string;
  page: number;
}

export interface IVideoTranscriptsOutput {
  videoTranscript: {
    sentences: Array<ITranscriptSentence>;
    sentencesNumber: number;
  };
}

export interface IProjectExistOutput {
  projectExist: boolean;
}

export interface IVideoClip {
  fileId: string;
  startTime: string;
  endTime: string;
  thumbnail: string;
}

export interface ICreateClipsInput {
  name: string;
  summary: string;
  projectId: string;
  slug: string;
  clips: Array<IVideoClip>;
  thumbnail: string;
}

export interface ICreateClipsOutput {
  createClipsVideo: IVideoDetails;
}

export interface ICreateFileInput {
  name: string;
  projectId: string;
  slug: string;
  id: string;
}

export interface ICreateFileInputExtended extends ICreateFileInput {
  body: File;
}

export interface ICreateFileOutput {
  createFile: IVideoDetails;
}

export enum ProjectStatuses {
  PENDING = "PENDING",
  CONFIGURED = "CONFIGURED",
}

export type ProjectsStatusType =
  | typeof ProjectStatuses.PENDING
  | typeof ProjectStatuses.CONFIGURED;

export interface ICreateProjectFormValues {
  id: string;
  hasPii?: boolean;
  hasDelayedFSDSUpload?: boolean;
  hasSignedInformedConsent?: boolean;
  hasVideoPersonsUnder18?: boolean;
  hasConsentFormSignedByParent?: boolean;
  hasCostsAgreement: boolean;
  members: Array<string>;
  customTag: string;
  tags: Array<string>;
  brands: Array<string>;
  regions: Array<string>;
  countries: Array<string>;
  categories: Array<string>;
  videos: Array<IVideo>;
  status?: string;
  name: string;
  blurMode: string;
  summary: string;
  email: string;
  costCenter: string;
  consentFormLink: string;
  cmk: string;
  startDate: Date | string;
  endDate: Date | string;
  FSDSUploadDate?: Date;
  files: Array<IFile>;
}

export interface ICreateProjectInput {
  name: string;
  type: "VIDEO";
  description: string;
  consentFormLink: string;
  startDate: string;
  endDate: string;
  clp: string;
  brands: Array<string>;
  categories: Array<string>;
  regions: Array<string>;
  countries: Array<string>;
  tags: Array<string>;
  members: Array<string>;
  transformations: Array<string>;
  hasPii: boolean;
  hasSignedInformedConsent?: boolean;
  hasVideoPersonsUnder18?: boolean;
  hasConsentFormSignedByParent?: boolean;
  costCenter?: string;
  hasCostsAgreed?: boolean;
  hasDelayedFSDSUpload: boolean;
  FSDSUploadDate?: string;
  blurMode?: string;
  slug?: string;
}

export interface ICreateProjectOutput {
  createProject: IProjectDetails;
}

export interface ICreateVideoInput {
  id: string;
  name: string;
  projectId: string;
  slug: string;
  tags?: Array<string>;
}

export interface ICreateVideoInputExtended extends ICreateVideoInput {
  body: File;
  videoLength: number;
}

export interface ICreateVideoOutput {
  createVideo: IVideoDetails;
}

export interface IDeleteFileInput {
  id: string;
}

export interface IDeleteFileOutput {
  deleteFile: IVideo;
}

export interface IDeleteProjectInput {
  id: string;
}

export interface IDeleteProjectOutput {
  deleteProject: IProject;
}

export interface IDeleteVideoInput {
  id: string;
}

export interface IDeleteVideoOutput {
  deleteVideo: IVideo;
}

export interface IClipsVideoInput {
  fileId: string;
  startTime: string;
  endTime: string;
  thumbnail?: string;
}

export interface IUpdateClipsVideoInput {
  id: string;
  name?: string;
  summary?: string;
  thumbnail?: string;
  clips: Array<IClipsVideoInput>;
}

export interface IUpdateClipsVideoOutput {
  updateClipsVideo: IVideo;
}

export interface IUpdateProjectInput {
  id: string;
  name?: string;
  status?: string;
  costCenter?: string;
  hasCostsAgreed?: boolean;
  endDate?: string;
  tags?: Array<string>;
  members?: Array<string>;
  brands?: Array<string>;
  categories?: Array<string>;
  regions?: Array<string>;
  countries?: Array<string>;
}

export interface IUpdateProjectOutput {
  updateProject: IProjectDetails;
}

export interface IUpdateVideoInput {
  id: string;
  name?: string;
  status?: string;
  tags?: Array<string>;
}

export interface IUpdateVideoOutput {
  updateVideo: IVideoDetails;
}

export interface IVideosDetailsInput {
  id: string;
}

export interface IVideosDetailsOutput {
  videosDetails: Array<IVideoDetails>;
}

export interface ICostCenterExistInput {
  id: string;
}

export interface ICostCenterExistOutput {
  costCenterExist: boolean;
}

export interface ISearchVideoTranscriptInput {
  page: number;
  videoId: string;
  text: string;
}

export interface ISearchVideoTranscriptItem {
  startTime: string;
  speakerId: string;
  endTime: string;
  text: string;
  translatedText?: string | null;
}

export interface ISearchVideoTranscriptOutput {
  searchVideoTranscript: {
    results: Array<ISearchVideoTranscriptItem>;
  };
}

export interface ITranscript extends ISearchVideoTranscriptItem {
  endTime: string;
  language: string;
  startTime: string;
  text: string;
}

export interface ITranscripts {
  translatedTopWords: Array<ITopWord> | null;
  topWords: Array<ITopWord> | null;
  emotions: {
    general: {
      sentiment: "Mixed" | "Negative" | "Neutral" | "Positive";
      sentimentScore: {
        mixed: ITranscriptEmotion;
        negative: ITranscriptEmotion;
        neutral: ITranscriptEmotion;
        positive: ITranscriptEmotion;
      };
    };
    tracked: {
      parts: Array<{ sentences: Array<ITranscriptSentence> }>;
    };
  };
}

export type FileType = "video" | "summary" | "file";

export interface IProjectMediaInput {
  id: string;
  page: number;
  fileType: FileType;
  itemsPerPage: number;
}

export interface IProjectMedia {
  files: Array<IFile>;
  videos: Array<IVideo>;
  summaries: Array<IVideo>;
  pageCount: number;
  totalCount: number;
}

export interface IProjectMediaOutput {
  projectMedia: IProjectMedia;
}
