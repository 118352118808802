import * as React from "react";

export function IconDuration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={29}
      height={29}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 6.344a.906.906 0 00-1.813 0v9.968a.907.907 0 00.457.787l6.344 3.625a.907.907 0 00.899-1.573L14.5 15.787V6.344z"
        fill="#808B9F"
      />
      <path
        d="M14.5 29a14.5 14.5 0 100-29 14.5 14.5 0 000 29zm12.688-14.5a12.687 12.687 0 11-25.375 0 12.687 12.687 0 0125.375 0z"
        fill="#808B9F"
      />
    </svg>
  );
}
