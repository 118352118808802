import { SVGProps } from "react";

export const IconRadio = ({
  fill = "#808B9F",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={0.5}
      width={15}
      height={15}
      rx={7.5}
      fill="#fff"
      stroke={fill}
    />
    <rect
      x={5}
      y={4.5}
      width={7}
      height={7}
      rx={3.5}
      fill={fill}
      stroke={fill}
    />
  </svg>
);
