import { SVGProps } from "react";

export const IconImageRecognition = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.003.5a3 3 0 0 0-3 3v15a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-15a3 3 0 0 0-3-3h-18Zm18 1.5a1.5 1.5 0 0 1 1.5 1.5v9.75l-5.666-2.92a.75.75 0 0 0-.865.139l-5.565 5.565-3.99-2.658a.75.75 0 0 0-.945.093L1.503 17V3.5a1.5 1.5 0 0 1 1.5-1.5h18Z"
      fill="#fff"
    />
  </svg>
);
