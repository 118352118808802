import { makeObservable } from "mobx";
import AWSAppSyncClient from "aws-appsync";

import { Types as SharedTypes } from "@shared";

import { Notificator } from "../store/basic";

import { Data } from "./data";
import { Client } from "./client";

export class _SharedStore extends Notificator {
  client: Client;
  data: Data;

  constructor({ awsClient }: SharedTypes.IAWSClientParams) {
    super();
    this.data = new Data();
    this.client = new Client({ awsClient });
    makeObservable(this, { data: true, client: true });
  }

  fetchUserData = async () => {
    const endpoint = SharedTypes.Endpoints.GET_USER_INFO;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.getUserInfo();
      if (response) {
        this.data.setUser(response);
        this.data.setRequestSuccess(endpoint);
      }
    } catch (e) {
      this.data.setRequestError(endpoint);
    }
  };

  getProjectMetadata = async () => {
    const endpoint = SharedTypes.Endpoints.PROJECT_FORM_METADATA;
    try {
      this.data.setRequestLoading(endpoint);
      const data = await this.client.projectFormMetaData();
      if (data) {
        this.data.setFormMetaData(data);
        this.data.setRequestSuccess(endpoint);
      }
    } catch (e) {
      this.data.setRequestError(endpoint);
    }
  };

  getUserGlobalRole = async () => {
    const endpoint = SharedTypes.Endpoints.GET_USER_INFO;
    try {
      this.data.setRequestReset(endpoint);
      this.data.setRequestLoading(endpoint);
      const data = await this.client.userGlobalRole();
      if (!data || !data?.userGlobalRole) {
        this.showErrorToast("Error while getting global role: null data");
        this.data.setRequestError(endpoint);
        return;
      }
      this.data.setGlobalRole(data.userGlobalRole);
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast("Error while getting global role:", e);
      this.data.setRequestError(endpoint);
    }
  };

  getCostCenterValidation = async (id: string): Promise<boolean> => {
    return await this.client.costCenterExists(id);
  };

  projectExist = async (name: string): Promise<boolean> => {
    const endpoint = SharedTypes.Endpoints.PROJECT_EXISTS;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.projectExist(name);
      if (!response) {
        throw new Error('"Error while getting project information');
      }
      this.data.setRequestSuccess(endpoint);
      return response.projectExist;
    } catch (e: any) {
      this.showErrorToast("Error while getting project information:", e);
      this.data.setRequestError(endpoint);
      return true;
    }
  };

  copyURLtoClipboard = async () => {
    await navigator.clipboard.writeText(window.location.href);
    this.showToast("URL copied to clipboard");
  };

  initPlatform = async () => {
    await Promise.all([
      this.fetchUserData(),
      this.getUserGlobalRole(),
      this.getProjectMetadata(),
    ]);
  };
}

export const SharedStore = new _SharedStore({
  awsClient: AWSAppSyncClient,
});
