import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { OutlinedTextFieldProps } from "@material-ui/core/TextField/TextField";

export const CustomTextField = styled(TextField)<OutlinedTextFieldProps>`
  flex: 1;
  &.MuiFormControl-root {
    margin-right: ${({ theme }) => theme.spacing.md}px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.primaryBlue};
  }
`;
