import moment from "moment";
import { FC, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { SharedComponents, Theme, Types as SharedTypes, Utils } from "@shared";
import { IconButton } from "@material-ui/core";
import { Types } from "@videoAnalytics";

import * as LocalComponents from "./lib";
import { Img } from "../video_card/lib";

export interface IVideoCardSmall {
  item: Types.IFile;
  pathTo: string;
  disabled?: boolean;
  hasAccess: boolean;
  onDelete: () => void;
}

const PendingCoverAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VideoCardSmall: FC<IVideoCardSmall> = ({
  item,
  pathTo,
  disabled = false,
  hasAccess,
  onDelete,
}) => {
  const { spacing, palette } = Theme.useStyledTheme();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  if (disabled) {
    return (
      <>
        <SharedComponents.Modal
          isOpen={open}
          cancelText={"No"}
          onCancel={handleClose}
          onConfirm={() => {
            handleClose();
            onDelete();
          }}
          confirmText={"Yes"}
          type={SharedTypes.ModalTypes.DEFAULT}
          title={"Do you want to delete this video ?"}
        />
        <SharedComponents.Row height={"100%"} width={"100%"}>
          <LocalComponents.PendingCover>
            <Img
              src={"/pending.webp"}
              style={{ filter: "brightness(50%)" }}
              alt=""
            />
            <PendingCoverAbsolute>
              <SharedComponents.Text
                text={"Video is being processed"}
                type={"xSmallMedium"}
                color={palette.primaryWhite}
              />
            </PendingCoverAbsolute>
          </LocalComponents.PendingCover>
          <SharedComponents.Column alignItems={"space-between"}>
            <SharedComponents.Text
              text={item.name}
              type={"button"}
              margin={`0 0 ${spacing.sm}px 0`}
            />
            {/*<SharedComponents.Row  justifyContent={"flex-start"}>*/}
            {/*  {inputItem.tags?.map(renderTag)}*/}
            {/*</SharedComponents.Row>*/}
            <SharedComponents.Row>
              <SharedComponents.Text
                text={item.ownerEmail}
                type={"xSmall"}
                margin={`0 ${spacing.md}px 0 0`}
              />
              <SharedComponents.Text
                text={moment(item.createdAt).format("MM/DD/YYYY, h:mm:ss A")}
                type={"xSmall"}
              />
            </SharedComponents.Row>
          </SharedComponents.Column>
          <SharedComponents.Column alignItems={"flex-end"}>
            {hasAccess && (
              <IconButton onClick={handleClickOpen}>
                <SharedComponents.Icons.IconDelete />
              </IconButton>
            )}
          </SharedComponents.Column>
        </SharedComponents.Row>
      </>
    );
  }

  return (
    <>
      <SharedComponents.Modal
        isOpen={open}
        cancelText={"No"}
        onCancel={handleClose}
        onConfirm={() => {
          handleClose();
          onDelete();
        }}
        confirmText={"Yes"}
        type={SharedTypes.ModalTypes.DEFAULT}
        title={"Do you want to delete this video ?"}
      />
      <LocalComponents.Wrapper
        tabIndex={0}
        onKeyDown={Utils.onKeyDown(() => navigate(pathTo))}
      >
        <SharedComponents.Link to={pathTo}>
          {item.status !== Types.ProjectVideoStatuses.SUCCEEDED && (
            <LocalComponents.PendingCover>
              <Img
                src={"/pending.webp"}
                style={{ filter: "brightness(50%)" }}
                alt=""
              />
              <PendingCoverAbsolute>
                <SharedComponents.Text
                  text={"Video is being processed"}
                  type={"xSmallMedium"}
                  color={palette.primaryWhite}
                />
              </PendingCoverAbsolute>
            </LocalComponents.PendingCover>
          )}
          {item.status === Types.ProjectVideoStatuses.SUCCEEDED && (
            <LocalComponents.Thumbnail src={item.thumbnail} />
          )}
        </SharedComponents.Link>
        <SharedComponents.Link to={pathTo}>
          <SharedComponents.Column alignItems={"space-between"} flex={4}>
            <SharedComponents.Text
              text={item.name}
              type={"button"}
              margin={`0 0 ${spacing.sm}px 0`}
            />
            <SharedComponents.Row>
              <SharedComponents.Text
                text={item.ownerEmail}
                type={"xSmall"}
                margin={`0 ${spacing.md}px 0 0`}
              />
              <SharedComponents.Text
                text={moment(item.createdAt).format("MM/DD/YYYY, h:mm:ss A")}
                type={"xSmall"}
              />
            </SharedComponents.Row>
          </SharedComponents.Column>
        </SharedComponents.Link>
        <SharedComponents.Column alignItems={"flex-end"}>
          {hasAccess && (
            <IconButton onClick={handleClickOpen}>
              <SharedComponents.Icons.IconDelete />
            </IconButton>
          )}
        </SharedComponents.Column>
      </LocalComponents.Wrapper>
    </>
  );
};
