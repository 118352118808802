import { FC, memo, useCallback, useMemo, useState } from "react";
import { useField } from "formik";
import { SharedComponents } from "@shared";
import { useStyledTheme } from "../../../../theme";
import { ISearchField } from "./types";
import * as SearchLib from "./lib";

export const Search: FC<ISearchField> = memo(
  ({
    height = 44,
    field,
    type = "text",
    placeholder,
    component,
    onKeyDown,
    onChange,
  }) => {
    const {
      palette: { primaryRed, gray, primaryBlue },
    } = useStyledTheme();
    const [input, meta, helpers] = useField(field);
    const [hasFocus, setFocus] = useState(false);
    const onBlur = useCallback(() => setFocus(false), [setFocus]);
    const onFocus = useCallback(() => setFocus(true), [setFocus]);
    const hasFocusOrValue = useMemo(
      () => (input.value && input!!.value.length > 0) || hasFocus,
      [input, hasFocus]
    );
    return (
      <SharedComponents.Column height={"fit-content"} flex={0}>
        <SearchLib.Wrapper>
          <SearchLib.StyledField
            hasFocus={hasFocusOrValue}
            onBlur={onBlur}
            onFocus={onFocus}
            height={height}
            component={component}
            placeholder={placeholder}
            type={type}
            name={field}
            onKeyDown={onKeyDown}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              helpers.setValue(e.target.value);
              onChange && e.target.value.length > 0 && onChange(e.target.value);
            }}
          />
          <SearchLib.Inner>
            <SharedComponents.Icons.IconSearch
              fill={hasFocusOrValue ? primaryBlue : gray}
            />
          </SearchLib.Inner>
        </SearchLib.Wrapper>
        {meta.error && meta.touched && (
          <SharedComponents.Text
            text={meta.error}
            type={"small"}
            color={primaryRed}
          />
        )}
      </SharedComponents.Column>
    );
  }
);
