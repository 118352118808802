import * as React from "react";

export function IconVideo({
  fill = "#808B9F",
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 44 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.75A5.5 5.5 0 015.5.25h20.625a5.5 5.5 0 015.453 4.78l8.553-3.801A2.75 2.75 0 0144 3.739V24.26a2.75 2.75 0 01-3.867 2.511l-8.555-3.8a5.5 5.5 0 01-5.453 4.779H5.5a5.5 5.5 0 01-5.5-5.5V5.75zm31.625 14.231l9.625 4.28V3.74l-9.625 4.279V19.98zM5.5 3a2.75 2.75 0 00-2.75 2.75v16.5A2.75 2.75 0 005.5 25h20.625a2.75 2.75 0 002.75-2.75V5.75A2.75 2.75 0 0026.125 3H5.5z"
        fill={fill}
      />
      <path
        d="M16.5 7c.398 0 .78.145 1.06.403.282.258.44.607.44.972V12.5h4.5c.398 0 .78.145 1.06.403.282.258.44.607.44.972s-.158.714-.44.972a1.572 1.572 0 01-1.06.403H18v4.125c0 .365-.158.714-.44.972a1.572 1.572 0 01-1.06.403c-.398 0-.78-.145-1.06-.403a1.32 1.32 0 01-.44-.972V15.25h-4.5c-.398 0-.78-.145-1.06-.403a1.32 1.32 0 01-.44-.972c0-.365.158-.714.44-.972a1.572 1.572 0 011.06-.403H15V8.375c0-.365.158-.714.44-.972A1.572 1.572 0 0116.5 7z"
        fill={fill}
      />
    </svg>
  );
}
