import { SVGProps } from "react";

export const IconUserFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.5 9a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z" fill="#003DA5" />
    <path
      d="M3 0a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3Zm18 1.5A1.5 1.5 0 0 1 22.5 3v18a1.5 1.5 0 0 1-1.5 1.5V21c0-1.5-1.5-6-9-6s-9 4.5-9 6v1.5A1.5 1.5 0 0 1 1.5 21V3A1.5 1.5 0 0 1 3 1.5h18Z"
      fill="#003DA5"
    />
  </svg>
);
