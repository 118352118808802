import { useNavigate } from "react-router-dom";
import { FC, useMemo } from "react";
import moment from "moment";

import { Utils } from "@shared";

import { useStyledTheme } from "../../theme";
import * as Types from "../../types";

import { Text } from "../text";
import { IVideoCard } from "./types";
import * as VideoCardLib from "./lib";
import { Link } from "../custom_link";
import { Column } from "../containers";
import { VerticalBox } from "../vertical_box";

const VideoCardWrapper: FC<IVideoCard> = ({ item, projectId, children }) => {
  const {
    spacing: { sm, md },
  } = useStyledTheme();

  const navigate = useNavigate();

  const parseCreationDate = useMemo(
    () => moment(item.createdAt).format("MM/DD/YYYY hh:mm:ss A"),
    [item.createdAt]
  );

  const to = `${Types.PATHS.VIDEO_ANALYTICS}/project/${projectId}/video/${item.id}`;

  return (
    <Link to={to}>
      <VideoCardLib.Container onKeyDown={Utils.onKeyDown(() => navigate(to))}>
        {children}
        <Column
          flex={0}
          height={"148px"}
          justifyContent={"space-between"}
          padding={`${sm / 2}px ${md}px`}
        >
          <Text type="h2" text={item.name} />
          <Column flex={0} height={"fit-content"}>
            <Text type="paragraph" text={item.ownerEmail} />
            <VerticalBox height={sm / 2} />
            <Text type="small" text={parseCreationDate} />
          </Column>
        </Column>
      </VideoCardLib.Container>
    </Link>
  );
};

export const VideoCard: FC<IVideoCard> = (props) => {
  if (props.item.status !== Types.ProjectVideoStatuses.SUCCEEDED) {
    return (
      <VideoCardWrapper {...props}>
        <VideoCardLib.PendingCover />
      </VideoCardWrapper>
    );
  }
  return (
    <VideoCardWrapper {...props}>
      <VideoCardLib.Img src={props.item.thumbnail} />
    </VideoCardWrapper>
  );
};
