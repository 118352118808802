import styled from "styled-components";
import { Pagination as P } from "@material-ui/lab";

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.palette.gray};
  margin-top: 16px;
`;

export const PaginationSelectContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

export const PaginationSelect = styled.select`
  border: none;
  outline: none;
  color: ${({ theme }) => theme.palette.primaryBlue};
  font-weight: 600;
  font-size: 0.75rem;
`;

export const Pagination = styled(P)`
  &.MuiPaginationItem-page {
    color: ${({ theme }) => theme.palette.primaryBlue};
    font-weight: 600;
    font-size: 0.75rem;
  }
`;
