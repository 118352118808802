import { LegendOrdinal, LegendItem, LegendLabel } from "@visx/legend";
import { Grid, XYChart, LineSeries, Axis } from "@visx/xychart";
import { ScaleInput, scaleOrdinal } from "@visx/scale";
import { TickFormatter } from "@visx/axis/lib/types";
import { curveBasis } from "@visx/curve";
import ReactPlayer from "react-player";
import { observer } from "mobx-react";
import { RefObject } from "react";

import { VideoAnalyticsStore } from "@videoAnalytics";
import { Theme, SharedComponents } from "@shared";
import styled from "styled-components";

interface ILineItem {
  x: string;
  y?: number;
}

export type ILineChart = {
  width: number;
  height: number;
  label: string;
  title: string;
  chartData: Record<string, Array<ILineItem>>;
  playerRef: RefObject<ReactPlayer>;
  getColorByKey: (key: string) => string;
  tickFormat?: TickFormatter<ScaleInput<any>>;
  colorRange: Array<string>;
  labelFormat?: (label: string) => string;
};

const LINE_CHART_CONFIG = {
  legendGlyphSize: 8,
  margin: {
    top: 16,
    right: 0,
    bottom: 64,
    left: 100,
  },
  accessors: {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y,
  },
  xTicks: 4,
  yTicks: 10,
  gridTicks: 10,
};

export const VideoLineChart = observer(
  ({
    width,
    height,
    label,
    title,
    chartData,
    playerRef,
    getColorByKey,
    tickFormat,
    colorRange,
    labelFormat,
  }: ILineChart) => {
    const {
      palette: { primaryBlue },
    } = Theme.useStyledTheme();
    const {
      data: { videoTranscriptPlayerProgress },
    } = VideoAnalyticsStore;

    const duration = playerRef.current?.getDuration() || 1;

    const x =
      (videoTranscriptPlayerProgress / duration) *
        (width - LINE_CHART_CONFIG.margin.left) +
      LINE_CHART_CONFIG.margin.left -
      1;

    const seekTo = (x?: number) => {
      if (!x) return;
      const progress =
        ((x - LINE_CHART_CONFIG.margin.left) /
          (width - LINE_CHART_CONFIG.margin.left)) *
        duration;
      playerRef.current?.seekTo(progress);
    };

    const colorScale = scaleOrdinal<string, string>({
      domain: Object.keys(chartData),
      range: colorRange,
    });

    const renderLegend = () => (
      <div>
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <SharedComponents.Row alignItems={"center"}>
              {labels.map((label, i) => (
                <LegendItem key={`legend-${i}`} alignItems={"center"}>
                  <svg
                    width={LINE_CHART_CONFIG.legendGlyphSize}
                    height={LINE_CHART_CONFIG.legendGlyphSize}
                  >
                    <circle
                      fill={label.value}
                      r={LINE_CHART_CONFIG.legendGlyphSize / 2}
                      cx={LINE_CHART_CONFIG.legendGlyphSize / 2}
                      cy={LINE_CHART_CONFIG.legendGlyphSize / 2}
                    />
                  </svg>
                  <LegendLabel
                    style={{ whiteSpace: "nowrap", margin: "0 16px 0 8px" }}
                    align="left"
                  >
                    {labelFormat
                      ? labelFormat(label.text)
                      : label.text.toUpperCase()}
                  </LegendLabel>
                </LegendItem>
              ))}
            </SharedComponents.Row>
          )}
        </LegendOrdinal>
      </div>
    );

    const renderLineSeries = (line: Array<ILineItem>, idx: number) => (
      <LineSeries
        offset={10}
        stroke={getColorByKey(Object.keys(chartData)[idx])}
        dataKey={`${idx}`}
        data={line}
        {...LINE_CHART_CONFIG.accessors}
        curve={curveBasis}
      />
    );

    return (
      <div>
        <SharedComponents.Text whiteSpace={"nowrap"} text={title} type={"h2"} />
        <SharedComponents.VerticalBox height={16} />
        {renderLegend()}
        <Hover>
          <XYChart
            xScale={{ type: "band" }}
            yScale={{ type: "linear" }}
            height={height - 40}
            width={width}
            margin={LINE_CHART_CONFIG.margin}
            onPointerDown={(event) => seekTo(event.svgPoint?.x)}
          >
            <Grid
              key={`grid`}
              columns={false}
              numTicks={LINE_CHART_CONFIG.gridTicks}
              strokeDasharray={"3"}
            />
            <>{Object.values(chartData).map(renderLineSeries)}</>
            <Axis
              key={`y-axis`}
              orientation="left"
              stroke={"none"}
              labelOffset={LINE_CHART_CONFIG.margin.left - 40}
              label={label}
              tickFormat={tickFormat}
            />
            <Axis
              key={`x-axis`}
              orientation="bottom"
              numTicks={LINE_CHART_CONFIG.xTicks}
              strokeWidth={0}
              stroke={"none"}
            />
            <path
              strokeWidth={2}
              stroke={primaryBlue}
              d={`M${x},${0} L${x},${height - 72}`}
            />
          </XYChart>
        </Hover>
      </div>
    );
  }
);

const Hover = styled.div`
  :hover {
    cursor: pointer;
  }
`;
