import { SharedComponents, Theme } from "@shared";

export const Tutorial = () => {
  const {
    spacing: { lg },
  } = Theme.useStyledTheme();
  return (
    <SharedComponents.Screen>
      <SharedComponents.VerticalBox height={44} />
      <SharedComponents.Text
        type={"h1"}
        text={'VAP "How To" Tutorial - Watch to learn more about VAP!'}
      />
      <SharedComponents.VerticalBox height={lg} />
      <SharedComponents.Player url={process.env.REACT_APP_TUTORIAL_LINK} />
    </SharedComponents.Screen>
  );
};
