import { FC } from "react";
import { observer } from "mobx-react";
import { TagsList } from "../../formik_lib/components";
import { SharedStore } from "@shared";

interface ICategoriesTagsList {
  isCreation: boolean;
}

export const CategoriesTagsList: FC<ICategoriesTagsList> = observer(
  ({ isCreation }) => {
    const {
      data: { categories },
    } = SharedStore;
    return (
      <TagsList
        isCreation={isCreation}
        data={categories}
        title="Choose project category*"
        field="categories"
      />
    );
  }
);
