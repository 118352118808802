import { useEffect } from "react";
import { observer } from "mobx-react";

import {
  SharedStore,
  SharedComponents,
  Types as SharedTypes,
  Utils,
} from "@shared";
import { VideoAnalyticsStore, Types } from "@videoAnalytics";

interface IProjectRoleController {
  children: React.ReactChildren | React.ReactChild;
  projectId: string;
}

export const ProjectRoleController = observer(
  ({ children, projectId }: IProjectRoleController) => {
    const {
      data: { hasUndefinedRole, hasEBPRole, hasNoneRole },
    } = SharedStore;

    const {
      data: { isProjectOwner, isProjectMember, loadings, clearSelectedProject },
      getEntityRole,
    } = VideoAnalyticsStore;

    const hasLoading = Utils.useQueryStatus(
      loadings,
      Types.Endpoints.USER_ROLE
    );

    useEffect(() => {
      getEntityRole(projectId, SharedTypes.EntityType.PROJECTS);

      return () => clearSelectedProject();
    }, []);

    if (hasLoading) {
      return <SharedComponents.LoadingIndicator />;
    }

    if (hasUndefinedRole) {
      return <SharedComponents.WaitingIndicator />;
    }

    if (hasEBPRole && !isProjectOwner && !isProjectMember) {
      return <SharedComponents.DeniedIndicator />;
    }

    if (hasNoneRole) {
      return <SharedComponents.DeniedIndicator />;
    }

    return (
      <SharedComponents.Box width={"100%"}>{children}</SharedComponents.Box>
    );
  }
);
