import { useField } from "formik";
import { useCallback } from "react";

import { SharedComponents, Theme } from "@shared";

import {
  REACT_PLAYER_CLASS_NAME,
  HOVER_BUTTONS_CLASS_NAME,
} from "../constants";
import { CellWrapper } from "./cell.wrapper";
import { CellHoverWrapper } from "./cell.hoverWrapper";

import { IChosenVideo } from "../../../../../types";

interface ICell {
  item: IChosenVideo;
}

export const Cell = ({ item }: ICell) => {
  const {
    spacing: { sm, md },
    palette: { primaryWhite },
  } = Theme.useStyledTheme();
  const [input, , helpers] = useField<Array<IChosenVideo>>("chosenVideos");

  const onDelete = useCallback(() => {
    helpers.setValue(input.value.filter((el) => el.id !== item.id));
  }, [input, helpers, item?.id]);

  return (
    <CellWrapper>
      <SharedComponents.Column>
        <img
          className={REACT_PLAYER_CLASS_NAME}
          src={item?.thumbnail}
          height="110px"
          width={"100%"}
          alt={""}
        />
        <CellHoverWrapper
          className={HOVER_BUTTONS_CLASS_NAME}
          onClick={onDelete}
        >
          <SharedComponents.Icons.IconDelete
            fill={primaryWhite}
            height={md}
            width={md}
          />
          <SharedComponents.Text
            text={"Delete"}
            type={"hover"}
            color={primaryWhite}
          />
        </CellHoverWrapper>
        <SharedComponents.Box margin={`0 0 ${sm / 2}px ${sm / 2}px`}>
          <SharedComponents.VerticalBox height={sm / 2} />
          <SharedComponents.Text text={item?.name} type={"xSmallMedium"} />
          <SharedComponents.VerticalBox height={sm * 2} />
          {/*<SharedComponents.Text text={item?.ownerEmail} type={"xSmall"} />*/}
          <SharedComponents.Text
            text={"24/12/2020 5:13:02 PM"}
            type={"xSmall"}
          />
        </SharedComponents.Box>
      </SharedComponents.Column>
    </CellWrapper>
  );
};
