import { FC } from "react";

import { SharedComponents } from "@shared";

import { useStyledTheme } from "../../theme";

import { IInfoItem } from "./types";

import * as InfoItemLib from "./lib";

export const InfoItem: FC<IInfoItem> = ({ value, title, Icon }) => {
  const theme = useStyledTheme();
  return (
    <InfoItemLib.Row>
      <Icon />
      <SharedComponents.Text type="xSmall" text={title} margin="0 0 0 4px" />
      <SharedComponents.Text type="xSmall" text=":" margin="0 4px 0 0" />
      <SharedComponents.Text
        type="xSmallMedium"
        text={value}
        color={theme.palette.primaryBlue}
        margin="0 24px 0 0"
      />
    </InfoItemLib.Row>
  );
};
