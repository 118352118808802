import { FC, useCallback } from "react";
import { observer } from "mobx-react";

import { SharedComponents, Theme, Pages } from "@shared";
import { VideoAnalyticsStore } from "@videoAnalytics";

import * as CVLib from "./components";
import { LIST_HEIGHT, LIST_ITEM_HEIGHT } from "./constants";

interface IChooseVideosPage {
  projectId: string;
}

export const ChooseVideosPage: FC<IChooseVideosPage> = observer(
  ({ projectId }) => {
    const {
      spacing: { md },
    } = Theme.useStyledTheme();

    const {
      data: { videosDetails },
    } = VideoAnalyticsStore;

    const { setCurrentStep, currentStep } =
      SharedComponents.useStepperContext();

    const onPrev = useCallback(
      () => setCurrentStep(currentStep - 1),
      [setCurrentStep]
    );
    const onNext = useCallback(
      () => setCurrentStep(currentStep + 1),
      [setCurrentStep]
    );

    if (!videosDetails) {
      return <SharedComponents.PageWrapper />;
    }

    return (
      <Pages.AddProjectStepTemplate
        onPrev={onPrev}
        onNext={onNext}
        fields={["chosenVideos"]}
      >
        <CVLib.Inner>
          <CVLib.SearchList
            data={videosDetails}
            height={LIST_HEIGHT}
            itemHeight={LIST_ITEM_HEIGHT}
          />
          <SharedComponents.HorizontalBox width={md} />
          <CVLib.ChosenVideos />
        </CVLib.Inner>
      </Pages.AddProjectStepTemplate>
    );
  }
);
