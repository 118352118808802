import { useField } from "formik";
import { useCallback } from "react";
import moment from "moment";

import { SharedComponents, Theme, Utils } from "@shared";
import { Types } from "@videoAnalytics";

import { IVideo } from "../../../../../types";
import {
  REACT_PLAYER_CLASS_NAME,
  HOVER_BUTTONS_CLASS_NAME,
} from "../constants";
import { CellWrapper } from "./cell.wrapper";
import { CellHoverWrapper } from "./cell.hoverWrapper";

interface ICell {
  item: Types.IVideo;
}

export const Cell = ({ item }: ICell) => {
  const {
    spacing: { sm, md },
    palette: { primaryWhite },
  } = Theme.useStyledTheme();
  const [input, , helpers] = useField<Array<IVideo>>("chosenVideos");
  const onDelete = useCallback(() => {
    helpers.setValue(input.value.filter((el) => el.id !== item.id));
  }, [input, helpers, item?.id]);
  return (
    <CellWrapper>
      <SharedComponents.Column>
        <img
          className={REACT_PLAYER_CLASS_NAME}
          src={item?.thumbnail}
          height="100px"
          width={"100%"}
          alt=""
        />
        <CellHoverWrapper
          tabIndex={0}
          className={HOVER_BUTTONS_CLASS_NAME}
          onClick={onDelete}
          onKeyDown={Utils.onKeyDown(onDelete)}
        >
          <SharedComponents.Icons.IconDelete
            fill={primaryWhite}
            height={md}
            width={md}
          />
          <SharedComponents.Text
            text={"Delete"}
            type={"hover"}
            color={primaryWhite}
          />
        </CellHoverWrapper>
        <SharedComponents.Box margin={`0 0 ${sm / 2}px ${sm / 2}px`}>
          <SharedComponents.VerticalBox height={sm / 2} />
          <SharedComponents.Text
            text={
              item?.name && item?.name.length > 56
                ? `${item?.name.slice(0, 56)}...`
                : item.name
            }
            type={"xSmallMedium"}
          />
          <SharedComponents.VerticalBox height={sm * 2} />
          <SharedComponents.Text text={item?.ownerEmail} type={"xSmall"} />
          <SharedComponents.Text
            text={moment(item?.createdAt).format("YYYY-MM-DD hh:mm:ss")}
            type={"xSmall"}
          />
        </SharedComponents.Box>
      </SharedComponents.Column>
    </CellWrapper>
  );
};
