import styled from "styled-components";

export const TagWrapper = styled.div`
  height: ${({ theme }) => `${theme.spacing.xl}px`};
  border-radius: ${({ theme }) => `${theme.spacing.md}px`};
  border: ${({ theme }) => `1px solid ${theme.palette.primaryBlue}`};
  width: fit-content;
  padding: ${({ theme }) => `0 0 0 ${theme.spacing.md}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => `0 ${theme.spacing.sm}px ${theme.spacing.sm}px 0`};
`;
