import GList from "./gridList";

import { SharedComponents } from "@shared";
import { observer } from "mobx-react";
import { useCallback } from "react";

const ITEM_WIDTH = 593;

function getGridGap(elementWidth: number, windowHeight: number) {
  if (elementWidth > 720 && windowHeight > 480) {
    return 10;
  } else {
    return 5;
  }
}

function getColumnCount(elementWidth: number, gridGap: number) {
  return Math.floor((elementWidth + gridGap) / (ITEM_WIDTH + gridGap));
}

function getWindowMargin(windowHeight: number) {
  return 1000000;
}

interface IGridList {
  data: Array<any>;
  renderItem: (item: any) => React.ReactElement;
  itemHeight?: number;
}

export const GridList = observer(
  ({ data, renderItem, itemHeight = 96 }: IGridList) => {
    const getItemData = useCallback(
      (item: any) => {
        return {
          key: item.id,
          height: itemHeight,
        };
      },
      [itemHeight]
    );

    if (data.length === 0) {
      return (
        <SharedComponents.Column
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <SharedComponents.Text
            text={"There are no files for current selection"}
            type={"button"}
          />
        </SharedComponents.Column>
      );
    }

    return (
      <GList
        items={data}
        getGridGap={getGridGap}
        getColumnCount={getColumnCount}
        getWindowMargin={getWindowMargin}
        getItemData={getItemData}
        fixedColumnWidth={ITEM_WIDTH}
        renderItem={renderItem}
      />
    );
  }
);
