import { ITheme } from "./types";

export const lightTheme: ITheme = {
  palette: {
    primaryWhite: "#ffffff",
    primaryBlue: "#003DA5",
    primaryOrange: "#E87722",
    primaryDarkYellow: "#F1B434",
    primaryRed: "#EF3340",
    secondaryBlue: "#00A3E0",
    secondaryDarkBlue: "#012169",
    secondaryGreen: "#00B140",
    background: "#ffffff",
    text: "#000000",
    gray: "#808B9F",
    shadow: "#808B9F33",
    timeline: "#d6dae0",
    secondaryGray: "#d9dce2",
  },
  spacing: {
    sm: 8,
    md: 16,
    lg: 24,
    xl: 48,
  },
};
