import { Types } from "@videoAnalytics";

export const initialValues: Types.ICreateProjectFormValues = {
  id: "",
  hasPii: undefined,
  hasSignedInformedConsent: undefined,
  hasConsentFormSignedByParent: true,
  hasVideoPersonsUnder18: undefined,
  hasDelayedFSDSUpload: false,
  hasCostsAgreement: false,
  status: undefined,
  members: [],
  tags: [],
  brands: [],
  regions: [],
  countries: [],
  categories: [],
  videos: [],
  blurMode: "",
  customTag: "",
  name: "",
  summary: "",
  email: "",
  costCenter: "",
  consentFormLink: "",
  cmk: "",
  startDate: new Date(),
  endDate: new Date(),
  FSDSUploadDate: new Date(),
  files: [],
};

export const filledValues: Types.ICreateProjectFormValues = {
  id: "",
  hasPii: false,
  hasSignedInformedConsent: undefined,
  hasConsentFormSignedByParent: true,
  hasVideoPersonsUnder18: undefined,
  hasDelayedFSDSUpload: false,
  hasCostsAgreement: false,
  status: undefined,
  members: [],
  tags: [],
  brands: [],
  regions: [],
  countries: [],
  categories: [],
  videos: [],
  blurMode: "",
  customTag: "",
  name: "May 2 001",
  summary: "summary",
  email: "",
  costCenter: "",
  consentFormLink: "",
  cmk: "0",
  startDate: new Date(),
  endDate: new Date(),
  FSDSUploadDate: new Date(),
  files: [],
};
