import { FC } from "react";
import { IconButton } from "@material-ui/core";
import * as CSLib from "./customSearch.icon.clear";
import { IClearButton } from "../types";

export const CSButtonClear: FC<IClearButton> = ({ clearSearchValue }) => {
  const onClick = () => clearSearchValue("");
  return (
    <IconButton onClick={onClick}>
      <CSLib.IconClear />
    </IconButton>
  );
};
