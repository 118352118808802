import { observer } from "mobx-react";

import { SharedComponents, SharedStore } from "@shared";
import { Templates } from "@videoAnalytics";
import { initialValues, validationSchema } from "./formik_data";
import { computed } from "mobx";

export const MyProjects = observer(() => {
  const {
    data: { hasUserRole, hasAdminRole, hasNonEmpRole },
  } = SharedStore;

  const showAllProject = computed(
    () => hasUserRole || hasAdminRole || hasNonEmpRole
  ).get();

  return (
    <SharedComponents.Screen>
      <SharedComponents.FormikLib.Form
        initialValues={{ ...initialValues, showAllProject }}
        validationSchema={validationSchema}
        onSubmit={(_) => {}}
      >
        <Templates.VideoProjectsPage />
      </SharedComponents.FormikLib.Form>
    </SharedComponents.Screen>
  );
});
