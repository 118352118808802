import { useField } from "formik";
import { v4 as uuidv4 } from "uuid";
import { useParams, useNavigate } from "react-router-dom";

import { SharedComponents, Theme, Utils, Types as SharedTypes } from "@shared";
import {
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
  Types,
} from "@videoAnalytics";

import { initialValues, validationSchema } from "./formik_data";
import { useCallback } from "react";

interface IAddVideoContent {
  projectId: string;
}

export const AddVideoContent = ({ projectId }: IAddVideoContent) => {
  const navigate = useNavigate();

  const {
    spacing: { md, lg },
    palette: { primaryBlue },
  } = Theme.useStyledTheme();

  const {
    createVideo,
    deleteVideo,
    client: {
      bucketClient: { loadings },
    },
  } = VideoAnalyticsStore;

  const [input, , helpers] =
    useField<Array<Types.ICreateVideoInputExtended>>("files");

  const onDrop = async (accFile: File[]) => {
    const files: Array<Types.ICreateVideoInputExtended> = accFile.map(
      (file) => {
        return {
          id: uuidv4(),
          name: file.name.split("_").join(" "),
          projectId: projectId,
          slug: file.name.toLowerCase().split(" ").join("_"),
          tags: [],
          type: SharedTypes.FilesType.VIDEOS,
          fileSize: Utils.formatSize(file.size),
          body: file,
          videoLength: 0,
        };
      }
    );
    await helpers.setValue([...input.value, ...files]);
    for (const file of files) {
      await createVideo(file);
    }
  };

  const onDelete = (id: string, name: string) => {
    deleteVideo(id, name).then(() => {
      const newFiles: Array<Types.ICreateVideoInputExtended> = input.value
        .slice()
        .filter((f) => f.id !== id);
      helpers.setValue(newFiles);
    });
  };

  const onCancel = useCallback(() => {
    navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}`);
  }, [navigate, projectId]);

  const onSubmit = useCallback(() => {
    navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}`);
  }, [navigate, projectId]);

  return (
    <VideoAnalyticsComponents.ProjectRoleController projectId={projectId}>
      <SharedComponents.Screen>
        <SharedComponents.VerticalBox height={md} />
        <SharedComponents.Text
          type={"h3SemiBold"}
          text={"Add videos"}
          color={primaryBlue}
        />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.UploadZone
          data={input.value}
          Icon={SharedComponents.Icons.IconVideo}
          loadings={loadings}
          onDrop={onDrop}
          onDelete={onDelete}
          accept={"video/*"}
          title={"Drag & drop video"}
        />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.Row justifyContent={"flex-end"}>
          <SharedComponents.Button
            onClick={onCancel}
            type={SharedTypes.ButtonType.OUTLINED}
            text={"CANCEL"}
          />
          <SharedComponents.HorizontalBox width={md} />
          <SharedComponents.Button
            onClick={onSubmit}
            disabled={input.value.length === 0}
            type={
              input.value.length === 0
                ? SharedTypes.ButtonType.DISABLED
                : SharedTypes.ButtonType.FILLED
            }
            text={"SUBMIT"}
          />
        </SharedComponents.Row>
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.ProjectRoleController>
  );
};

export const AddVideo = () => {
  const { projectId } = useParams();
  return (
    <SharedComponents.Screen>
      <SharedComponents.FormikLib.Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(_) => {}}
      >
        <AddVideoContent projectId={projectId || ""} />
      </SharedComponents.FormikLib.Form>
    </SharedComponents.Screen>
  );
};
