import * as React from "react";

export function IconSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7414 10.3451C12.7097 9.02376 13.1434 7.38557 12.9557 5.75823C12.7681 4.13089 11.9728 2.63442 10.7292 1.56821C9.48555 0.501998 7.88518 -0.0553194 6.24827 0.00775377C4.61136 0.0708269 3.05862 0.749639 1.9007 1.90838C0.742785 3.06713 0.0650835 4.62035 0.00318169 6.25731C-0.0587201 7.89427 0.499742 9.49424 1.56684 10.7371C2.63394 11.98 4.13098 12.7741 5.75845 12.9607C7.38593 13.1472 9.02381 12.7123 10.3444 11.7431H10.3434C10.3734 11.7831 10.4054 11.8211 10.4414 11.8581L14.2914 15.7081C14.4789 15.8957 14.7333 16.0012 14.9986 16.0013C15.2638 16.0014 15.5183 15.8961 15.7059 15.7086C15.8936 15.5211 15.999 15.2667 15.9991 15.0014C15.9992 14.7362 15.8939 14.4817 15.7064 14.2941L11.8564 10.4441C11.8207 10.4079 11.7822 10.3745 11.7414 10.3441V10.3451ZM11.9994 6.50108C11.9994 7.22335 11.8572 7.93855 11.5808 8.60584C11.3044 9.27313 10.8992 9.87944 10.3885 10.3902C9.8778 10.9009 9.27148 11.306 8.60419 11.5824C7.9369 11.8588 7.2217 12.0011 6.49943 12.0011C5.77716 12.0011 5.06196 11.8588 4.39467 11.5824C3.72738 11.306 3.12107 10.9009 2.61035 10.3902C2.09962 9.87944 1.6945 9.27313 1.4181 8.60584C1.14169 7.93855 0.999433 7.22335 0.999433 6.50108C0.999433 5.04239 1.5789 3.64344 2.61035 2.61199C3.6418 1.58054 5.04074 1.00108 6.49943 1.00108C7.95812 1.00108 9.35707 1.58054 10.3885 2.61199C11.42 3.64344 11.9994 5.04239 11.9994 6.50108Z"
        fill="#808B9F"
      />
    </svg>
  );
}
