import * as React from "react";

export function IconVideo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.35a5 5 0 015-5h18.75a5 5 0 014.957 4.346l7.776-3.455A2.5 2.5 0 0140 3.523v18.655a2.5 2.5 0 01-3.515 2.283l-7.777-3.455a5 5 0 01-4.958 4.345H5a5 5 0 01-5-5v-15zm28.75 12.938l8.75 3.89V3.523l-8.75 3.89v10.875zM5 2.851a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h18.75a2.5 2.5 0 002.5-2.5v-15a2.5 2.5 0 00-2.5-2.5H5z"
        fill="#808B9F"
      />
    </svg>
  );
}
