import * as Yup from "yup";

import { Types } from "@videoAnalytics";

export const getValidationSchema = ({
  nameVerificationCb,
  hasEditMode,
  name,
}: Types.IGetValidationSchemaProps) =>
  Yup.object().shape({
    id: Yup.string(),
    name: Yup.string()
      .required("This field is required")
      .test(
        "minLengthCheck",
        "At least 2 characters",
        (value: string | undefined) => {
          if (value && value.length < 2) return false;
          return true;
        }
      )
      .test(
        "maxLengthCheck",
        "Not more than 60 characters",
        (value: string | undefined) => {
          if (value && value.length > 60) return false;
          return true;
        }
      )
      .test(
        "checkName",
        "This project name is already registered.",
        async (value: any) => {
          if (hasEditMode && name === value) return true;
          const res = await nameVerificationCb(value || "");
          return !res;
        }
      ),
    summary: Yup.string().required("This field is required"),
    email: Yup.string().email("Invalid email"),
    costCenter: Yup.string().required("This field is required"),
    consentFormLink: Yup.string().required("This field is required"),
    cmk: Yup.string().required("This field is required"),
    members: Yup.array(),
    customTag: Yup.string(),
    tags: Yup.array(),
    brands: Yup.array(),
    regions: Yup.array().min(1, "You need to select at least one region"),
    countries: Yup.array().min(1, "You need to select at least one country"),
    // .test({
    //   name: "regions",
    //   message:
    //     "While you selected a country from Grt. China region, videos may not be uploaded at this time",
    //   test: (value) => {
    //     return (
    //       value !== undefined &&
    //       !value.includes("c8d83207-4c88-411a-8958-bbcfb5a490e9") &&
    //       !value.includes("dea67dcf-8767-4a81-b92e-e4e43ec4340a") &&
    //       !value.includes("23b66f94-24b4-42e8-927f-92a972b3a329") &&
    //       !value.includes("9a5522ed-61c3-472e-99f6-2c9dc74a8109")
    //     );
    //   },
    // }),
    categories: Yup.array().min(1, "You need to select at least one category"),
    videos: Yup.array(),
    startDate: Yup.date().required("This field is required"),
    endDate: Yup.date().required("This field is required"),
    FSDSUploadDate: Yup.date(),
    hasPii: Yup.boolean().required("This field is required"),
    blurMode: Yup.string().when("hasPii", {
      is: true,
      then: Yup.string().required("You need to select an algorithm"),
      otherwise: Yup.string().notRequired(),
    }),
    hasSignedInformedConsent: Yup.boolean()
      .required("This field is required")
      .oneOf(
        [true],
        "Videos may not be uploaded to the VAP without informed consent"
      ),
    hasVideoPersonsUnder18: Yup.boolean().required("This field is required"),
    hasConsentFormSignedByParent: Yup.boolean().when("hasVideoPersonsUnder18", {
      is: true,
      then: Yup.boolean().oneOf(
        [true],
        "Videos may not be uploaded to the VAP without informed consent"
      ),
      otherwise: Yup.bool().notRequired(),
    }),
    files: Yup.array(),
    hasDelayedFSDSUpload: Yup.boolean(),
    hasCostsAgreement: Yup.boolean()
      .required("You should agree to the total cost estimate")
      .oneOf([true], "You should agree to the total cost estimate"),
  });

export const formatSpeaker = (speakerId: string | undefined) => {
  if (!speakerId) return "Speaker";
  const number = speakerId.split("_")[1] ? speakerId.split("_")[1] : "";
  return `Speaker ${parseInt(number) + 1}`;
};
