import styled from "styled-components";

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
`;
