import { useCallback, FC } from "react";
import { useField } from "formik";
import { IconButton } from "@material-ui/core";

import { Theme, SharedComponents } from "@shared";

import { IFormikTag } from "./types";
import * as TagButtonLib from "./lib";

export const TagButton: FC<IFormikTag> = ({ item, optionsField }) => {
  const {
    palette: { gray, text },
  } = Theme.useStyledTheme();
  const [input, , helpers] = useField(optionsField);

  const onClick = useCallback(
    () =>
      helpers.setValue(input.value.filter((member: string) => member !== item)),
    [helpers, input.value, item]
  );

  return (
    <TagButtonLib.TagWrapper>
      <SharedComponents.Text type={"xSmallMedium"} text={item} color={text} />
      <IconButton onClick={onClick}>
        <SharedComponents.Icons.IconClose fill={gray} />
      </IconButton>
    </TagButtonLib.TagWrapper>
  );
};
