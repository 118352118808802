import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import awsconfig from "./aws_exports";
import { configure } from "mobx";

Amplify.configure(awsconfig);
configure({
  enforceActions: "never",
});

// remove console.log from production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
