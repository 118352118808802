import { ITabsConfig } from "../types";

export const PROJECTS_LIST_ITEM_HEIGHT = 400;
export const PROJECT_LIST_HEIGHT = PROJECTS_LIST_ITEM_HEIGHT + 50;
export const TABS_CONFIG: ITabsConfig = {
  headers: [
    { value: "0", label: "Videos" },
    {
      value: "1",
      label: "Related summaries",
    },
    {
      value: "2",
      label: "Other files",
    },
  ],
};
