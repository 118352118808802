import { SharedComponents, Theme, Types } from "@shared";
import { useMemo, useState } from "react";

interface ITag {
  tag: string;
}

const Tag = ({ tag }: ITag) => {
  const {
    palette: { primaryBlue },
    spacing: { sm },
  } = Theme.useStyledTheme();
  return (
    <>
      <SharedComponents.Row
        flex={0}
        width={"fit-content"}
        height={"fit-content"}
      >
        <SharedComponents.Box
          border={`1px solid ${primaryBlue}`}
          padding={"9px 33px"}
          margin={"4px 0"}
          style={{ borderRadius: "25px" }}
        >
          <SharedComponents.Text
            text={tag}
            color={primaryBlue}
            type={"xSmallSemiBold"}
            whiteSpace={"nowrap"}
          />
        </SharedComponents.Box>
        <SharedComponents.HorizontalBox width={sm} />
      </SharedComponents.Row>
      <SharedComponents.VerticalBox height={sm} />
    </>
  );
};

interface ITagsSection {
  tags: Array<Types.ITag>;
}

export const TagsSection = ({ tags }: ITagsSection) => {
  const {
    spacing: { sm },
    palette: { primaryBlue },
  } = Theme.useStyledTheme();
  const [showAllTags, setShowingAllTags] = useState<boolean>(false);

  const fTags = useMemo(
    () => (showAllTags ? tags : tags.slice(0, 5)),
    [tags, showAllTags]
  );

  const title = useMemo(
    () => (showAllTags ? "Show less tags" : "Show more tags"),
    [showAllTags]
  );
  return (
    <SharedComponents.Box>
      <SharedComponents.Text text={"Tags"} type={"h2"} />
      <SharedComponents.VerticalBox height={sm} />
      <SharedComponents.Text
        type={"xSmall"}
        text={"If you want to add more tags go to the specific page"}
      />
      <SharedComponents.VerticalBox height={sm} />
      <SharedComponents.Row>
        <SharedComponents.Row flex={5} style={{ flexWrap: "wrap" }}>
          {fTags.map((tag) => (
            <Tag key={tag.id} tag={tag.name} />
          ))}
        </SharedComponents.Row>
        {tags.length > 5 && (
          <SharedComponents.Row
            justifyContent={"flex-end"}
            alignItems={"center"}
            flex={1}
            cursor={"pointer"}
            onClick={() => setShowingAllTags(!showAllTags)}
          >
            <SharedComponents.Text
              text={title}
              type={"button"}
              color={primaryBlue}
            />
            <SharedComponents.HorizontalBox width={16} />
            <SharedComponents.Icons.IconArrowDown fill={primaryBlue} />
          </SharedComponents.Row>
        )}
      </SharedComponents.Row>
    </SharedComponents.Box>
  );
};
