export const IconProjectFile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.25 0H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6.75L11.25 0Zm0 1.5v3a2.25 2.25 0 0 0 2.25 2.25h3V21a1.5 1.5 0 0 1-1.5 1.5H3A1.5 1.5 0 0 1 1.5 21V3A1.5 1.5 0 0 1 3 1.5h8.25Z"
      fill="#000"
    />
  </svg>
);
