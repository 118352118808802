import { SharedComponents } from "@shared";

export const EmptyIndicator = () => {
  return (
    <SharedComponents.Column
      height={"189px"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <SharedComponents.Icons.IconSearch width={62} height={62} />
      <SharedComponents.VerticalBox height={16} />
      <SharedComponents.Text type={"h2"} text={"There is no data to show"} />
      <SharedComponents.VerticalBox height={4} />
      <SharedComponents.Text
        type={"h3"}
        text={"Please, enter your search term into the search field"}
      />
    </SharedComponents.Column>
  );
};
