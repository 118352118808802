import { FC } from "react";

import * as FrameLib from "./lib";
import { IFrame } from "./types";

export const Frame: FC<IFrame> = ({ children }) => {
  return (
    <FrameLib.Wrapper>
      <FrameLib.TopBar />
      <FrameLib.Navbar />
      <FrameLib.ScreenWrapper id="scrollableDiv">
        {children}
      </FrameLib.ScreenWrapper>
    </FrameLib.Wrapper>
  );
};
