import { FC, memo, useEffect, useMemo, useState } from "react";
import { PieChart as RePieChart, Pie as RePie, Cell as ReCell } from "recharts";

import { SharedComponents, Theme } from "@shared";

import { renderActiveShape, findMaxIndex } from "./utils";
import * as PieChartLib from "./lib";
import { IPieChart } from "./types";

interface IFormattedTranscriptEmotion {
  type: "mixed" | "negative" | "neutral" | "positive";
  percent: number;
  color: string;
}

const renderCell = (item: IFormattedTranscriptEmotion) => (
  <ReCell key={item.color} fill={item.color} />
);

const renderLegend = (item: IFormattedTranscriptEmotion) => (
  <PieChartLib.Legend item={item} key={item.color} />
);

export const TranscriptPieChart: FC<IPieChart> = memo(({ data }) => {
  const {
    palette: { text, secondaryDarkBlue },
    spacing: { md },
  } = Theme.useStyledTheme();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onMouseEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const fData = useMemo((): Array<IFormattedTranscriptEmotion> => {
    const { mixed, negative, neutral, positive } =
      data?.transcripts.emotions.general.sentimentScore;

    return [
      { ...mixed, type: "mixed" },
      { ...negative, type: "negative" },
      { ...neutral, type: "neutral" },
      { ...positive, type: "positive" },
    ];
  }, [data]);

  useEffect(() => {
    const highestIndex = findMaxIndex(fData);
    setActiveIndex(highestIndex);
  }, [data, setActiveIndex]);

  return (
    <SharedComponents.Column
      height={"456px"}
      alignItems={"flex-start"}
      overflow={"hidden"}
    >
      <SharedComponents.Text
        text={"Transcript  analysis"}
        type={"h2"}
        color={text}
      />
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Text
        text={"Sentiment "}
        type={"xSmallSemiBold"}
        color={secondaryDarkBlue}
      />
      <PieChartLib.Root>
        <RePieChart width={400} height={300}>
          <RePie
            isAnimationActive={false}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={fData}
            innerRadius={60}
            outerRadius={72}
            paddingAngle={0}
            dataKey="percent"
            onMouseEnter={onMouseEnter}
          >
            {fData.map(renderCell)}
          </RePie>
        </RePieChart>
      </PieChartLib.Root>
      <SharedComponents.Row>{fData.map(renderLegend)}</SharedComponents.Row>
    </SharedComponents.Column>
  );
});
