import { FC } from "react";
import styled from "styled-components";

interface IVerticalBox {
  height: number;
}

const Box = styled.div<IVerticalBox>`
  height: ${({ height }) => `${height}px`};
`;

export const VerticalBox: FC<IVerticalBox> = ({ height }) => {
  return <Box height={height} />;
};
