import { useNavigate } from "react-router-dom";

import { SharedComponents, Types as SharedTypes, Theme } from "@shared";

import * as LILib from "./lib";

export const DeniedIndicator = () => {
  const {
    palette: { primaryBlue },
    spacing: { md },
  } = Theme.useStyledTheme();
  const navigate = useNavigate();
  return (
    <LILib.Wrapper>
      <SharedComponents.Text
        text={"403: Access denied"}
        type={"h1"}
        color={primaryBlue}
      />
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Button
        type={SharedTypes.ButtonType.FILLED}
        text={"BACK"}
        onClick={() => navigate(SharedTypes.PATHS.HOME)}
      />
    </LILib.Wrapper>
  );
};
