import { SharedComponents, Theme, Constants } from "@shared";

interface IProjectNotFound {
  onBack?: () => void;
  title?: string;
  subtitle?: string;
}

export const NotFound = ({
  onBack,
  title = "Oops something went wrong..",
  subtitle = "We couldn't find the page you are looking for",
}: IProjectNotFound) => {
  const {
    spacing: { lg, md },
  } = Theme.useStyledTheme();
  return (
    <SharedComponents.Screen>
      <SharedComponents.Box height={`calc(100vh - ${Constants.TOPBAR_SIZE}px)`}>
        <SharedComponents.Column
          alignItems={"center"}
          justifyContent={"center"}
        >
          <SharedComponents.Icons.IconLogoSmall width={69} height={69} />
          <SharedComponents.VerticalBox height={lg} />
          <SharedComponents.Text
            text={title}
            textAlign={"center"}
            type={"h2"}
          />
          <SharedComponents.VerticalBox height={md} />
          <SharedComponents.Text
            text={subtitle}
            textAlign={"center"}
            type={"h3SemiBold"}
          />
          {onBack && (
            <>
              <SharedComponents.VerticalBox height={lg} />
              <SharedComponents.Button
                text={"BACK TO HOME PAGE"}
                onClick={onBack}
              />
            </>
          )}
        </SharedComponents.Column>
      </SharedComponents.Box>
    </SharedComponents.Screen>
  );
};
