import { Types } from "@videoAnalytics";

export const ParseEmotions = (
  data: Types.IBasicEmotions | Types.IDetailedEmotions
) => {
  // @ts-ignore
  // todo investigate typing here
  const dataToDictionary: { [index: string]: IEmotion } = data;
  return Object.keys(data).map((key) => ({
    type: key,
    color: dataToDictionary[key].color,
    percent: Math.floor(dataToDictionary[key].percent),
    count: dataToDictionary[key].count,
  }));
};
export const SumPercentage = (data: Types.IEmotion[]) =>
  data.reduce((previousValue, currentValue) => {
    previousValue += currentValue.percent;
    return previousValue;
  }, 0);
