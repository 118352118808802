import { observer } from "mobx-react";
import { computed } from "mobx";

import { SharedComponents, Theme, Types as SharedTypes } from "@shared";
import { VideoAnalyticsStore } from "@videoAnalytics";

import { PieChart } from "../../../video_details/components/pie_chart";

export const ProjectAnalytics = observer(() => {
  const {
    palette: { primaryWhite },
    spacing: { lg, md },
  } = Theme.useStyledTheme();
  const {
    data: { selectedProject },
  } = VideoAnalyticsStore;

  const emotionsBasicData: Array<SharedTypes.IPieChartItem> = computed(() => [
    {
      value: selectedProject?.emotions.basic?.positive.percent || 0,
      name: "Positive",
      color: selectedProject?.emotions.basic?.positive.color || "transparent",
    },
    {
      value: selectedProject?.emotions.basic?.negative.percent || 0,
      name: "Negative",
      color: selectedProject?.emotions.basic?.negative.color || "transparent",
    },
    {
      value: selectedProject?.emotions.basic?.neutral.percent || 0,
      name: "Neutral",
      color: selectedProject?.emotions.basic?.neutral.color || "transparent",
    },
  ]).get();

  const hasEmptyBasicData = computed(() => {
    return (
      selectedProject?.emotions.basic?.positive.percent === 0 &&
      selectedProject?.emotions.basic?.negative.percent === 0 &&
      selectedProject?.emotions.basic?.neutral.percent === 0
    );
  }).get();

  const emotionsDetailedData: Array<SharedTypes.IPieChartItem> = computed(
    () => [
      {
        value: selectedProject?.emotions.detailed?.angry.percent || 0,
        name: "Angry",
        color: selectedProject?.emotions.detailed?.angry.color || "transparent",
      },
      {
        value: selectedProject?.emotions.detailed?.calm.percent || 0,
        name: "Calm",
        color: selectedProject?.emotions.detailed?.calm.color || "transparent",
      },
      {
        value: selectedProject?.emotions.detailed?.confused.percent || 0,
        name: "Confused",
        color:
          selectedProject?.emotions.detailed?.confused.color || "transparent",
      },
      {
        value: selectedProject?.emotions.detailed?.sad.percent || 0,
        name: "Sad",
        color: selectedProject?.emotions.detailed?.sad.color || "transparent",
      },
      {
        value: selectedProject?.emotions.detailed?.happy.percent || 0,
        name: "Happy",
        color: selectedProject?.emotions.detailed?.happy.color || "transparent",
      },
      {
        value: selectedProject?.emotions.detailed?.fear.percent || 0,
        name: "Fear",
        color: selectedProject?.emotions.detailed?.fear.color || "transparent",
      },
      {
        value: selectedProject?.emotions.detailed?.disgusted.percent || 0,
        name: "Disgusted",
        color:
          selectedProject?.emotions.detailed?.disgusted.color || "transparent",
      },
    ]
  ).get();

  const hasEmptyDetailedData = computed(() => {
    return (
      selectedProject?.emotions.detailed?.angry.percent === 0 &&
      selectedProject?.emotions.detailed?.calm.percent === 0 &&
      selectedProject?.emotions.detailed?.confused.percent === 0 &&
      selectedProject?.emotions.detailed?.fear.percent === 0 &&
      selectedProject?.emotions.detailed?.happy.percent === 0 &&
      selectedProject?.emotions.detailed?.sad.percent === 0 &&
      selectedProject?.emotions.detailed?.surprised.percent === 0 &&
      selectedProject?.emotions.detailed?.disgusted.percent === 0
    );
  }).get();

  if (hasEmptyDetailedData && hasEmptyBasicData) {
    return null;
  }

  return (
    <SharedComponents.Column height={"344px"}>
      <SharedComponents.VerticalBox height={lg} />
      <SharedComponents.Text text={"Video analytics"} type={"h2"} />
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Row>
        <SharedComponents.Row flex={2} background={primaryWhite}>
          {!hasEmptyBasicData && (
            <PieChart data={emotionsBasicData} title={"Sentiment"} />
          )}
          {!hasEmptyDetailedData && (
            <PieChart data={emotionsDetailedData} title={"Emotions"} />
          )}
        </SharedComponents.Row>
      </SharedComponents.Row>
    </SharedComponents.Column>
  );
});
