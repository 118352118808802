import { computed } from "mobx";
import { useField } from "formik";
import { v4 as uuidv4 } from "uuid";
import { useCallback } from "react";
import { observer } from "mobx-react";
import { useParams, useNavigate } from "react-router-dom";

import { SharedComponents, Theme, Types as SharedTypes, Utils } from "@shared";
import {
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
  Types,
} from "@videoAnalytics";

import { initialValues, validationSchema } from "./formik_data";

interface IAddVideoContent {
  projectId: string;
}

export const AddFileContent = observer(({ projectId }: IAddVideoContent) => {
  const navigate = useNavigate();

  const {
    spacing: { md, lg },
    palette: { primaryBlue },
  } = Theme.useStyledTheme();

  const {
    createFile,
    deleteFile,
    // getProject,
    data: { setRequestReset, errors },
    client: {
      bucketClient: { loadings },
    },
  } = VideoAnalyticsStore;

  const [input, , helpers] =
    useField<Array<Types.ICreateFileInputExtended>>("files");

  const onDrop = async (accFile: File[]) => {
    const files: Array<Types.ICreateFileInputExtended> = accFile.map((file) => {
      return {
        id: uuidv4(),
        name: file.name.split("_").join(" "),
        projectId: projectId,
        slug: file.name.toLowerCase().split(" ").join("_"),
        tags: [],
        type: Types.FilesType.FILES,
        fileSize: Utils.formatSize(file.size),
        body: file,
      };
    });

    await helpers.setValue([...input.value, ...files]);
    for (const file of files) {
      await createFile(file);
    }
  };

  const onDelete = (id: string, name: string) => {
    deleteFile(id, name).then(() => {
      const hasError = computed(() =>
        errors.get(Types.Endpoints.DELETE_FILE)
      ).get();
      if (!hasError) {
        const newFiles: Array<Types.ICreateFileInputExtended> = input.value
          .slice()
          .filter((f) => f.id !== id);
        helpers.setValue(newFiles);
      } else {
        setRequestReset(Types.Endpoints.DELETE_FILE);
      }
    });
  };

  const onConfirm = useCallback(async () => {
    // await Promise.all([getProject(projectId)]);
    navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}`);
  }, [navigate, projectId]);

  return (
    <VideoAnalyticsComponents.ProjectRoleController projectId={projectId}>
      <SharedComponents.Screen>
        <SharedComponents.VerticalBox height={md} />
        <SharedComponents.Text
          type={"h3SemiBold"}
          text={"Add files"}
          color={primaryBlue}
        />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.UploadZone
          accept={
            "image/*, application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf,.txt"
          }
          data={input.value}
          Icon={SharedComponents.Icons.IconVideo}
          loadings={loadings}
          onDrop={onDrop}
          onDelete={onDelete}
        />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.Row justifyContent={"flex-end"}>
          <SharedComponents.Button
            onClick={onConfirm}
            type={SharedTypes.ButtonType.OUTLINED}
            text={"CANCEL"}
          />
          <SharedComponents.HorizontalBox width={md} />
          <SharedComponents.Button
            onClick={onConfirm}
            disabled={input.value.length === 0}
            type={
              input.value.length === 0
                ? SharedTypes.ButtonType.DISABLED
                : SharedTypes.ButtonType.FILLED
            }
            text={"SUBMIT"}
          />
        </SharedComponents.Row>
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.ProjectRoleController>
  );
});

export const AddFile = () => {
  const { projectId } = useParams();
  return (
    <SharedComponents.Screen>
      <SharedComponents.FormikLib.Form
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(_) => {}}
      >
        <AddFileContent projectId={projectId || ""} />
      </SharedComponents.FormikLib.Form>
    </SharedComponents.Screen>
  );
};
