import { useDropzone } from "react-dropzone";

import { Types, SharedComponents, Theme } from "@shared";

import * as LocalComponents from "./lib";

interface IUploadZone {
  onDrop: (accFiles: File[]) => void | Promise<void>;
  onDelete: (id: string, name: string) => void;
  loadings: Types.ObservableProgressMap;
  data: Array<Types.IFile>;
  Icon: React.FC;
  accept?: string;
  title?: string;
}

export const UploadZone = ({
  onDelete,
  onDrop,
  loadings,
  data,
  Icon,
  accept = "/*",
  title = "Drag & drop file",
}: IUploadZone) => {
  const {
    spacing: { md, sm },
    palette: { gray },
  } = Theme.useStyledTheme();

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept,
  });

  return (
    <SharedComponents.Box>
      <SharedComponents.Box>
        <LocalComponents.Wrapper {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <LocalComponents.Inner>
            {Icon}
            <SharedComponents.Text
              text={title}
              type="h3"
              margin={`${sm}px 0 0`}
            />
            <SharedComponents.Text
              text="or"
              color={gray}
              type="h3"
              margin={`0 0 ${sm}px`}
            />
            <SharedComponents.Button
              type={Types.ButtonType.OUTLINED}
              text="Browse"
              onClick={open}
            />
          </LocalComponents.Inner>
        </LocalComponents.Wrapper>
      </SharedComponents.Box>
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Box>
        {data.map((item) => (
          <SharedComponents.UploadedFile
            key={item.temporaryId}
            loadings={loadings}
            file={item}
            onDelete={onDelete}
          />
        ))}
      </SharedComponents.Box>
    </SharedComponents.Box>
  );
};
