import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  height: 44px;
  background: transparent;
  border-radius: ${({ theme }) => `${theme.spacing.sm}px`};
  border: ${({ theme }) => `1px solid ${theme.palette.primaryBlue}`};
  padding: ${({ theme }) => `0 ${theme.spacing.md}px`};
`;
