import { FC } from "react";

import { SharedComponents } from "@shared";

import { Dot } from "./pieChart.legend.dot";
import { Wrapper } from "./pieChart.legend.wrapper";

interface IFormattedTranscriptEmotion {
  type: "mixed" | "negative" | "neutral" | "positive";
  percent: number;
  color: string;
}
interface ILegend {
  item: IFormattedTranscriptEmotion;
}

export const Legend: FC<ILegend> = ({ item }) => {
  return (
    <Wrapper>
      <Dot color={item.color} />
      <SharedComponents.Text text={item.type.toUpperCase()} type={"xSmall"} />
    </Wrapper>
  );
};
