import styled from "styled-components";

import { IRound } from "../types";

export const Round = styled.div<IRound>`
  ${({ background }) => {
    return {
      width: "8px",
      height: "8px",
      borderRadius: "100%",
      marginRight: "8px",
      marginTop: "8px",
      flexBasis: "8px",
      background,
    };
  }}
`;
