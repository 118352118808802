import { makeObservable } from "mobx";
import { ToastOptions } from "react-toastify";

import { Types } from "@shared";

import { BucketClient } from "./bucketClient";

export class FileUploader {
  bucketClient: BucketClient;

  constructor() {
    this.bucketClient = new BucketClient();
    makeObservable(this, { bucketClient: true });
  }

  putToBucket = (
    input: Types.IPutToS3Params,
    showToast: (msg: string, options?: ToastOptions) => void
  ) => {
    this.bucketClient.putToBucket(input, showToast);
  };

  clearData = () => {
    this.bucketClient.loadings.clear();
    this.bucketClient.errors.clear();
  };
}
