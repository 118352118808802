import { FC, useCallback, KeyboardEvent } from "react";
import { useField } from "formik";

import * as SharedComponents from "../../../../components";
import * as Theme from "../../../../theme";

import * as CTLib from "./lib";

import { ICustomTags } from "./types";

const Input: FC<ICustomTags> = ({ inputField, tagsField, title }) => {
  const [inputProps, inputMeta, inputHelpers] = useField(inputField);
  const [membersInputProps, , membersHelpers] = useField(tagsField);
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === "Enter") {
        if (!inputMeta.error && inputProps.value.length > 0) {
          if (!membersInputProps.value.includes(inputProps.value)) {
            membersHelpers.setValue([
              ...membersInputProps.value,
              inputProps.value,
            ]);
          }
          inputHelpers.setValue("");
          inputHelpers.setTouched(false);
        }
      }
    },
    [
      membersHelpers,
      inputProps,
      inputHelpers,
      membersInputProps,
      inputMeta.error,
    ]
  );
  return (
    <SharedComponents.FormikLib.Field
      title={title}
      field={inputField}
      onKeyDown={onKeyDown}
      placeholder={"Enter a custom tag"}
    />
  );
};

export const CustomTags: FC<ICustomTags> = ({
  inputField,
  tagsField,
  title,
}) => {
  const {
    spacing: { sm },
  } = Theme.useStyledTheme();
  const [members, ,] = useField(tagsField);
  const renderMember = useCallback(
    (item: string, index: number) => (
      <CTLib.Tag item={item} key={index} tagsField={tagsField} />
    ),
    [tagsField]
  );
  return (
    <SharedComponents.Column alignItems="flex-start">
      <SharedComponents.Box width={"100%"}>
        <Input inputField={inputField} tagsField={tagsField} title={title} />
      </SharedComponents.Box>
      <SharedComponents.FlexContainer
        direction="row"
        padding={sm}
        justifyContent={"flex-start"}
      >
        {members.value.map(renderMember)}
      </SharedComponents.FlexContainer>
    </SharedComponents.Column>
  );
};
