export const formatSize = (size: number | string) => {
  if (typeof size === "string") {
    return size;
  }
  if (size === 0) return "";
  switch (true) {
    case size < 1000:
      return `${size} Bytes`;
    case size >= 1000 && size < 1000000:
      return `${(size / 1000).toFixed(1)} Kb`;
    case size >= 1000000 && size < 1000000000:
      return `${(size / 1000000).toFixed(1)} Mb`;
    default:
      return "";
  }
};
