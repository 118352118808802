import { FC, memo, useCallback, useMemo, useState } from "react";
import { useField } from "formik";

import { CircularProgress } from "@material-ui/core";

import { SharedComponents } from "@shared";

import { useStyledTheme } from "../../../../theme";

import { IFormikField } from "./types";
import { StyledField } from "./lib";

export const Field: FC<IFormikField> = memo(
  ({
    height = 44,
    field,
    title,
    subtitle = "",
    type = "text",
    placeholder,
    component,
    onKeyDown,
    onBlur,
    disabled = false,
    hasLoading = false,
  }) => {
    const {
      spacing: { sm, md },
      palette: { primaryRed },
    } = useStyledTheme();
    const [hasFocus, setFocus] = useState(false);
    const [input, meta] = useField(field);
    const _onBlur = useCallback(async () => {
      setFocus(false);
      onBlur && (await onBlur());
    }, [setFocus, onBlur]);
    const onFocus = useCallback(() => setFocus(true), [setFocus]);
    const hasFocusOrValue = useMemo(
      () => (input.value && input.value.length > 0) || hasFocus,
      [input, hasFocus]
    );
    return (
      <SharedComponents.Column>
        <SharedComponents.Text text={title} type="h3SemiBold" />
        <SharedComponents.VerticalBox height={sm} />
        {subtitle && <SharedComponents.Text text={subtitle} type="xSmall" />}
        <SharedComponents.VerticalBox height={md} />
        <div style={{ position: "relative", width: "100%" }}>
          <StyledField
            hasFocus={hasFocusOrValue}
            onBlur={_onBlur}
            onFocus={onFocus}
            height={height}
            component={component}
            placeholder={placeholder}
            type={type}
            name={field}
            onKeyDown={onKeyDown}
            disabled={disabled}
          />
          <div
            style={{
              position: "absolute",
              right: 16,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {hasLoading && <CircularProgress size={24} />}
          </div>
        </div>

        {meta.error && meta.touched && (
          <SharedComponents.Text
            text={meta.error}
            type={"small"}
            color={primaryRed}
          />
        )}
      </SharedComponents.Column>
    );
  }
);
