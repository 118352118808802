import { createContext, useContext } from "react";
import { IStepperContext } from "../types";

export const StepperContext = createContext<IStepperContext | null>(null);

export const useStepperContext = () => {
  const context = useContext(StepperContext);

  if (!context) {
    throw new Error("Stepper context is not defined");
  }

  return context;
};
