import styled from "styled-components";

export const IconWrapper = styled.div`
  position: absolute;
  right: ${({ theme }) => `${theme.spacing.md}px`};
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
`;
