import { observer } from "mobx-react";
import { useField } from "formik";
import { computed } from "mobx";
import { useEffect, useState } from "react";

import { SharedComponents, SharedStore } from "@shared";

import { WARNING_LIST } from "../constants";

interface IRegionsTagsList {
  isCreation: boolean;
  showWarning: boolean;
}

export const RegionsTagsList = observer(
  ({ showWarning = true, isCreation }: IRegionsTagsList) => {
    const {
      data: { regions },
    } = SharedStore;
    const warningList = computed(() =>
      regions.filter((c) => WARNING_LIST.includes(c.name.toLowerCase()))
    ).get();
    const [selectedRegions] = useField<Array<string>>("regions");
    const [hasWarning, setWarning] = useState(false);
    /**
     * Show warning if country in warning list
     * */
    useEffect(() => {
      const hasWarning = selectedRegions.value.find((item) =>
        warningList.map((i) => i.id).includes(item)
      );
      hasWarning ? setWarning(true) : setWarning(false);
    }, [selectedRegions.value, setWarning, warningList]);
    return (
      <SharedComponents.FormikLib.TagsList
        isCreation={isCreation}
        hasWarning={hasWarning && showWarning}
        warningTitle={
          "While you selected a Grt. China region, videos may not be uploaded at this time"
        }
        data={regions}
        title="Choose region*"
        field="regions"
      />
    );
  }
);
