import { observer } from "mobx-react";
import { useField } from "formik";
import { useEffect, useMemo, useState } from "react";

import { SharedComponents, SharedStore } from "@shared";

import { WARNING_LIST, GRT_CHINA_LIST } from "../constants";
import { computed } from "mobx";

interface ICountriesTagsList {
  isCreation: boolean;
  showWarning: boolean;
}

export const CountriesTagsList = observer(
  ({ showWarning = true, isCreation }: ICountriesTagsList) => {
    const {
      data: { countries },
    } = SharedStore;
    const warningList = computed(() =>
      countries.filter((c) => WARNING_LIST.includes(c.name.toLowerCase()))
    ).get();
    const grtChinaList = computed(() =>
      countries.filter((c) => GRT_CHINA_LIST.includes(c.name.toLowerCase()))
    ).get();
    const [selectedRegions] = useField<Array<string>>("regions");
    const [selectedCountries, , countriesHelper] =
      useField<Array<string>>("countries");
    const [hasWarning, setWarning] = useState(false);

    const filteredCountries = useMemo(() => {
      return selectedRegions.value.length === 0
        ? countries
        : countries.filter((c) => selectedRegions.value.includes(c.parentId));
    }, [selectedRegions.value, countries]);

    /**
     * Uncheck all countries from unselected regions
     */
    useEffect(() => {
      if (!!selectedRegions.value.length) {
        const fullCountriesData = countries.filter((c) =>
          selectedCountries.value.includes(c.id)
        );
        const newCountries = fullCountriesData
          .filter((c) => selectedRegions.value.includes(c.parentId))
          .map((i) => i.id);
        countriesHelper.setValue(newCountries);
      } else {
        countriesHelper.setValue([]);
      }
    }, [selectedRegions.value.length]);

    const warningTitle = useMemo(() => {
      const selectedCountriesNames = filteredCountries
        .filter(
          (c) =>
            selectedRegions.value.includes(c.parentId) &&
            selectedCountries.value.includes(c.id)
        )
        .map((i) => i.name.toLowerCase());
      const grtChinaCountries = selectedCountries.value.find((item) =>
        grtChinaList.map((i) => i.id).includes(item)
      );
      const hasGrtChinaCountries =
        grtChinaCountries && grtChinaCountries.length > 0;
      const hasUSA = selectedCountriesNames.includes("united states");
      if (hasUSA && !hasGrtChinaCountries) {
        return "Videos containing consumers from the state of Illinois may NOT be uploaded to VAP";
      } else if (hasUSA && hasGrtChinaCountries) {
        return "Videos containing consumers from the state of Illinois and Grt. China region may NOT be uploaded to VAP";
      } else if (!hasUSA && hasGrtChinaCountries) {
        return "While you selected a country from Grt. China region, videos may not be uploaded at this time";
      }
      return "";
    }, [
      filteredCountries,
      grtChinaList,
      warningList,
      countries,
      selectedCountries.value,
      selectedRegions.value,
    ]);

    /**
     * Show warning if country in warning list
     * */
    useEffect(() => {
      const hasWarning = selectedCountries.value.find((item) =>
        warningList.map((i) => i.id).includes(item)
      );
      hasWarning ? setWarning(true) : setWarning(false);
    }, [selectedCountries.value, setWarning, warningList]);
    return (
      <SharedComponents.FormikLib.TagsList
        isCreation={isCreation}
        hasWarning={hasWarning && showWarning}
        warningTitle={warningTitle}
        data={filteredCountries}
        title="Choose country*"
        field="countries"
        disabled={!selectedRegions.value.length}
      />
    );
  }
);
