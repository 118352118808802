import { observer } from "mobx-react";
import { useField } from "formik";
import { useEffect, useMemo } from "react";

import { SharedComponents, SharedStore } from "@shared";

interface IBrandsTagsList {
  isCreation?: boolean;
}

export const BrandsTagsList = observer(
  ({ isCreation = false }: IBrandsTagsList) => {
    const {
      data: { brands },
    } = SharedStore;
    const [selectedCategories] = useField<Array<string>>("categories");
    const [selectedBrands, , brandsHelpers] = useField<Array<string>>("brands");
    const filteredBrands = useMemo(() => {
      return selectedCategories.value.length === 0
        ? brands
        : brands.filter((b) => selectedCategories.value.includes(b.parentId));
    }, [selectedCategories.value, brands]);
    /**
     * Uncheck all brands from unselected category
     */
    useEffect(() => {
      if (selectedCategories.value.length > 0) {
        const fullBrandsData = brands.filter((b) =>
          selectedBrands.value.includes(b.id)
        );
        const newBrands = fullBrandsData
          .filter((b) => selectedCategories.value.includes(b.parentId))
          .map((i) => i.id);
        brandsHelpers.setValue(newBrands);
      } else {
        brandsHelpers.setValue([]);
      }
    }, [selectedCategories.value.length]);
    return (
      <SharedComponents.FormikLib.TagsList
        isCreation={isCreation}
        data={filteredBrands}
        title="Choose project brand"
        field="brands"
        disabled={selectedCategories.value.length === 0}
      />
    );
  }
);
