import styled from "styled-components";
import { Tab } from "@material-ui/core";

export const TabHeader = styled(Tab)`
  &.MuiTab-root {
    background: ${({ theme }) => theme.palette.primaryBlue};
    opacity: 1;
    color: ${({ theme }) => theme.palette.primaryWhite};
    font-weight: 600;
    border-top: 1px solid ${({ theme }) => theme.palette.primaryBlue};
    border-left: 1px solid ${({ theme }) => theme.palette.primaryBlue};
    border-right: 1px solid ${({ theme }) => theme.palette.primaryBlue};
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    :nth-child(2) {
      margin: 0 ${({ theme }) => theme.spacing.lg}px;
    }
  }
  &.Mui-selected {
    background: transparent;
    color: ${({ theme }) => theme.palette.primaryBlue};
  }
`;
