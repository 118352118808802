import styled from "styled-components";

interface IGrip {
  width: number;
  background?: string;
}

export const Grip = styled.div<IGrip>`
  position: absolute;
  z-index: 1000;
  width: ${({ width }) => `${width}px`};
  background: ${({ background = "#F1B434" }) => background};
  height: 48px;

  &:hover {
    cursor: grab;
  }
`;
