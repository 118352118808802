import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 473px;
  padding: 0 0 24px 0;
  max-width: 1900px;
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.gray}`};
  flex: 1;
`;
