import { computed } from "mobx";

import { Types } from "@videoAnalytics";

export const useTop5Tags = (tags?: Array<Types.INameRef>) => {
  return computed(() => {
    const empty = "...";
    if (tags) {
      if (tags.length === 0) return empty;
      const length = tags.length < 5 ? tags.length : 5;
      return tags.reduce((acc, curr, index) => {
        if (index < length) {
          if (index === length - 1) {
            if (index < 5) {
              const newAcc = acc + `#${curr.name}`;
              return newAcc;
            } else {
              const newAcc = acc + `#${curr.name}...`;
              return newAcc;
            }
          }
          const newAcc = acc + `#${curr.name}, `;
          return newAcc;
        } else {
          return acc;
        }
      }, "");
    }
    return empty;
  }).get();
};
