import styled from "styled-components";
import { IFlexContainer } from "./types";

export const FlexContainer = styled.div<IFlexContainer>`
  display: flex;
  flex: ${({ flexFactor = "none" }) => flexFactor};
  flex-direction: ${({ direction = "row" }) => direction};
  justify-content: ${({ justifyContent = "center" }) => justifyContent};
  align-items: ${({ alignItems = "center" }) => alignItems};
  padding: ${({ padding = 24 }) => padding}px 0;
  margin: ${({ margin = "0 0 0 0" }) => margin};
  flex-wrap: ${({ wrap = "wrap" }) => wrap};
`;
