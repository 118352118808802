import { SVGProps } from "react";

export const IconTextRecognitionActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 3a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3h-3.75a1.5 1.5 0 0 0-1.2.6l-2.85 3.8a1.5 1.5 0 0 1-2.4 0l-2.85-3.8a1.5 1.5 0 0 0-1.2-.6H3a3 3 0 0 1-3-3V3Zm5.25 1.5a.75.75 0 0 0 0 1.5h13.5a.75.75 0 1 0 0-1.5H5.25Zm0 3.75a.75.75 0 0 0 0 1.5h13.5a.75.75 0 1 0 0-1.5H5.25Zm0 3.75a.75.75 0 1 0 0 1.5h7.5a.75.75 0 1 0 0-1.5h-7.5Z"
      fill="#fff"
    />
  </svg>
);
