import styled from "styled-components";

interface ICover {
  left: number;
  width: number;
}

export const Cover = styled.div<ICover>`
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: ${({ left }) => `${left}px`};
  width: ${({ width }) => `${width}px`};
  background: rgba(241, 180, 52, 0.33);
`;
