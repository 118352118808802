import { IBlurTypeItem } from "../types";

enum BlurAlgortihms {
  BlurEyes = "BlurEyes",
  BlurEyesNoseMouth = "BlurEyesNoseMouth",
  BlurForeheadEyesNose = "BlurForeheadEyesNose",
  BlurFace = "BlurFace",
}

export const ALGORITHMS: Array<IBlurTypeItem> = [
  {
    src: "/BlurEyes.webp",
    name: "Blur eyes only",
    field: "blurMode",
    value: BlurAlgortihms.BlurEyes,
  },
  {
    src: "/BlurEyesNoseMouth.webp",
    name: "Blur eyes, nose, mouth",
    field: "blurMode",
    value: BlurAlgortihms.BlurEyesNoseMouth,
  },
  {
    src: "/BlurForeheadEyesNose.webp",
    name: "Blur forehead, eyes, nose",
    field: "blurMode",
    value: BlurAlgortihms.BlurForeheadEyesNose,
  },
  {
    src: "/BlurFace.webp",
    name: "Blur face",
    field: "blurMode",
    value: BlurAlgortihms.BlurFace,
  },
];
