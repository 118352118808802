import { FC } from "react";
import moment from "moment";
import { observer } from "mobx-react";

import { SharedComponents, Theme } from "@shared";

import { IVideoInfo } from "./types";
import * as VideoInfoLib from "./lib";

export const VideoInfo: FC<IVideoInfo> = observer(({ videoDetails }) => {
  const {
    spacing: { md },
  } = Theme.useStyledTheme();

  const formatDate = moment(videoDetails?.createdAt).format(
    "MM/DD/YYYY, h:mm:ss"
  );

  if (videoDetails === undefined) {
    return null;
  }
  return (
    <VideoInfoLib.Wrapper marginTop={md}>
      <SharedComponents.InfoItem
        title="Owner"
        value={videoDetails.ownerEmail}
        Icon={SharedComponents.Icons.IconUser}
      />
      <SharedComponents.InfoItem
        title="Created at"
        value={formatDate}
        Icon={SharedComponents.Icons.IconUpdate}
      />
    </VideoInfoLib.Wrapper>
  );
});
