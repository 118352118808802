import { computed } from "mobx";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Interweave } from "interweave";
import { Skeleton } from "@material-ui/lab";
import { useRef, useState, useEffect } from "react";

import { transformHTMLContent } from "../../../../../../templates/video_projects_page/components/search_modal/utils";
import { InterweaveWrapper } from "../../../../../../templates/video_projects_page/components/search_modal/components/interweave_wrapper";

import { Types, Utils as VAPUtils, VideoAnalyticsStore } from "@videoAnalytics";
import { SharedComponents, Theme, Utils } from "@shared";

import { usePlayerContext } from "../../context";

interface IListItem {
  index: number;
}

const ListItemWrapper = styled.div<{ isActive: boolean; isLast: boolean }>`
  display: flex;
  height: fit-content;
  background: ${({ isActive }) =>
    isActive ? "rgba(128, 145, 159, .25)" : "transparent"};
  border-left: ${({ isActive, theme }) =>
    isActive ? `2px solid ${theme.palette.primaryBlue}` : "none"};
  transition: 0.1s ease;
  padding: 16px 16px 16px 4px;
  border-bottom: ${({ isLast }) => (isLast ? "1px solid black" : "none")};
  &:hover {
    cursor: pointer;
  }
`;

export const ListItem = observer(({ index }: IListItem) => {
  const {
    palette: { primaryBlue },
    spacing: { sm, md, lg },
  } = Theme.useStyledTheme();

  const {
    data: {
      setVideoTranscriptPlayerProgress,
      videoTranscriptPlayerProgress,
      videoTranscript,
      videoTranscriptSearchValue,
      searchedVideoTranscripts,
    },
  } = VideoAnalyticsStore;

  const item: Types.ISearchVideoTranscriptItem | undefined = computed(() => {
    if (videoTranscriptSearchValue !== "") {
      if (searchedVideoTranscripts.length === 0) return undefined;
      if (index > searchedVideoTranscripts?.length - 1) return undefined;
      return searchedVideoTranscripts[index];
    }
    if (!videoTranscript) return undefined;
    if (index > videoTranscript?.length - 1) return undefined;
    return videoTranscript[index];
  }).get();

  const hasSpeakerTitle = computed(() => {
    if (
      item &&
      videoTranscript[index - 1] &&
      videoTranscript[index - 1].speakerId === item.speakerId
    ) {
      return false;
    }
    return true;
  }).get();

  const isLast = computed(() => {
    if (
      item &&
      videoTranscript[index + 1] &&
      videoTranscript[index + 1].speakerId !== item.speakerId
    ) {
      return false;
    }
    return true;
  }).get();

  const [isActive, setActive] = useState(false);

  const itemRef = useRef<HTMLDivElement>(null);

  const { listWrapperRef, playerRef } = usePlayerContext();

  useEffect(() => {
    if (
      item &&
      videoTranscriptPlayerProgress >= parseFloat(item.startTime) &&
      videoTranscriptPlayerProgress < parseFloat(item.endTime)
    ) {
      if (!isActive) {
        setActive(true);
      }
    } else {
      if (isActive) {
        setActive(false);
      }
    }
  }, [item, videoTranscriptPlayerProgress, setActive, isActive]);

  const onClick = () => {
    if (!item) return;
    playerRef.current?.seekTo(parseFloat(item.startTime));
    // listWrapperRef.current?.scrollToRow(index);
    const offset = listWrapperRef.current?.getOffsetForRow({
      index,
      alignment: "start",
    });
    listWrapperRef.current?.scrollToPosition(offset);
    setVideoTranscriptPlayerProgress(parseFloat(item.startTime));
  };

  if (!item) {
    return (
      <div>
        <SharedComponents.Text
          text={"Loading..."}
          type={"button"}
          color={primaryBlue}
        />
        <SharedComponents.VerticalBox height={lg} />
        <Skeleton variant={"rect"} height={md} width={"20%"} />
        <SharedComponents.VerticalBox height={lg} />
        <Skeleton variant={"rect"} height={md} width={"80%"} />
        <SharedComponents.VerticalBox height={sm} />
        <Skeleton variant={"rect"} height={md} width={"80%"} />
        <SharedComponents.VerticalBox height={sm} />
        <Skeleton variant={"rect"} height={md} width={"80%"} />
      </div>
    );
  }

  return (
    <ListItemWrapper
      ref={itemRef}
      onKeyDown={Utils.onKeyDown(onClick)}
      isActive={isActive}
      isLast={!isLast}
      onClick={onClick}
    >
      <SharedComponents.Column>
        {hasSpeakerTitle && (
          <SharedComponents.Text
            type={"h3SemiBold"}
            text={VAPUtils.formatSpeaker(item.speakerId)}
            color={primaryBlue}
          />
        )}
        <SharedComponents.VerticalBox height={sm} />
        <SharedComponents.Row>
          <SharedComponents.Column>
            <SharedComponents.Text
              type={"xSmallSemiBold"}
              text={`${Utils.formatTimeShort(
                parseFloat(item.startTime)
              )} - ${Utils.formatTimeShort(parseFloat(item.endTime))}`}
            />
          </SharedComponents.Column>
          <SharedComponents.HorizontalBox width={sm} />
          <SharedComponents.Column flex={3.5}>
            <InterweaveWrapper>
              <Interweave
                transform={transformHTMLContent}
                content={
                  !item?.translatedText || item?.translatedText === ""
                    ? item.text
                    : item.translatedText
                }
              />
            </InterweaveWrapper>
          </SharedComponents.Column>
        </SharedComponents.Row>
      </SharedComponents.Column>
    </ListItemWrapper>
  );
});
