import styled from "styled-components";
import { SCROLLBAR } from "../../scrollbar";

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 46px;
  width: 100%;
  ${() => SCROLLBAR}
`;
