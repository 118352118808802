import styled from "styled-components";
import { Switch as MuiSwitch } from "@material-ui/core";

export const Switch = styled(MuiSwitch)`
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.palette.secondaryGray};
    opacity: 1;
  }
  .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.palette.primaryBlue};
  }
  .MuiSwitch-track {
    background-color: ${({ theme }) => theme.palette.secondaryGray};
    opacity: 1;
  }
  .MuiSwitch-switchBase .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.palette.gray};
  }
`;
