import styled from "styled-components";

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => ` 0 ${theme.spacing.lg}px 0 ${theme.spacing.sm}px`};
  overflow: auto;
  min-width: 0;
`;
