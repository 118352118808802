import * as React from "react";

export function IconParticipants(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.5 30s2.5 0 2.5-2.5-2.5-10-12.5-10S15 25 15 27.5s2.5 2.5 2.5 2.5h20zm-19.945-2.5a.64.64 0 01-.055-.01c.003-.66.418-2.575 1.9-4.3 1.38-1.617 3.805-3.19 8.1-3.19 4.293 0 6.718 1.575 8.1 3.19 1.483 1.725 1.895 3.642 1.9 4.3l-.02.005a.685.685 0 01-.035.005h-19.89zm9.945-15a5 5 0 100-10 5 5 0 000 10zm7.5-5a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zM17.34 18.2a14.7 14.7 0 00-3.075-.617A18.378 18.378 0 0012.5 17.5C2.5 17.5 0 25 0 27.5 0 29.168.833 30 2.5 30h10.54a5.596 5.596 0 01-.54-2.5c0-2.525.943-5.105 2.725-7.26a13.229 13.229 0 012.115-2.04zM12.3 20a13.733 13.733 0 00-2.3 7.5H2.5c0-.65.41-2.575 1.9-4.31 1.362-1.59 3.73-3.14 7.9-3.188V20zM3.75 8.75a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm7.5-5a5 5 0 100 10 5 5 0 000-10z"
        fill="#808B9F"
      />
    </svg>
  );
}
