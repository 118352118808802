import { FC } from "react";

import { SharedComponents } from "@shared";

import { IControls } from "./types";
import { ICON_SIZE } from "./constants";
import * as ControlsLib from "./lib";

export const Controls: FC<IControls> = ({ isPlaying, onClick }) => {
  if (isPlaying) {
    return (
      <ControlsLib.Wrapper onClick={onClick} isPlaying={isPlaying}>
        <SharedComponents.Icons.IconPause
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
      </ControlsLib.Wrapper>
    );
  }

  return (
    <ControlsLib.Wrapper onClick={onClick} isPlaying={isPlaying}>
      <SharedComponents.Icons.IconPlay width={ICON_SIZE} height={ICON_SIZE} />
    </ControlsLib.Wrapper>
  );
};
