import styled from "styled-components";

interface IBorder {
  left: number;
  width: number;
  height: number;
  type: "top" | "bottom";
}

export const Border = styled.div<IBorder>`
  position: absolute;
  ${({ type }) => {
    if (type === "top") return { top: 0 };
    if (type === "bottom") return { bottom: 0 };
    return "";
  }};
  left: ${({ left }) => `${left}px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background: ${({ theme }) => theme.palette.primaryDarkYellow};
`;
