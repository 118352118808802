import { SVGProps } from "react";

export function IconArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6a.75.75 0 01.75-.75h8.69L6.219 2.031A.751.751 0 117.281.969l4.5 4.5a.75.75 0 010 1.062l-4.5 4.5a.751.751 0 01-1.062-1.062l3.22-3.219H.75A.75.75 0 010 6z"
        fill="#003DA5"
      />
    </svg>
  );
}
