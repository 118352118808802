import { useCallback, useEffect, useMemo, useRef } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { observer } from "mobx-react";
import "react-virtualized/styles.css";
import { computed } from "mobx";
import * as _ from "lodash";
import {
  InfiniteLoader,
  List,
  ListRowProps,
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer,
} from "react-virtualized";

import { Types, VideoAnalyticsStore } from "@videoAnalytics";
import { SharedComponents, Theme } from "@shared";

import {
  TranscriptPlayer,
  ListItem,
  Refresher,
  WordCloud,
  FaceExpressionsChart,
  FaceEmotionsChart,
  SpeakerAnalyticsChart,
} from "./components";

import { PlayerContext } from "./context";

interface ITVideoAnalysis {
  videoDetails?: Types.IVideoDetails;
  hasAccess: boolean;
  isRaw: boolean;
  startTime?: string;
}

export const VideoAnalysis = observer(
  ({ hasAccess, isRaw, videoDetails, startTime }: ITVideoAnalysis) => {
    const {
      data: {
        videoTranscriptSentencesNumber,
        clearVideoTranscript,
        setVideoTranscriptPlayerProgress,
        videoTranscriptSearchValue,
        setVideoTranscriptPage,
        setVideoTranscriptSearchValue,
        setSearchedVideoTranscript,
        searchedVideoTranscripts,
        videoTranscriptPage,
        videoTranscript,
        loadings,
      },
      getVideoTranscripts,
      searchVideoTranscript,
    } = VideoAnalyticsStore;
    const {
      spacing: { lg },
    } = Theme.useStyledTheme();

    const cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 48,
    });

    function rowRenderer({ key, index, style, parent }: ListRowProps) {
      return (
        <CellMeasurer
          rowIndex={index}
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
        >
          <div style={style}>
            <ListItem index={index} />
          </div>
        </CellMeasurer>
      );
    }

    const isRowLoaded = computed(
      () =>
        loadings.get(Types.Endpoints.VIDEO_TRANSCRIPTS) ||
        loadings.get(Types.Endpoints.SEARCH_VIDEO_TRANSCRIPT)
    ).get();

    const next = useCallback(
      async (stopIndex: number) => {
        if (searchedVideoTranscripts.length > 0) return;
        if (!videoDetails?.id || videoDetails.id === "") return;

        if (stopIndex > videoTranscriptPage * THRESHOLD) {
          const newPage = videoTranscriptPage + 1;
          setVideoTranscriptPage(newPage);
          const input = {
            id: videoDetails?.id,
            page: newPage,
          };
          await getVideoTranscripts(input);
        }
      },
      [
        searchedVideoTranscripts.length,
        videoDetails?.id,
        videoTranscriptPage,
        setVideoTranscriptPage,
        getVideoTranscripts,
      ]
    );

    const playerRef = useRef<ReactPlayer>(null);
    const listWrapperRef = useRef<List>(null);

    const contextValue = useMemo(
      () => ({
        playerRef,
        listWrapperRef,
        startTime,
      }),
      [playerRef, listWrapperRef, startTime]
    );

    const rowCount = computed(() =>
      videoTranscriptSearchValue !== ""
        ? searchedVideoTranscripts.length
        : videoTranscript.length
    ).get();

    const debouncedSearch = useRef(
      _.debounce(
        (text: string) =>
          searchVideoTranscript({
            text,
            videoId: videoDetails?.id || "",
            page: 1,
          }),
        500
      )
    );

    useEffect(() => {
      return () => {
        clearVideoTranscript();
        setVideoTranscriptPlayerProgress(0);
        setSearchedVideoTranscript([]);
        setVideoTranscriptSearchValue("");
      };
    }, []);

    useEffect(() => {
      if (videoTranscriptSearchValue !== "" && videoDetails?.id) {
        debouncedSearch.current(videoTranscriptSearchValue);
      }
    }, [videoTranscriptSearchValue, videoDetails]);

    return (
      <SharedComponents.Row>
        <PlayerContext.Provider value={contextValue}>
          <>
            <Refresher listWrapperRef={listWrapperRef} />
            <SharedComponents.Column>
              <TranscriptPlayer
                playerRef={playerRef}
                height={PLAYER_HEIGHT}
                hasAccess={hasAccess}
                isRaw={isRaw}
              />
              <SharedComponents.Column flex={1}>
                <VideoAnalysisListWrapper>
                  <InfiniteLoader
                    threshold={THRESHOLD}
                    isRowLoaded={(params) => {
                      return isRowLoaded || false;
                    }}
                    loadMoreRows={(params) => {
                      return next(params.stopIndex);
                    }}
                    rowCount={videoTranscriptSentencesNumber}
                  >
                    {({ onRowsRendered }) => (
                      <>
                        <SharedComponents.Row
                          flex={0}
                          justifyContent={"space-between"}
                        >
                          <SharedComponents.Text
                            type={"button"}
                            text={"Transcript"}
                          />
                        </SharedComponents.Row>
                        <SharedComponents.VerticalBox height={lg} />
                        <SharedComponents.Search
                          height={40}
                          value={videoTranscriptSearchValue}
                          onClear={() => {
                            setVideoTranscriptSearchValue("");
                            setSearchedVideoTranscript([]);
                          }}
                          onChange={setVideoTranscriptSearchValue}
                        />
                        <SharedComponents.VerticalBox height={lg} />
                        <List
                          deferredMeasurementCache={cache}
                          overscanRowCount={5}
                          ref={listWrapperRef}
                          height={PLAYER_HEIGHT * 3}
                          onRowsRendered={onRowsRendered}
                          rowCount={rowCount}
                          rowHeight={cache.rowHeight}
                          rowRenderer={rowRenderer}
                          width={(window.innerWidth - 128 - 24 - 16) / 2}
                        />
                      </>
                    )}
                  </InfiniteLoader>
                </VideoAnalysisListWrapper>
              </SharedComponents.Column>
            </SharedComponents.Column>
            <SharedComponents.HorizontalBox width={16} />
            <SharedComponents.Column>
              <SharedComponents.Box height={"330px"}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FaceExpressionsChart
                      title={"Sentiments by face expressions"}
                      label={"Sentiment"}
                      width={width}
                      height={height}
                    />
                  )}
                </AutoSizer>
              </SharedComponents.Box>
              <SharedComponents.VerticalBox height={16} />
              <SharedComponents.Box height={"330px"}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FaceEmotionsChart
                      title={"Emotions by face expressions"}
                      label={"Emotions"}
                      width={width}
                      height={height}
                    />
                  )}
                </AutoSizer>
              </SharedComponents.Box>
              <SharedComponents.VerticalBox height={16} />
              <SharedComponents.Box height={"330px"}>
                <AutoSizer>
                  {({ width, height }) => (
                    <SpeakerAnalyticsChart
                      title={"Speaker analytics based on text"}
                      label={"Transcript"}
                      width={width}
                      height={height}
                    />
                  )}
                </AutoSizer>
              </SharedComponents.Box>
              <SharedComponents.VerticalBox height={16} />
              <SharedComponents.Box height={"400px"}>
                <WordCloud videoDetails={videoDetails} />
              </SharedComponents.Box>
            </SharedComponents.Column>
          </>
        </PlayerContext.Provider>
      </SharedComponents.Row>
    );
  }
);

const PLAYER_HEIGHT = 344;
const THRESHOLD = 50;

const VideoAnalysisListWrapper = styled.div`
  height: ${PLAYER_HEIGHT * 3}px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
