import { SharedComponents, Theme } from "@shared";

import { IBlurTypeItem } from "../types";
import { useField } from "formik";

export const BlurTypeItem = ({ src, name, field, value }: IBlurTypeItem) => {
  const {
    spacing: { sm },
    palette: { primaryBlue },
  } = Theme.useStyledTheme();

  const [input, , helpers] = useField(field);
  return (
    <SharedComponents.Column>
      <img src={src} height={"244px"} alt="" />
      <SharedComponents.Row alignItems={"center"}>
        <input
          type={"radio"}
          checked={input.value === value}
          onChange={() => helpers.setValue(value)}
        />
        <SharedComponents.HorizontalBox width={sm} />
        <SharedComponents.Text
          type={"button"}
          text={name}
          color={primaryBlue}
        />
      </SharedComponents.Row>
    </SharedComponents.Column>
  );
};
