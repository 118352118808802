import styled from "styled-components";

export const TextLinkContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  transition: 0.15s ease-in-out;

  :hover {
    transform: scale(1.1);
    margin-right: 4px;
  }
`;
