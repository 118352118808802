import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  position: absolute;
  top: -16px;
  right: 0;
  z-index: 1000;

  height: fit-content;
  overflow: hidden;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  border: 1px solid #003da5;

  transition: 0.2s linear;
`;
