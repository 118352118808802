import { SharedComponents, Theme } from "@shared";

import { PendingCoverWrapper } from "./vc.pendingCover.wrapper";
import { Img } from "./vc.thumbnail.img";
import styled from "styled-components";

const PendingCoverAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PendingCover = () => {
  const {
    spacing: { lg },
    palette: { primaryWhite },
  } = Theme.useStyledTheme();
  return (
    <PendingCoverWrapper>
      <Img src={"/pending.webp"} style={{ filter: "brightness(50%)" }} alt="" />
      <PendingCoverAbsolute>
        <SharedComponents.Text
          text={"Video is being processed"}
          type={"h2"}
          color={primaryWhite}
          whiteSpace={"nowrap"}
        />
        <SharedComponents.VerticalBox height={lg} />
      </PendingCoverAbsolute>
    </PendingCoverWrapper>
  );
};
