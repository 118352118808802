import { useCallback, ReactElement, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";

import { SharedComponents, Theme, Types } from "@shared";

interface IAddProjectStepTemplate {
  children: ReactElement;
  onNext?: (p?: any) => void;
  onPrev?: (p?: any) => void;
  fields: Array<string>;
  nextTitle?: string;
}

export const AddProjectStepTemplate = ({
  children,
  onPrev,
  fields,
  onNext,
  nextTitle,
}: IAddProjectStepTemplate) => {
  const {
    spacing: { md },
  } = Theme.useStyledTheme();

  const { currentStep, setCurrentStep, steps } =
    SharedComponents.useStepperContext();

  const { errors, setFieldTouched } = useFormikContext();

  /**
   * ERRORS BASED ON field PROPS
   * */
  const filteredErrors = useMemo(
    () =>
      Object.keys(errors)
        .filter((key) => fields.includes(key))
        .reduce((obj, key) => {
          //@ts-ignore
          obj[key] = errors[key];
          return obj;
        }, {}),
    [fields, errors]
  );

  const onPrevHandler = useCallback(() => {
    if (currentStep === 1) {
      onPrev && onPrev();
      return;
    }
    setCurrentStep(currentStep - 1);
    onPrev && onPrev();
  }, [onPrev, currentStep, setCurrentStep]);

  const onNextHandler = () => {
    /**
     * WE TOUCH ALL NEEDED FIELDS TO BE VALIDATED
     * */
    fields.forEach((field) => {
      setFieldTouched(field, true);
    });
    /**
     * PREVENT MOVING FORWARD IF FORM HAS ANY ERRORS
     * */
    if (Object.keys(filteredErrors).length > 0) return;
    /**
     * FOR LAST STEP CALLING onNext()
     * */
    if (currentStep === steps.length) {
      onNext && onNext();
    } else {
      /**
       * OTHERWISE GO TO THE NEXT STEP OPTIONALLY CALLING onNext()
       * */
      onNext && onNext();
    }
  };

  const showBackButton = useMemo(
    () => currentStep !== steps.length - 1,
    [currentStep, steps]
  );

  const fNextTitle = useMemo(
    () =>
      nextTitle ? nextTitle : currentStep === steps.length ? "SUBMIT" : "NEXT",
    [nextTitle, currentStep, steps]
  );

  useEffect(() => {
    /**
     * INITIAL VALIDATION
     * */
    fields.forEach((field) => {
      setFieldTouched(field, false, true);
    });
  }, []);

  return (
    <SharedComponents.Column>
      {children}
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Row justifyContent={"flex-end"}>
        {showBackButton && (
          <SharedComponents.Button
            type={Types.ButtonType.OUTLINED}
            text={"BACK"}
            onClick={onPrevHandler}
          />
        )}
        <SharedComponents.HorizontalBox width={md} />
        <SharedComponents.Button
          type={Types.ButtonType.FILLED}
          text={fNextTitle}
          onClick={onNextHandler}
        />
      </SharedComponents.Row>
      <SharedComponents.VerticalBox height={md} />
    </SharedComponents.Column>
  );
};
