import styled from "styled-components";

export const TagWrapper = styled.div`
  border-radius: 25px;
  border: ${({ theme }) => `1px solid ${theme.palette.primaryBlue}`};
  width: fit-content;
  padding: 9px 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => `0 ${theme.spacing.sm}px ${theme.spacing.sm}px 0`};
`;
