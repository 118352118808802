import styled from "styled-components";
import { Dialog } from "@material-ui/core";

export const Wrapper = styled(Dialog)`
  .MuiPaper-root {
    min-height: 169px;
    width: 70%;
    height: 80%;
    border-radius: 5px;
    overflow: hidden;

    -webkit-box-shadow: 0px 8px 24px 0px rgba(128, 139, 159, 0.2);
    -moz-box-shadow: 0px 8px 24px 0px rgba(128, 139, 159, 0.2);
    box-shadow: 0px 8px 24px 0px rgba(128, 139, 159, 0.2);
  }
`;
