import {
  PieChart as PieReChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Sector,
} from "recharts";

import { SharedComponents, Theme, Types } from "@shared";
import { useMemo, useState } from "react";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  startAngle,
  endAngle,
  fill,
  payload,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const delta = outerRadius / 6;
  if (percent * 100 < 20) {
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <text
          x={x > cx ? x + radius + delta : x - radius - delta}
          y={y > cy ? y + delta : y - delta}
          fill="black"
          fontWeight={"bold"}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  }

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <text
        x={x}
        y={y}
        fill="black"
        fontWeight={"bold"}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {/*{`${(percent * 100).toFixed(0)}% ${*/}
        {/*  payload?.count ? `(${payload.count} times)` : ""*/}
        {/*}`}*/}
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

interface IPieChart {
  data: Array<Types.IPieChartItem>;
  title: string;
}

export const PieChart = ({ data, title }: IPieChart) => {
  const {
    palette: { primaryBlue },
    spacing: { lg },
  } = Theme.useStyledTheme();

  const biggestIndex = useMemo(
    () =>
      data.reduce((idx: number, curr, i: number, arr) => {
        if (!curr?.value || !arr[idx]) {
          return idx;
        }
        return curr.value > arr[idx]?.value ? i : idx;
      }, 0),
    [data]
  );

  const [activeIndex, setActiveIndex] = useState<number>(biggestIndex);

  return (
    <SharedComponents.Column>
      <SharedComponents.Text
        text={title}
        type={"xSmallSemiBold"}
        color={primaryBlue}
      />
      <SharedComponents.VerticalBox height={lg} />
      <ResponsiveContainer width="100%" height="100%">
        <PieReChart>
          <Pie
            animationDuration={0}
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            activeIndex={activeIndex}
            activeShape={renderCustomizedLabel}
            outerRadius={75}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={(_, index) => setActiveIndex(index)}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend iconSize={8} iconType={"circle"} />
        </PieReChart>
      </ResponsiveContainer>
    </SharedComponents.Column>
  );
};
