import { FC } from "react";

import { SharedComponents, Theme } from "@shared";

import { IPlayer } from "./types";
import * as PlayerLib from "./lib";

export const Player: FC<IPlayer> = ({
  height = 480,
  url,
  onProgress,
  playerRef,
}) => {
  const {
    palette: { primaryWhite },
  } = Theme.useStyledTheme();
  if (url === undefined || url === "") {
    return (
      <SharedComponents.Column
        height={`${height}px`}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <SharedComponents.Text
          text={"Please wait, while we are processing video"}
          color={primaryWhite}
          type={"h2"}
        />
      </SharedComponents.Column>
    );
  }
  return (
    <PlayerLib.Wrapper>
      <PlayerLib.StyledPlayer
        ref={playerRef}
        config={{
          file: {
            attributes: {
              onContextMenu: (e: any) => e.preventDefault(),
              controlsList: "nodownload",
            },
          },
        }}
        onProgress={onProgress}
        controls
        url={url}
        width="100%"
        height={height}
      />
    </PlayerLib.Wrapper>
  );
};
