import { computed } from "mobx";
import { observer } from "mobx-react";
import { IconButton } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  SharedComponents,
  SharedStore,
  Utils,
  Types as SharedTypes,
  Theme,
} from "@shared";
import {
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
  Types,
} from "@videoAnalytics";
import { CommonPages } from "@modules";

import * as LocalComponents from "./components";
import { validationSchema, initialValues } from "./formik_data";

export const ProjectDetails = observer(() => {
  /**
   * route params
   * */
  const { projectId } = useParams();

  const navigate = useNavigate();
  /**
   * storage values
   * */
  const { getProjectMetadata, data, copyURLtoClipboard } = SharedStore;
  const {
    getProject,
    deleteProject,
    getProjectsStats,
    getProjects,
    clearSelectedProject,
    data: {
      setRequestReset,
      loadings,
      errors,
      selectedProject,
      isProjectOwner,
      isProjectMember,
    },
  } = VideoAnalyticsStore;

  const top5Tags = Utils.useTop5Tags(selectedProject?.tags);

  const hasProjectDetailsLoading = Utils.useQueryStatus(
    loadings,
    Types.Endpoints.PROJECT_DETAILS
  );

  const hasProjectDetailsErrors = Utils.useQueryStatus(
    errors,
    Types.Endpoints.PROJECT_DETAILS
  );
  const hasTaxonomyLoading = Utils.useQueryStatus(
    data.loadings,
    Types.Endpoints.PROJECT_FORM_METADATA
  );
  /**
   *theme data
   * */
  const {
    spacing: { sm, md, lg, xl },
    palette: { gray },
  } = Theme.useStyledTheme();

  /**
   * local state
   * */
  const [isDeleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);

  /**
   * memoized callback
   * */
  const onEditProject = useCallback(
    () =>
      navigate(
        `${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}/edit`
      ),
    [navigate, projectId]
  );

  const onCancelDeleteModal = useCallback(() => {
    setDeleteModalOpened(false);
  }, [setDeleteModalOpened]);

  const onShowDeleteModal = () => {
    setDeleteModalOpened(true);
  };

  const onDeleteProject = async () => {
    if (!selectedProject) {
      return;
    }
    setDeleteModalOpened(false);
    navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}`);
    await Promise.all([
      getProjects(),
      getProjectsStats(),
      deleteProject(selectedProject.id, ""),
    ]);
  };

  const hasAccess = computed(
    () =>
      ((isProjectOwner || isProjectMember) &&
        (data.hasEBPRole || data.hasUserRole)) ||
      data.hasAdminRole
  ).get();

  /**
   * side effects
   * */
  useEffect(() => {
    const init = async () => {
      await Promise.all([getProjectMetadata(), getProject(projectId || "")]);
    };
    init();
    return () => {
      clearSelectedProject();
      setRequestReset(Types.Endpoints.UPDATE_PROJECT);
    };
  }, []);

  if (hasProjectDetailsLoading || hasTaxonomyLoading) {
    return <SharedComponents.LoadingIndicator />;
  }

  if (selectedProject === undefined || hasProjectDetailsErrors) {
    return (
      <CommonPages.NotFound
        title={"Project does not exist"}
        subtitle={"We couldn't find the project you are looking for"}
        onBack={() => navigate(SharedTypes.PATHS.VIDEO_ANALYTICS)}
      />
    );
  }

  return (
    <VideoAnalyticsComponents.ProjectRoleController projectId={projectId || ""}>
      <SharedComponents.Screen>
        <SharedComponents.Modal
          isOpen={isDeleteModalOpened}
          cancelText={"No"}
          onCancel={onCancelDeleteModal}
          onConfirm={onDeleteProject}
          confirmText={"Yes"}
          type={SharedTypes.ModalTypes.DEFAULT}
          title={"Do you want to delete the project ?"}
        />
        <SharedComponents.VerticalBox height={32} />
        <SharedComponents.Breadcrumbs />
        <SharedComponents.FormikLib.Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(_) => {}}
        >
          <SharedComponents.Box>
            {hasAccess && (
              <SharedComponents.Row justifyContent={"flex-end"}>
                <SharedComponents.Button
                  type={SharedTypes.ButtonType.OUTLINED}
                  text={"ADD VIDEOS"}
                  onClick={() =>
                    navigate(
                      `${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}/video/add`
                    )
                  }
                />
                <SharedComponents.HorizontalBox width={md} />
                <SharedComponents.Button
                  type={SharedTypes.ButtonType.OUTLINED}
                  text={"ADD FILES"}
                  onClick={() =>
                    navigate(
                      `${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}/file/add`
                    )
                  }
                />
                <SharedComponents.HorizontalBox width={md} />
                <SharedComponents.Button
                  onClick={() =>
                    navigate(
                      `${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}/summary/add`
                    )
                  }
                  type={SharedTypes.ButtonType.FILLED}
                  text={"CREATE SUMMARY"}
                />
              </SharedComponents.Row>
            )}
            <SharedComponents.VerticalBox height={md} />
            <SharedComponents.AdvancedFilters
              data={[]}
              hasFilters={false}
              searchField={"text"}
            />
            <SharedComponents.VerticalBox height={lg} />
            <SharedComponents.Row justifyContent={"space-between"}>
              {selectedProject.hasPii && (
                <SharedComponents.Label text={"PII"} />
              )}
              {selectedProject.hasPii && (
                <SharedComponents.HorizontalBox width={sm} />
              )}
              <SharedComponents.Text text={selectedProject.name} type={"h1"} />
              <SharedComponents.Row justifyContent={"flex-end"}>
                <IconButton onClick={copyURLtoClipboard}>
                  <SharedComponents.Icons.IconShare />
                </IconButton>
                {hasAccess && (
                  <IconButton onClick={onEditProject}>
                    <SharedComponents.Icons.IconEdit />
                  </IconButton>
                )}
                {hasAccess && (
                  <IconButton onClick={onShowDeleteModal}>
                    <SharedComponents.Icons.IconDelete />
                  </IconButton>
                )}
              </SharedComponents.Row>
            </SharedComponents.Row>
            <SharedComponents.VerticalBox height={sm} />
            {/*BASIC DATA INFO*/}
            <SharedComponents.Row>
              <SharedComponents.InfoTile
                Icon={SharedComponents.Icons.IconClp}
                name={"CLP/Study #:"}
                value={selectedProject.clp}
              />
              <SharedComponents.HorizontalBox width={lg} />
              <SharedComponents.InfoTile
                Icon={SharedComponents.Icons.IconUser}
                name={"Owner:"}
                value={selectedProject.ownerEmail}
              />
              <SharedComponents.HorizontalBox width={lg} />
              <SharedComponents.InfoTile
                Icon={SharedComponents.Icons.IconFile}
                name={"Files:"}
                value={selectedProject.stats.filesNumber}
              />
              <SharedComponents.HorizontalBox width={lg} />
              <SharedComponents.InfoTile
                Icon={SharedComponents.Icons.IconTaxonomy}
                name={"Taxonomy:"}
                value={selectedProject.taxonomy || "..."}
              />
              <SharedComponents.HorizontalBox width={lg} />
              <SharedComponents.InfoTile
                Icon={SharedComponents.Icons.IconTags}
                name={"Project tags:"}
                value={top5Tags}
              />
            </SharedComponents.Row>
            <SharedComponents.VerticalBox height={sm} />
            {/* PROJECT SUMMARY */}
            <SharedComponents.Row alignItems={"center"}>
              <SharedComponents.SummaryTile
                Icon={SharedComponents.Icons.IconParticipants}
                iconHeight={"30px"}
                iconWidth={"40px"}
                name={"Project Members"}
                justifyContent={"flex-start"}
                value={selectedProject.members.length}
                flex={2.5}
              />
              <SharedComponents.Box
                width={"1px"}
                height={"40px"}
                background={gray}
              />
              <SharedComponents.SummaryTile
                Icon={SharedComponents.Icons.IconVideoUpload}
                iconHeight={"25px"}
                iconWidth={"40px"}
                name={"Videos"}
                value={selectedProject.stats.videosNumber}
                flex={2}
              />
              <SharedComponents.Box
                width={"1px"}
                height={"40px"}
                background={gray}
              />
              <SharedComponents.SummaryTile
                Icon={SharedComponents.Icons.IconSummaries}
                iconHeight={"34px"}
                iconWidth={"34px"}
                name={"Summaries"}
                value={selectedProject.stats.summariesLength}
                flex={2}
              />
              <SharedComponents.Box
                width={"1px"}
                height={"40px"}
                background={gray}
              />
              <SharedComponents.SummaryTile
                Icon={SharedComponents.Icons.IconFiles}
                iconHeight={"26px"}
                iconWidth={"32px"}
                name={"Files"}
                value={selectedProject.stats.filesNumber}
                flex={1.7}
              />
              <SharedComponents.Box
                width={"1px"}
                height={"40px"}
                background={gray}
              />
              <SharedComponents.SummaryTile
                Icon={SharedComponents.Icons.IconDuration}
                iconHeight={"30px"}
                iconWidth={"30px"}
                name={"Total videos duration"}
                value={selectedProject.stats.videosLength}
                flex={3}
              />
            </SharedComponents.Row>
            <SharedComponents.VerticalBox height={md} />
            <LocalComponents.ProjectAnalytics />
            <SharedComponents.VerticalBox height={md} />
            <LocalComponents.TagsSection tags={selectedProject.tags} />
            <SharedComponents.VerticalBox height={md} />
            <SharedComponents.Separator />
            <LocalComponents.Tabs
              projectId={projectId || ""}
              hasAccess={hasAccess}
            />
            <SharedComponents.VerticalBox height={xl} />
          </SharedComponents.Box>
        </SharedComponents.FormikLib.Form>
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.ProjectRoleController>
  );
});
