import styled from "styled-components";
import { ILabel } from "../types";

export const Label = styled.label<ILabel>`
  display: flex;
  width: 205px;
  align-items: center;
  justify-content: space-evenly;
  font-weight: ${({ isToggled }) => (isToggled ? "800" : "500")};
`;
