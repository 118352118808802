import * as React from "react";

export function IconError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 37.5a17.5 17.5 0 110-35 17.5 17.5 0 010 35zm0 2.5a20 20 0 100-40 20 20 0 000 40z"
        fill="#EF3340"
      />
      <path
        d="M11.378 11.378a1.285 1.285 0 011.82 0L20 18.183l6.803-6.805a1.288 1.288 0 011.82 1.82L21.818 20l6.805 6.803a1.288 1.288 0 01-1.82 1.82L20 21.818l-6.802 6.805a1.288 1.288 0 01-1.82-1.82L18.183 20l-6.805-6.802a1.285 1.285 0 010-1.82z"
        fill="#EF3340"
      />
    </svg>
  );
}
