import { ChangeEvent, FC, useCallback, KeyboardEvent } from "react";
import { useField } from "formik";

import { FlexContainer } from "../../../flex_container";
import { useStyledTheme } from "../../../../theme";
import { Text } from "../../../text";

import * as InputGroupLib from "./lib";

import { IInputGroup } from "./types";

const renderItem = (groupField: string) => (item: string, index: number) =>
  <InputGroupLib.Tag item={item} key={index} groupField={groupField} />;

export const InputGroup: FC<IInputGroup> = ({
  inputField,
  groupField,
  title,
}) => {
  const {
    spacing: { sm },
  } = useStyledTheme();

  const [inputProps, , inputHelpers] = useField(inputField);
  const [membersInputProps, , membersHelpers] = useField(groupField);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      inputHelpers.setValue(e.target.value, false),
    [inputHelpers]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === "Enter") {
        membersHelpers.setValue([...membersInputProps.value, inputProps.value]);
        inputHelpers.setValue("");
      }
    },
    [membersHelpers, inputProps, inputHelpers, membersInputProps]
  );

  return (
    <FlexContainer direction="column" padding={0} alignItems="flex-start">
      <Text text={title} type="button" margin={`${sm}px 0 `} />

      <InputGroupLib.Wrapper>
        <InputGroupLib.Input
          value={inputProps.value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </InputGroupLib.Wrapper>
      <FlexContainer direction="row" padding={sm}>
        {membersInputProps.value.map(renderItem)}
      </FlexContainer>
    </FlexContainer>
  );
};
