import { SharedComponents, Theme } from "@shared";
import { TextLinkContainer } from "./textLink.container";

interface ITextLink {
  to: string;
}

export const TextLink = ({ to }: ITextLink) => {
  const theme = Theme.useStyledTheme();
  return (
    <SharedComponents.Link to={to}>
      <TextLinkContainer>
        <SharedComponents.Text
          type="xSmallMedium"
          text="View more"
          margin="0 8px 0 0"
          color={theme.palette.primaryBlue}
        />
        <SharedComponents.Icons.IconArrowRight />
      </TextLinkContainer>
    </SharedComponents.Link>
  );
};
