import { FC, useEffect, useMemo, useRef } from "react";

import { SharedComponents, Theme } from "@shared";
import { IStepperStep } from "../types";
import { useStepperContext } from "../context";

import { StepWrapper } from "./stepper.step.wrapper";

export const Step: FC<IStepperStep> = ({ name, index }) => {
  const {
    palette: { gray, primaryBlue, text },
  } = Theme.useStyledTheme();
  const { setWidths, paddingRight, currentStep } = useStepperContext();
  const ref = useRef<HTMLDivElement>(null);
  const isSelected = useMemo(
    () => currentStep === index + 1,
    [currentStep, index]
  );
  const color = useMemo(() => {
    return isSelected ? primaryBlue : currentStep > index ? text : gray;
  }, [isSelected, currentStep, index, primaryBlue, gray]);
  useEffect(() => {
    const width: number = ref.current?.offsetWidth || 0;
    setWidths((prevState) => [...prevState, { width, index }]);
  }, [setWidths, ref, index]);
  return (
    <StepWrapper ref={ref} paddingRight={paddingRight}>
      <SharedComponents.Text
        type={currentStep >= index + 1 ? "h2" : "h2Regular"}
        text={name}
        color={color}
        whiteSpace={"nowrap"}
      />
    </StepWrapper>
  );
};
