import { useParams, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";

import {
  SharedComponents,
  SharedStore,
  Types as SharedTypes,
  Utils,
  Theme,
} from "@shared";
import {
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
  Types,
} from "@videoAnalytics";
import { CommonPages } from "@modules";

import * as LocalComponents from "./components";

export const VideoDetails = observer(() => {
  /**
   * route params
   * */
  const { videoId, projectId, startTime } = useParams();
  const fVideoId = useMemo(() => videoId || "", [videoId]);
  const navigate = useNavigate();

  /**
   * theme data
   * */
  const {
    spacing: { lg, md, sm },
  } = Theme.useStyledTheme();

  /**
   * storage values
   * */
  const {
    data: { hasAdminRole, hasUserRole, hasEBPRole },
  } = SharedStore;
  const {
    clearVideoDetails,
    getVideoDetails,
    getProject,
    deleteVideo,
    getProjects,
    getProjectsStats,
    getVideoTranscripts,
    data: {
      setRequestReset,
      videoDetails,
      loadings,
      errors,
      isFileOwner,
      isFileAdmin,
      isFileMember,
    },
  } = VideoAnalyticsStore;

  const hasAccess = computed(
    () =>
      ((isFileOwner || isFileAdmin || isFileMember) &&
        (hasEBPRole || hasUserRole)) ||
      hasAdminRole
  ).get();

  const hasVideoDetailsLoading = Utils.useQueryStatus(
    loadings,
    Types.Endpoints.VIDEO_DETAILS
  );

  const hasVideoDetailsError = Utils.useQueryStatus(
    errors,
    Types.Endpoints.VIDEO_DETAILS
  );

  const hasVideoDetailsSuccess = Utils.useQueryStatus(
    errors,
    Types.Endpoints.VIDEO_DETAILS
  );

  /**
   * local state
   * */
  const [isRaw, setRaw] = useState<boolean>(false);
  const [isDeleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);

  /**
   * memoized callback
   * */
  const onCancelDeleteModal = useCallback(() => {
    setDeleteModalOpened(false);
  }, [setDeleteModalOpened]);

  const onShowDeleteModal = () => {
    setDeleteModalOpened(true);
  };

  const onDeleteProject = async () => {
    setDeleteModalOpened(false);
    if (!projectId) {
      await deleteVideo(fVideoId, videoDetails?.name || "");
      navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}`);
      await Promise.all([getProjects(), getProjectsStats()]);
      return;
    } else {
      navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}`);
      await deleteVideo(fVideoId, videoDetails?.name || "");
      await getProject(projectId);
      return;
    }
  };

  const onSwitchClick = useCallback(() => setRaw((prev) => !prev), [setRaw]);

  /**
   * side effects
   * */
  useEffect(() => {
    const init = async () => {
      await getVideoDetails(fVideoId);
      await getVideoTranscripts({ id: fVideoId, page: 1 });
    };
    init();
    return () => {
      clearVideoDetails();
      setRequestReset(Types.Endpoints.VIDEO_DETAILS);
    };
  }, []);

  if (hasVideoDetailsError || (hasVideoDetailsSuccess && !videoDetails)) {
    return (
      <CommonPages.NotFound
        onBack={() => navigate(SharedTypes.PATHS.VIDEO_ANALYTICS)}
        title={"Video does not exist"}
        subtitle={"We couldn't find the video you are looking for "}
      />
    );
  }

  return (
    <VideoAnalyticsComponents.FileRoleController
      hasExternalLoading={hasVideoDetailsLoading}
      fileId={fVideoId}
    >
      <SharedComponents.Screen>
        {hasAccess && (
          <SharedComponents.Modal
            isOpen={isDeleteModalOpened}
            cancelText={"No"}
            onCancel={onCancelDeleteModal}
            onConfirm={onDeleteProject}
            confirmText={"Yes"}
            type={SharedTypes.ModalTypes.DEFAULT}
            title={"Do you want to delete the video ?"}
          />
        )}
        <SharedComponents.VerticalBox height={32} />
        <SharedComponents.Row>
          <SharedComponents.Breadcrumbs />
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={lg} />
        {/*VIDEO NAME AND ACTIONS BUTTON*/}
        <LocalComponents.VideoTitleActions
          projectId={projectId || ""}
          videoDetails={videoDetails}
          hasAccess={hasAccess}
          onSwitchClick={onSwitchClick}
          onShowDeleteModal={onShowDeleteModal}
        />
        <SharedComponents.VerticalBox height={sm} />
        {/*BASIC VIDEO INFO*/}
        <LocalComponents.VideoBasics videoDetails={videoDetails} />
        <SharedComponents.VerticalBox height={md} />
        {/*SUMMARY INFO*/}
        <LocalComponents.VideoSummary videoDetails={videoDetails} />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.Separator />
        <SharedComponents.VerticalBox height={lg} />
        {/*VIDEO ANALYSIS*/}
        <LocalComponents.VideoAnalysis
          videoDetails={videoDetails}
          isRaw={isRaw}
          hasAccess={hasAccess}
          startTime={startTime}
        />
        <SharedComponents.VerticalBox height={lg} />
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.FileRoleController>
  );
});
