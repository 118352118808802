import * as React from "react";

export function IconCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 0H4a2 2 0 00-2 2 2 2 0 00-2 2v10a2 2 0 002 2h7a2 2 0 002-2 2 2 0 002-2V2a2 2 0 00-2-2zm0 13V4a2 2 0 00-2-2H3a1 1 0 011-1h7a1 1 0 011 1v10a1 1 0 01-1 1zM1 4a1 1 0 011-1h7a1 1 0 011 1v10a1 1 0 01-1 1H2a1 1 0 01-1-1V4z"
        fill="#808B9F"
      />
    </svg>
  );
}
