import { SVGProps } from "react";

export function Icon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 864 297"
            {...props}
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        d="M231.25,159.15a49.91,49.91,0,1,0-21,71.44l1.54,2.51-1.47,6,22.35,36.66,13.88-8.41-22.36-36.66-5.94-1.47-1.41-2.39A50.19,50.19,0,0,0,231.25,159.15Zm-78.52,48.58a42.43,42.43,0,1,1,58.33,13.89A42.66,42.66,0,0,1,152.73,207.73Z"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g id="logo_color_wihout_pg" data-name="logo color wihout pg">
                <g id="title">
                    <path
                        d="M401.22,94.73l-18,42h-11.7l-17.94-42h12.84l11.4,27.36,11.64-27.36Z"
                        fill="#003da5"
                    />
                    <path
                        d="M404.7,98.87a5.82,5.82,0,0,1,0-8.64,7.18,7.18,0,0,1,5-1.74,7.5,7.5,0,0,1,5,1.65,5.36,5.36,0,0,1,1.92,4.23,5.85,5.85,0,0,1-1.92,4.47,8.11,8.11,0,0,1-10.08,0Zm-.66,5.34h11.39v32.52H404Z"
                        fill="#003da5"
                    />
                    <path
                        d="M457.37,92.21v44.52H446.51v-3.24q-3.18,3.78-9.54,3.78a16.17,16.17,0,0,1-8.1-2.07,15,15,0,0,1-5.79-5.91,19.51,19.51,0,0,1,0-17.73,14.81,14.81,0,0,1,5.79-5.85,16.35,16.35,0,0,1,8.1-2q5.82,0,9,3.36V92.21Zm-13.14,33.93a8.1,8.1,0,0,0,1.92-5.73,8,8,0,0,0-1.89-5.64,6.36,6.36,0,0,0-4.89-2,6.46,6.46,0,0,0-4.92,2,7.9,7.9,0,0,0-1.92,5.64,8.1,8.1,0,0,0,1.92,5.73,6.41,6.41,0,0,0,4.92,2.07A6.34,6.34,0,0,0,444.23,126.14Z"
                        fill="#003da5"
                    />
                    <path
                        d="M497.75,123.35H474.12a6.58,6.58,0,0,0,2.79,3.87,9.28,9.28,0,0,0,5.18,1.35,11.29,11.29,0,0,0,4.06-.66,11.91,11.91,0,0,0,3.44-2.16l6,6.24q-4.74,5.28-13.85,5.28a22.14,22.14,0,0,1-10-2.16,15.92,15.92,0,0,1-9-14.7,16.27,16.27,0,0,1,2.3-8.61,16.1,16.1,0,0,1,6.4-6,20.47,20.47,0,0,1,18-.15,15.27,15.27,0,0,1,6.26,5.82,17.09,17.09,0,0,1,2.32,9C497.94,120.61,497.88,121.55,497.75,123.35Zm-21.56-10.08a6.67,6.67,0,0,0-2.25,4.08h13.31a6.77,6.77,0,0,0-2.25-4.08,7.22,7.22,0,0,0-8.81,0Z"
                        fill="#003da5"
                    />
                    <path
                        d="M510.11,135.11a16.07,16.07,0,0,1-8.94-14.7,16,16,0,0,1,8.91-14.61,22.09,22.09,0,0,1,18.93,0,15.89,15.89,0,0,1,6.54,5.94,17.29,17.29,0,0,1,0,17.37,16,16,0,0,1-6.54,6,21.75,21.75,0,0,1-18.9,0Zm14.35-9a8.15,8.15,0,0,0,1.92-5.73,8,8,0,0,0-1.92-5.64,7,7,0,0,0-9.85,0,7.9,7.9,0,0,0-1.92,5.64,8.1,8.1,0,0,0,1.92,5.73,6.89,6.89,0,0,0,9.85,0Z"
                        fill="#003da5"
                    />
                    <path
                        d="M589.31,128.57H571.55l-3.3,8.16H556.13l18.54-42h11.7l18.6,42H592.61Zm-3.48-8.76-5.4-13.44L575,119.81Z"
                        fill="#003da5"
                    />
                    <path
                        d="M638.72,107.27q3.69,3.6,3.69,10.86v18.6H631V120q0-6.66-5.34-6.66a6.1,6.1,0,0,0-4.71,1.92q-1.77,1.92-1.77,5.76v15.72h-11.4V104.21h10.86v3.54a12.92,12.92,0,0,1,4.56-3,15.45,15.45,0,0,1,5.76-1A13.36,13.36,0,0,1,638.72,107.27Z"
                        fill="#003da5"
                    />
                    <path
                        d="M675.77,107.36Q680,111,680,118.67v18.06H669.41v-4.2q-2.46,4.74-9.6,4.74a15,15,0,0,1-6.54-1.32,9.69,9.69,0,0,1-4.17-3.57,9.46,9.46,0,0,1-1.41-5.13,8.32,8.32,0,0,1,3.54-7.17q3.54-2.55,10.92-2.55h6.48q-.3-5.16-6.9-5.16a15.83,15.83,0,0,0-4.74.75,12.63,12.63,0,0,0-4.08,2.07l-3.84-7.74a21.27,21.27,0,0,1,6.57-2.79,31.24,31.24,0,0,1,7.77-1Q671.51,103.67,675.77,107.36Zm-9.3,21.75a5.34,5.34,0,0,0,2.16-2.82v-2.82h-4.92q-5,0-5,3.3a2.9,2.9,0,0,0,1.14,2.4,4.91,4.91,0,0,0,3.12.9A6.31,6.31,0,0,0,666.47,129.11Z"
                        fill="#003da5"
                    />
                    <path d="M687.23,92.21h11.4v44.52h-11.4Z" fill="#003da5" />
                    <path
                        d="M740.27,104.21l-14,33.84q-2.46,6-6,8.43a14.9,14.9,0,0,1-8.64,2.43,17.43,17.43,0,0,1-5.19-.81,12.5,12.5,0,0,1-4.23-2.13l4-8a6.89,6.89,0,0,0,2.31,1.41,7.67,7.67,0,0,0,2.73.51,4.94,4.94,0,0,0,2.67-.66,5.07,5.07,0,0,0,1.77-2.1l-13.92-32.94h11.7l8,19.86,8-19.86Z"
                        fill="#003da5"
                    />
                    <path
                        d="M766.55,135.41a11.49,11.49,0,0,1-3.51,1.38,19.48,19.48,0,0,1-4.41.48q-6.36,0-9.78-3.12t-3.42-9.3V113.93h-4.74v-8.52h4.74V97h11.4v8.46h7.44v8.52h-7.44v10.8a3.83,3.83,0,0,0,.93,2.73,3.25,3.25,0,0,0,2.49,1,6,6,0,0,0,3.48-1Z"
                        fill="#003da5"
                    />
                    <path
                        d="M771.59,98.87a5.82,5.82,0,0,1,0-8.64,7.22,7.22,0,0,1,5-1.74,7.46,7.46,0,0,1,5,1.65,5.33,5.33,0,0,1,1.92,4.23,5.82,5.82,0,0,1-1.92,4.47,7.14,7.14,0,0,1-5,1.77A7.22,7.22,0,0,1,771.59,98.87Zm-.66,5.34h11.4v32.52h-11.4Z"
                        fill="#003da5"
                    />
                    <path
                        d="M796.67,135.11a16.31,16.31,0,0,1,0-29.31,21.31,21.31,0,0,1,9.66-2.13,18.45,18.45,0,0,1,9.54,2.37,13.12,13.12,0,0,1,5.7,6.57l-8.82,4.5q-2.22-4.38-6.48-4.38a6.82,6.82,0,0,0-5.07,2,7.69,7.69,0,0,0-2,5.64,7.88,7.88,0,0,0,2,5.73,6.77,6.77,0,0,0,5.07,2.07q4.26,0,6.48-4.38l8.82,4.5a13.12,13.12,0,0,1-5.7,6.57,18.45,18.45,0,0,1-9.54,2.37A21.06,21.06,0,0,1,796.67,135.11Z"
                        fill="#003da5"
                    />
                    <path
                        d="M829.67,136.34a21.24,21.24,0,0,1-6.36-2.43l3.42-7.8A19.32,19.32,0,0,0,832,128.3a23.61,23.61,0,0,0,6.06.81,9.71,9.71,0,0,0,3.93-.57,1.79,1.79,0,0,0,1.17-1.65q0-1.08-1.41-1.53a29.18,29.18,0,0,0-4.47-.87,44.15,44.15,0,0,1-6.63-1.26,10.23,10.23,0,0,1-4.71-2.88,8.24,8.24,0,0,1-2-5.88,8.89,8.89,0,0,1,1.86-5.55,12.33,12.33,0,0,1,5.43-3.84,23.27,23.27,0,0,1,8.55-1.41,34.56,34.56,0,0,1,7,.72,20.18,20.18,0,0,1,5.82,2l-3.42,7.74a19,19,0,0,0-9.36-2.4,9.33,9.33,0,0,0-4,.63,1.88,1.88,0,0,0-1.26,1.65q0,1.14,1.38,1.59a31.64,31.64,0,0,0,4.56.93,50.28,50.28,0,0,1,6.66,1.38,10.18,10.18,0,0,1,4.59,2.85,8.11,8.11,0,0,1,1.95,5.79,8.8,8.8,0,0,1-1.86,5.49,12.12,12.12,0,0,1-5.49,3.81,24.61,24.61,0,0,1-8.73,1.38A35,35,0,0,1,829.67,136.34Z"
                        fill="#003da5"
                    />
                    <path
                        d="M389.94,170.63q4.68,3.9,4.68,10.74t-4.68,10.74q-4.68,3.9-12.84,3.9H366.72v12.72h-6v-42H377.1Q385.26,166.73,389.94,170.63Zm-4.32,17.73a9.64,9.64,0,0,0,0-14q-3-2.43-8.7-2.43h-10.2v18.84h10.2Q382.62,190.79,385.62,188.36Z"
                        fill="#003da5"
                    />
                    <path d="M403.2,164.21H409v44.52H403.2Z" fill="#003da5" />
                    <path
                        d="M441.62,179.87q3.51,3.24,3.51,9.66v19.2h-5.46v-4.2a9.1,9.1,0,0,1-4.11,3.39,15.69,15.69,0,0,1-6.33,1.17,13.25,13.25,0,0,1-8.55-2.58,8.87,8.87,0,0,1-.15-13.53q3.06-2.55,9.72-2.55h9.13v-1.14a7.35,7.35,0,0,0-2.17-5.7q-2.16-2-6.36-2a16.84,16.84,0,0,0-5.52.93,14.22,14.22,0,0,0-4.56,2.49l-2.39-4.32a17.27,17.27,0,0,1,5.87-3,24.6,24.6,0,0,1,7.26-1.05Q438.11,176.63,441.62,179.87Zm-5.73,23.31a8.48,8.48,0,0,0,3.49-4.17v-4.44h-8.89q-7.32,0-7.32,4.92a4.45,4.45,0,0,0,1.86,3.78,8.5,8.5,0,0,0,5.16,1.38A10.7,10.7,0,0,0,435.89,203.18Z"
                        fill="#003da5"
                    />
                    <path
                        d="M473.69,206.87a8.59,8.59,0,0,1-3.12,1.65,13,13,0,0,1-3.84.57q-4.8,0-7.44-2.58t-2.64-7.38V181.67h-5.4v-4.74h5.4v-7h5.76v7h9.12v4.74h-9.12v17.22a5.59,5.59,0,0,0,1.29,4,4.8,4.8,0,0,0,3.69,1.38,6.94,6.94,0,0,0,4.5-1.5Z"
                        fill="#003da5"
                    />
                    <path
                        d="M487.13,174.17v2.76h9.24v4.74h-9.12v27.06h-5.76V181.67h-5.4v-4.74h5.4v-2.82a10.13,10.13,0,0,1,2.76-7.5q2.76-2.76,7.74-2.76a13.05,13.05,0,0,1,3.6.48,8.76,8.76,0,0,1,2.88,1.38l-1.74,4.38a7.17,7.17,0,0,0-4.44-1.5Q487.13,168.59,487.13,174.17Z"
                        fill="#003da5"
                    />
                    <path
                        d="M506.45,207a15.23,15.23,0,0,1-5.91-5.79,17.51,17.51,0,0,1,0-16.74,15,15,0,0,1,5.91-5.76,18.51,18.51,0,0,1,17,0,15.07,15.07,0,0,1,5.88,5.76,17.51,17.51,0,0,1,0,16.74,15.29,15.29,0,0,1-5.88,5.79,18.27,18.27,0,0,1-17,0Zm14-4.35a10,10,0,0,0,3.81-4,13.09,13.09,0,0,0,0-11.7,9.75,9.75,0,0,0-3.81-3.93,11.61,11.61,0,0,0-11,0,10,10,0,0,0-3.84,3.93,12.84,12.84,0,0,0,0,11.7,10.16,10.16,0,0,0,3.84,4,11.39,11.39,0,0,0,11,0Z"
                        fill="#003da5"
                    />
                    <path
                        d="M549.41,178.07a16.11,16.11,0,0,1,7.14-1.44v5.58a11.25,11.25,0,0,0-1.32-.06,9.69,9.69,0,0,0-7.35,2.79q-2.67,2.79-2.67,7.95v15.84h-5.76v-31.8H545v5.34A9.83,9.83,0,0,1,549.41,178.07Z"
                        fill="#003da5"
                    />
                    <path
                        d="M612.92,180.11q3.51,3.48,3.51,10.32v18.3h-5.76V191.09q0-4.62-2.13-7a7.82,7.82,0,0,0-6.09-2.34,9.12,9.12,0,0,0-7,2.73q-2.58,2.73-2.58,7.83v16.38h-5.76V191.09q0-4.62-2.13-7a7.82,7.82,0,0,0-6.09-2.34,9.12,9.12,0,0,0-7,2.73q-2.58,2.73-2.58,7.83v16.38h-5.76v-31.8h5.52v4.74a11.16,11.16,0,0,1,4.56-3.75,15.27,15.27,0,0,1,6.42-1.29,14.08,14.08,0,0,1,6.6,1.5,10.42,10.42,0,0,1,4.44,4.38,12.18,12.18,0,0,1,5-4.32,16.33,16.33,0,0,1,7.23-1.56Q609.41,176.63,612.92,180.11Z"
                        fill="#003da5"
                    />
                </g>
                <g id="logo">
                    <ellipse
                        id="bg"
                        cx="148.4"
                        cy="148.93"
                        rx="144.31"
                        ry="144.81"
                        fill="#003da5"
                    />
                    <g id="logo-2" data-name="logo">
                        <g id="computer">
                            <path
                                id="bg-2"
                                data-name="bg"
                                d="M205.76,76.86H84.55C76.22,76.86,69.4,83,69.4,90.45V167.7c0,7.47,6.82,13.59,15.15,13.59H205.76c8.34,0,15.15-6.12,15.15-13.59V90.45C220.91,83,214.1,76.86,205.76,76.86Z"
                                fill="#00a3e0"
                                fillRule="evenodd"
                            />
                            <path
                                id="play"
                                d="M153.5,118.31c-7.68-4.71-16.37-9.48-19.76-11.49-11.27-6.68-10.21-.56-10.21,13.41v17.69c0,14-1.06,20.09,10.21,13.4,3.12-1.84,10.7-6,17.87-10.34C170.72,129.48,172.33,129.85,153.5,118.31Z"
                                fill="#003da5"
                                fillRule="evenodd"
                            />
                            <path
                                id="border"
                                d="M62.91,90.2a21,21,0,0,1,21-21.06H206A21,21,0,0,1,227,90.2v76.59a21,21,0,0,1-21,21.06H161.85l5.72,11.48h15.55a5.75,5.75,0,0,1,0,11.49H106.8a5.75,5.75,0,0,1,0-11.49h15.54l5.72-11.48H83.9a21,21,0,0,1-21-21.06Zm145,87.63H82A9.85,9.85,0,0,1,72.2,168V89A9.85,9.85,0,0,1,82,79.16H207.89A9.85,9.85,0,0,1,217.72,89V168A9.85,9.85,0,0,1,207.89,177.83Z"
                                fill="#fff"
                                fillRule="evenodd"
                            />
                        </g>
                        <g id="circle">
                            <path
                                d="M148.4,279.43c-71.95,0-130.5-58.55-130.5-130.5S76.45,18.43,148.4,18.43a130.65,130.65,0,0,1,130.5,130.5C278.9,220.88,220.36,279.43,148.4,279.43Zm0-255a124.5,124.5,0,1,0,124.5,124.5A124.64,124.64,0,0,0,148.4,24.43Z"
                                fill="#e87722"
                            />
                            <path
                                d="M235.08,246.48l-4-4.48a124.65,124.65,0,0,0,41.81-93.07h6A130.65,130.65,0,0,1,235.08,246.48Z"
                                fill="#e87722"
                            />
                            <path
                                d="M278.9,148.93h-6c0-1.73,0-3.47-.1-5.19a124.51,124.51,0,0,0-223-70.82L45,69.25A130.5,130.5,0,0,1,278.79,143.5C278.87,145.29,278.9,147.12,278.9,148.93Z"
                                fill="#e87722"
                            />
                        </g>
                        <g id="search">
                            <ellipse
                                id="bg-3"
                                data-name="bg"
                                cx="188.68"
                                cy="185.33"
                                rx="42.81"
                                ry="42.96"
                                fill="#003da5"
                            />
                            <g id="barchart">
                                <path
                                    d="M223,201.12V169.61H204.45v49.45A37.28,37.28,0,0,0,209,216.6,38.4,38.4,0,0,0,223,201.12Z"
                                    fill="#e87722"
                                />
                                <path
                                    d="M173.53,219.11V192.9H155v8.33c.45.89.93,1.77,1.45,2.63A38.35,38.35,0,0,0,173.53,219.11Z"
                                    fill="#fedb00"
                                />
                                <path
                                    d="M198.26,221.24v-40H179.71v40A38.44,38.44,0,0,0,189,222.4,37.59,37.59,0,0,0,198.26,221.24Z"
                                    fill="#f1b434"
                                />
                            </g>
                            <g id="search-2" data-name="search">
                                <g clipPath="url(#clip-path)">
                                    <ellipse
                                        id="bg-4"
                                        data-name="bg"
                                        cx="148"
                                        cy="149.29"
                                        rx="145"
                                        ry="145.5"
                                        fill="#fff"
                                    />
                                    <g id="barchart-2" data-name="barchart">
                                        <path
                                            d="M223.47,203.15v-31.5H204.58V221.1a37.56,37.56,0,0,0,4.65-2.46A38.58,38.58,0,0,0,223.47,203.15Z"
                                            fill="#ec1c24"
                                        />
                                        <path
                                            d="M173.1,221.14v-26.2H154.22v8.33c.45.89.95,1.77,1.47,2.63A38.74,38.74,0,0,0,173.1,221.14Z"
                                            fill="#ec1c24"
                                        />
                                        <path
                                            d="M198.28,223.28v-40H179.4v40a39.69,39.69,0,0,0,9.48,1.15A39,39,0,0,0,198.28,223.28Z"
                                            fill="#ec1c24"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
