import { SVGProps } from "react";

export const IconTutorial = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 4.5a.75.75 0 0 0 .75.75h16.5a.75.75 0 1 0 0-1.5H3.75A.75.75 0 0 0 3 4.5Zm3-3a.75.75 0 0 0 .75.75h10.5a.75.75 0 1 0 0-1.5H6.75A.75.75 0 0 0 6 1.5Zm4.148 8.364A.75.75 0 0 0 9 10.5V18a.75.75 0 0 0 1.148.636l6-3.75a.75.75 0 0 0 0-1.272l-6-3.75Z"
      fill="#fff"
    />
    <path
      d="M2.25 21.75A2.25 2.25 0 0 1 0 19.5V9a2.25 2.25 0 0 1 2.25-2.25h19.5A2.25 2.25 0 0 1 24 9v10.5a2.25 2.25 0 0 1-2.25 2.25H2.25Zm19.5-1.5a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H2.25A.75.75 0 0 0 1.5 9v10.5a.75.75 0 0 0 .75.75h19.5Z"
      fill="#fff"
    />
  </svg>
);
