import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Draggable, { DraggableData } from "react-draggable";
import Tooltip from "@material-ui/core/Tooltip";

import { Utils, SharedComponents, Theme } from "@shared";

import { usePlayerContext } from "../../../context";
import * as Lib from "../lib";

const DRAGGABLE_CONFIG: {
  grid: [number, number];
  scale: number;
  axis: "x";
} = {
  grid: [1, 1],
  scale: 1,
  axis: "x",
};

interface ITimelineGrip {
  dragAreaRef: React.RefObject<HTMLDivElement>;
  setTooltipVisibility: Dispatch<SetStateAction<boolean>>;
  isTooltipVisible: boolean;
}

export const TimelineGrip = ({
  dragAreaRef,
  isTooltipVisible,
  setTooltipVisibility,
}: ITimelineGrip) => {
  const {
    palette: { primaryWhite },
  } = Theme.useStyledTheme();
  const {
    duration,
    seekTo,
    progress,
    timelineWidth,
    gripWidth,
    setTimelineWidth,
    playing,
  } = usePlayerContext();

  const [clientX, setClientX] = useState(0);

  const clientXFromPlayer = Utils.range(
    0,
    duration,
    0,
    timelineWidth,
    progress
  );

  const onDrag = useCallback(
    (event: any, position: DraggableData) => {
      if (isTooltipVisible) {
        setTooltipVisibility(false);
      }
      const currentPlayerProgress = Utils.range(
        0,
        timelineWidth,
        0,
        duration,
        position.x
      );

      const pos = dragAreaRef.current?.getBoundingClientRect();
      const updatedBound = pos!.right - pos!.left;
      if (timelineWidth !== updatedBound) {
        setTimelineWidth(updatedBound);
      }
      if (
        position.x >= 0 &&
        position.x <= timelineWidth &&
        position.x !== clientX
      ) {
        setClientX(position.x);
        seekTo(currentPlayerProgress);
      }
    },
    [
      setClientX,
      seekTo,
      isTooltipVisible,
      setTooltipVisibility,
      setTimelineWidth,
      timelineWidth,
      duration,
    ]
  );

  const bounds = useMemo(
    () => ({
      left: 0,
      right: timelineWidth,
    }),
    [timelineWidth]
  );

  const position = useMemo(
    () => ({ x: clientX === 0 ? clientXFromPlayer : clientX, y: 0 }),
    [clientX, clientXFromPlayer]
  );

  const left = useMemo(() => {
    if (clientX === 0) {
      return clientXFromPlayer > timelineWidth / 2
        ? clientXFromPlayer - 58
        : clientXFromPlayer;
    } else {
      return clientX > timelineWidth / 2 ? clientX - 58 : clientX;
    }
  }, [clientX, clientXFromPlayer, timelineWidth]);

  useEffect(() => {
    if (playing) {
      setClientX(Utils.range(0, duration, 0, timelineWidth, progress));
    }
  }, [playing, progress]);

  return (
    <SharedComponents.Box>
      <Lib.TimelineMarker positionX={left} progress={progress} />
      <Tooltip
        open={isTooltipVisible}
        arrow
        title={
          <SharedComponents.Column width={"200px"}>
            <SharedComponents.Text
              color={primaryWhite}
              text={`Drag this line to select start point for the new clip and press "Add Clip" button`}
              type={"xSmall"}
            ></SharedComponents.Text>
          </SharedComponents.Column>
        }
      >
        <Draggable
          {...DRAGGABLE_CONFIG}
          handle=".timelineGrip"
          position={position}
          bounds={bounds}
          onDrag={onDrag}
        >
          <Lib.Grip className="timelineGrip" width={gripWidth} />
        </Draggable>
      </Tooltip>
    </SharedComponents.Box>
  );
};
