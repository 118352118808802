import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 150px;
  padding: ${({ theme }) => `0 ${theme.spacing.lg}px`};
  height: 72px;
  position: relative;
`;
