import { FC, memo, useCallback, useEffect } from "react";
import { Field, useField } from "formik";

import { Utils } from "@shared";

import { useStyledTheme } from "../../../../theme";
import { Column, Row } from "../../../containers";
import { VerticalBox } from "../../../vertical_box";
import { HorizontalBox } from "../../../horizontal_box";
import { Text } from "../../../text";
import { IFormikCheckbox } from "./types";

export const Checkbox: FC<IFormikCheckbox> = memo(
  ({ title = "", field, subtitle = "", touched = false }) => {
    const {
      spacing: { sm },
      palette: { primaryRed },
    } = useStyledTheme();

    const [input, meta, helpers] = useField(field);

    const onClick = useCallback(
      () => helpers.setValue(!input.value),
      [helpers, input]
    );

    useEffect(() => {
      if (touched) {
        helpers.setTouched(true);
      }
    }, [touched]);

    return (
      <Column flex={0}>
        <Text type={"h3SemiBold"} text={title} />
        <VerticalBox height={sm} />
        <Row alignItems={"center"}>
          <Field
            name={field}
            onClick={onClick}
            onKeyDown={Utils.onKeyDown(onClick)}
            type={"checkbox"}
            checked={input.value}
          />
          <HorizontalBox width={sm} />
          <Text type={"button"} text={subtitle} />
        </Row>
        {meta.error && meta.touched && (
          <Text type={"small"} color={primaryRed} text={meta.error} />
        )}
      </Column>
    );
  }
);
