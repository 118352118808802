import { ULWrapper } from "../../../pages/add_project/components/ul_wrapper";

export const PiiNote = () => {
  return (
    <ULWrapper>
      *Project Owners are responsible for assessing videos for PII and
      identifying if PII is present. Project Owners are responsible for:
      <ul>
        <li>
          <b>Following all P&G Information Security policies </b>
          <a
            rel="noreferrer"
            target={"_blank"}
            href={"https://pgone.sharepoint.com/sites/wise/Pages/Home.aspx"}
          >
            (ITPolicy.PG.com)
          </a>
          , controls and guidelines associated with handling Highly Restricted
          information.
        </li>
        <li>
          <b>
            If your video is of consumers, or includes consumer data, you must
            review and follow all relevant consumer data handling policies
          </b>{" "}
          <a
            rel="noreferrer"
            target={"_blank"}
            href={
              "https://pgone.sharepoint.com/sites/NewPrivacyCentral/SitePages/Consumer.aspx"
            }
          >
            (Privacy Central / consumer)
          </a>{" "}
          including ensuring the videos can only be shared with those with a
          need to view and deleting the video from all sources according to
          required{" "}
          <a
            rel="noreferrer"
            target={"_blank"}
            href={"https://pg.myretentionschedule.com/#HOME"}
          >
            retention policies
          </a>
          . Adherence to these policies is solely your responsibility as the
          video owner.
        </li>
      </ul>
    </ULWrapper>
  );
};
