const awsconfig = {
  aws_project_region: process.env.REACT_APP_COGNITO_REGION,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_COGNITO_REGION,
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEBCLIENT,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  oauth: {
    domain: process.env.REACT_APP_APPSYNC_DOMAIN,
    scope: ["email", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : process.env.REACT_APP_REDIRECT,
    responseType: "code",
  },
};
export default awsconfig;
