import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import * as _ from "lodash";

import { SharedComponents, Utils } from "@shared";
import { VideoAnalyticsStore, Types } from "@videoAnalytics";

import * as Components from "./components";

interface ISearchModal {
  isOpened: boolean;
  onClose: () => void;
}

export const SearchModal = observer(({ isOpened, onClose }: ISearchModal) => {
  const {
    fullSearch,
    data: {
      elasticVideos,
      elasticProjects,
      loadings,
      setRequestReset,
      clearFullSearchData,
    },
  } = VideoAnalyticsStore;

  const hasLoading = Utils.useQueryStatus(
    loadings,
    Types.Endpoints.FULL_SEARCH
  );

  const [value, setValue] = useState<string>("");

  const isEmpty = useMemo(
    () =>
      value === "" ||
      (elasticProjects.length === 0 && elasticVideos.length === 0),
    [value, elasticProjects, elasticVideos]
  );
  const fullSearchThrottled = useRef(
    _.debounce(function (input: Types.IFullSearchInput) {
      fullSearch(input);
    }, 500)
  );

  useEffect(() => {
    if (isOpened && value.length > 0) {
      fullSearchThrottled.current({ text: value });
    }
  }, [isOpened, value]);

  useEffect(() => {
    return () => {
      clearFullSearchData();
      setRequestReset(Types.Endpoints.FULL_SEARCH);
      setValue("");
    };
  }, []);

  return (
    <Components.Wrapper
      onClose={onClose}
      fullScreen={true}
      fullWidth={true}
      open={isOpened}
    >
      <Components.Search
        value={value}
        onChange={async (v) => setValue(v)}
        onClear={() => setValue("")}
      />
      {hasLoading && <Components.LoadingIndicator />}
      {!hasLoading && isEmpty && <Components.EmptyIndicator />}
      {!hasLoading && !isEmpty && (
        <OutputWrapper>
          {elasticProjects.length > 0 && (
            <Components.Projects data={elasticProjects} />
          )}
          {elasticVideos.length > 0 && (
            <Components.Videos data={elasticVideos} />
          )}
          <SharedComponents.VerticalBox height={36} />
        </OutputWrapper>
      )}
    </Components.Wrapper>
  );
});

const OutputWrapper = styled.div`
  display: block;
  overflow-y: scroll;
`;
