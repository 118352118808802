import { observer } from "mobx-react";
import { computed } from "mobx";

import { VideoAnalyticsStore, Types, Utils as VapUtils } from "@videoAnalytics";
import { Theme, Utils } from "@shared";

import { usePlayerContext } from "../../../context";

import { VideoLineChart } from "../video_line_chart";

export type ILineChart = {
  width: number;
  height: number;
  label: string;
  title: string;
};

export const SpeakerAnalyticsChart = observer(
  ({ width, height, label, title }: ILineChart) => {
    const {
      palette: { secondaryDarkBlue },
    } = Theme.useStyledTheme();
    const {
      data: { videoDetails },
    } = VideoAnalyticsStore;

    const { playerRef } = usePlayerContext();

    const chartData = computed(() => {
      const allSentences: Array<Types.ITranscriptSentence> = [];
      videoDetails?.transcripts.emotions.tracked.parts.forEach((part) => {
        allSentences.push(...part.sentences);
      });

      const output: Record<string, Array<{ x: string; y?: number }>> = {};

      const timePoints: Record<string, string> = {};

      allSentences.forEach((sentence) => {
        if (output[sentence.speakerId] === undefined) {
          output[sentence.speakerId] = [];
        }
        if (timePoints[sentence.startTime] === undefined) {
          timePoints[sentence.startTime] = sentence.startTime;
        }

        output[sentence.speakerId].push({
          x: Utils.formatTimeShort(parseInt(sentence.startTime)),
          y:
            (sentence?.sentiment?.positive?.percent -
              sentence?.sentiment?.negative?.percent) *
            100,
        });
      });

      return output;
    }).get();

    const getColorByKey = (key: string) => {
      switch (key) {
        case "spk_0":
          return "#EF33E7";
        case "spk_1":
          return "#F1B434";
        case "spk_2":
          return "#C933EF";
        case "spk_3":
          return "#2B28CC";
        case "spk_4":
          return "#F1B434";
        case "spk_5":
          return "#00B140";
        case "spk_6":
          return "#EF3340";
        default:
          return secondaryDarkBlue;
      }
    };

    return (
      <VideoLineChart
        width={width}
        height={height}
        label={label}
        title={title}
        chartData={chartData}
        playerRef={playerRef}
        getColorByKey={getColorByKey}
        colorRange={[
          "#EF33E7",
          "#F1B434",
          "#C933EF",
          "#2B28CC",
          "#F1B434",
          "#00B140",
          "#EF3340",
        ]}
        tickFormat={(value) => {
          if (value == 0) {
            return "Neutral";
          }
          if (value == 50) {
            return "Positive";
          }
          if (value == -50) {
            return "Positive";
          }
          if (value == 100) {
            return "Strongly positive";
          }
          return "";
        }}
        labelFormat={(label) => VapUtils.formatSpeaker(label)}
      />
    );
  }
);
