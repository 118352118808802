import { useCallback, FC } from "react";
import { useField } from "formik";

import { SharedComponents } from "@shared";

import { IInputGroupTag } from "../types";

import { TagIconWrapper } from "./ig.tag.iconWrapper";
import { TagWrapper } from "./ig.tag.wrapper";

export const Tag: FC<IInputGroupTag> = ({ item, groupField }) => {
  const [input, , helpers] = useField(groupField);

  const onClick = useCallback(
    () =>
      helpers.setValue(input.value.filter((member: string) => member !== item)),
    [helpers, input.value, item]
  );

  return (
    <TagWrapper>
      <SharedComponents.Text type={"xSmallMedium"} text={item} />
      <TagIconWrapper onClick={onClick}>
        <SharedComponents.Icons.IconClose />
      </TagIconWrapper>
    </TagWrapper>
  );
};
