import { useEffect, useRef, useState } from "react";

import { SharedComponents, Utils } from "@shared";

import { useCreateClipsContext } from "../../../../context";
import { usePlayerContext } from "../../context";

import { generateControlPointsPosition } from "./utils";
import * as Lib from "./lib";

interface IControlPoint {
  left: number;
  time: number;
}

const renderTimelineLine = (item: IControlPoint, index: number) => {
  return (
    <Lib.Line
      first={index === 0}
      key={item.time}
      left={item.left}
      videoDuration={Utils.formatSeconds(item.time)}
    />
  );
};

export const Timeline = () => {
  const { timelineHeight } = useCreateClipsContext();
  const { hasEditMode, setTimelineWidth, duration, index } = usePlayerContext();
  const dragAreaRef = useRef<HTMLDivElement>(null);

  const [controlPoints, setControlPoints] = useState<Array<IControlPoint>>([]);

  const [isTooltipVisible, setTooltipVisibility] = useState(true);

  // GET TIMELINE WIDTH
  useEffect(() => {
    const pos = dragAreaRef.current?.getBoundingClientRect();
    const width = pos!.right - pos!.left;
    const points = generateControlPointsPosition(width, duration);
    setTimelineWidth(width);
    setControlPoints(points);
  }, [duration, setTimelineWidth]);

  // useEffect(() => {
  //   const onResize = () => {
  //     const pos = dragAreaRef.current?.getBoundingClientRect();
  //     const timelineWidth = pos!.right - pos!.left;
  //     setTimelineWidth(timelineWidth);
  //     setControlPoints(
  //       generateControlPointsPosition(timelineWidth, duration, 60)
  //     );
  //   };
  //   window.addEventListener("resize", onResize);
  //
  //   return () => window.removeEventListener("resize", onResize);
  // }, [setControlPoints, setTimelineWidth, duration]);

  return (
    <SharedComponents.Column
      height={"100%"}
      width={"100%"}
      justifyContent={"center"}
    >
      <Lib.Timeline ref={dragAreaRef} height={timelineHeight}>
        {hasEditMode && <Lib.Editor />}
        {!hasEditMode && (
          <Lib.TimelineGrip
            isTooltipVisible={index === 0 && isTooltipVisible}
            setTooltipVisibility={setTooltipVisibility}
            dragAreaRef={dragAreaRef}
          />
        )}
        <Lib.BufferBar />
        {controlPoints.map(renderTimelineLine)}
      </Lib.Timeline>
    </SharedComponents.Column>
  );
};
