import styled from "styled-components";

export const TagIconWrapper = styled.div`
  height: ${({ theme }) => `${theme.spacing.md}px`};
  width: ${({ theme }) => `${theme.spacing.md}px`};
  cursor: pointer;
  margin-left: ${({ theme }) => `${theme.spacing.sm}px`};
  display: flex;
  align-items: center;
  justify-content: center;
`;
