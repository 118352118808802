import { FC, memo } from "react";
import DatePicker from "react-date-picker";
import { FastField, FastFieldProps } from "formik";

import { Theme, SharedComponents } from "@shared";

import "./css/calendar.css";

import { IFormikCalendar } from "./types";

const renderCalendar =
  (name: string, disabled?: boolean) =>
  ({ field, form }: FastFieldProps) =>
    (
      <DatePicker
        format={"MM-dd-y"}
        clearIcon={null}
        value={field.value}
        onChange={(date: Date) => {
          if (date.getFullYear() > 2060) {
            form.setFieldValue(name, field.value);
            return;
          }
          form.setFieldValue(name, date);
        }}
        calendarIcon={<SharedComponents.Icons.IconCalendar />}
        disabled={disabled}
      />
    );
export const Calendar: FC<IFormikCalendar> = memo(
  ({ title, field, disabled }) => {
    const {
      spacing: { sm },
    } = Theme.useStyledTheme();
    return (
      <SharedComponents.FlexContainer
        direction="column"
        alignItems="flex-start"
        padding={0}
        style={{ zIndex: 1000 }}
      >
        <SharedComponents.Text text={title} type="h3SemiBold" />
        <SharedComponents.VerticalBox height={sm} />
        <FastField name={field}>{renderCalendar(field, disabled)}</FastField>
      </SharedComponents.FlexContainer>
    );
  }
);
