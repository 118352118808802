import styled from "styled-components";
import { Dialog } from "@material-ui/core";

export const Wrapper = styled(Dialog)`
  .MuiPaper-root {
    min-width: 368px;
    min-height: 169px;
    padding: ${({ theme }) => theme.spacing.lg}px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
