import { FC, useCallback, useEffect, KeyboardEvent, useState } from "react";
import { useField } from "formik";
import { computed } from "mobx";

import { SharedComponents, Theme } from "@shared";

import { Search } from "../search";

import { IFormik } from "./types";

export const Autocomplete: FC<IFormik> = ({
  inputField,
  optionsField,
  title,
  subtitle,
  onChange,
  options,
}) => {
  const {
    spacing: { sm, md },
    palette: { primaryRed },
  } = Theme.useStyledTheme();

  const [inputProps, inputMeta, inputHelpers] = useField(inputField);
  const [membersInputProps, membersInputMeta, membersHelpers] =
    useField(optionsField);
  const [isOptionsVisible, setOptionsVisibility] = useState(false);
  /**
   * only values weren't selected yet
   * */
  const formattedData = computed(() =>
    options.filter((d) => !membersInputProps.value.includes(d))
  ).get();

  const clearInput = useCallback(() => {
    inputHelpers.setValue("");
    inputHelpers.setTouched(false);
  }, [inputHelpers]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === "Enter") {
        if (!inputMeta.error) {
          if (
            !membersInputProps.value.includes(inputProps.value) &&
            options.includes(inputProps.value)
          ) {
            membersHelpers.setValue([
              ...membersInputProps.value,
              inputProps.value,
            ]);
            clearInput();
          } else if (!options.includes(inputProps.value)) {
            membersHelpers.setTouched(true, false);
            membersHelpers.setError(
              "Value you try to provide is not in our directory."
            );
          } else {
            clearInput();
          }
        } else {
          inputHelpers.setTouched(true);
        }
      }
    },
    [
      membersHelpers,
      inputProps.value,
      inputHelpers,
      membersInputProps,
      inputMeta.error,
      options,
    ]
  );

  const onSelect = useCallback(
    (email: string) => {
      membersHelpers.setValue([...membersInputProps.value, email]);
      inputHelpers.setValue("");
    },
    [membersHelpers, membersInputProps]
  );

  // show options if value has changed
  useEffect(() => {
    inputProps.value?.length > 0
      ? setOptionsVisibility(true)
      : setOptionsVisibility(false);
  }, [inputProps.value?.length]);

  return (
    <SharedComponents.Column height={"fit-content"}>
      <SharedComponents.Column>
        {isOptionsVisible && formattedData.length > 0 && (
          <SharedComponents.TagOptions
            setOptionsVisibility={setOptionsVisibility}
            data={formattedData}
            onSelect={onSelect}
            hasError={
              inputMeta.error !== undefined &&
              inputMeta.error.length > 0 &&
              inputMeta.touched
            }
          />
        )}
        <SharedComponents.Text text={title} type="h3SemiBold" />
        <SharedComponents.Text text={subtitle} type="xSmall" />
        {membersInputMeta.error && membersInputMeta.touched && (
          <SharedComponents.Text
            text={membersInputMeta.error}
            type={"small"}
            color={primaryRed}
          />
        )}
        <SharedComponents.VerticalBox height={sm} />
        <Search
          field={inputField}
          onKeyDown={onKeyDown}
          placeholder={"Search team members e-mail"}
          onChange={onChange}
        />
        <SharedComponents.VerticalBox height={md} />
      </SharedComponents.Column>
      <SharedComponents.Row>
        {membersInputProps.value.map((item: string, index: number) => (
          <SharedComponents.TagButton
            item={item}
            key={index}
            optionsField={optionsField}
          />
        ))}
      </SharedComponents.Row>
    </SharedComponents.Column>
  );
};
