import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { SharedComponents, Utils as SharedUtils, Pages } from "@shared";
import {
  Types,
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
} from "@videoAnalytics";

import { initialValues, validationSchema } from "./formik_data";
import { ICreateClipValues, IFormInner } from "./types";
import { CREATE_CLIP_STEPS } from "./mock";
import * as LocalPages from "./pages";
import * as Utils from "./utils";

const FormInner: FC<IFormInner> = observer(({ projectId, onSubmit }) => {
  return (
    <>
      <Pages.AddProjectTemplate
        paddingRight={124}
        title={"Create summary"}
        steps={CREATE_CLIP_STEPS}
      >
        <LocalPages.SummaryDetailsPage projectId={projectId} />
        <LocalPages.ChooseVideosPage projectId={projectId} />
        <LocalPages.CreateClipsPage />
        <LocalPages.ChangeClipsOrder projectId={projectId} />
      </Pages.AddProjectTemplate>
    </>
  );
});

export const AddSummary = observer(() => {
  const { projectId } = useParams();
  const {
    createClipsVideo,
    getVideosDetails,
    data: { loadings, setRequestReset },
  } = VideoAnalyticsStore;

  const hasVideosDetailsLoading = SharedUtils.useQueryStatus(
    loadings,
    Types.Endpoints.VIDEOS_DETAILS
  );

  const hasCreating = SharedUtils.useQueryStatus(
    loadings,
    Types.Endpoints.CREATE_CLIPS_VIDEO
  );

  const onSubmit = (values: ICreateClipValues) => {
    if (hasCreating) {
      return;
    }
    const input: Types.ICreateClipsInput = {
      name: values.name,
      summary: values.summary,
      slug: values.name.toLowerCase().split(" ").join("_") + ".mp4",
      projectId: projectId || "",
      clips: values.clips.map((el) => ({
        fileId: el.fileId,
        startTime: Utils.formatTime(el.from),
        endTime: Utils.formatTime(el.to),
        thumbnail: el.dataUri,
      })),
      thumbnail: (values.clips[0] && values.clips[0].dataUri) || "",
    };
    createClipsVideo(input);
  };

  useEffect(() => {
    const init = async () => {
      await getVideosDetails(projectId || "");
    };
    init();
    return () => {
      setRequestReset(Types.Endpoints.CREATE_CLIPS_VIDEO);
      setRequestReset(Types.Endpoints.VIDEOS_DETAILS);
    };
  }, []);

  if (hasVideosDetailsLoading) {
    return <SharedComponents.LoadingIndicator />;
  }

  return (
    <VideoAnalyticsComponents.ProjectRoleController projectId={projectId || ""}>
      <SharedComponents.Screen>
        <SharedComponents.FormikLib.Form
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <FormInner projectId={projectId || ""} onSubmit={onSubmit} />
        </SharedComponents.FormikLib.Form>
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.ProjectRoleController>
  );
});
