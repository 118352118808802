import { SVGProps } from "react";

export const IconHomeActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={23}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.749.252a.856.856 0 0 0-1.214 0L.25 10.537a.856.856 0 0 0-.25.607v11.999A.857.857 0 0 0 .857 24h7.714a.857.857 0 0 0 .857-.857v-6.857h3.428v6.857a.857.857 0 0 0 .857.857h7.714a.857.857 0 0 0 .857-.857v-12a.857.857 0 0 0-.25-.606l-2.321-2.32V2.574a.857.857 0 0 0-.857-.857h-1.714a.857.857 0 0 0-.857.857v2.216L11.749.252Z"
      fill="#fff"
    />
  </svg>
);
