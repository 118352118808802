import { FC } from "react";
import { ISearchBar } from "./types";
import { CustomSearch } from "../custom_search";
import { CustomToggle } from "../custom_toggle";
import { FlexContainer } from "../flex_container";

export const SearchBar: FC<ISearchBar> = ({
  setShowFilters,
  showFilters,
  searchValue,
  setSearchValue,
  showSwitch = true,
}) => {
  return (
    <FlexContainer>
      <CustomSearch
        placeholder={"Search Video"}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {showSwitch && (
        <CustomToggle
          isToggled={showFilters}
          toggleCallback={setShowFilters}
          label={"Advanced filters"}
        />
      )}
    </FlexContainer>
  );
};
