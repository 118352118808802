import { FC } from "react";

import { SharedComponents, Theme } from "@shared";

import * as CostItemLib from "./lib";
import { ICostItem } from "./types";

export const CostItem: FC<ICostItem> = ({ title, subtitle }) => {
  const {
    palette: { primaryBlue },
    spacing: { sm, lg },
  } = Theme.useStyledTheme();
  return (
    <CostItemLib.Wrapper>
      <SharedComponents.Text text={title} type="h3SemiBold" />
      <SharedComponents.VerticalBox height={sm} />
      <CostItemLib.Row>
        <SharedComponents.Icons.IconCost
          fill={primaryBlue}
          width={lg}
          height={lg}
        />
        <SharedComponents.HorizontalBox width={sm} />
        <SharedComponents.Text
          text={subtitle}
          type="button"
          color={primaryBlue}
        />
      </CostItemLib.Row>
    </CostItemLib.Wrapper>
  );
};
