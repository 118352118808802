import styled from "styled-components";
import Toggle from "react-toggle";

export const Toggler = styled(Toggle)`
  .react-toggle-track {
    background: ${({ theme }) => theme.palette.gray};
  }
  &.react-toggle--checked .react-toggle-thumb {
    border-color: ${({ theme }) => theme.palette.primaryBlue};
  }
  &.react-toggle--checked .react-toggle-track {
    background: ${({ theme }) => theme.palette.primaryBlue};
  }

  //  HOVERS
  &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${({ theme }) => theme.palette.gray};
  }
  &.react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: ${({ theme }) => theme.palette.primaryBlue};
  }

  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
  }
`;
