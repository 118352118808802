import { FC, useCallback, useEffect, useMemo } from "react";
import { useField } from "formik";
import { observer } from "mobx-react";
import { SharedStore } from "../../../../store";
import { IMetaItem } from "../../../../store/types";
import { useStyledTheme } from "../../../../theme";
import { Text } from "../../../text";
import { Column } from "../../../containers";
import { VerticalBox } from "../../../vertical_box";
import * as TagsListLib from "./lib";
import { ITagsList } from "./types";

export const TagsList: FC<ITagsList> = observer(
  ({
    data,
    isElastic = false,
    title,
    field,
    disabled,
    hasWarning = false,
    warningTitle = "",
  }) => {
    const {
      spacing: { sm },
      palette: { primaryRed, text },
    } = useStyledTheme();
    const {
      data: { setFiltersValue, filtersValue },
    } = SharedStore;

    const [input, meta, helpers] = useField(field);
    const [isCreationMode, ,] = useField("isCreation");

    const isAllSelected = useMemo(
      () => input.value.length === data.length && data.length > 0,
      [input, data]
    );
    const onSelectAll = useCallback(() => {
      const removeSelectedValues = filtersValue.filter(
        (value) =>
          !data.map((item) => (isElastic ? item.name : item.id)).includes(value)
      );
      const addSelectedValues = [
        ...filtersValue,
        ...data.map((el) => (isElastic ? el.name : el.id)),
      ];
      if (isAllSelected) {
        helpers.setValue([]);
        setFiltersValue(removeSelectedValues);
      } else {
        helpers.setValue(data.map((item) => (isElastic ? item.name : item.id)));
        if (!isCreationMode.value) {
          setFiltersValue(addSelectedValues);
        }
      }
    }, [
      data,
      filtersValue,
      helpers,
      isAllSelected,
      isCreationMode.value,
      setFiltersValue,
    ]);
    const renderItem = useCallback(
      (item: IMetaItem) => {
        const setSelectedData = () => {
          if (input.value.includes(isElastic ? item.name : item.id)) {
            helpers.setValue(
              input.value.filter((el: string) =>
                isElastic ? el !== item.name : el !== item.id
              )
            );
            setFiltersValue(
              filtersValue.filter((el: string) =>
                isElastic ? el !== item.name : el !== item.id
              )
            );
          } else {
            helpers.setValue([...input.value, isElastic ? item.name : item.id]);
            // if (!isCreationMode.value) {
            //   setFiltersValue([...filtersValue, item.id]);
            // }
          }
        };
        return (
          <TagsListLib.ListItem
            isElastic={isElastic}
            disabled={disabled}
            key={item.id}
            id={item.id}
            title={item.name}
            selectedData={input.value}
            setSelectedData={setSelectedData}
          />
        );
      },
      [
        disabled,
        input.value,
        helpers,
        setFiltersValue,
        filtersValue,
        isCreationMode.value,
      ]
    );

    useEffect(() => {
      return () => {
        if (isCreationMode.value === false) {
          helpers.setValue([]);
          setFiltersValue([]);
        }
      };
    }, [isCreationMode.value]);
    return (
      <Column height={"422px"}>
        <Text text={title} type="h3SemiBold" color={text} />
        <VerticalBox height={sm} />
        {hasWarning && !meta.error && (
          <Text type={"xSmall"} text={warningTitle} color={primaryRed} />
        )}
        {meta.error && meta.touched && (
          <Text type={"xSmall"} text={meta.error} color={primaryRed} />
        )}
        <TagsListLib.Inner>
          <TagsListLib.AllItem
            isAllSelected={isAllSelected}
            onSelectAll={onSelectAll}
            disabled={disabled}
          />
          <TagsListLib.Separator />
          {data.map(renderItem)}
        </TagsListLib.Inner>
      </Column>
    );
  }
);
