import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string()
    .min(2, "At least 2 characters")
    .max(60, "Not more than 60 characters")
    .required("This field is required"),
  tags: Yup.array(),
});

export const initialValues = {
  id: "",
  tags: [],
  name: "",
};
