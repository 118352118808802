import { observer } from "mobx-react";
import { useField } from "formik";
import { v4 as uuidv4 } from "uuid";

import { SharedComponents, Pages, Theme, Utils } from "@shared";

import { VideoAnalyticsStore, Types } from "@videoAnalytics";

export const UploadVideos = observer(() => {
  const {
    spacing: { md },
  } = Theme.useStyledTheme();
  const { currentStep, setCurrentStep } = SharedComponents.useStepperContext();
  const {
    createVideo,
    deleteVideo,
    client: {
      bucketClient: { loadings },
    },
  } = VideoAnalyticsStore;
  const [id, ,] = useField<string>("id");
  const [input, , helpers] =
    useField<Array<Types.ICreateVideoInputExtended>>("videos");

  const onDrop = async (accFile: File[]) => {
    const files: Array<Types.ICreateVideoInputExtended> = accFile.map(
      (file) => {
        return {
          id: uuidv4(),
          name: file.name.split("_").join(" "),
          projectId: id.value,
          slug: file.name.toLowerCase().split(" ").join("_"),
          tags: [],
          type: Types.FilesType.VIDEOS,
          fileSize: Utils.formatSize(file.size),
          body: file,
          videoLength: 0,
        };
      }
    );
    files.forEach((file) => {
      const objectUrl = URL.createObjectURL(file.body);
      const video = document.createElement("video");
      video.ondurationchange = (e) => {
        const index = files.findIndex((el) => el.id === file.id);
        files[index].videoLength = video.duration;
      };
      video.src = objectUrl;
    });
    await helpers.setValue([...input.value, ...files]);
    for (const file of files) {
      await createVideo(file);
    }
  };

  const onDelete = (id: string, name: string) => {
    deleteVideo(id, name).then(() => {
      const newFiles: Array<Types.ICreateVideoInputExtended> = input.value
        .slice()
        .filter((f) => f.id !== id);
      helpers.setValue(newFiles);
    });
  };

  return (
    <Pages.AddProjectStepTemplate
      fields={[]}
      nextTitle="Next"
      onPrev={() => setCurrentStep(currentStep - 1)}
      onNext={() => setCurrentStep(currentStep + 1)}
    >
      <SharedComponents.Box>
        <SharedComponents.UploadZone
          data={input.value}
          loadings={loadings}
          onDelete={onDelete}
          onDrop={onDrop}
          Icon={SharedComponents.Icons.IconVideoUpload}
          accept={"video/*"}
          title={"Drag & drop video"}
        />
        <SharedComponents.VerticalBox height={md} />
      </SharedComponents.Box>
    </Pages.AddProjectStepTemplate>
  );
});
