import { Field } from "formik";
import styled from "styled-components";

export const StyledField = styled(Field)<any>`
  width: 100%;
  background: transparent;
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ theme }) => `${theme.spacing.sm}px`};
  border: ${({ theme, hasFocus }) =>
    `1px solid ${hasFocus ? theme.palette.primaryBlue : theme.palette.gray}`};
  padding: ${({ theme, padding }) =>
    padding ? padding : `${theme.spacing.sm}px ${theme.spacing.md}px`};
  vertical-align: baseline;

  resize: none;
  font-size: ${({ fontWeight }) => (fontWeight ? `${fontWeight}px` : "1rem")};
  font-weight: 400;

  ::placeholder {
    color: ${({ theme }) => `1px solid ${theme.palette.gray}`};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${({ theme }) => `1px solid ${theme.palette.gray}`};
  }

  ::-ms-input-placeholder {
    color: ${({ theme }) => `1px solid ${theme.palette.gray}`};
  }
`;
