import { FC, memo, useEffect, useState } from "react";
import { PieChart as RePieChart, Pie as RePie, Cell as ReCell } from "recharts";

import { Types } from "@videoAnalytics";

import { renderActiveShape, findMaxIndex } from "./utils";
import * as PieChartLib from "./lib";
import { IPieChart } from "./types";

const renderCell = (item: Types.IParsedEmotion) => (
  <ReCell key={item.color} fill={item.color} />
);

export const PieChart: FC<IPieChart> = memo(({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const onMouseEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const highestIndex = findMaxIndex(data);
    setActiveIndex(highestIndex);
  }, [data, setActiveIndex]);

  return (
    <PieChartLib.Root>
      <RePieChart width={400} height={300}>
        <RePie
          isAnimationActive={false}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          innerRadius={60}
          outerRadius={72}
          paddingAngle={0}
          dataKey="percent"
          onMouseEnter={onMouseEnter}
        >
          {data.map(renderCell)}
        </RePie>
      </RePieChart>
    </PieChartLib.Root>
  );
});
