import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useMemo, useCallback, useState } from "react";
import { Chip, IconButton, Tooltip } from "@material-ui/core";
import {
  Theme,
  SharedComponents,
  SharedStore,
  Types as SharedTypes,
} from "@shared";
import { Types } from "@videoAnalytics";

import { VideoAnalyticsStore } from "@videoAnalytics";

import * as LocalComponents from "./lib";

interface IListItem {
  item: Types.IProject;
}

export const ListItem = observer(({ item }: IListItem) => {
  /**
   * navigation values
   * */
  const navigate = useNavigate();
  /**
   * context values
   * */
  const {
    spacing: { lg, md, sm },
  } = Theme.useStyledTheme();
  /**
   * storage values
   * */
  const {
    data: { userData, hasNoneRole, hasNonEmpRole, hasAdminRole },
  } = SharedStore;
  const { deleteProject, getProjects, getProjectsStats } = VideoAnalyticsStore;
  /**
   * local state
   * */
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
  /**
   * memoized functions
   * */
  const onOpenDeleteModal = useCallback(() => {
    setDeleteModalOpened(true);
  }, [setDeleteModalOpened]);

  const onCancelDeleteModal = useCallback(() => {
    setDeleteModalOpened(false);
  }, [setDeleteModalOpened]);

  const onEdit = useCallback(
    () =>
      navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${item.id}/edit`),
    [navigate]
  );
  /**
   * memoized values
   * */
  const lastVideos = useMemo(() => item?.videos?.slice(0, 3), [item?.videos]);
  const customTags = useMemo(
    () =>
      item?.tags
        ?.slice(0, 5)
        .map((item: any) => `#${item.name}`)
        .join(", ") + "...",
    [item]
  );

  // Draft status means cost center was not provided by project owner
  const hasDraftStatus: boolean = useMemo(
    () => item?.status === Types.ProjectStatuses.CONFIGURED,
    [item?.status]
  );

  const isOwner = useMemo(
    () =>
      hasAdminRole ||
      item.ownerEmail.toLowerCase() ===
        userData?.attributes.email.toLowerCase(),
    [userData, item, hasAdminRole]
  );

  const onDeleteProject = async () => {
    setDeleteModalOpened(false);
    await deleteProject(item.id, item.name);
    await Promise.all([getProjects(), getProjectsStats()]);
  };

  return (
    <>
      <SharedComponents.Modal
        isOpen={isDeleteModalOpened}
        onCancel={onCancelDeleteModal}
        onConfirm={onDeleteProject}
        type={SharedTypes.ModalTypes.DEFAULT}
        title={"Do you want to delete the project ?"}
        cancelText={"No"}
        confirmText={"Yes"}
      />
      <LocalComponents.Container>
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.Row
          flex={0}
          height={"fit-content"}
          alignItems={"center"}
        >
          {item?.hasPii && <SharedComponents.Label text={"PII"} />}
          {item?.hasPii && <SharedComponents.HorizontalBox width={sm} />}
          <SharedComponents.Link
            to={`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${item.id}`}
          >
            <SharedComponents.Text type="h1" text={item.name} />
          </SharedComponents.Link>
          {!hasDraftStatus && (
            <SharedComponents.Row alignItems={"center"}>
              <SharedComponents.HorizontalBox width={sm} />
              <Chip label="Draft" color={"primary"} />
            </SharedComponents.Row>
          )}
          {!hasNoneRole && !hasNonEmpRole && isOwner && (
            <SharedComponents.Row flex={1} justifyContent={"flex-end"}>
              <Tooltip title={"Edit"} arrow>
                <IconButton onClick={onEdit}>
                  <SharedComponents.Icons.IconEdit />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <IconButton onClick={onOpenDeleteModal}>
                  <SharedComponents.Icons.IconDelete />
                </IconButton>
              </Tooltip>
            </SharedComponents.Row>
          )}
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={sm} />
        <SharedComponents.Row justifyContent="space-between">
          <SharedComponents.Row>
            <LocalComponents.Summary
              title="Owner"
              value={item.ownerEmail}
              Icon={SharedComponents.Icons.IconUser}
            />
            <LocalComponents.Summary
              title="Videos"
              value={item.videosNumber.toString()}
              Icon={SharedComponents.Icons.IconVideo}
            />
            <LocalComponents.Summary
              title="Taxonomy"
              value={item?.taxonomy || ""}
              Icon={SharedComponents.Icons.IconTaxonomy}
            />
            <LocalComponents.Summary
              title="Tags"
              value={customTags || ""}
              Icon={SharedComponents.Icons.IconTags}
            />
          </SharedComponents.Row>
          <LocalComponents.TextLink
            to={`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${item.id}`}
          />
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={md} />
        {lastVideos && lastVideos?.length > 0 && (
          <LocalComponents.Grid>
            {lastVideos.length > 0 &&
              lastVideos.map((v, index: number) => (
                <SharedComponents.VideoCard
                  projectId={item.id}
                  key={index}
                  item={v}
                />
              ))}
          </LocalComponents.Grid>
        )}
      </LocalComponents.Container>
    </>
  );
});
