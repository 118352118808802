import * as React from "react";

export function IconFile({ fill, ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 37 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M23.002 0l13.713 14.094V28.97h-3.047V14.094h-6.095a4.51 4.51 0 01-3.232-1.376 4.765 4.765 0 01-1.339-3.322V3.132H6.242c-.808 0-1.583.33-2.155.917a3.177 3.177 0 00-.892 2.215v37.583c0 .83.32 1.627.892 2.215a3.006 3.006 0 002.155.917h12.19v3.132H6.241a6.012 6.012 0 01-4.31-1.835 6.353 6.353 0 01-1.785-4.429V6.264c0-1.661.643-3.255 1.786-4.43A6.012 6.012 0 016.243 0h16.76z"
        fill={fill}
      />
    </svg>
  );
}
