import { ULWrapper } from "../../../pages/add_project/components/ul_wrapper";

export const UlOptionalCosts = () => {
  return (
    <ULWrapper>
      Cost includes:
      <ul>
        <li>DIY Concierge</li>
      </ul>
    </ULWrapper>
  );
};
