import { ITranscriptEmotion } from "@src/shared/store/types";

export function findMaxIndex(arr: ITranscriptEmotion[]) {
  if (arr.length === 0) {
    return -1;
  }

  let max = arr[0].percent;
  let maxIndex = 0;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i].percent > max) {
      maxIndex = i;
      max = arr[i].percent;
    }
  }

  return maxIndex;
}
