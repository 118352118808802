import styled from "styled-components";
import { ICustomLink } from "./types";

export const Redirect = styled.a<ICustomLink>`
  text-decoration: none;
  display: flex;
  flex-direction: inherit;
  align-items: center;
  cursor: pointer;
  justify-content: ${({ justifyContent = "center" }) => justifyContent};
  padding: ${({ padding = 0 }) => padding};
`;
