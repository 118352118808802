import { Utils } from "@shared";

export const generateControlPointsPosition = (
  timelineWidth: number,
  duration: number
) => {
  const getDivider = () => {
    switch (true) {
      case duration <= 60:
        return 10;
      case duration <= 600:
        return 30;
      case duration <= 6000:
        return 60;
      case duration > 6000:
        return 600;
      default:
        return 60;
    }
  };
  const divider = getDivider();
  const arr: Array<{ left: number; time: number }> = [];
  for (let i = 0; i <= duration; i++) {
    if (i === 0) {
      arr.push({ left: 0, time: i });
    }
    if (i % divider === 0 && i !== 0) {
      const left = Utils.range(0, duration, 0, timelineWidth, i);
      arr.push({ left, time: i });
    }
  }
  return arr;
};
