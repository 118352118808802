import { FC, useMemo } from "react";

import { SharedComponents, Theme } from "@shared";
import { Types } from "@videoAnalytics";

import * as AnalysisLib from "./lib";
import { IAnalysis, IAnalysisWithData } from "./types";
import { ParseEmotions /*SumPercentage*/ } from "./utils";

const renderLegend = (item: Types.IParsedEmotion) => {
  if (item.type.toLowerCase().includes("type")) return;
  return <AnalysisLib.Legend item={item} key={item.color} />;
};

export const AnalysisWithData: FC<IAnalysisWithData> = ({
  title,
  subtitle,
  data,
}) => {
  const {
    spacing: { md },
    palette: { text, secondaryDarkBlue },
  } = Theme.useStyledTheme();

  const DATA = useMemo(() => ParseEmotions(data), [data]);

  return (
    <SharedComponents.Column
      height={"456px"}
      alignItems={"flex-start"}
      overflow={"hidden"}
    >
      <SharedComponents.Text text={title} type={"h2"} color={text} />
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Text
        text={subtitle}
        type={"xSmallSemiBold"}
        color={secondaryDarkBlue}
      />
      <SharedComponents.Row flex={0} justifyContent={"space-between"}>
        <SharedComponents.Box>
          <SharedComponents.PieChart data={DATA} />
          <AnalysisLib.Root>{DATA.map(renderLegend)}</AnalysisLib.Root>
        </SharedComponents.Box>
      </SharedComponents.Row>
    </SharedComponents.Column>
  );
};

export const Analysis: FC<IAnalysis> = ({ title, subtitle, data }) => {
  if (!data) {
    return null;
  }

  return <AnalysisWithData title={title} subtitle={subtitle} data={data} />;
};
