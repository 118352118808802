import styled from "styled-components";
import { Icon } from "./icon";

export const Logo = styled(Icon)`
  margin-bottom: 24px;

  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }
`;
