import styled from "styled-components";

export const UL = styled.ul`
  margin-left: ${({
    theme: {
      spacing: { md },
    },
  }) => `${md}px`};
  margin-top: ${({
    theme: {
      spacing: { sm },
    },
  }) => `${sm}px`};
`;
