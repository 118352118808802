import * as React from "react";

export function IconScissors(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 2.5C.888 1.616 1.428.538 2.36 0L6 6.226 9.643 0c.932.538 1.472 1.616.858 2.5L6.81 7.61l1.556 2.661a2.5 2.5 0 11-.794.637L6 8.73l-1.572 2.177a2.5 2.5 0 11-.794-.637L5.19 7.61 1.5 2.5zm2.5 10a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zm7 0a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
        fill="#fff"
      />
    </svg>
  );
}
