import { FC } from "react";
import { observer } from "mobx-react";

import { SharedComponents, Theme } from "@shared";

import { IInfoColumn } from "../types";

import { InfoColumnWrapper } from "./summary.infoColumn.wrapper";
import { TextWrapper } from "./summary.infoColumn.textWrapper";

export const InfoColumn: FC<IInfoColumn> = observer(
  ({ justifyContent = "space-between", title, value, Icon }) => {
    const {
      palette: { primaryBlue },
    } = Theme.useStyledTheme();
    return (
      <InfoColumnWrapper justifyContent={justifyContent}>
        <Icon width="24px" height="24px" />
        <TextWrapper>
          <SharedComponents.Text text={title} type="button" />
          <SharedComponents.Text text={value} type="h1" color={primaryBlue} />
        </TextWrapper>
      </InfoColumnWrapper>
    );
  }
);
