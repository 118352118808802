import { FC, useCallback, useMemo } from "react";

import { useField } from "formik";
import { observer } from "mobx-react";
import { ListChildComponentProps } from "react-window";

import { SharedComponents, Theme, Utils } from "@shared";
import { Types } from "@videoAnalytics";

import { ItemWrapper } from "./sl.item.wrapper";
import { ItemInner } from "./sl.item.inner";
import { StyledContainer } from "./sl.item.styledContainer";

const EXTENDED_ROW_STYLE = { cursor: "pointer" };

export const Item: FC<ListChildComponentProps> = observer(
  ({ style, data, index }) => {
    const {
      spacing: { sm, md },
      palette: { primaryBlue },
    } = Theme.useStyledTheme();
    const [input, , helpers] = useField<Array<Types.IVideo>>("chosenVideos");
    // memoized values
    const item: Types.IVideo = useMemo(() => data[index], [index, data]);
    const hasChosen: boolean = useMemo(
      () => (input.value.find((el) => el.id === item.id) ? true : false),
      [input, item]
    );
    // memoized callbacks
    const onAddPress = useCallback(() => {
      if (hasChosen) {
        helpers.setValue(input.value.filter((el) => el.id !== item.id));
      } else {
        helpers.setValue([...input.value, item]);
      }
    }, [hasChosen, helpers, input, item]);

    const Icon = useMemo(
      () =>
        hasChosen ? (
          <SharedComponents.Icons.IconCheck width={md} height={sm} />
        ) : (
          <SharedComponents.Icons.IconVideo
            fill={primaryBlue}
            width={24}
            height={15}
          />
        ),
      [hasChosen]
    );

    return (
      <StyledContainer externalStyle={style} hasChosen={hasChosen}>
        <ItemWrapper>
          <img src={item.thumbnail} width={"200px"} height={"100px"} alt="" />
          <SharedComponents.HorizontalBox width={md} />
          <ItemInner>
            <SharedComponents.Text text={item.name} type={"button"} />
            <SharedComponents.VerticalBox height={6} />
            <SharedComponents.Row
              alignItems={"center"}
              width={"100%"}
              height={`${md}px`}
              flex={0}
            >
              {/*{item.tags.map(renderTag)}*/}
            </SharedComponents.Row>
            <SharedComponents.VerticalBox height={md} />
            <SharedComponents.Text type={"xSmall"} text={item.ownerEmail} />
          </ItemInner>
          <SharedComponents.HorizontalBox width={md} />
          <SharedComponents.Row
            padding={`${md}px 0`}
            onClick={onAddPress}
            onKeyDown={Utils.onKeyDown(onAddPress)}
            width={"fit-content"}
            justifyContent={"flex-end"}
            alignItems={"flex-start"}
            flex={0.35}
            style={EXTENDED_ROW_STYLE}
          >
            <SharedComponents.Text
              text={hasChosen ? "Already chosen" : "Add to summary"}
              type={"xSmallSemiBold"}
              color={primaryBlue}
            />
            <SharedComponents.HorizontalBox width={sm} />
            <SharedComponents.Column
              alignItems={"center"}
              flex={0}
              width={"fit-width"}
            >
              {Icon}
            </SharedComponents.Column>
            <SharedComponents.HorizontalBox width={sm} />
          </SharedComponents.Row>
        </ItemWrapper>
      </StyledContainer>
    );
  }
);
