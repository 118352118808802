import { useCallback, FC } from "react";
import { useField } from "formik";

import { SharedComponents, Theme } from "@shared";
import { IconButton } from "@material-ui/core";

import { ICustomTagsTag } from "../types";

import { TagWrapper } from "./ct.tag.wrapper";

export const Tag: FC<ICustomTagsTag> = ({ item, tagsField }) => {
  const {
    palette: { primaryBlue },
  } = Theme.useStyledTheme();
  const [input, , helpers] = useField(tagsField);

  const onClick = useCallback(
    () =>
      helpers.setValue(input.value.filter((member: string) => member !== item)),
    [helpers, input.value, item]
  );

  return (
    <TagWrapper>
      <SharedComponents.Text
        type={"xSmallSemiBold"}
        text={item}
        color={primaryBlue}
      />
      <SharedComponents.HorizontalBox width={10} />
      <IconButton onClick={onClick}>
        <SharedComponents.Icons.IconClose />
      </IconButton>
    </TagWrapper>
  );
};
