import styled from "styled-components";

export const PendingCoverWrapper = styled.div`
  width: 100%;
  height: 200px;
  background: ${({ theme }) => theme.palette.secondaryGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
