import { createContext, Dispatch, SetStateAction, useContext } from "react";

interface IPlayerContextValues {
  id: string;
  seekTo: (amount: number) => void;
  duration: number;
  progress: number;
  hasEditMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  timelineWidth: number;
  setTimelineWidth: Dispatch<SetStateAction<number>>;
  gripWidth: number;
  playing: boolean;
  index: number;
}

export const PlayerContext = createContext<IPlayerContextValues | null>(null);

export const usePlayerContext = () => {
  const context = useContext(PlayerContext);

  if (!context) {
    throw new Error("Player context is not defined");
  }

  return context;
};
