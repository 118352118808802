import styled from "styled-components";
import { NAVBAR_SIZE, TOPBAR_SIZE } from "../../../../constants";

export const NavbarWrapper = styled.div`
  width: ${NAVBAR_SIZE}px;
  min-width: ${NAVBAR_SIZE}px;
  height: 100%;
  background: ${({ theme }) => theme.palette.primaryBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: ${TOPBAR_SIZE}px;
  align-items: center;
  box-shadow: 0px 8px 24px 0px ${({ theme }) => theme.palette.shadow};
  z-index: 1;
  position: fixed;
  left: 0px;
`;
