import { FC, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useFormikContext } from "formik";
import { Theme } from "@shared";
import { Text } from "../text";
import * as TagsLib from "./lib";
import { ITagsSection } from "./types";

const renderTag = (
  tag: string,
  canDelete: boolean,
  onDelete: (tag: string) => void
) => (
  <TagsLib.Tag key={tag} tag={tag} canDelete={canDelete} onDelete={onDelete} />
);
export const TagsSection: FC<ITagsSection> = observer(
  ({ title = "Tags", showInput = true }) => {
    // DECLARATIONS
    const theme = Theme.useStyledTheme();
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const { values, setFieldValue, submitForm } = useFormikContext<any>();
    // EFFECTS
    useEffect(() => {
      if (values.tags.length <= 4 && isExpanded) {
        setExpanded(false);
      }
    }, [isExpanded, setExpanded, values.tags.length]);
    // FUNCTIONS
    const onToggle = useCallback(
      () => setExpanded((prevState) => !prevState),
      [setExpanded]
    );
    const onDelete = useCallback(
      async (tag: string) => {
        await setFieldValue("tags", [
          ...values.tags.filter((item: string) => item !== tag),
        ]);
        await submitForm();
      },
      [setFieldValue, submitForm, values.tags]
    );
    // RENDER
    return (
      <TagsLib.Wrapper padding={theme.spacing.lg}>
        <Text text={title} type="button" />
        {!showInput && (
          <Text
            text={
              !!values.tags.length
                ? `If you want to add video tags, go to the specific video page`
                : "No video tags to display"
            }
            type="xSmall"
          />
        )}
        <TagsLib.Inner padding={theme.spacing.sm}>
          {showInput && <TagsLib.Input placeholder="New tag" type="text" />}
          <TagsLib.Row isExpanded={isExpanded}>
            {values.tags?.map((tag: any) =>
              renderTag(tag, showInput, onDelete)
            )}
          </TagsLib.Row>
          {values.tags.length > 4 && (
            <TagsLib.Expand onToggle={onToggle} isExpanded={isExpanded} />
          )}
        </TagsLib.Inner>
      </TagsLib.Wrapper>
    );
  }
);
