import { FC } from "react";
import { SharedComponents, Theme } from "@shared";
import { ITSTag } from "../types";
import { TagWrapper } from "./ts.tag.wrapper";
import { IconClose } from "./ts.icon.close";

export const Tag: FC<ITSTag> = ({ tag, canDelete, onDelete }) => {
  const theme = Theme.useStyledTheme();
  const handleClick = () => {
    if (canDelete) {
      onDelete(tag);
    }
    return;
  };
  return (
    <TagWrapper onClick={handleClick}>
      <SharedComponents.Text
        text={tag}
        type="xSmallMedium"
        color={theme.palette.primaryBlue}
        margin={`${theme.spacing.md}px`}
      />
      {canDelete && (
        <IconClose
          width={`${theme.spacing.sm}px`}
          height={`${theme.spacing.sm}px`}
        />
      )}
    </TagWrapper>
  );
};
