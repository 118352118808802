import { SVGProps } from "react";

export const IconSurveys = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#fff">
      <path d="M21.75 4.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H2.25a.75.75 0 0 1-.75-.75V5.25a.75.75 0 0 1 .75-.75h19.5ZM2.25 3A2.25 2.25 0 0 0 0 5.25v13.5A2.25 2.25 0 0 0 2.25 21h19.5A2.25 2.25 0 0 0 24 18.75V5.25A2.25 2.25 0 0 0 21.75 3H2.25Z" />
      <path d="M10.5 8.25a.75.75 0 0 1 .75-.75h7.5a.75.75 0 1 1 0 1.5h-7.5a.75.75 0 0 1-.75-.75ZM8.256 6.969a.75.75 0 0 1 0 1.062l-2.25 2.25a.75.75 0 0 1-1.062 0l-.75-.75a.751.751 0 0 1 1.062-1.062l.219.22 1.719-1.72a.75.75 0 0 1 1.062 0ZM10.5 14.25a.75.75 0 0 1 .75-.75h7.5a.75.75 0 1 1 0 1.5h-7.5a.75.75 0 0 1-.75-.75Zm-2.244-1.281a.75.75 0 0 1 0 1.062l-2.25 2.25a.75.75 0 0 1-1.062 0l-.75-.75a.75.75 0 1 1 1.062-1.062l.219.22 1.719-1.72a.75.75 0 0 1 1.062 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
