import { useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import { observer } from "mobx-react";

import { SharedComponents, SharedStore } from "@shared";

export const CostCenterInput = observer(() => {
  const [input, meta, helpers] = useField<string>("costCenter");
  const [isValid, setValid] = useState<boolean | undefined>(undefined);
  const { getCostCenterValidation } = SharedStore;

  const onBlur = useCallback(async () => {
    const isValid = await getCostCenterValidation(input.value);
    setValid(isValid);
  }, [getCostCenterValidation, input.value, setValid]);

  useEffect(() => {
    if (
      input.value.length > 0 &&
      isValid === false &&
      meta.error === undefined
    ) {
      helpers.setTouched(true, false);
      helpers.setError("Cost center does not exist");
    } else if (isValid === true && meta.error !== undefined) {
      helpers.setError(undefined);
    }
  }, [isValid, helpers, meta.error]);

  return (
    <SharedComponents.FormikLib.Field
      title={"Cost center"}
      subtitle={"Enter the ID (for example: 1310101234)"}
      placeholder={"Enter a cost center ID"}
      field={"costCenter"}
      onBlur={onBlur}
    />
  );
});
