import styled from "styled-components";

import { IStepperProgressBar } from "../types";

export const ProgressBar = styled.div<IStepperProgressBar>`
  width: ${({ width }) => `${width}px`};
  height: 4px;
  background: ${({ theme }) => theme.palette.primaryBlue};
  margin-bottom: ${({ theme }) => `${theme.spacing.md}px`};
  transition: 0.5s linear;
`;
