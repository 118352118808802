import { useState, useMemo } from "react";

import { SharedComponents, Theme } from "@shared";

import { StepperContext } from "./context";
import * as StepperLib from "./lib";
import { IStepper, IWidth } from "./types";

const renderStep = (name: string, i: number) => (
  <StepperLib.Step index={i} name={name} key={name} />
);

export const Stepper = ({ children, steps, title, paddingRight }: IStepper) => {
  const {
    spacing: { md, lg },
    palette: { primaryBlue },
  } = Theme.useStyledTheme();
  const [widths, setWidths] = useState<Array<IWidth>>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const value = useMemo(
    () => ({
      currentStep,
      setCurrentStep,
      widths,
      setWidths,
      paddingRight,
      steps,
      title,
      children,
    }),
    [currentStep, setCurrentStep, widths, setWidths, paddingRight, title, steps]
  );
  const progressBarWidth: number = useMemo(() => {
    let width = 0;
    for (let i = 0; i < currentStep; i++) {
      if (!widths[i]) break;
      width += i === 0 ? widths[i].width - paddingRight / 2 : widths[i].width;
    }
    return width;
  }, [currentStep, widths, paddingRight]);
  return (
    <StepperContext.Provider value={value}>
      <div>
        <SharedComponents.VerticalBox height={md} />
        <SharedComponents.Breadcrumbs />
        <SharedComponents.VerticalBox height={md} />
        <SharedComponents.Row
          flex={0}
          height={"fit-content"}
          justifyContent="space-between"
          alignItems="center"
        >
          <SharedComponents.Text
            type={"h3SemiBold"}
            text={title}
            color={primaryBlue}
          />
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={lg} />
        <StepperLib.Wrapper>
          <StepperLib.Inner>{steps.map(renderStep)}</StepperLib.Inner>
          <StepperLib.ProgressBar width={progressBarWidth} />
          <StepperLib.Steps currentStep={currentStep}>
            {children}
          </StepperLib.Steps>
        </StepperLib.Wrapper>
      </div>
    </StepperContext.Provider>
  );
};

export { useStepperContext } from "./context";
