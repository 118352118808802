import { FC } from "react";
import { observer } from "mobx-react";

import { SharedComponents, Theme, SharedStore } from "@shared";

import { IFileItem } from "./types";
import { CircularProgress } from "@material-ui/core";
import { computed } from "mobx";

export const FileItem: FC<IFileItem> = observer(({ item, projectId, role }) => {
  const {
    spacing: { md, lg },
  } = Theme.useStyledTheme();

  const {
    // deleteFile,
    data: { loadings },
  } = SharedStore;

  const hasLoading = computed(() => loadings.get(item.id)).get();

  // const onClick = useCallback(() => {
  //   deleteFile(item.id);
  // }, [deleteFile, item?.id]);

  return (
    <SharedComponents.Row>
      <SharedComponents.Icons.IconDocument height={lg} width={18} />
      <SharedComponents.HorizontalBox width={md} />
      <SharedComponents.Column justifyContent={"space-between"} height={"100%"}>
        <a href={item.fileUrl} target={"_blank"}>
          <SharedComponents.Text type={"button"} text={item.name} />
        </a>
        <SharedComponents.Row height={"fit-content"} flex={0}>
          <SharedComponents.Text type={"xSmall"} text={item.ownerEmail} />
          <SharedComponents.HorizontalBox width={md} />
          <SharedComponents.Text type={"xSmall"} text={item.createdAt} />
        </SharedComponents.Row>
      </SharedComponents.Column>
      {hasLoading ? (
        <CircularProgress size={16} />
      ) : (
        <>
          {/*{role !== Types.UserRole.NONE && (*/}
          {/*  <IconButton onClick={onClick}>*/}
          {/*    <SharedComponents.Icons.IconDelete />*/}
          {/*  </IconButton>*/}
          {/*)}*/}
        </>
      )}
    </SharedComponents.Row>
  );
});
