import { FC } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { SharedComponents, Theme, Utils } from "@shared";

import { IAllItem } from "../types";

import { TLListItemWrapper } from "./tl.listitem.wrapper";

export const AllItem: FC<IAllItem> = ({
  onSelectAll,
  isAllSelected,
  disabled,
}) => {
  const {
    spacing: { sm },
  } = Theme.useStyledTheme();
  return (
    <TLListItemWrapper>
      <Checkbox
        disabled={disabled}
        checked={isAllSelected}
        color="primary"
        onChange={onSelectAll}
        onKeyDown={disabled ? undefined : Utils.onKeyDown(onSelectAll)}
      />
      <SharedComponents.Text text="All" type="small" margin={`0 ${sm}px`} />
    </TLListItemWrapper>
  );
};
