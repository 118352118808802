import { FC, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";

import { SharedComponents, Theme } from "@shared";

import { IInfiniteScrollList } from "./types";

export const InfiniteScrollList: FC<IInfiniteScrollList> = observer(
  ({ next, hasMore, data, ListItem, scrollableTarget = "scrollableDiv" }) => {
    const {
      spacing: { lg },
    } = Theme.useStyledTheme();

    const renderItem = useCallback(
      (item: any, index: number) => (
        <ListItem key={index} item={item} index={index} />
      ),
      [ListItem]
    );

    const loader = useMemo(
      () =>
        data.length === 0 ? null : (
          <SharedComponents.Column
            flex={0}
            height={"fit-content"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <SharedComponents.VerticalBox height={lg} />
            <h4>Loading...</h4>
            <SharedComponents.VerticalBox height={lg} />
          </SharedComponents.Column>
        ),
      [data.length]
    );

    return (
      <InfiniteScroll
        dataLength={data.length}
        next={next}
        hasMore={hasMore}
        loader={loader}
        scrollThreshold={0.5}
        scrollableTarget={scrollableTarget}
      >
        {data.map(renderItem)}
      </InfiniteScroll>
    );
  }
);
