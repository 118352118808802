import AutoSizer from "react-virtualized-auto-sizer";
import { observer } from "mobx-react";
import { computed } from "mobx";

import { Theme, SharedComponents } from "@shared";
import { Types } from "@videoAnalytics";

import { Text } from "@visx/text";
import { scaleLog } from "@visx/scale";
import { Wordcloud } from "@visx/wordcloud";

interface IWordCloud {
  videoDetails?: Types.IVideoDetails;
}

export const WordCloud = observer(({ videoDetails }: IWordCloud) => {
  /**
   * theme data
   * */
  const {
    palette: {
      primaryRed,
      primaryBlue,
      primaryDarkYellow,
      secondaryGreen,
      secondaryDarkBlue,
      primaryOrange,
    },
    spacing: { lg },
  } = Theme.useStyledTheme();

  const words = computed(() => {
    if (
      videoDetails?.transcripts?.translatedTopWords &&
      videoDetails?.transcripts?.translatedTopWords.length > 0
    ) {
      return videoDetails.transcripts.translatedTopWords?.map((item) => ({
        text: item.word,
        value: item.number,
      }));
    }
    return (
      videoDetails?.transcripts?.topWords?.map((item) => ({
        text: item.word,
        value: item.number,
      })) || []
    );
  }).get();

  const fontScale = scaleLog({
    domain: [
      Math.min(...words.map((w) => w.value)),
      Math.max(...words.map((w) => w.value)),
    ],
    range: [10, 48],
  });

  const fontSizeSetter = (datum: any) => fontScale(datum.value);

  const colors = [
    primaryRed,
    primaryBlue,
    primaryDarkYellow,
    secondaryGreen,
    secondaryDarkBlue,
    primaryOrange,
  ];

  if (!videoDetails) {
    return <div />;
  }

  return (
    <SharedComponents.Box height={"100%"}>
      <SharedComponents.Row
        height={"fit-content"}
        flex={0}
        justifyContent={"space-between"}
      >
        <SharedComponents.Text type={"h2"} text={"Top words"} />
        {/*<SharedComponents.Button*/}
        {/*  text={"DOWNLOAD AS .CSV"}*/}
        {/*  type={SharedTypes.ButtonType.OUTLINED}*/}
        {/*/>*/}
      </SharedComponents.Row>
      <SharedComponents.VerticalBox height={lg} />
      <AutoSizer>
        {({ width }) => {
          return (
            <div>
              <Wordcloud
                words={words}
                width={width}
                height={330}
                fontSize={fontSizeSetter}
                font={"Impact"}
                padding={4}
                spiral={"archimedean"}
                rotate={0}
              >
                {(cloudWords) =>
                  cloudWords.map((w, i) => (
                    <Text
                      key={w.text}
                      fill={colors[i % colors.length]}
                      textAnchor={"middle"}
                      transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                      fontSize={w.size}
                      fontFamily={w.font}
                    >
                      {w.text}
                    </Text>
                  ))
                }
              </Wordcloud>
            </div>
          );
        }}
      </AutoSizer>
    </SharedComponents.Box>
  );
});
