import { FC, memo, useCallback, KeyboardEvent } from "react";
import styled from "styled-components";

import "./index.css";

import { IText, IParagraph } from "./types";

export const Text: FC<IText> = memo(
  ({
    onClick,
    onKeyDown,
    type,
    noSelection,
    color = "#000",
    textAlign,
    text,
    ...props
  }) => {
    const _onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLParagraphElement>) => {
        if (event?.code === "Enter") {
          onKeyDown && onKeyDown(event);
        }
      },
      [props]
    );
    return (
      <Paragraph
        onClick={onClick}
        onKeyDown={_onKeyDown}
        tabIndex={0}
        className={`${type} ${noSelection ? "noSelection" : ""}`}
        {...props}
        style={{ color, textAlign }}
      >
        {text}
      </Paragraph>
    );
  },
  (prevProps, nextProps) =>
    prevProps.text === nextProps.text &&
    prevProps.type === nextProps.type &&
    prevProps.color === nextProps.color &&
    prevProps.fontWeight === nextProps.fontWeight &&
    prevProps.fontSize === nextProps.fontSize &&
    prevProps.whiteSpace === nextProps.whiteSpace
);

const Paragraph = styled.p<IParagraph>`
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  border-bottom: ${({ borderBottom }) => borderBottom};
  white-space: ${({ whiteSpace = "pre-wrap" }) => whiteSpace};
  font-size: ${({ fontSize }) => fontSize};
  text-align: ${({ textAlign }) => textAlign};
  font-weight: ${({ fontWeight }) => fontWeight};
`;
