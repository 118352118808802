import { ChangeEvent, FC, useCallback, useState } from "react";

import { Text } from "../text";

import * as InputLib from "./lib";
import { IInput } from "./types";

export const Input: FC<IInput> = ({ placeholder, title, height }) => {
  const [value, setValue] = useState<string>("");

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value),
    [setValue]
  );
  return (
    <InputLib.Wrapper>
      <Text type="h3" text={title} />
      <InputLib.Field
        height={height}
        placeholder={placeholder}
        isEmpty={!value}
        value={value}
        onChange={onChange}
      />
    </InputLib.Wrapper>
  );
};
