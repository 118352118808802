import { IconButton } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

import { SharedComponents, SharedStore, Theme } from "@shared";
import { Types } from "@videoAnalytics";

interface IVideoTitleActions {
  videoDetails?: Types.IVideoDetails;
  hasAccess: boolean;
  onSwitchClick: () => void;
  onShowDeleteModal: () => void;
  projectId: string;
}

export const VideoTitleActions = observer(
  ({
    videoDetails,
    hasAccess,
    onSwitchClick,
    onShowDeleteModal,
    projectId,
  }: IVideoTitleActions) => {
    const navigate = useNavigate();

    const {
      spacing: { sm },
    } = Theme.useStyledTheme();

    /**
     * storage values
     * */
    const { copyURLtoClipboard } = SharedStore;

    return (
      <SharedComponents.Row
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {videoDetails?.projectHasPii && <SharedComponents.Label text={"PII"} />}
        {videoDetails?.projectHasPii && (
          <SharedComponents.HorizontalBox width={sm} />
        )}
        <SharedComponents.Text text={videoDetails?.name} type={"h1"} />
        <SharedComponents.Row justifyContent={"flex-end"} alignItems={"center"}>
          {hasAccess && videoDetails?.projectHasPii && (
            <SharedComponents.Row alignItems={"center"}>
              <SharedComponents.Switch onClick={onSwitchClick} />
              <SharedComponents.Text text={"Show raw video"} type={"xSmall"} />
            </SharedComponents.Row>
          )}
          <IconButton onClick={copyURLtoClipboard}>
            <SharedComponents.Icons.IconShare />
          </IconButton>
          {hasAccess && (
            <IconButton
              onClick={() =>
                navigate(
                  `/video-analytics/project/${projectId}/video/${videoDetails?.id}/edit`
                )
              }
            >
              <SharedComponents.Icons.IconEdit />
            </IconButton>
          )}
          {hasAccess && (
            <IconButton onClick={onShowDeleteModal}>
              <SharedComponents.Icons.IconDelete />
            </IconButton>
          )}
        </SharedComponents.Row>
      </SharedComponents.Row>
    );
  }
);
