import { SVGProps } from "react";

export const IconTutorialActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M3.75 5.25a.75.75 0 0 1 0-1.5h16.5a.75.75 0 1 1 0 1.5H3.75Zm3-3a.75.75 0 0 1 0-1.5h10.5a.75.75 0 1 1 0 1.5H6.75ZM0 19.5a2.25 2.25 0 0 0 2.25 2.25h19.5A2.25 2.25 0 0 0 24 19.5V9a2.25 2.25 0 0 0-2.25-2.25H2.25A2.25 2.25 0 0 0 0 9v10.5Zm9.387-9.655a.75.75 0 0 1 .76.019l6 3.75a.75.75 0 0 1 0 1.272l-6 3.75A.75.75 0 0 1 9 18v-7.5a.75.75 0 0 1 .387-.655Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
