import styled from "styled-components";

import { IButtonWrapper } from "../types";

export const Wrapper = styled.div<IButtonWrapper>`
  width: ${({ width = "fit-content" }) => width};
  height: ${({ height = "fit-content" }) => height};
  background: ${({ buttonColor = "#E4E6F7", disabled, theme }) =>
    disabled ? theme.palette.primaryWhite : buttonColor};
  border: ${({ border = "transparent", disabled, theme }) =>
    disabled ? `1px solid ${theme.palette.primaryBlue}` : border};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.15s linear;
  border-radius: 50px;
  padding: 8px 32px;

  p {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ disabled }) =>
      disabled ? "rgba(0,61,165, 0.6) !important" : "inherit"};
  }
`;
