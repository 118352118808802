import moment from "moment";
import { makeObservable } from "mobx";
import AWSAppSyncClient from "aws-appsync";

import { Types as SharedTypes } from "@shared";
import { Types } from "@videoAnalytics";

import { Notificator } from "../../../shared/store/basic";

import { Client } from "./client";
import { Data } from "./data";

class _VideoAnalyticsStore extends Notificator {
  client: Client;
  data: Data;
  constructor({ awsClient }: SharedTypes.IAWSClientParams) {
    super();
    this.client = new Client(awsClient);
    this.data = new Data();
    makeObservable(this, { client: true });
  }

  createClipsVideo = async (input: Types.ICreateClipsInput) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.CREATE_CLIPS_VIDEO;
    try {
      this.showToast(`Creating summary: "${input.name}"`);
      this.data.setRequestLoading(endpoint);
      const formattedInput = {
        ...input,
        clips: input.clips.map((el) => ({
          fileId: el.fileId,
          startTime: el.startTime + "0",
          endTime: el.endTime + "0",
          thumbnail: el.thumbnail,
        })),
      };
      await this.client.createClipsVideo(formattedInput);
      this.data.setVideosDetails(undefined);
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while creating summary ${input.name}: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  projectMedia = async (input: Types.IProjectMediaInput) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.PROJECT_MEDIA;
    try {
      this.data.setRequestLoading(endpoint);
      const data = await this.client.projectMedia(input);
      this.data.setProjectMedia(data?.projectMedia);
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while updating video: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  updateVideo = async (input: Types.IUpdateVideoInput) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.UPDATE_VIDEO;
    try {
      this.showToast(`Updating video: "${input.name}"`);
      this.data.setRequestLoading(endpoint);
      await this.client.updateVideo(input);
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while updating video: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  searchVideoTranscript = async (input: Types.ISearchVideoTranscriptInput) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.SEARCH_VIDEO_TRANSCRIPT;
    try {
      this.data.setRequestLoading(endpoint);
      const data = await this.client.searchVideoTranscript(input);
      this.data.setSearchedVideoTranscript(
        data?.searchVideoTranscript.results || []
      );
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while searching video transcripts: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  getProjectNameStatus = async (name: string): Promise<boolean | undefined> => {
    return await this.client.projectNameExist(name);
  };

  getEntityRole = async (
    entityId: string,
    entityType: SharedTypes.EntityType
  ) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.USER_ROLE;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.userRole({ entityId, entityType });
      if (!response || !response?.userRole) {
        throw new Error("Could not find user data");
      }
      if (entityType === SharedTypes.EntityType.PROJECTS) {
        this.data.setSelectedProjectRole(response.userRole.role);
      }
      if (entityType === SharedTypes.EntityType.FILES) {
        this.data.setSelectedFileRole(response.userRole.role);
      }
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.data.setRequestError(endpoint);
    }
  };

  clearProjects = () => {
    this.data.clearProjects();
  };

  getProjects = async () => {
    const endpoint: Types.IEndpoint = Types.Endpoints.PROJECTS;
    try {
      this.data.setRequestLoading(endpoint);
      this.data.clearProjects();
      this.data.setCurrentProjectsPage(1);
      const response = await this.client.projects({
        page: this.data.currentProjectsPage,
      });
      if (response) {
        this.data.setProjects(response.projects);
      } else {
        this.data.setRequestError(endpoint);
        return;
      }
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while fetching projects: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  getMyProjects = async () => {
    const endpoint: Types.IEndpoint = Types.Endpoints.PROJECTS;
    try {
      this.data.setRequestLoading(endpoint);
      this.data.clearProjects();
      this.data.setCurrentProjectsPage(1);
      const response = await this.client.userProjects({
        page: this.data.currentProjectsPage,
      });
      if (response) {
        this.data.setProjects(response.userProjects);
      } else {
        this.data.setRequestError(endpoint);
        this.showErrorToast("Error while fetching projects: null data");
        return;
      }
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while fetching projects: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  getProject = async (id: string) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.PROJECT_DETAILS;
    try {
      this.data.setRequestLoading(endpoint);
      this.data.clearSelectedProject();
      const response = await this.client.projectDetails({ id });
      this.data.setSelectedProject(response?.projectDetails);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.data.setRequestError(endpoint);
    }
  };

  getMoreUserProjects = async () => {
    const endpoint: Types.IEndpoint = Types.Endpoints.MORE_USER_PROJECTS;
    try {
      this.data.setRequestLoading(endpoint);
      this.data.setCurrentProjectsPage(this.data.currentProjectsPage + 1);
      const response = await this.client.userProjects({
        page: this.data.currentProjectsPage,
      });
      response?.userProjects && this.data.mergeProjects(response.userProjects);
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while fetching projects: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  getMoreProjects = async () => {
    const endpoint: Types.IEndpoint = Types.Endpoints.MORE_PROJECTS;
    try {
      this.data.setRequestLoading(endpoint);
      this.data.setCurrentProjectsPage(this.data.currentProjectsPage + 1);
      const response = await this.client.projects({
        page: this.data.currentProjectsPage,
      });
      response?.projects && this.data.mergeProjects(response.projects);
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while fetching projects: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  // BUNCH OF VIDEOS FOR SUMMARY CREATION
  getVideosDetails = async (videoId: string) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.VIDEOS_DETAILS;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.videosDetails({ id: videoId });
      if (!response || !response?.videosDetails) {
        this.showErrorToast("Error while fetching videos details: null data");
        return;
      }
      this.data.setVideosDetails(response.videosDetails);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.data.setRequestError(endpoint);
    }
  };

  // SINGLE VIDEO FOR VIDEO_DETAILS PAGE
  getVideoDetails = async (videoId: string) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.VIDEO_DETAILS;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.videoDetails({ id: videoId });
      if (!response || !response?.videoDetails) {
        throw new Error("Could not find video data");
      }
      this.data.setVideoDetails(response.videoDetails);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.data.setRequestError(endpoint);
    }
  };

  getVideoTranscripts = async (input: Types.IVideoTranscriptsInput) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.VIDEO_TRANSCRIPTS;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.videoTranscript(input);
      if (!response || !response?.videoTranscript) {
        this.showErrorToast("Error while fetching videos details: null data");
        return;
      }
      this.data.setVideoTranscript(response);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.data.setRequestError(endpoint);
    }
  };

  getProjectsStats = async () => {
    const endpoint: Types.IEndpoint = Types.Endpoints.PROJECTS_STATS;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.projectsStats();
      if (response) {
        this.data.setProjectStats(response.projectsStats);
      } else {
        this.data.setRequestError(endpoint);
        this.showErrorToast("Error while fetching projects stats: null data");
        return;
      }
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while fetching projects stats: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  getMyProjectsStats = async () => {
    const endpoint: Types.IEndpoint = Types.Endpoints.PROJECTS_STATS;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.userProjectsStats();
      if (response) {
        this.data.setMyProjectsStats(response.userProjectsStats);
      } else {
        this.data.setRequestError(endpoint);
        this.showErrorToast("Error while fetching projects stats: null data");
        return;
      }
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while fetching projects stats: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  createProject = async (
    values: Types.ICreateProjectFormValues,
    onChangeProjectId: (id: string) => void
  ) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.CREATE_PROJECT;
    try {
      this.data.setRequestLoading(endpoint);
      const input: Types.ICreateProjectInput = {
        type: "VIDEO",
        brands: values.brands,
        tags: values.tags,
        categories: values.categories,
        countries: values.countries,
        regions: values.regions,
        members: values.members,
        clp: values.cmk,
        consentFormLink: values.consentFormLink,
        description: values.summary,
        hasDelayedFSDSUpload: values.hasDelayedFSDSUpload || false,
        hasSignedInformedConsent: values.hasSignedInformedConsent || false,
        hasPii: values?.hasPii || false,
        name: values.name,
        blurMode: values.blurMode || "",
        transformations: [],
        startDate: moment(values.startDate).format("YYYY-MM-DD hh:mm:ss"),
        endDate: moment(values.endDate).format("YYYY-MM-DD hh:mm:ss"),
        //optional fields
        FSDSUploadDate: values.hasDelayedFSDSUpload
          ? moment(values.FSDSUploadDate).format("YYYY-MM-DD hh:mm:ss")
          : undefined,
        hasVideoPersonsUnder18: values.hasVideoPersonsUnder18,
        hasConsentFormSignedByParent: values.hasConsentFormSignedByParent,
      };
      const data = await this.client.createProject(input);
      if (!data || !data.createProject) {
        this.showErrorToast("Error while creating project: null data");
        return;
      } else {
        onChangeProjectId(data.createProject.id);
      }
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.data.setRequestError(endpoint);
      this.showErrorToast(`Error while creating project: ${e}`);
    }
  };

  updateProject = async (
    id: string,
    costCenter: string,
    name: string,
    tags: Array<string>,
    hasCostAgreement: boolean,
    brands: Array<string>,
    countries: Array<string>,
    categories: Array<string>,
    regions: Array<string>,
    members: Array<string>
  ) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.UPDATE_PROJECT;
    try {
      this.data.setRequestLoading(endpoint);
      const input: Types.IUpdateProjectInput = {
        costCenter,
        hasCostsAgreed: hasCostAgreement,
        id,
        name,
        status: Types.ProjectStatuses.CONFIGURED,
        tags,
        brands,
        countries,
        categories,
        regions,
        members,
      };
      await this.client.updateProject(input);
      this.data.setRequestSuccess(endpoint);
    } catch (e: any) {
      this.showErrorToast(`Error while updating project: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  createFile = async ({
    body,
    id,
    slug,
    projectId,
    name,
  }: Types.ICreateFileInputExtended): Promise<void> => {
    const endpoint: Types.IEndpoint = Types.Endpoints.CREATE_FILE;
    try {
      this.showToast(`Uploading file ${name}`);
      this.data.setRequestLoading(endpoint);
      const data = await this.client.createFile({
        slug,
        name,
        projectId,
        id,
      });
      if (!data || !data.createFile) {
        this.showErrorToast("Error while creating file: null data");
        return;
      }
      body &&
        this.client.putToBucket(
          {
            body,
            id,
            path: data.createFile.rawPath || "",
            type: SharedTypes.FilesType.FILES,
          },
          this.showToast
        );
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while getting metadata for ${name}: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  createVideo = async ({
    body,
    id,
    slug,
    projectId,
    name,
  }: Types.ICreateVideoInputExtended) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.CREATE_FILE;
    try {
      this.showToast(`Uploading file ${name}`);
      this.data.setRequestLoading(endpoint);
      const data = await this.client.createVideo({
        slug,
        name,
        projectId,
        id,
        tags: [],
      });
      if (!data || !data.createVideo) {
        this.showErrorToast("Error while creating video: null data");
        return;
      }
      body &&
        this.client.putToBucket(
          {
            body,
            id,
            path: data.createVideo.rawPath || "",
            type: SharedTypes.FilesType.VIDEOS,
          },
          this.showToast
        );
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while getting metadata for ${name}: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  deleteFile = async (id: string, name: string) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.DELETE_FILE;
    try {
      this.showToast(`Start deleting file ${name}`);
      this.data.setRequestLoading(endpoint);
      await this.client.deleteFile({ id });
      this.showSuccessToast(`File ${name} has been successfully deleted`);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while deleting ${name} file: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  deleteProject = async (id: string, name: string) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.DELETE_PROJECT;
    try {
      this.showToast(`Start deleting project ${name}`);
      this.data.setRequestLoading(endpoint);
      await this.client.deleteProject({ id });
      this.showSuccessToast(`Project ${name} has been successfully deleted`);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while deleting ${name} file: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  deleteVideo = async (id: string, name: string) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.DELETE_VIDEO;
    try {
      this.showToast(`Start deleting video ${name}`);
      this.data.setRequestLoading(endpoint);
      await this.client.deleteVideo({ id });
      this.showSuccessToast(`Video ${name} has been successfully deleted`);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while deleting ${name} file: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  getVideoClips = async (videoId: string) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.VIDEO_CLIPS;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.videoClips({ id: videoId });
      if (!response || !response?.videoClips) {
        this.showErrorToast("Error while getting video clips: null data");
        return;
      }
      this.data.setVideoClips(response?.videoClips);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while getting clips for summary: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  // searchProjects = async (input: Types.ISearchProjectsInput) => {
  //   const endpoint: Types.IEndpoint = Types.Endpoints.SEARCH_PROJECTS;
  //   try {
  //     const fInput: Types.ISearchProjectsInput = {
  //       categories: input.categories,
  //       brands: input.brands,
  //       countries: input.countries,
  //       regions: input.regions,
  //       text: input.text,
  //       page: 1,
  //       types: ["VIDEO"],
  //     };
  //     this.data.setRequestLoading(endpoint);
  //     const response = await this.client.searchProjects(fInput);
  //     if (!response || !response.searchProjects) {
  //       this.showErrorToast(`Error while searching projects: null data`);
  //       return;
  //     }
  //     this.data.setElasticData(response);
  //     this.data.setRequestSuccess(endpoint);
  //   } catch (e) {
  //     this.showErrorToast(`Error while getting file details: ${e}`);
  //     this.data.setRequestError(endpoint);
  //   }
  // };

  fullSearch = async (input: Types.IFullSearchInput) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.FULL_SEARCH;
    try {
      const fInput: Types.IFullSearchInput = {
        categories: input.categories,
        brands: input.brands,
        countries: input.countries,
        regions: input.regions,
        text: input.text,
        page: 1,
      };
      this.data.setRequestLoading(endpoint);
      const response = await this.client.fullSearch(fInput);
      if (!response || !response?.fullSearch) {
        this.showErrorToast(`Error while searching projects: null data`);
        return;
      }
      this.data.setFullSearchData(response);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while getting file details: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  updateClipsVideo = async (params: Types.IUpdateClipsVideoInput) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.UPDATE_CLIPS_VIDEO;
    try {
      this.showToast(`Updating summary ${params.name}`);
      this.data.setRequestLoading(endpoint);
      await this.client.updateClipsVideo(params);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while updating summary: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  searchUsers = async (text: string) => {
    const endpoint: Types.IEndpoint = Types.Endpoints.SEARCH_USERS;
    try {
      this.data.setRequestLoading(endpoint);
      const response = await this.client.searchUsers({ text });
      response?.searchUsers && this.data.setEmails(response);
      this.data.setRequestSuccess(endpoint);
    } catch (e) {
      this.showErrorToast(`Error while searching users: ${e}`);
      this.data.setRequestError(endpoint);
    }
  };

  clearSelectedProject = () => {
    this.data.clearSelectedProject();
  };

  clearVideoDetails = () => {
    this.data.clearVideoDetails();
  };
}

export const VideoAnalyticsStore = new _VideoAnalyticsStore({
  awsClient: AWSAppSyncClient,
});
