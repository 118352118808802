import styled from "styled-components";

import { IControls } from "../types";

export const Wrapper = styled.div<IControls>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(transparent, transparent, #222);

  opacity: ${({ isPlaying }) => `${isPlaying ? 0 : 1}`};

  transition: 0.2s linear;

  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;
