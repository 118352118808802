import { observer } from "mobx-react";
import { computed } from "mobx";

import { VideoAnalyticsStore } from "@videoAnalytics";
import { Utils, Theme } from "@shared";

import { VideoLineChart } from "../video_line_chart";
import { usePlayerContext } from "../../../context";

type TKey = "negative" | "positive" | "neutral";

export type ILineChart = {
  width: number;
  height: number;
  label: string;
  title: string;
};

export const FaceExpressionsChart = observer(
  ({ width, height, label, title }: ILineChart) => {
    const {
      palette: { primaryOrange, secondaryGreen, primaryRed },
    } = Theme.useStyledTheme();
    const {
      data: { videoDetails },
    } = VideoAnalyticsStore;

    const { playerRef } = usePlayerContext();

    const chartData = computed(() => {
      const output: Record<TKey, Array<{ x: string; y?: number }>> = {
        negative: [] as Array<any>,
        neutral: [],
        positive: [],
      };

      videoDetails?.emotions.basic.forEach((emotion) => {
        const pushItem = (key: TKey) => {
          if (emotion[key]?.count > 0) {
            output[key].push({
              x: Utils.formatTimeShort(parseInt(emotion.startTime)),
              y: emotion[key]?.count,
            });
          } else {
            output[key].push({
              x: Utils.formatTimeShort(parseInt(emotion.startTime)),
            });
          }
        };
        Object.keys(output).forEach((value) => {
          const v = value as TKey;
          pushItem(v);
        });
      });
      return output;
    }).get();

    const getColorByKey = (key: string) => {
      switch (key) {
        case "negative":
          return primaryRed;
        case "neutral":
          return primaryOrange;
        case "positive":
          return secondaryGreen;
        default:
          return "black";
      }
    };

    return (
      <VideoLineChart
        playerRef={playerRef}
        label={label}
        title={title}
        width={width}
        height={height}
        chartData={chartData}
        getColorByKey={getColorByKey}
        colorRange={[primaryRed, primaryOrange, secondaryGreen]}
      />
    );
  }
);
