import * as React from "react";

export function IconCost(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.016 0H.984a.954.954 0 00-.703.281A.954.954 0 000 .984v12c0 .313.094.563.281.75a.954.954 0 00.703.282h22.032a.954.954 0 00.703-.282c.187-.187.281-.437.281-.75v-12a.954.954 0 00-.281-.703.954.954 0 00-.703-.281zm-21 2.016H3a.954.954 0 01-.281.703.954.954 0 01-.703.281v-.984zm0 9.984v-.984c.656 0 .984.328.984.984h-.984zm6.468 0H5.016c0-.844-.29-1.555-.868-2.133C3.57 9.29 2.86 9 2.016 9V5.016c.843 0 1.554-.29 2.132-.868.579-.578.868-1.289.868-2.132h3.468c-1 1.406-1.5 3.062-1.5 4.968 0 1.875.5 3.547 1.5 5.016zM12 12c-.813 0-1.516-.5-2.11-1.5C9.298 9.5 9 8.328 9 6.984c0-1.343.297-2.507.89-3.492.594-.984 1.297-1.476 2.11-1.476s1.516.492 2.11 1.476c.593.985.89 2.149.89 3.492 0 1.375-.29 2.555-.867 3.54C13.555 11.507 12.843 12 12 12zm9.984 0H21c0-.656.328-.984.984-.984V12zm0-3c-.843 0-1.554.29-2.132.867-.579.578-.868 1.29-.868 2.133H15.61c1-1.469 1.5-3.14 1.5-5.016 0-1.906-.5-3.562-1.5-4.968h3.375c0 .843.29 1.554.868 2.132.578.579 1.289.868 2.132.868V9zm0-6a.954.954 0 01-.703-.281.954.954 0 01-.281-.703h.984V3zm0 14.016h-5.578c.407-.563.61-1.235.61-2.016H15c0 .063-.008.14-.023.234-.016.094-.079.258-.188.493a1.92 1.92 0 01-.445.609c-.188.172-.485.328-.89.469-.407.14-.892.21-1.454.21-.844 0-1.516-.163-2.016-.492-.5-.328-.789-.617-.867-.867A2.243 2.243 0 019 15H6.984c0 .594.204 1.266.61 2.016H2.016V15H0v2.016c0 .53.195.992.586 1.382.39.391.867.586 1.43.586h19.968c.563 0 1.04-.195 1.43-.586.39-.39.586-.851.586-1.382V15h-2.016v2.016zm0 4.968h-5.578c.407-.562.61-1.218.61-1.968H15c0 .187-.031.39-.094.609-.062.219-.336.5-.82.844-.484.343-1.18.515-2.086.515-.844 0-1.516-.156-2.016-.468-.5-.313-.789-.594-.867-.844A2.244 2.244 0 019 20.016H6.984c0 .562.204 1.218.61 1.968H2.016v-1.968H0v1.968c0 .563.195 1.04.586 1.43.39.39.867.586 1.43.586h19.968c.563 0 1.04-.195 1.43-.586.39-.39.586-.867.586-1.43v-1.968h-2.016v1.968z"
        fill="#003DA5"
      />
    </svg>
  );
}
