import { observer } from "mobx-react";
import { computed } from "mobx";

import { VideoAnalyticsStore } from "@videoAnalytics";
import { Utils } from "@shared";

import { VideoLineChart } from "../video_line_chart";
import { usePlayerContext } from "../../../context";

type TKey =
  | "angry"
  | "calm"
  | "confused"
  | "surprised"
  | "disgusted"
  | "fear"
  | "happy"
  | "sad";

export type ILineChart = {
  width: number;
  height: number;
  label: string;
  title: string;
};

export const FaceEmotionsChart = observer(
  ({ width, height, label, title }: ILineChart) => {
    const {
      data: { videoDetails },
    } = VideoAnalyticsStore;

    const { playerRef } = usePlayerContext();

    const chartData = computed(() => {
      const output: Record<TKey, Array<{ x: string; y?: number }>> = {
        angry: [],
        surprised: [],
        calm: [],
        confused: [],
        disgusted: [],
        fear: [],
        happy: [],
        sad: [],
      };

      videoDetails?.emotions.detail.forEach((emotion) => {
        const pushItem = (key: TKey) => {
          if (emotion[key]?.count > 0) {
            output[key].push({
              x: Utils.formatTimeShort(parseInt(emotion.startTime)),
              y: emotion[key]?.count,
            });
          } else {
            output[key].push({
              x: Utils.formatTimeShort(parseInt(emotion.startTime)),
            });
          }
        };
        Object.keys(output).forEach((value) => {
          const v = value as TKey;
          pushItem(v);
        });
      });
      return output;
    }).get();

    const getColorByKey = (key: string) => {
      switch (key) {
        case "happy":
          return "#00B140";
        case "sad":
          return "#EF5533";
        case "angry":
          return "#E87722";
        case "confused":
          return "#EF33C6";
        case "disgusted":
          return "#C933EF";
        case "surprised":
          return "#2B28CC";
        case "calm":
          return "#2B28CC";
        case "calm":
          return "#F1B434";
        case "fear":
          return "#EF3340";
        default:
          return "black";
      }
    };

    return (
      <VideoLineChart
        playerRef={playerRef}
        label={label}
        title={title}
        width={width}
        height={height}
        chartData={chartData}
        getColorByKey={getColorByKey}
        colorRange={[
          "#E87722",
          "#2B28CC",
          "#F1B434",
          "#EF33C6",
          "#C933EF",
          "#EF3340",
          "#00B140",
          "#EF5533",
        ]}
      />
    );
  }
);
