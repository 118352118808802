import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { observer } from "mobx-react";

import {
  SharedComponents,
  SharedStore,
  Types as SharedTypes,
  Utils,
  Theme,
} from "@shared";
import {
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
  Types,
} from "@videoAnalytics";
import { computed } from "mobx";

export const SummaryDetails = observer(() => {
  /**
   * route params
   * */
  const { videoId, projectId } = useParams();
  const fVideoId = useMemo(() => videoId || "", [videoId]);
  const navigate = useNavigate();

  /**
   * theme data
   * */
  const {
    spacing: { lg, sm },
  } = Theme.useStyledTheme();
  /**
   * storage values
   * */
  const {
    copyURLtoClipboard,
    data: { hasAdminRole, hasUserRole, hasEBPRole },
  } = SharedStore;
  const {
    clearVideoDetails,
    getVideoDetails,
    getProject,
    deleteVideo,
    getProjects,
    getProjectsStats,
    data: {
      setRequestReset,
      videoDetails,
      loadings,
      isFileOwner,
      isFileAdmin,
      isFileMember,
    },
  } = VideoAnalyticsStore;

  const hasVideoDetailsLoading = Utils.useQueryStatus(
    loadings,
    Types.Endpoints.VIDEO_DETAILS
  );
  /**
   * local state
   * */
  const [isDeleteModalOpened, setDeleteModalOpened] = useState<boolean>(false);
  // const [isRaw, setRaw] = useState<boolean>(false);
  /**
   * memoized callback
   * */
  const onCancelDeleteModal = useCallback(() => {
    setDeleteModalOpened(false);
  }, [setDeleteModalOpened]);

  const onShowDeleteModal = () => {
    setDeleteModalOpened(true);
  };

  const onDeleteProject = async () => {
    setDeleteModalOpened(false);
    if (!projectId) {
      await deleteVideo(fVideoId, videoDetails?.name || "");
      navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}`);
      await Promise.all([getProjects(), getProjectsStats()]);
      return;
    } else {
      navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}`);
      await deleteVideo(fVideoId, videoDetails?.name || "");
      await getProject(projectId);
      return;
    }
  };

  // const onSwitchClick = useCallback(() => setRaw((prev) => !prev), [setRaw]);

  const hasAccess = computed(
    () =>
      ((isFileOwner || isFileAdmin || isFileMember) &&
        (hasEBPRole || hasUserRole)) ||
      hasAdminRole
  ).get();

  const url = computed(() => {
    // if (hasAccess && videoDetails!.rawVideoUrl !== "" && isRaw) {
    //   return videoDetails!.rawVideoUrl;
    // }
    // return videoDetails!.processedPath || "";
    return videoDetails?.processedPath || "";
  }).get();

  /**
   * side effects
   * */
  useEffect(() => {
    const init = async () => {
      await getVideoDetails(fVideoId);
    };
    init();
    return () => {
      clearVideoDetails();
      setRequestReset(Types.Endpoints.VIDEO_DETAILS);
    };
  }, []);

  return (
    <VideoAnalyticsComponents.FileRoleController
      hasExternalLoading={hasVideoDetailsLoading}
      fileId={fVideoId}
    >
      <SharedComponents.Screen>
        {hasAccess && (
          <SharedComponents.Modal
            isOpen={isDeleteModalOpened}
            cancelText={"No"}
            onCancel={onCancelDeleteModal}
            onConfirm={onDeleteProject}
            confirmText={"Yes"}
            type={SharedTypes.ModalTypes.DEFAULT}
            title={"Do you want to delete the summary ?"}
          />
        )}
        <SharedComponents.VerticalBox height={32} />
        <SharedComponents.Breadcrumbs />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.Row
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <SharedComponents.Text text={videoDetails?.name} type={"h1"} />
          <SharedComponents.Row
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {/*{hasAccess && (*/}
            {/*  <SharedComponents.Row alignItems={"center"}>*/}
            {/*    <SharedComponents.Switch onClick={onSwitchClick} />*/}
            {/*    <SharedComponents.Text*/}
            {/*      text={"Show raw video"}*/}
            {/*      type={"xSmall"}*/}
            {/*    />*/}
            {/*  </SharedComponents.Row>*/}
            {/*)}*/}
            <IconButton onClick={copyURLtoClipboard}>
              <SharedComponents.Icons.IconShare />
            </IconButton>
            {hasAccess && (
              <IconButton
                onClick={() =>
                  navigate(
                    `/video-analytics/project/${projectId}/summary/${videoId}/edit`
                  )
                }
              >
                <SharedComponents.Icons.IconEdit />
              </IconButton>
            )}
            {hasAccess && (
              <IconButton onClick={onShowDeleteModal}>
                <SharedComponents.Icons.IconDelete />
              </IconButton>
            )}
          </SharedComponents.Row>
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={sm} />
        <SharedComponents.Separator />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.Row>
          <SharedComponents.Column flex={1}>
            <SharedComponents.Player url={url} />
          </SharedComponents.Column>
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={lg} />
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.FileRoleController>
  );
});
