import { ULWrapper } from "../../../pages/add_project/components/ul_wrapper";

export const ULAnalytics = () => {
  return (
    <ULWrapper>
      Cost includes:
      <ul>
        <li>Scene Object Detection in Video</li>
        <li>Audio to Text Transcription</li>
        <li>Text Translation to English + Other Languages</li>
        <li>Face Blurring</li>
        <li>Audio Distortion</li>
        <li>Video Storage for 5 Years</li>
      </ul>
    </ULWrapper>
  );
};
