import * as React from "react";

export function IconProjects(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.116 0l8.414 8.2v8.656h-1.87V8.2h-3.74a2.843 2.843 0 01-1.983-.8 2.699 2.699 0 01-.821-1.933V1.822H3.83c-.496 0-.972.192-1.322.534-.35.342-.548.805-.548 1.288v21.867c0 .483.197.947.548 1.289.35.341.826.533 1.322.533h7.48v1.823H3.83a3.79 3.79 0 01-2.644-1.068A3.598 3.598 0 01.09 25.511V3.644c0-.966.394-1.893 1.096-2.577A3.79 3.79 0 013.83 0h10.285z"
        fill="#808B9F"
      />
    </svg>
  );
}
