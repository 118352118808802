import { SharedComponents, Theme } from "@shared";

interface ISummaryTile {
  Icon: React.FC<any>;
  name: string;
  value: string | number;
  flex: number;
  justifyContent?: "flex-start" | "center" | "flex-end";
  iconWidth?: string;
  iconHeight?: string;
}

export const SummaryTile = ({
  Icon,
  name,
  value,
  flex,
  justifyContent = "flex-end",
  iconHeight = "30px",
  iconWidth = "30px",
}: ISummaryTile) => {
  const {
    palette: { primaryBlue, gray },
    spacing: { sm, md },
  } = Theme.useStyledTheme();
  return (
    <SharedComponents.Row
      alignItems={"flex-end"}
      flex={flex}
      width={"fit-content"}
      height={"fit-content"}
    >
      <SharedComponents.Row flex={1} justifyContent={justifyContent}>
        <Icon fill={gray} width={iconWidth} height={iconHeight} />
      </SharedComponents.Row>
      <SharedComponents.HorizontalBox width={md} />
      <SharedComponents.Column
        flex={2}
        width={"fit-content"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <SharedComponents.Row width={"fit-content"}>
          <SharedComponents.Text
            text={name}
            type={"button"}
            whiteSpace={"nowrap"}
          />
          <SharedComponents.Row width={sm} />
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={sm} />
        <SharedComponents.Text
          text={value}
          type={"h1"}
          color={primaryBlue}
          whiteSpace={"nowrap"}
        />
      </SharedComponents.Column>
    </SharedComponents.Row>
  );
};
