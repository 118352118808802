import { computed } from "mobx";
import { observer } from "mobx-react";
import { useCallback } from "react";

import { IconButton } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import { SharedComponents, Theme, Types } from "@shared";

import { formatSize } from "./utils";

interface IUploadItem {
  file: Types.IFile;
  onDelete: (id: string, name: string) => void;
  loadings: Types.ObservableProgressMap;
}

export const UploadedFile = observer(
  ({ file, onDelete, loadings }: IUploadItem) => {
    const {
      palette: { gray, primaryBlue },
      spacing: { sm },
    } = Theme.useStyledTheme();

    const onClick = useCallback(
      () => onDelete(file.id, file.name),
      [onDelete, file]
    );

    const progress = computed(() => loadings.get(file.id)?.progress || 0).get();

    const hasProgress = computed(() => loadings.has(file.id)).get();

    return (
      <SharedComponents.Box>
        <SharedComponents.Box>
          <SharedComponents.Row
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <SharedComponents.Text text={file?.name || ""} type={"h3"} />
            {(progress == 100 || !file.body) && (
              <IconButton onClick={onClick}>
                <SharedComponents.Icons.IconClose />
              </IconButton>
            )}
          </SharedComponents.Row>
          {progress !== 100 && <SharedComponents.VerticalBox height={6} />}
          {!hasProgress && (
            <>
              <LinearProgress
                variant="determinate"
                value={file.body ? progress : 100}
              />
              <SharedComponents.VerticalBox height={sm} />
            </>
          )}
          {hasProgress && (
            <>
              <LinearProgress variant="determinate" value={progress} />
              <SharedComponents.VerticalBox height={sm} />
              <SharedComponents.Row
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <SharedComponents.Row alignItems={"center"}>
                  {(progress == 100 || !file.body) && (
                    <>
                      <SharedComponents.Icons.IconCheck />
                      <SharedComponents.HorizontalBox width={sm} />
                      <SharedComponents.Text
                        text={"File added"}
                        type="xSmallSemiBold"
                        color={primaryBlue}
                      />
                    </>
                  )}
                </SharedComponents.Row>
                <SharedComponents.HorizontalBox width={sm} />
                <SharedComponents.Text
                  text={formatSize(file?.fileSize || 0)}
                  type={"xSmall"}
                  color={gray}
                />
                <SharedComponents.HorizontalBox width={sm} />
              </SharedComponents.Row>
            </>
          )}
        </SharedComponents.Box>
        <SharedComponents.VerticalBox height={12} />
      </SharedComponents.Box>
    );
  }
);
