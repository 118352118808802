import { memo, useCallback } from "react";

import { Theme, Types, Utils } from "@shared";

import { Text } from "../text";

import * as ButtonLib from "./lib";
import { IButton } from "./types";

export const Button = memo(
  ({
    text,
    fontType = "button",
    onClick,
    textColor = "#fff",
    buttonColor = "#003DA5",
    width = 123,
    height = 44,
    border,
    padding,
    borderColor,
    disabled = false,
    type = Types.ButtonType.FILLED,
  }: IButton) => {
    const {
      palette: { primaryWhite, primaryBlue, gray },
    } = Theme.useStyledTheme();

    const onClickHandler = useCallback(() => {
      if (disabled) {
        return;
      } else if (onClick) {
        onClick();
      }
    }, [disabled, onClick]);

    if (type === Types.ButtonType.OUTLINED) {
      return (
        <ButtonLib.Wrapper
          onKeyDown={disabled ? undefined : Utils.onKeyDown(onClick)}
          onClick={onClickHandler}
          borderColor={primaryBlue}
          buttonColor={primaryWhite}
          border={`1px solid ${primaryBlue}`}
          disabled={disabled}
        >
          <Text type={"button"} text={text} color={primaryBlue} />
        </ButtonLib.Wrapper>
      );
    }

    if (type === Types.ButtonType.FILLED) {
      return (
        <ButtonLib.Wrapper
          onKeyDown={disabled ? undefined : Utils.onKeyDown(onClick)}
          onClick={onClickHandler}
          borderColor={primaryBlue}
          buttonColor={primaryBlue}
          border={`1px solid ${primaryBlue}`}
          disabled={disabled}
        >
          <Text noSelection type={"button"} text={text} color={primaryWhite} />
        </ButtonLib.Wrapper>
      );
    }

    if (type === Types.ButtonType.DISABLED) {
      return (
        <ButtonLib.Wrapper
          onClick={onClickHandler}
          borderColor={gray}
          buttonColor={gray}
          border={`1px solid ${gray}`}
          disabled={true}
        >
          <Text noSelection type={"button"} text={text} />
        </ButtonLib.Wrapper>
      );
    }

    return (
      <ButtonLib.Wrapper
        padding={padding}
        width={width}
        height={height}
        onKeyDown={disabled ? undefined : Utils.onKeyDown(onClickHandler)}
        onClick={onClickHandler}
        borderColor={borderColor}
        buttonColor={buttonColor}
        border={border}
        disabled={disabled}
      >
        <Text noSelection type={fontType} text={text} color={textColor} />
      </ButtonLib.Wrapper>
    );
  }
);
