import { Types } from "@videoAnalytics";

export function findMaxIndex(arr: Array<Types.IParsedEmotion>) {
  if (arr.length === 0) {
    return -1;
  }

  let max = arr[0].count;
  let maxIndex = 0;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i].count > max) {
      maxIndex = i;
      max = arr[i].count;
    }
  }

  return maxIndex;
}
