import * as FiltersLib from "./lib";
import { observer } from "mobx-react";

interface ITaxonomyFilters {
  isCreation?: boolean;
}

const TaxonomyFiltersObserver = observer(() => {
  // const { values } = useFormikContext<any>();
  // const {
  //   data: {
  //     regions,
  //     brands,
  //     countries,
  //     categories,
  //     searchValue,
  //     setCurrentElasticProjectsPage,
  //   },
  //   getFullSearch,
  // } = SharedStore;
  // const elasticSearchData = computed(() => ({
  //   regions: regions
  //     .filter((item) => values.regions.includes(item.id))
  //     .map((r) => r.name),
  //   brands: brands
  //     .filter((item) => values.brands.includes(item.id))
  //     .map((b) => b.name),
  //   countries: countries
  //     .filter((item) => values.countries.includes(item.id))
  //     .map((c) => c.name),
  //   categories: categories
  //     .filter((item) => values.categories.includes(item.id))
  //     .map((c) => c.name),
  // })).get();

  return <></>;
});

export const TaxonomyFilters = ({ isCreation = false }: ITaxonomyFilters) => {
  return (
    <>
      <FiltersLib.CategoriesTagsList isCreation={isCreation} />
      <FiltersLib.BrandsTagsList isCreation={isCreation} />
      <FiltersLib.RegionsTagsList
        showWarning={isCreation}
        isCreation={isCreation}
      />
      <FiltersLib.CountriesTagsList
        showWarning={isCreation}
        isCreation={isCreation}
      />
      {!isCreation && <TaxonomyFiltersObserver />}
    </>
  );
};
