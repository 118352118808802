import { useState } from "react";

import { SharedComponents, Theme } from "@shared";

import * as Components from "./components";
import { IDownloadItem } from "./types";

interface IDownloadButton {
  data: Array<IDownloadItem>;
}

export const DownloadButton = ({ data }: IDownloadButton) => {
  const {
    palette: { primaryBlue },
  } = Theme.useStyledTheme();

  const [isExpanded, setExpanded] = useState<boolean>(false);

  return (
    <Components.Container>
      <Components.ButtonContainer
        onClick={() => setExpanded((prevState) => !prevState)}
      >
        <SharedComponents.Text
          noSelection
          text={"DOWNLOAD"}
          type={"button"}
          color={primaryBlue}
        />
      </Components.ButtonContainer>
      {isExpanded && (
        <SharedComponents.Column alignItems={"center"}>
          {data.map(({ name, path }) => (
            <SharedComponents.Column key={name} alignItems={"center"}>
              <SharedComponents.Separator width={"90%"} />
              <Components.DownloadItem name={name} path={path} />
            </SharedComponents.Column>
          ))}
        </SharedComponents.Column>
      )}
    </Components.Container>
  );
};
