import { CommonPages, VideoAnalyticsPages } from "@modules";

import { Icons } from "../../components";
import { MAIL_RECIPIENTS } from "../../constants";
import * as Types from "../../types";

export const BASIC_NAVBAR_TOOLS_TO_ANALYZE_EASILY: Types.INavbarSectionConfig =
  {
    title: "",
    data: [
      {
        path: Types.PATHS.INITIAL,
        element: <VideoAnalyticsPages.MyProjects />,
        name: "Home",
        breadcrumb: "Home",
        icons: {
          inactiveIcon: Icons.IconHome,
          activeIcon: Icons.IconHomeActive,
        },
      },
      {
        path: Types.PATHS.TUTORIAL,
        element: <CommonPages.Tutorial />,
        name: "Tutorial",
        breadcrumb: "Tutorial",
        icons: {
          inactiveIcon: Icons.IconTutorial,
          activeIcon: Icons.IconTutorialActive,
        },
      },
      {
        path: MAIL_RECIPIENTS,
        external: true,
        element: <div />,
        name: "Contact us",
        breadcrumb: "Contact us",
        icons: {
          inactiveIcon: Icons.IconContactUs,
          activeIcon: Icons.IconContactUsActive,
        },
      },
    ],
  };

export const NAVBAR_ROUTES: Array<Types.INavbarSectionConfig> = [
  BASIC_NAVBAR_TOOLS_TO_ANALYZE_EASILY,
];

export const ROUTES = [
  ...NAVBAR_ROUTES.flatMap((routeConfig) => routeConfig.data),
  {
    path: Types.PATHS.VIDEO_ANALYTICS_PROJECT_SUMMARY_EDIT,
    element: <VideoAnalyticsPages.EditSummary />,
    name: "Video analytics - Edit summary",
    breadcrumb: "Edit summary",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_PROJECT_SUMMARY,
    element: <VideoAnalyticsPages.SummaryDetails />,
    name: "Video analytics - Summary details",
    breadcrumb: "Summary details",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS,
    element: <VideoAnalyticsPages.MyProjects />,
    name: "Video analytics",
    breadcrumb: "Video analytics",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_PROJECT,
    element: <VideoAnalyticsPages.ProjectDetails />,
    name: "Video analytics - Project details",
    breadcrumb: "Project details",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_PROJECT_ADD,
    element: <VideoAnalyticsPages.AddProject />,
    name: "Video analytics - Add project",
    breadcrumb: "Add project",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_PROJECT_EDIT,
    element: <VideoAnalyticsPages.EditProject />,
    name: "Video analytics - Edit project",
    breadcrumb: "Edit project",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_ADD_VIDEO,
    element: <VideoAnalyticsPages.AddVideo />,
    name: "Video analytics - Add Video",
    breadcrumb: "Add Video",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_ADD_FILE,
    element: <VideoAnalyticsPages.AddFile />,
    name: "Video analytics - Add file",
    breadcrumb: "Add file",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_PROJECT_VIDEO,
    element: <VideoAnalyticsPages.VideoDetails />,
    name: "Video analytics - Video details",
    breadcrumb: "Video details",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_VIDEO_EDIT,
    element: <VideoAnalyticsPages.EditVideo />,
    name: "Video analytics - Edit video",
    breadcrumb: "Edit video",
  },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_VIDEO,
    element: <VideoAnalyticsPages.VideoDetails />,
    name: "Video analytics - Video details",
    breadcrumb: "Video",
  },
  // {
  //   path: Types.PATHS.VIDEO_ANALYTICS_VIDEO_ONLY,
  //   element: <VideoAnalyticsPages.VideoDetailsOnly />,
  //   name: "Video analytics - Video details",
  //   breadcrumb: "Video",
  // },
  {
    path: Types.PATHS.VIDEO_ANALYTICS_ADD_SUMMARY,
    element: <VideoAnalyticsPages.AddSummary />,
    name: "Video analytics - Summary",
    breadcrumb: "Summary",
  },
];
