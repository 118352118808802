import { observer } from "mobx-react";

import { SharedComponents, Theme } from "@shared";
import { Types } from "@videoAnalytics";

import { computed } from "mobx";

interface IVideoBasics {
  videoDetails?: Types.IVideoDetails;
}

export const useTop5Tags = (tags?: Array<string>) => {
  return computed(() => {
    const empty = "...";
    if (tags) {
      if (tags.length === 0) return empty;
      const length = tags.length < 5 ? tags.length : 5;
      return tags.reduce((acc, curr, index) => {
        if (index < length) {
          if (index === length - 1) {
            if (index < 5) {
              const newAcc = acc + `#${curr}`;
              return newAcc;
            } else {
              const newAcc = acc + `#${curr}...`;
              return newAcc;
            }
          }
          const newAcc = acc + `#${curr}, `;
          return newAcc;
        } else {
          return acc;
        }
      }, "");
    }
    return empty;
  }).get();
};

export const VideoBasics = observer(({ videoDetails }: IVideoBasics) => {
  const {
    spacing: { lg },
  } = Theme.useStyledTheme();

  const top5Tags = useTop5Tags(videoDetails?.tags);

  return (
    <SharedComponents.Row>
      <SharedComponents.InfoTile
        Icon={SharedComponents.Icons.IconUser}
        name={"Owner:"}
        value={videoDetails?.ownerEmail || ""}
      />
      <SharedComponents.HorizontalBox width={lg} />
      <SharedComponents.InfoTile
        Icon={SharedComponents.Icons.IconUpdate}
        name={"Update:"}
        value={videoDetails?.updatedAt || ""}
      />
      <SharedComponents.HorizontalBox width={lg} />
      <SharedComponents.InfoTile
        Icon={SharedComponents.Icons.IconTags}
        name={"Video tags:"}
        value={top5Tags}
      />
    </SharedComponents.Row>
  );
});
