import { SharedComponents, Theme, Constants, Types } from "@shared";

import * as LocalComponents from "./lib";
import styled from "styled-components";
import { useMemo } from "react";

interface IModal {
  Icon?: React.FC<any>;
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  type: Types.ModalTypes;
  hasConciergeNote?: boolean;
}

export const Paragraph = styled.p`
  font-size 0.75rem;
  text-align: left;
  
`;

export const Modal = ({
  Icon,
  title,
  description,
  isOpen,
  onConfirm,
  cancelText,
  confirmText = "Yes",
  type,
  onCancel,
  hasConciergeNote = false,
}: IModal) => {
  const {
    spacing: { sm, md },
    palette: { secondaryGreen, primaryRed, text },
  } = Theme.useStyledTheme();

  const color = useMemo(() => {
    switch (type) {
      case Types.ModalTypes.DEFAULT:
        return text;
      case Types.ModalTypes.SUCCESS:
        return secondaryGreen;
      case Types.ModalTypes.ERROR:
        return primaryRed;
      default:
        return text;
    }
  }, [type]);

  return (
    <LocalComponents.Dialog open={isOpen}>
      <SharedComponents.Column
        justifyContent={"center"}
        alignItems={type !== Types.ModalTypes.DEFAULT ? "center" : "flex-start"}
      >
        {Icon && (
          <>
            <Icon width={40} height={40} fill={color} />
            <SharedComponents.VerticalBox height={md} />
          </>
        )}
        <SharedComponents.Text text={title} type="h2" color={color} />
        <SharedComponents.VerticalBox height={sm} />
        {description && (
          <>
            <SharedComponents.Text
              text={description}
              type="h3"
              textAlign={"center"}
            />
            <SharedComponents.VerticalBox height={sm} />
          </>
        )}
        {hasConciergeNote && (
          <>
            <SharedComponents.VerticalBox height={sm} />
            <Paragraph>
              If you have any questions or need help, please reach out to
              our&nbsp;
              <a href={Constants.MAIL_RECIPIENTS}>Concierge</a>.
            </Paragraph>
          </>
        )}
        <SharedComponents.VerticalBox height={36} />
        {type !== Types.ModalTypes.DEFAULT && (
          <SharedComponents.Button
            type={Types.ButtonType.FILLED}
            text={confirmText}
            onClick={onConfirm}
          />
        )}
        {type === Types.ModalTypes.DEFAULT && confirmText && (
          <SharedComponents.Row justifyContent={"flex-end"}>
            {cancelText && (
              <SharedComponents.Button
                type={Types.ButtonType.OUTLINED}
                text={"No"}
                onClick={onCancel}
              />
            )}
            <SharedComponents.HorizontalBox width={sm} />
            <SharedComponents.Button
              type={Types.ButtonType.FILLED}
              text={confirmText}
              onClick={onConfirm}
            />
          </SharedComponents.Row>
        )}
      </SharedComponents.Column>
    </LocalComponents.Dialog>
  );
};
