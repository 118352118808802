import { FC, memo, useCallback, useMemo, useState } from "react";
import { useField } from "formik";

import { IconButton } from "@material-ui/core";
import { SharedComponents, Theme } from "@shared";

import { IFormikField } from "./types";
import * as FieldLib from "./lib";

export const Field: FC<IFormikField> = memo(
  ({
    height = 40,
    field,
    type = "text",
    placeholder,
    component,
    onKeyDown,
    onBlur,
    disabled = false,
  }) => {
    const {
      palette: { primaryRed, gray },
    } = Theme.useStyledTheme();
    const [hasFocus, setFocus] = useState(false);
    const [input, meta, helpers] = useField(field);
    const _onBlur = useCallback(async () => {
      setFocus(false);
      onBlur && (await onBlur());
    }, [setFocus, onBlur]);
    const onClear = useCallback(() => helpers.setValue(""), [helpers]);
    const onFocus = useCallback(() => setFocus(true), [setFocus]);
    const hasFocusOrValue = useMemo(
      () => (input.value && input.value.length > 0) || hasFocus,
      [input, hasFocus]
    );
    return (
      <SharedComponents.Column
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <FieldLib.Wrapper>
          <FieldLib.StyledField
            hasfocus={hasFocusOrValue.toString()}
            onBlur={_onBlur}
            onFocus={onFocus}
            height={height}
            component={component}
            placeholder={placeholder}
            type={type}
            name={field}
            onKeyDown={onKeyDown}
            disabled={disabled}
          />
          <FieldLib.AbsoluteContainer height={height}>
            <IconButton disabled>
              <SharedComponents.Icons.IconSearch />
            </IconButton>
            <SharedComponents.Box
              height={"60%"}
              width={"1px"}
              background={gray}
            />
            <IconButton onClick={onClear}>
              <SharedComponents.Icons.IconClose />
            </IconButton>
          </FieldLib.AbsoluteContainer>
        </FieldLib.Wrapper>
        {meta.error && meta.touched && (
          <SharedComponents.Text
            text={meta.error}
            type={"small"}
            color={primaryRed}
          />
        )}
      </SharedComponents.Column>
    );
  }
);
