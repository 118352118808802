import { FC } from "react";
import { SharedComponents, Theme } from "@shared";

export interface ISummary {
  title: string;
  value: string;
  Icon: FC;
}

export const Summary: FC<ISummary> = ({ value, title, Icon }) => {
  const theme = Theme.useStyledTheme();
  return (
    <SharedComponents.Row flex={0} height={"fit-content"}>
      <Icon />
      <SharedComponents.Text type="xSmall" text={title} margin="0 0 0 4px" />
      <SharedComponents.Text type="xSmall" text=":" margin="0 4px 0 0" />
      <SharedComponents.Text
        whiteSpace="nowrap"
        type="xSmallMedium"
        text={value}
        color={theme.palette.primaryBlue}
        margin="0 24px 0 0"
      />
    </SharedComponents.Row>
  );
};
