import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { SharedComponents, Pages, SharedStore } from "@shared";
import {
  Templates,
  Types,
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
  Utils,
} from "@videoAnalytics";

import { PROJECT_CREATION_STEPS } from "./constants";
import { initialValues } from "./formik_data";
import * as Components from "./components";
import * as LocalPages from "./pages";
import { IValues } from "./types";

export const AddProject = observer(() => {
  const { projectExist } = SharedStore;
  const {
    data: { clearSelectedProject, setRequestReset },
    client: { clearData },
  } = VideoAnalyticsStore;

  /**
   * FORMIK DATA
   * */
  const validationSchema = Utils.getValidationSchema({
    nameVerificationCb: projectExist,
    hasEditMode: false,
    name: "",
  });

  const [isAssistanceModalOpen, setAssistanceModalOpen] = useState(false);

  useEffect(() => {
    setAssistanceModalOpen(true);
    return () => {
      clearData();
      clearSelectedProject();
      setRequestReset(Types.Endpoints.UPDATE_PROJECT);
      setRequestReset(Types.Endpoints.CREATE_PROJECT);
    };
  }, [clearData, clearSelectedProject, setRequestReset]);

  return (
    <VideoAnalyticsComponents.AddProjectController>
      <SharedComponents.Screen>
        <Components.AssistanceModal
          isOpen={isAssistanceModalOpen}
          setOpen={setAssistanceModalOpen}
          modalText={
            "Do you need assistance running this project (i.e. panel, scheduling, etc.)?"
          }
        />
        <SharedComponents.FormikLib.Form
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values: IValues) => alert(JSON.stringify(values))}
        >
          <Pages.AddProjectTemplate
            steps={PROJECT_CREATION_STEPS}
            title={"Create Project"}
            paddingRight={64}
          >
            <LocalPages.ProjectDetailsPage isEditMode={false} />
            <Templates.ProjectTagsPage />
            <Templates.ConsentPage />
            <Templates.ProjectAlgorithmsPage />
            <LocalPages.UploadVideos />
            <LocalPages.CostsEstimatePage isEditMode={false} />
          </Pages.AddProjectTemplate>
        </SharedComponents.FormikLib.Form>
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.AddProjectController>
  );
});
