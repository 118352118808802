import { FC } from "react";

import { SharedComponents, Theme } from "@shared";

import { ITSExpandContainer } from "../types";

import { ExpandContainer } from "./ts.expand.wrapper";
import { ExpandIconContainer } from "./ts.expand.iconContainer";
import { IconArrowDown } from "./ts.icon.arrowDown";

export const Expand: FC<ITSExpandContainer> = ({ isExpanded, onToggle }) => {
  const theme = Theme.useStyledTheme();
  return (
    <ExpandContainer onClick={onToggle}>
      <SharedComponents.Text
        text={isExpanded ? "Hide more tags" : "See more tags"}
        type="button"
        margin={`0 ${theme.spacing.sm}px 0 0 `}
      />
      <ExpandIconContainer isExpanded={isExpanded}>
        <IconArrowDown
          width={`${theme.spacing.sm}px`}
          height={`${theme.spacing.sm}px`}
        />
      </ExpandIconContainer>
    </ExpandContainer>
  );
};
