import { FC, useMemo } from "react";
import { useField } from "formik";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";

import { Types } from "@videoAnalytics";

import { Item } from "./sl.item";
import { FixedSizeList } from "./sl.fixedSizeList";

interface IVideos {
  height: number;
  itemHeight: number;
  data?: Array<Types.IVideoDetails>;
}

export const Videos: FC<IVideos> = ({ itemHeight, height, data }) => {
  const [search] = useField<string>("search");
  const filteredVideos = useMemo(
    () =>
      data?.filter(
        (el) =>
          el.status === "SUCCEEDED" &&
          (el.ownerEmail.toLowerCase().includes(search.value.toLowerCase()) ||
            el?.name?.toLowerCase().includes(search.value.toLowerCase()))
      ),
    [search]
  );
  return (
    <AutoSizer>
      {({ width }: Size) => (
        <FixedSizeList
          itemData={filteredVideos}
          className="List"
          height={height}
          itemCount={(filteredVideos && filteredVideos.length) || 0}
          itemSize={itemHeight}
          width={width}
          overscanCount={4}
          layout={"vertical"}
        >
          {Item}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};
