import { useField } from "formik";
import { observer } from "mobx-react";
import { TabContext } from "@material-ui/lab";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import { SharedComponents, Types as SharedTypes, Utils } from "@shared";
import { VideoAnalyticsStore, Types } from "@videoAnalytics";

import * as TabsLib from "./components";
import { TABS_CONFIG } from "./constants";
import { IProjectDetailsTabs, ITabsHeaderInterface } from "./types";

const renderTabsHeaders = (header: ITabsHeaderInterface) => (
  <TabsLib.TabHeader
    tabIndex={0}
    key={header.label}
    value={header.value}
    label={header.label}
  />
);

export const Tabs = observer(
  ({ projectId, hasAccess }: IProjectDetailsTabs) => {
    /**
     * formik values
     * */
    const [input] = useField("text");

    const {
      projectMedia,
      data: {
        projectFiles,
        projectSummaries,
        projectVideos,
        loadings,
        totalCount,
        pageCount,
      },
    } = VideoAnalyticsStore;

    const [page, setPage] = useState<number>(1);
    const [fileType, setFileType] = useState<Types.FileType>("video");

    const hasLoading = Utils.useQueryStatus(
      loadings,
      Types.Endpoints.PROJECT_MEDIA
    );

    const formattedVideos = useMemo(
      () =>
        projectVideos
          ?.slice()
          .filter((el) =>
            el?.name?.toLowerCase().includes(input.value.toLowerCase())
          ) || [],
      [projectVideos, input.value]
    );

    const formattedSummaries = useMemo(
      () =>
        projectSummaries
          ?.slice()
          .filter((el) =>
            el?.name?.toLowerCase().includes(input.value.toLowerCase())
          ) || [],
      [projectSummaries, input.value]
    );

    const formattedFiles = useMemo(
      () =>
        projectFiles
          ?.slice()
          .filter((el) =>
            el?.name?.toLowerCase().includes(input.value.toLowerCase())
          ) || [],
      [projectFiles, input.value]
    );
    /**
     * local state
     * */
    const [value, setValue] = useState<"0" | "1" | "2">("0");
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);

    const handleChange = (
      event: ChangeEvent<{}>,
      newValue: "0" | "1" | "2"
    ) => {
      if (newValue === "0") {
        setFileType("video");
      }
      if (newValue === "1") {
        setFileType("summary");
      }
      if (newValue === "2") {
        setFileType("file");
      }
      setPage(1);
      setValue(newValue);
    };
    /**
     * storage values
     * */
    const { deleteFile, deleteVideo, getProject } = VideoAnalyticsStore;

    const onDeleteFile = useCallback(
      async (id: string, name: string) => {
        await deleteFile(id, name);
        await getProject(projectId);
      },
      [getProject, deleteFile]
    );

    const onDeleteVideo = useCallback(
      async (id: string, name: string) => {
        await deleteVideo(id, name);
        await getProject(projectId);
      },
      [getProject, deleteFile, projectId]
    );

    useEffect(() => {
      projectMedia({ id: projectId, page, fileType, itemsPerPage });
    }, [projectId, page, fileType, itemsPerPage]);

    return (
      <TabContext value={value}>
        <TabsLib.HeadersWrapper onChange={handleChange} variant={"fullWidth"}>
          {TABS_CONFIG.headers.map(renderTabsHeaders)}
        </TabsLib.HeadersWrapper>
        <TabsLib.Panel value={"0"}>
          <TabsLib.Paginator
            hasLoading={hasLoading}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalCount={totalCount}
            pageCount={pageCount}
            page={page}
            onChange={(_, p) => setPage(p)}
            Component={
              <SharedComponents.GridList
                data={formattedVideos}
                renderItem={(item) => (
                  <SharedComponents.VideoCardSmall
                    hasAccess={hasAccess}
                    pathTo={`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}/video/${item.id}`}
                    item={item}
                    onDelete={() => onDeleteVideo(item.id, item.name)}
                  />
                )}
              />
            }
          />
        </TabsLib.Panel>
        <TabsLib.Panel value={"1"}>
          <TabsLib.Paginator
            hasLoading={hasLoading}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalCount={totalCount}
            pageCount={pageCount}
            page={page}
            onChange={(_, p) => setPage(p)}
            Component={
              <SharedComponents.GridList
                data={formattedSummaries}
                renderItem={(item) => (
                  <SharedComponents.VideoCardSmall
                    hasAccess={hasAccess}
                    pathTo={`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}/summary/${item.id}`}
                    item={item}
                    disabled={
                      item.status !== Types.ProjectVideoStatuses.SUCCEEDED
                    }
                    onDelete={() => onDeleteVideo(item.id, item.name)}
                  />
                )}
              />
            }
          />
        </TabsLib.Panel>
        <TabsLib.Panel value={"2"}>
          <TabsLib.Paginator
            hasLoading={hasLoading}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalCount={totalCount}
            pageCount={pageCount}
            page={page}
            onChange={(_, p) => setPage(p)}
            Component={
              <SharedComponents.GridList
                itemHeight={48}
                data={formattedFiles}
                renderItem={(item) => (
                  <SharedComponents.ProjectFile
                    hasAccess={hasAccess}
                    onDelete={() => onDeleteFile(item.id, item.name)}
                    item={item}
                  />
                )}
              />
            }
          />
        </TabsLib.Panel>
      </TabContext>
    );
  }
);
