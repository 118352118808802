import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import { SharedComponents } from "@shared";

const ListItemWrapper = styled.div`
  height: 34px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => `${theme.spacing.lg}px`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.gray}`};

  :hover {
    background: ${({ theme }) => theme.palette.secondaryGray};
  }
`;

interface IListItem {
  title: string;
  setOptionsVisibility: Dispatch<SetStateAction<boolean>>;
  onSelect: (text: string) => void;
}

const ListItem = ({ title, setOptionsVisibility, onSelect }: IListItem) => {
  return (
    <ListItemWrapper
      onClick={() => {
        onSelect(title);
        setOptionsVisibility(false);
      }}
    >
      <SharedComponents.Text type={"xSmall"} text={title} />
    </ListItemWrapper>
  );
};

export default React.memo(ListItem);
