import { FC } from "react";
import { observer } from "mobx-react";

import { IUserRole } from "@src/shared/store/types";

import { IVideoDetails } from "../../types";

export interface IVideoFormValues {
  name: string;
  tags: string[];
}

interface IVideoName {
  videoDetails: IVideoDetails;
  role: IUserRole;
}

export const VideoName: FC<IVideoName> = observer(() => {
  /**
   * Storage data
   * */
  // const {
  //   ui: { copyURLtoClipboard },
  // } = SharedStore;
  // const {
  //   checkIfVideoExists,
  //   data: { hasVideoUpdateProgress, isVideoExisting, setVideoExisting },
  // } = VideoDetailsStore;
  // /**
  //  * Theme data
  //  * */
  // const theme = Theme.useStyledTheme();
  // /**
  //  * Formik state
  //  * */
  // const { submitForm } = useFormikContext<IVideoFormValues>();
  // const [input, meta, helpers] = useField("name");
  // const { setValue } = helpers;
  // /**
  //  * Local state
  //  * */
  // const [isEditMode, setEditMode] = useState<boolean>(false);
  // const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  // /**
  //  * Memoized values
  //  * */
  // const isOwner = useMemo(() => role === "OWNER" || role === "USER", [role]);
  // /**
  //  * Callbacks
  //  * */
  // const debouncedVideoNameValidation = _.debounce((value: string) => {
  //   const slug = value.split(" ").join("-");
  //   checkIfVideoExists(slug);
  // }, 500);
  //
  // const handleChange = useCallback(
  //   (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //     setVideoExisting(undefined);
  //     setValue(event.target.value);
  //     debouncedVideoNameValidation(event.target.value);
  //   },
  //   [setVideoExisting, setValue, debouncedVideoNameValidation]
  // );
  //
  // const handleChangeEditMode = () => {
  //   setEditMode((state) => !state);
  // };
  //
  // const cancelEditField = () => {
  //   setValue(videoDetails.title);
  //   setEditMode(false);
  // };
  // const handleEditVideo = async () => {
  //   if (!isVideoExisting) {
  //     await submitForm();
  //     setEditMode(false);
  //   }
  // };
  // const handleDeleteVideo = () => {
  //   setOpenDeleteModal(true);
  // };
  // const textFieldLabel = useMemo(
  //   () =>
  //     videoDetails.title.length > 16
  //       ? `${videoDetails.title.slice(0, 16)}...`
  //       : videoDetails.title,
  //   [videoDetails.title]
  // );
  // /**
  //  * Optional JSX
  //  * */
  // const renderField = isEditMode ? (
  //   <TextField
  //     name={"name"}
  //     label={textFieldLabel}
  //     value={input.value}
  //     onChange={handleChange}
  //     error={!!meta.error}
  //     helperText={meta.error}
  //   />
  // ) : (
  //   <SharedComponents.Text
  //     text={videoDetails?.title || ""}
  //     type="h1"
  //     margin={`${theme.spacing.sm}px 0 0`}
  //   />
  // );
  //
  // useEffect(() => {
  //   if (
  //     videoDetails.title !== input.value &&
  //     isVideoExisting &&
  //     meta.error === undefined
  //   ) {
  //     helpers.setError("Video with the same title already exists");
  //   } else if (videoDetails.title === input.value && meta.error !== undefined) {
  //     helpers.setError(undefined);
  //   }
  // }, [
  //   videoDetails.title,
  //   input.value,
  //   isVideoExisting,
  //   meta.error,
  //   helpers.setError,
  // ]);
  //
  // if (hasVideoUpdateProgress) {
  //   return <CircularProgress />;
  // }
  //
  // return (
  //   <>
  //     <SharedComponents.Row
  //       justifyContent={isEditMode ? "flex-start" : "space-between"}
  //     >
  //       {renderField}
  //       {isEditMode && (
  //         <>
  //           <IconButton onClick={handleEditVideo}>
  //             <SharedComponents.Icons.IconCheck />
  //           </IconButton>
  //           <IconButton onClick={cancelEditField}>
  //             <SharedComponents.Icons.IconClose />
  //           </IconButton>
  //         </>
  //       )}
  //
  //       <div>
  //         {!isEditMode && (
  //           <Tooltip title={"Share video"} arrow>
  //             <IconButton onClick={copyURLtoClipboard}>
  //               <SharedComponents.Icons.IconShare width={16} height={16} />
  //             </IconButton>
  //           </Tooltip>
  //         )}
  //         {!isEditMode && isOwner && (
  //           <>
  //             <Tooltip title={"Edit video"} arrow>
  //               <IconButton onClick={handleChangeEditMode}>
  //                 <SharedComponents.Icons.IconEdit width={16} height={16} />
  //               </IconButton>
  //             </Tooltip>
  //             <Tooltip title={"Delete Video"} arrow>
  //               <IconButton onClick={handleDeleteVideo}>
  //                 <SharedComponents.Icons.IconDelete width={16} height={16} />
  //               </IconButton>
  //             </Tooltip>
  //           </>
  //         )}
  //       </div>
  //     </SharedComponents.Row>
  //     <DeleteModal
  //       isOpen={openDeleteModal}
  //       setOpen={setOpenDeleteModal}
  //       modalText={
  //         "Are you sure that you want to delete this video?\nThis action cannot be reversed!"
  //       }
  //       id={videoDetails.id}
  //     />
  //   </>
  // );
  return <div />;
});
