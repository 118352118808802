import { Theme } from "@shared";

import { Box } from "../containers";

interface ISeparator {
  height?: string;
  width?: string;
}

export const Separator = ({ height = "1px", width = "100%" }: ISeparator) => {
  const {
    palette: { gray },
  } = Theme.useStyledTheme();
  return <Box height={height} width={width} background={gray} />;
};
