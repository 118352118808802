import * as Yup from "yup";

import { ICreateClipValues } from "../types";

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "At least 2 characters")
    .max(60, "Not more than 60 characters")
    .required("This field is required"),
  summary: Yup.string().required("This field is required"),
  search: Yup.string(),
  videos: Yup.array(),
  chosenVideos: Yup.array().min(1, "You should select al least one video"),
  clips: Yup.array().min(1, "You should select at least one clip"),
  timelines: Yup.array(),
});

export const initialValues: ICreateClipValues = {
  name: "",
  summary: "",
  search: "",
  chosenVideos: [],
  clips: [],
  timelines: [],
};
