import { FC, useCallback } from "react";
import { IconButton } from "@material-ui/core";

import { SharedComponents, Types } from "@shared";

import * as BackModalLib from "./components";
import { IBackModal } from "./types";

export const BackModal: FC<IBackModal> = ({
  isOpen = false,
  setOpen,
  modalText,
  onBack,
}) => {
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <BackModalLib.Wrapper open={isOpen} onClose={onClose}>
      <SharedComponents.FlexContainer justifyContent={"flex-end"} padding={0}>
        <IconButton onClick={onClose}>
          <SharedComponents.Icons.IconClose />
        </IconButton>
      </SharedComponents.FlexContainer>
      <SharedComponents.FlexContainer>
        <SharedComponents.Text text={modalText} type={"h3SemiBold"} />
      </SharedComponents.FlexContainer>
      <SharedComponents.FlexContainer padding={0} justifyContent={"flex-end"}>
        <SharedComponents.Button
          type={Types.ButtonType.OUTLINED}
          text={"YES"}
          onClick={onBack}
        />
        <BackModalLib.Separator />
        <SharedComponents.Button text={"NO"} onClick={onClose} />
      </SharedComponents.FlexContainer>
    </BackModalLib.Wrapper>
  );
};
