import { SVGProps } from "react";

export const IconImg = ({
  fill = "#808B9F",
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={88}
    height={78}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.761 44.5a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5Z"
      fill={fill}
    />
    <path
      d="M77.011 66.5a11 11 0 0 1-11 11h-55a11 11 0 0 1-11-11v-44a11 11 0 0 1 10.99-11 11 11 0 0 1 11-11h55a11 11 0 0 1 11 11v44a11 11 0 0 1-10.99 11ZM77.001 6H22a5.5 5.5 0 0 0-5.5 5.5h49.51a11 11 0 0 1 11 11V61a5.5 5.5 0 0 0 5.49-5.5v-44A5.5 5.5 0 0 0 77 6ZM11.01 17a5.5 5.5 0 0 0-5.5 5.5v44l14.553-12.947a2.75 2.75 0 0 1 3.465-.341l14.63 9.752 20.405-20.405a2.75 2.75 0 0 1 3.174-.517L71.51 52.75V22.5a5.5 5.5 0 0 0-5.5-5.5h-55Z"
      fill={fill}
    />
  </svg>
);
