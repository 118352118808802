import { useFormikContext } from "formik";
import { useCallback } from "react";
import { observer } from "mobx-react";

import { SharedComponents, Theme, Pages } from "@shared";
import { Types } from "@videoAnalytics";
import * as ProjectAlgoComponents from "./components";

export const ProjectAlgorithmsPage = observer(() => {
  const {
    spacing: { sm, md, lg },
    palette: { primaryBlue },
  } = Theme.useStyledTheme();

  const { values } = useFormikContext<Types.ICreateProjectFormValues>();
  const { currentStep, setCurrentStep } = SharedComponents.useStepperContext();

  const onNextHandler = useCallback(
    () => setCurrentStep(currentStep + 1),
    [setCurrentStep, currentStep]
  );

  const onBackHandler = useCallback(
    () => setCurrentStep(currentStep - 1),
    [setCurrentStep, currentStep]
  );

  return (
    <Pages.AddProjectStepTemplate
      fields={values.hasPii ? ["blurMode"] : []}
      nextTitle="Next"
      onPrev={onBackHandler}
      onNext={onNextHandler}
    >
      <SharedComponents.Box>
        <SharedComponents.VerticalBox height={44} />
        <ProjectAlgoComponents.BlurTypes field={"blurMode"} />
        <SharedComponents.Text type="h3SemiBold" text="Video Analytics Cost:" />
        <SharedComponents.VerticalBox height={sm} />
        <SharedComponents.Row>
          <SharedComponents.Icons.IconCost />
          <SharedComponents.HorizontalBox width={sm} />
          <SharedComponents.Text
            text="$1.50/minute"
            type="h3SemiBold"
            color={primaryBlue}
          />
          <SharedComponents.Text
            text=" (rounded to the whole minute)"
            type="h3"
            color={primaryBlue}
          />
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={sm} />
        <ProjectAlgoComponents.ULAnalytics />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.Text
          type="h3SemiBold"
          text="Optional Costs:"
          margin={`${md}px 0`}
        />
        <SharedComponents.FlexContainer padding={0} justifyContent="flex-start">
          <SharedComponents.Icons.IconCost />
          <SharedComponents.Text
            margin={`0 ${sm}px`}
            text="$40/hour"
            type="h3SemiBold"
            color={primaryBlue}
          />
        </SharedComponents.FlexContainer>
        <SharedComponents.VerticalBox height={sm} />
        <ProjectAlgoComponents.UlOptionalCosts />
      </SharedComponents.Box>
    </Pages.AddProjectStepTemplate>
  );
});
