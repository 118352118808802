import styled from "styled-components";
import { IItemWrapper } from "./types";

export const ItemWrapper = styled.div<IItemWrapper>`
  margin: ${({ theme }) => `0 0 ${theme.spacing.sm}px 0`};
  width: 816px;
  height: 111px;
  background: ${({ theme }) => `${theme.palette.white}`};
  border-radius: ${({ theme }) => `${theme.spacing.sm}px`};

  border: ${({ isDragging, theme }) =>
    isDragging ? `1px solid ${theme.palette.border}` : "none"};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ draggableStyle }) => ({ ...draggableStyle })}
`;
