import styled from "styled-components";
import { IBox, IFlexBox, IHorizontalBox, IVerticalBox } from "./types";

export const Box = styled.div<IBox>`
  display: block;
  position: relative;
  border: ${({ border = "none" }) => `${border}`};
  border-radius: ${({ borderRadius }) => `${borderRadius}`};
  height: ${({ height = "100%" }) => `${height}`};
  width: ${({ width = "100%" }) => `${width}`};
  margin: ${({ margin }) => `${margin}`};
  padding: ${({ padding }) => `${padding}`};
  background: ${({ background }) => background};
  cursor: ${({ cursor }) => `${cursor}`};
  overflow: ${({ overflow = "none" }) => `${overflow}`};
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  min-width: ${({ minWidth }) => `${minWidth}`};
`;

export const HorizontalBox = ({ width }: IHorizontalBox) => {
  return <Box width={`${width}px`} />;
};

export const VerticalBox = ({ height }: IVerticalBox) => {
  return <Box height={`${height}px`} />;
};

export const Row = styled.div<IFlexBox>`
  display: flex;
  position: relative;
  background: ${({ background = "transparent" }) => `${background}`};
  flex: ${({ flex = 1 }) => flex};
  border: ${({ border = "none" }) => `${border}`};
  height: ${({ height = "100%" }) => `${height}`};
  margin: ${({ margin }) => `${margin}`};
  padding: ${({ padding }) => `${padding}`};
  width: ${({ width = "100%" }) => `${width}`};
  align-items: ${({ alignItems = "flex-start" }) => `${alignItems}`};
  cursor: ${({ cursor }) => `${cursor}`};
  justify-content: ${({ justifyContent = "flex-start" }) =>
    `${justifyContent}`};
  overflow: ${({ overflow = "none" }) => `${overflow}`};
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  min-width: ${({ minWidth }) => `${minWidth}`};
  flex-wrap: ${({ flexWrap }) => `${flexWrap}`};
  opacity: ${({ opacity }) => `${opacity}`};
`;

export const Column = styled.div<IFlexBox>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: ${({ flex = 1 }) => flex};
  background: ${({ background = "transparent" }) => `${background}`};
  border: ${({ border = "none" }) => `${border}`};
  height: ${({ height = "100%" }) => `${height}`};
  margin: ${({ margin }) => `${margin}`};
  padding: ${({ padding }) => `${padding}`};
  width: ${({ width = "100%" }) => `${width}`};
  align-items: ${({ alignItems = "flex-start" }) => `${alignItems}`};
  cursor: ${({ cursor }) => `${cursor}`};
  justify-content: ${({ justifyContent = "flex-start" }) =>
    `${justifyContent}`};
  overflow: ${({ overflow = "none" }) => `${overflow}`};
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  min-width: ${({ minWidth }) => `${minWidth}`};
  flex-wrap: ${({ flexWrap }) => `${flexWrap}`};
  opacity: ${({ opacity }) => `${opacity}`};
`;
