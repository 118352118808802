import { FC } from "react";

import { SharedComponents, Types } from "@shared";

import { IButtonsGroup } from "../types";

import { ButtonsWrapper } from "./bg.buttons.wrapper";
import { ButtonSeparator } from "./bg.buttons.separator";

export const Buttons: FC<IButtonsGroup> = ({
  titleNext,
  titleBack,
  onBackHandler,
  onNextHandler,
}) => {
  return (
    <ButtonsWrapper>
      <SharedComponents.Button
        type={Types.ButtonType.OUTLINED}
        text={titleBack}
        onClick={onBackHandler}
      />
      <ButtonSeparator />
      <SharedComponents.Button text={titleNext} onClick={onNextHandler} />
    </ButtonsWrapper>
  );
};
