import styled from "styled-components";
import { observer } from "mobx-react";
import { Skeleton } from "@material-ui/lab";

import { SharedComponents, Theme } from "@shared";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const EmptyBox = () => (
  <SharedComponents.Box height={"fit-content"} width={"32%"}>
    <SharedComponents.Box
      height={"216px"}
      width={"100%"}
      background={`rgba(44, 53, 67,0.1)`}
    >
      <SharedComponents.Column alignItems={"center"} justifyContent={"center"}>
        <SharedComponents.Icons.IconImg
          fill={`rgba(44, 53, 67,0.2)`}
          width={88}
          height={78}
        />
      </SharedComponents.Column>
    </SharedComponents.Box>
    <SharedComponents.Box padding={"16px"}>
      <Skeleton variant="rect" width={"48%"} height={24} />
      <SharedComponents.VerticalBox height={36} />
      <Skeleton variant="rect" width={"36%"} height={16} />
      <SharedComponents.VerticalBox height={16} />
      <Skeleton variant="rect" width={"78%"} height={16} />
    </SharedComponents.Box>
  </SharedComponents.Box>
);

export const Empty = observer(() => {
  const {
    spacing: { md },
  } = Theme.useStyledTheme();

  return (
    <Wrapper>
      <SharedComponents.Row
        flex={0}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <EmptyBox />
        <EmptyBox />
        <EmptyBox />
      </SharedComponents.Row>
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Text
        text={"You have no projects to display"}
        type={"h1"}
        textAlign={"center"}
      />
    </Wrapper>
  );
});
