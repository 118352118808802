import { useField } from "formik";
import { useEffect, useMemo, useState } from "react";
import Draggable, { DraggableData } from "react-draggable";

import { Utils, Theme } from "@shared";

import { IClip, ITimelineItem } from "../../../../../../../types";
import { usePlayerContext } from "../../../context";
import * as Lib from "../lib";

const DRAGGABLE_CONFIG: {
  grid: [number, number];
  scale: number;
  axis: "x";
} = {
  grid: [1, 1],
  scale: 1,
  axis: "x",
};

export const Editor = () => {
  const {
    palette: { secondaryDarkBlue },
  } = Theme.useStyledTheme();
  const [selectedClipId, ,] = useField<string | undefined>("selectedClipId");
  const [clips, ,] = useField<Array<IClip>>("clips");
  const [input, , helpers] = useField<Array<ITimelineItem>>("timelines");
  const [leftGripZIndex, setLeftGripZIndex] = useState<1 | 2>(1);
  const [rightGripZIndex, setRightGripZIndex] = useState<1 | 2>(1);
  const {
    timelineWidth,
    hasEditMode,
    seekTo,
    duration,
    id,
    progress,
    gripWidth,
    playing,
  } = usePlayerContext();
  const clientXLeftPlayer = Utils.range(
    0,
    duration,
    0,
    timelineWidth,
    progress
  );

  const [clientXLeft, setClientXLeft] = useState(0);
  const [clientXRight, setClientXRight] = useState(gripWidth * 2);

  const onLeftDrag = (event: any, { x }: DraggableData) => {
    if (leftGripZIndex !== 2) {
      setLeftGripZIndex(2);
    }
    if (x >= 0 && x <= clientXRight - gripWidth * 2 && x !== clientXLeft) {
      setClientXLeft(x);
    }
  };

  const onLeftDragStop = (event: any, { x }: DraggableData) => {
    setLeftGripZIndex(1);
    const currentPlayerProgress = Utils.range(0, timelineWidth, 0, duration, x);
    seekTo(currentPlayerProgress);
    const timelineItemIndex = input.value.findIndex((el) => el.id === id);
    if (timelineItemIndex !== -1) {
      const newTimelineItem = {
        ...input.value[timelineItemIndex],
        progressFrom: currentPlayerProgress,
      };
      helpers.setValue([
        ...input.value.slice(0, timelineItemIndex),
        newTimelineItem,
        ...input.value.slice(timelineItemIndex + 1),
      ]);
    }
  };

  const rightGripBounds = useMemo(
    () => ({
      left: clientXLeft + gripWidth * 2,
      right: timelineWidth,
    }),
    [clientXLeft, gripWidth, timelineWidth]
  );
  const leftGripBounds = useMemo(
    () => ({
      left: 0,
      right: clientXRight - gripWidth * 2,
    }),
    [clientXRight, gripWidth]
  );

  const cursorBounds = useMemo(
    () => ({
      left: clientXLeft,
      right: clientXRight,
    }),
    [clientXLeft, clientXRight]
  );
  const rightGripPosition = useMemo(
    () => ({ x: clientXRight, y: 0 }),
    [clientXRight]
  );

  const leftGripPosition = useMemo(
    () => ({ x: clientXLeft, y: 0 }),
    [clientXLeft]
  );

  const cursorPosition = useMemo(
    () => ({ x: Utils.range(0, duration, 0, timelineWidth, progress), y: 0 }),
    [duration, timelineWidth, progress]
  );

  const onRightDrag = (event: any, { x }: DraggableData) => {
    if (rightGripZIndex !== 2) {
      setRightGripZIndex(2);
    }
    if (x >= clientXLeft + gripWidth * 2 && x <= timelineWidth) {
      setClientXRight(x);
    }
  };

  const onRightDragStop = (event: any, { x }: DraggableData) => {
    setRightGripZIndex(1);
    const currentPlayerProgress = Utils.range(0, timelineWidth, 0, duration, x);
    seekTo(currentPlayerProgress);
    const timelineItemIndex = input.value.findIndex((el) => el.id === id);
    if (timelineItemIndex !== -1) {
      const newTimelineItem = {
        ...input.value[timelineItemIndex],
        progressTo: currentPlayerProgress,
      };
      helpers.setValue([
        ...input.value.slice(0, timelineItemIndex),
        newTimelineItem,
        ...input.value.slice(timelineItemIndex + 1),
      ]);
    }
  };

  const onCursorDrag = (event: any, { x }: DraggableData) => {
    const currentPlayerProgress = Utils.range(0, timelineWidth, 0, duration, x);
    seekTo(currentPlayerProgress);
  };

  useEffect(() => {
    if (hasEditMode) {
      if (!selectedClipId.value) {
        const offsetRight = Utils.range(
          0,
          duration,
          0,
          timelineWidth,
          duration / 10
        );
        const from =
          clientXLeftPlayer + offsetRight > timelineWidth
            ? timelineWidth - offsetRight
            : clientXLeftPlayer;
        const to =
          clientXLeftPlayer + offsetRight > timelineWidth
            ? timelineWidth
            : clientXLeftPlayer + offsetRight;
        setClientXLeft(from);
        setClientXRight(to);
        seekTo(Utils.range(0, timelineWidth, 0, duration, from));

        const progressFrom = Utils.range(0, timelineWidth, 0, duration, from);
        const progressTo = Utils.range(0, timelineWidth, 0, duration, to);

        const timelineItemIndex = input.value.findIndex((el) => el.id === id);

        if (timelineItemIndex !== -1) {
          const newTimelineItem = {
            ...input.value[timelineItemIndex],
            progressFrom,
            progressTo,
          };
          helpers.setValue([
            ...input.value.slice(0, timelineItemIndex),
            newTimelineItem,
            ...input.value.slice(timelineItemIndex + 1),
          ]);
        }
      } else {
        const clip = clips.value.find((c) => c.id === selectedClipId.value);
        if (clip) {
          const from = Utils.range(0, duration, 0, timelineWidth, clip.from);
          const to = Utils.range(0, duration, 0, timelineWidth, clip.to);
          setClientXLeft(from);
          setClientXRight(to);
          seekTo(Utils.range(0, timelineWidth, 0, duration, from));
          const timelineItemIndex = input.value.findIndex((el) => el.id === id);
          if (timelineItemIndex !== -1) {
            const newTimelineItem = {
              ...input.value[timelineItemIndex],
              progressFrom: clip.from,
              progressTo: clip.to,
            };
            helpers.setValue([
              ...input.value.slice(0, timelineItemIndex),
              newTimelineItem,
              ...input.value.slice(timelineItemIndex + 1),
            ]);
          }
        }
      }
    }
  }, [hasEditMode, selectedClipId.value]);

  useEffect(() => {
    const boundaryLeft = Utils.range(
      0,
      timelineWidth,
      0,
      duration,
      clientXLeft
    );
    if (playing) {
      const boundaryRight = Utils.range(
        0,
        timelineWidth,
        0,
        duration,
        clientXRight
      );
      if (progress > boundaryRight || progress < boundaryLeft) {
        seekTo(boundaryLeft);
      }
    }
  }, [playing, clientXRight, progress, clientXLeft]);

  return (
    <>
      <Lib.Cover left={clientXLeft} width={clientXRight - clientXLeft} />
      <Lib.TimelineMarker
        zIndex={leftGripZIndex}
        positionX={clientXLeft}
        progress={Utils.range(0, timelineWidth, 0, duration, clientXLeft)}
      />
      <Draggable
        {...DRAGGABLE_CONFIG}
        handle=".leftGrip"
        position={leftGripPosition}
        bounds={leftGripBounds}
        onDrag={onLeftDrag}
        onStop={onLeftDragStop}
      >
        <Lib.Grip className="leftGrip" width={gripWidth} />
      </Draggable>
      <Lib.TimelineMarker
        zIndex={rightGripZIndex}
        positionX={clientXRight}
        progress={Utils.range(0, timelineWidth, 0, duration, clientXRight)}
      />
      <Draggable
        {...DRAGGABLE_CONFIG}
        handle=".rightGrip"
        position={rightGripPosition}
        bounds={rightGripBounds}
        onDrag={onRightDrag}
        onStop={onRightDragStop}
      >
        <Lib.Grip className="rightGrip" width={gripWidth} />
      </Draggable>
      <Draggable
        {...DRAGGABLE_CONFIG}
        handle=".cursor"
        position={cursorPosition}
        bounds={cursorBounds}
        onDrag={onCursorDrag}
      >
        <Lib.Grip
          className="cursor"
          background={secondaryDarkBlue}
          width={gripWidth}
        />
      </Draggable>
    </>
  );
};
