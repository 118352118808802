import { useField } from "formik";

import { SharedComponents, Theme } from "@shared";

export const FSDSUploadSection = () => {
  const {
    spacing: { lg },
  } = Theme.useStyledTheme();
  const [input] = useField("hasDelayedFSDSUpload");
  return (
    <>
      <SharedComponents.FormikLib.Checkbox
        subtitle="If your video contains upstream initiatives, or other content, in which the blurred video may not be immediately shared in FSDS, check here"
        title="Consumer research videos are automatically shared in FSDS upon upload to the Video Analytics Platform."
        field={"hasDelayedFSDSUpload"}
      />
      <SharedComponents.VerticalBox height={lg} />
      {input.value && (
        <SharedComponents.FormikLib.Calendar
          title="What date may the blurred video associated with this project appear in FSDS?"
          field="FSDSUploadDate"
        />
      )}
    </>
  );
};
