import styled from "styled-components";

import { IRow } from "../types";

export const Row = styled.div<IRow>`
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent = "space-between" }: IRow) =>
    justifyContent};
  align-items: center;
  padding: 16px 0;
`;
