import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  files: Yup.array(),
  customTag: Yup.string(),
  customTags: Yup.array(),
});

export const initialValues = {
  files: [],
  customTag: "",
  customTags: [],
};
