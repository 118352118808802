import { FC, useRef, useEffect } from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  createMasonryCellPositioner,
  Masonry,
  MasonryCellProps,
  Size,
} from "react-virtualized";

import { SharedComponents, Utils } from "@shared";

import * as FGLib from "./lib";
import { IFileGrid } from "./types";
import { useWindowSize } from "../../hooks";
import { observer } from "mobx-react";

const SPACER = 24;
const COLUMN_COUNT = 2;
const COLUMN_WIDTH = window.innerWidth / 2 - SPACER - 96;
const cache = new CellMeasurerCache({
  defaultHeight: 48,
  defaultWidth: COLUMN_WIDTH,
});
const cellPositioner = createMasonryCellPositioner({
  cellMeasurerCache: cache,
  columnCount: COLUMN_COUNT,
  columnWidth: COLUMN_WIDTH,
  spacer: SPACER,
});

export const FileGrid: FC<IFileGrid> = observer(
  ({ input, projectId, role }) => {
    const projects = Utils.useLoadingStatus("projects");
    const userProjects = Utils.useLoadingStatus("userProjects");
    const ref = useRef<Masonry>(null);
    const { width } = useWindowSize();
    const cellRenderer = ({ index, key, parent, style }: MasonryCellProps) => {
      const item = input[index];
      const wrapperStyle = {
        ...style,
        paddingBottom: "8px",
        background: "transparent",
      };
      if (!item) {
        return <></>;
      }
      return (
        <div style={wrapperStyle} key={key}>
          <CellMeasurer cache={cache} index={index} parent={parent}>
            {/*<FGLib.Cell>*/}
            {/*  /!*<FGLib.FileItem item={item} projectId={projectId} role={role} />*!/*/}
            {/*</FGLib.Cell>*/}
          </CellMeasurer>
        </div>
      );
    };

    const onResize = (_: Size) => {
      cellPositioner.reset({
        columnCount: COLUMN_COUNT,
        columnWidth: COLUMN_WIDTH,
        spacer: SPACER,
      });
      cache.clearAll();
      ref.current?.clearCellPositions();
    };

    useEffect(() => {
      cellPositioner.reset({
        columnCount: COLUMN_COUNT,
        columnWidth: COLUMN_WIDTH,
        spacer: SPACER,
      });
      cache.clearAll();
      ref.current?.clearCellPositions();
    }, [width]);

    if (projects.hasLoading || userProjects.hasLoading) {
      return (
        <FGLib.Wrapper>
          <SharedComponents.LoadingIndicator />
        </FGLib.Wrapper>
      );
    }

    return (
      <FGLib.Wrapper>
        <AutoSizer onResize={onResize}>
          {({ width, height }) => {
            return (
              <Masonry
                autoHeight={false}
                ref={ref}
                height={height}
                width={width}
                cellCount={input.length}
                cellMeasurerCache={cache}
                cellPositioner={cellPositioner}
                cellRenderer={cellRenderer}
              />
            );
          }}
        </AutoSizer>
      </FGLib.Wrapper>
    );
  }
);
