import { Interweave } from "interweave";
import { useNavigate } from "react-router-dom";
import { computed } from "mobx";
import { observer } from "mobx-react";

import { SharedComponents, Types as SharedTypes } from "@shared";
import { Types } from "@videoAnalytics";

import { transformHTMLContent } from "../../utils";
import { InterweaveTitleWrapper } from "../interweave_wrapper";
import { Hover } from "../hover";

interface IProjects {
  data: Array<Types.ISearchItem>;
}

export const Projects = ({ data }: IProjects) => {
  return (
    <>
      <SharedComponents.Box height={"fit-content"} padding={"0 24px"}>
        <SharedComponents.VerticalBox height={32} />
        <SharedComponents.Text type={"h2"} text={"Projects"} />
        <SharedComponents.VerticalBox height={16} />
      </SharedComponents.Box>
      <SharedComponents.Separator />
      {data.map((matchOutput: Types.ISearchItem, i) => (
        <ProjectItem item={matchOutput} key={i} />
      ))}
    </>
  );
};

interface IProjectItem {
  item: Types.ISearchItem;
}

export const ProjectItem = observer(({ item }: IProjectItem) => {
  const navigate = useNavigate();

  const name = computed(() => {
    const matchedNameIndex = item.results.findIndex((el) =>
      el.key.toLowerCase().includes("name")
    );
    if (matchedNameIndex !== -1) {
      const output = JSON.parse(
        item.results[matchedNameIndex].value[0].value
      ) as Record<"text", string>;
      return output.text;
    }
    return item.name;
  }).get();

  return (
    <Hover>
      <SharedComponents.Row
        onClick={() =>
          navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${item.id}`)
        }
        flex={0}
        height={"40px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0 24px"}
        margin={"24px 0 0"}
      >
        <SharedComponents.Row flex={1} height={"48px"} alignItems={"center"}>
          <SharedComponents.Icons.IconFile
            width={11.82}
            height={15.36}
            fill={"#808B9F"}
          />
          <SharedComponents.HorizontalBox width={16} />
          <InterweaveTitleWrapper>
            <Interweave transform={transformHTMLContent} content={name} />
          </InterweaveTitleWrapper>
        </SharedComponents.Row>
        <SharedComponents.Icons.IconArrowRight
          width={12}
          height={10.5}
          fill={"#808B9F"}
        />
      </SharedComponents.Row>
    </Hover>
  );
});
