import * as React from "react";

export function IconDocument(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.25 10.5a.75.75 0 100 1.5h7.5a.75.75 0 100-1.5h-7.5zm-.75 3.75a.75.75 0 01.75-.75h7.5a.75.75 0 110 1.5h-7.5a.75.75 0 01-.75-.75zm0 3a.75.75 0 01.75-.75h3a.75.75 0 110 1.5h-3a.75.75 0 01-.75-.75z"
        fill="#000"
      />
      <path
        d="M11.25 0H3a3 3 0 00-3 3v18a3 3 0 003 3h12a3 3 0 003-3V6.75L11.25 0zm0 1.5v3a2.25 2.25 0 002.25 2.25h3V21a1.5 1.5 0 01-1.5 1.5H3A1.5 1.5 0 011.5 21V3A1.5 1.5 0 013 1.5h8.25z"
        fill="#000"
      />
    </svg>
  );
}
