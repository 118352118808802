import { SVGProps } from "react";

export const IconContactUs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 3a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Zm3-1.5A1.5 1.5 0 0 0 1.5 3v.325l10.5 6.3 10.5-6.3V3A1.5 1.5 0 0 0 21 1.5H3Zm19.5 3.574-7.062 4.238 7.062 4.346V5.074Zm-.051 10.314-8.46-5.206L12 11.374l-1.989-1.192-8.46 5.205A1.5 1.5 0 0 0 3 16.5h18a1.5 1.5 0 0 0 1.449-1.111ZM1.5 13.659l7.062-4.346L1.5 5.075v8.583Z"
      fill="#fff"
    />
  </svg>
);
