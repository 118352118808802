import styled from "styled-components";

import { SharedComponents, Theme } from "@shared";
import { observer } from "mobx-react";

interface ILabel {
  text: string;
}

const LabelWrapper = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.palette.primaryBlue}`};
`;

export const Label = observer(({ text }: ILabel) => {
  const {
    palette: { primaryBlue },
  } = Theme.useStyledTheme();
  return (
    <LabelWrapper>
      <SharedComponents.Text
        text={text}
        type={"xSmallSemiBold"}
        color={primaryBlue}
      />
    </LabelWrapper>
  );
});
