import styled from "styled-components";
import { TabList } from "@material-ui/lab";

export const HeadersWrapper = styled(TabList)`
  padding-top: ${({ theme }) => theme.spacing.lg}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primaryBlue};
  .MuiTabs-indicator {
    background: transparent;
  }
`;
