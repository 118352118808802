import { computed } from "mobx";
import { observer } from "mobx-react";

import { SharedComponents, Theme, Types } from "@shared";

interface IVideoSummary {
  videoDetails: Types.IVideoDetails;
}

export const VideoSummary = observer(({ videoDetails }: IVideoSummary) => {
  const {
    spacing: { lg },
    palette: { gray },
  } = Theme.useStyledTheme();

  const projectStats = computed(() => videoDetails.stats).get();

  return (
    <SharedComponents.Summary.Row justifyContent={"flex-start"}>
      <SharedComponents.Summary.InfoColumn
        width="15"
        title="Total videos duration"
        value={projectStats?.videoLength || 0}
        Icon={SharedComponents.Summary.IconVideo}
      />
      <SharedComponents.HorizontalBox width={lg} />
      <SharedComponents.Summary.Border borderColor={gray} />
      <SharedComponents.HorizontalBox width={lg} />
      <SharedComponents.Summary.InfoColumn
        width="24"
        title="Total numbers of words"
        value={projectStats?.totalWordsNumber || 0}
        Icon={SharedComponents.Summary.IconWords}
      />
      <SharedComponents.HorizontalBox width={lg} />
      <SharedComponents.Summary.Border borderColor={gray} />
    </SharedComponents.Summary.Row>
  );
});
