import styled from "styled-components";

export const ULWrapper = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;

  ul {
    list-style: circle;
    padding: ${({ theme }) => `0 ${theme.spacing.xl}px`};
  }

  b {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }
`;
