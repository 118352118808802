import styled from "styled-components";

export const Input = styled.input`
  padding: ${({ theme }) => `0 ${theme.spacing.md}px`};
  height: 44px;
  width: 100%;
  border-radius: ${({ theme }) => `${theme.spacing.sm}px`};
  border: ${({ theme }) => `1px solid ${theme.palette.primaryBlue}`};
  background: transparent;
`;
