import styled from "styled-components";
import { Link } from "react-router-dom";

import { SharedComponents, Types } from "@shared";

import { UserInfo } from "./topbar.userInfo";

const TopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-box-shadow: 0px 8px 24px 0px rgba(128, 139, 159, 0.2);
  -moz-box-shadow: 0px 8px 24px 0px rgba(128, 139, 159, 0.2);
  box-shadow: 0px 8px 24px 0px rgba(128, 139, 159, 0.2);
`;

export const TopBar = () => {
  return (
    <TopBarWrapper>
      <SharedComponents.Row
        alignItems={"flex-start"}
        cursor={"pointer"}
        padding={"0 0 0 24px"}
        height={"fit-content"}
        background={"white"}
      >
        <Link to={Types.PATHS.INITIAL}>
          <SharedComponents.Icons.IconLogo height={"56px"} />
        </Link>
      </SharedComponents.Row>
      <UserInfo />
      <SharedComponents.HorizontalBox width={32} />
    </TopBarWrapper>
  );
};
