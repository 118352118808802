import { FC, memo } from "react";
import { Field } from "formik";

import { Utils } from "@shared";

import { useStyledTheme } from "../../../../theme";
import { Column, Row } from "../../../containers";
import { VerticalBox } from "../../../vertical_box";
import { HorizontalBox } from "../../../horizontal_box";
import { Text } from "../../../text";
import { IFormikRadio } from "./types";

export const Radio: FC<IFormikRadio> = memo(
  ({ title = "", field, value, subtitle = "", onClick }) => {
    const {
      spacing: { sm },
    } = useStyledTheme();

    return (
      <Column flex={0}>
        <Text type={"h3SemiBold"} text={title} />
        <VerticalBox height={sm} />
        <Row alignItems={"center"}>
          <Field
            onKeyDown={Utils.onKeyDown(onClick)}
            onClick={onClick}
            type={"radio"}
            checked={value}
          />
          <HorizontalBox width={sm} />
          <Text
            whiteSpace={"nowrap"}
            type={value ? "h3SemiBold" : "h3"}
            text={subtitle}
          />
        </Row>
      </Column>
    );
  }
);
