import styled from "styled-components";

import { ITSRow } from "../types";
import { ROW_COLLAPSED_HEIGHT, ROW_EXPANDED_HEIGHT } from "../constants";

export const Row = styled.div<ITSRow>`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  max-height: ${({ isExpanded }: ITSRow) =>
    isExpanded ? ROW_EXPANDED_HEIGHT : ROW_COLLAPSED_HEIGHT};
  transition: 0.15s linear;
  overflow-y: ${({ isExpanded }: ITSRow) =>
    isExpanded ? "scroll" : "hidden"}; ;
`;
