import { useCallback, useMemo, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import ReactPlayer from "react-player";
import { observer } from "mobx-react";
import { useField } from "formik";

import { SharedComponents, Theme, Types as SharedTypes } from "@shared";

import { IClip } from "../../../../types";
import { useCreateClipsContext } from "../../context";
import { PlayerContext } from "./context";

import * as Lib from "./lib";

interface IListItem {
  item: SharedTypes.IProjectVideo;
  index: number;
}

const REACT_PLAYER_CONFIG = {
  file: {
    attributes: {
      crossorigin: "anonymous",
    },
  },
};

const GRIP_WIDTH = 5;

export const ListItem = observer(({ item, index }: IListItem) => {
  const {
    spacing: { sm },
  } = Theme.useStyledTheme();
  const { listItemHeight } = useCreateClipsContext();

  const [clips, , clipsHelpers] = useField<Array<IClip>>("clips");
  /**
   * ReactPlayer reference
   */
  const playerRef = useRef<ReactPlayer>(null);
  /**
   * Describes react player state
   */
  const [playing, setPlaying] = useState<boolean>(false);
  /**
   * Callback shows pause on controls when video is ended
   * onEnded callback is triggered a little bit earlier
   * that's why setTimeout was added
   */
  const onEnded = useCallback(() => {
    setTimeout(() => {
      setPlaying(false);
    }, 0);
  }, [setPlaying]);

  const onHandlePlaying = useCallback(() => {
    setPlaying((prevState) => !prevState);
  }, [setPlaying]);
  /**
   * Field to describe if user select clip to edit
   */
  const [, , selectedClipIdHelpers] = useField<string | undefined>(
    "selectedClipId"
  );
  /**
   * Describes duration of video clip
   */
  const [duration, setDuration] = useState<number>(0);
  /**
   * Describes edit mode for clip creation
   */
  const [hasEditMode, setEditMode] = useState<boolean>(false);
  /**
   * Describes edit mode for clip creation
   */
  const [timelineWidth, setTimelineWidth] = useState<number>(0);

  /**
   * Video player's progress in seconds
   */
  const [progress, setProgress] = useState<number>(0);
  /**
   * Handler for saving duration of current video
   */
  const onDuration = useCallback(
    (duration: number) => setDuration(parseFloat(duration.toFixed(2))),
    [setDuration]
  );
  /**
   * Helper for moving video to exact frame
   */
  const seekTo = useCallback(
    (amount: number) => {
      if (playerRef.current) {
        playerRef.current?.seekTo(amount, "seconds");
      }
    },
    [playerRef.current]
  );

  const onProgress = useCallback(
    (state) => {
      setProgress(state.playedSeconds);
    },
    [setProgress]
  );

  const fClips = useMemo(
    () => clips.value.filter((file) => file.fileId === item.id),
    [clips.value, item]
  );

  const contextValue = useMemo(
    () => ({
      index,
      id: item.id,
      seekTo,
      duration,
      progress,
      hasEditMode,
      setEditMode,
      timelineWidth,
      setTimelineWidth,
      playing,
      gripWidth: GRIP_WIDTH,
    }),
    [
      index,
      item,
      seekTo,
      duration,
      progress,
      hasEditMode,
      setEditMode,
      timelineWidth,
      setTimelineWidth,
      playing,
    ]
  );

  return (
    <SharedComponents.Box width={"100%"} height={`${listItemHeight}px`}>
      <PlayerContext.Provider value={contextValue}>
        <SharedComponents.Row flex={0} height={"100%"} padding={"16px 0"}>
          <SharedComponents.Column flex={2}>
            <SharedComponents.Column flex={2} background={"black"}>
              <ReactPlayer
                className="react-player"
                progressInterval={250}
                onDuration={onDuration}
                progress={progress}
                onProgress={onProgress}
                ref={playerRef}
                width={"100%"}
                url={item.processedPath}
                config={REACT_PLAYER_CONFIG}
                playing={playing}
                onEnded={onEnded}
              />
              <Lib.Controls isPlaying={playing} onClick={onHandlePlaying} />
            </SharedComponents.Column>
            <SharedComponents.Column flex={1} style={{ zIndex: 1000 }}>
              <Lib.Timeline />
              <Lib.TimelineActionButton playerRef={playerRef} item={item} />
            </SharedComponents.Column>
          </SharedComponents.Column>
          <SharedComponents.HorizontalBox width={sm} />
          <SharedComponents.Column
            flex={1.25}
            height={`100%`}
            style={{ overflowY: "scroll" }}
          >
            {fClips.map((c) => (
              <SharedComponents.Row
                flex={0}
                height={"100px"}
                key={c.id}
                margin={"0 0 8px 0"}
              >
                <SharedComponents.Column flex={1.5}>
                  <img src={c.dataUri} width={"100%"} height={"100%"} alt="" />
                </SharedComponents.Column>
                <SharedComponents.HorizontalBox width={sm} />
                <SharedComponents.Column flex={1.5}>
                  <SharedComponents.Text type="button" text={c.clipName} />
                </SharedComponents.Column>
                <SharedComponents.Row flex={0}>
                  <IconButton
                    onClick={() => {
                      selectedClipIdHelpers.setValue(c.id);
                      setEditMode(true);
                    }}
                  >
                    <SharedComponents.Icons.IconEdit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      clipsHelpers.setValue(
                        clips.value.slice().filter((clip) => clip.id !== c.id)
                      );
                    }}
                  >
                    <SharedComponents.Icons.IconDelete />
                  </IconButton>
                </SharedComponents.Row>
              </SharedComponents.Row>
            ))}
          </SharedComponents.Column>
        </SharedComponents.Row>
      </PlayerContext.Provider>
      <SharedComponents.Separator />
    </SharedComponents.Box>
  );
});
