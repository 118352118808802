import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";

import { SharedComponents, Theme, Types } from "@shared";

import { Field } from "./field";
// import { validationSchema, initialValues } from "./formik_data";

export interface IAdvancedFilters {
  data: Array<Types.IAdvancedFiltersItem>;
  searchField: string;
  hasFilters?: boolean;
  isElastic?: boolean;
}

export const AdvancedFilters = observer(
  ({
    data,
    searchField,
    hasFilters = true,
    isElastic = false,
  }: IAdvancedFilters) => {
    const {
      spacing: { md, lg },
    } = Theme.useStyledTheme();

    const [shouldBeExpanded, setExpanded] = useState(false);
    const [isVisible, setVisible] = useState(false);

    const onSwitchClick = useCallback(
      () => setExpanded((prev) => !prev),
      [setExpanded]
    );

    const renderItem = useCallback(
      ({ name, data, field }: Types.IAdvancedFiltersItem) => (
        <SharedComponents.FormikLib.TagsList
          key={name}
          data={data}
          title={name}
          field={field}
          isElastic={isElastic}
          isCreation={false}
        />
      ),
      [isElastic]
    );

    useEffect(() => {
      if (shouldBeExpanded) {
        setTimeout(() => setVisible(true), 40);
      } else if (!shouldBeExpanded && isVisible) {
        setTimeout(() => setVisible(false), 40);
      }
    }, [shouldBeExpanded, isVisible]);

    return (
      <>
        <SharedComponents.Row height={"40px"} alignItems={"center"}>
          <SharedComponents.Row>
            <Field field={searchField} placeholder="Search..." />
          </SharedComponents.Row>
          <SharedComponents.HorizontalBox width={lg} />
          <SharedComponents.Row
            flex={0}
            width={"fit-content"}
            alignItems={"center"}
            height="40px"
          >
            {hasFilters && (
              <SharedComponents.Row alignItems={"center"}>
                <SharedComponents.Text
                  whiteSpace={"nowrap"}
                  text="Advanced filters"
                  type="small"
                />
                <SharedComponents.Switch
                  color="primary"
                  onClick={onSwitchClick}
                />
              </SharedComponents.Row>
            )}
          </SharedComponents.Row>
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={md} />
        {isVisible && (
          <>
            <SharedComponents.Row height={"400px"}>
              {data.map(renderItem)}
            </SharedComponents.Row>
            <SharedComponents.VerticalBox height={md} />
          </>
        )}
      </>
    );
  }
);
