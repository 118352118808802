import { observer } from "mobx-react";
import { computed } from "mobx";

import { SharedComponents, Theme } from "@shared";
import { Types } from "@videoAnalytics";

import { DownloadButton } from "../download_button";
import { IDownloadItem } from "../download_button/types";

interface IVideoSummary {
  videoDetails?: Types.IVideoDetails;
}

export const VideoSummary = observer(({ videoDetails }: IVideoSummary) => {
  const {
    palette: { gray },
    spacing: { lg },
  } = Theme.useStyledTheme();

  const downloadData = computed<Array<IDownloadItem>>(() => {
    const output: Array<IDownloadItem> = [];

    if (videoDetails?.downloadPath) {
      output.push({
        name: "Blurred video",
        path: videoDetails.downloadPath,
      });
    }

    if (
      videoDetails &&
      videoDetails?.outputFiles.length > 0 &&
      videoDetails?.outputFiles[0].processedPath
    ) {
      output.push({
        name: "Transcripts",
        path: videoDetails?.outputFiles[0].processedPath,
      });
    }

    return output;
  }).get();

  return (
    <SharedComponents.Row alignItems={"center"}>
      <SharedComponents.SummaryTile
        Icon={SharedComponents.Icons.IconDuration}
        iconHeight={"30px"}
        iconWidth={"30px"}
        name={"Total videos duration"}
        justifyContent={"flex-start"}
        value={videoDetails?.stats.videoLength || ""}
        flex={1}
      />
      <SharedComponents.HorizontalBox width={lg} />
      <SharedComponents.Box width={"1px"} height={"40px"} background={gray} />
      <SharedComponents.SummaryTile
        Icon={SharedComponents.Icons.IconWords}
        iconHeight={"30px"}
        iconWidth={"30px"}
        name={"Total number of words"}
        value={videoDetails?.stats.totalWordsNumber || "0"}
        flex={1.5}
      />
      <SharedComponents.Column flex={3} alignItems={"flex-end"}>
        <DownloadButton data={downloadData} />
      </SharedComponents.Column>
    </SharedComponents.Row>
  );
});
