import styled from "styled-components";
import { Interweave } from "interweave";
import { useNavigate } from "react-router-dom";
import { computed } from "mobx";
import { useMemo } from "react";

import { SharedComponents, Types as SharedTypes, Utils } from "@shared";
import { Types } from "@videoAnalytics";

import { transformHTMLContent } from "../../utils";
import { Hover } from "../hover";
import { InterweaveWrapper } from "../interweave_wrapper";

interface IVideos {
  data: Array<Types.ISearchItem>;
}

const renderItem = (matchOutput: Types.ISearchItem) => (
  <VideoItem key={matchOutput.id} item={matchOutput} />
);

export const Videos = ({ data }: IVideos) => {
  return (
    <>
      <SharedComponents.Box height={"fit-content"} padding={"0 24px"}>
        <SharedComponents.VerticalBox height={32} />
        <SharedComponents.Text type={"h2"} text={"Videos"} />
        <SharedComponents.VerticalBox height={16} />
      </SharedComponents.Box>
      <SharedComponents.Separator />
      {data.map(renderItem)}
    </>
  );
};

interface IVideoItem {
  item: Types.ISearchItem;
}

interface ITranscriptItemOutput {
  startTime: string;
  endTime: string;
  text: string;
}

export const VideoItem = ({ item }: IVideoItem) => {
  const navigate = useNavigate();

  const transcripts: Array<ITranscriptItemOutput> | undefined = useMemo(() => {
    const results = item.results.find((el) => el?.key === "TRANSCRIPT");
    if (results) {
      return results.value.map((el: { value: string; matchType: string }) =>
        JSON.parse(el.value)
      ) as Array<ITranscriptItemOutput>;
    }
    return results;
  }, []);

  const name = computed(() => {
    const matchedNameIndex = item.results.findIndex((el) =>
      el.key.toLowerCase().includes("name")
    );
    if (matchedNameIndex !== -1) {
      const output = JSON.parse(
        item.results[matchedNameIndex].value[0].value
      ) as Record<"text", string>;
      return output.text;
    }
    return item.name;
  }).get();

  return (
    <SharedComponents.Box background={"inherit"} height={"fit-content"}>
      <Hover>
        <SharedComponents.Row
          onClick={() =>
            navigate(
              `/video-analytics/project/${item.projectId}/video/${item.id}`
            )
          }
          flex={0}
          height={"40px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={"0 24px"}
          margin={"24px 0 0"}
        >
          <SharedComponents.Row
            width={"100%"}
            height={"40px"}
            flex={1}
            alignItems={"center"}
          >
            <div>
              <SharedComponents.Icons.IconVideoUpload
                width={24.53}
                height={15.33}
                fill={"#808B9F"}
              />
            </div>
            <SharedComponents.HorizontalBox width={16} />
            <SharedComponents.Row alignItems={"center"}>
              <InterweaveWrapper>
                <Interweave transform={transformHTMLContent} content={name} />
              </InterweaveWrapper>
            </SharedComponents.Row>
          </SharedComponents.Row>
          <SharedComponents.Icons.IconArrowRight
            width={12}
            height={10.5}
            fill={"#808B9F"}
          />
        </SharedComponents.Row>
      </Hover>
      {transcripts && <SharedComponents.VerticalBox height={24} />}
      <SharedComponents.Box padding={"0 24px"}>
        {transcripts &&
          transcripts.map((t, i) => (
            <TranscriptItem
              videoId={item.id}
              projectId={item.projectId}
              key={i}
              item={t}
            />
          ))}
      </SharedComponents.Box>
    </SharedComponents.Box>
  );
};

const TranscriptItemWrapper = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  flex: 0;
  border-left: 1px solid #808b9f;
  padding-left: 24px;
  padding-bottom: 24px;
`;

interface ITranscriptItem {
  item: ITranscriptItemOutput;
  projectId: string;
  videoId: string;
}

const TranscriptItem = ({ item, projectId, videoId }: ITranscriptItem) => {
  const navigate = useNavigate();
  return (
    <Hover>
      <TranscriptItemWrapper
        onClick={() =>
          navigate(
            `${
              SharedTypes.PATHS.VIDEO_ANALYTICS
            }/project/${projectId}/video/${videoId}/${parseInt(
              item.startTime
            ).toString()}`
          )
        }
      >
        <SharedComponents.Text
          text={`${Utils.formatTimeShort(
            parseFloat(item?.startTime)
          )} - ${Utils.formatTimeShort(parseFloat(item?.endTime))}`}
          type={"small"}
          whiteSpace={"nowrap"}
        />
        <SharedComponents.HorizontalBox width={16} />
        <SharedComponents.Column>
          <InterweaveWrapper>
            <Interweave transform={transformHTMLContent} content={item?.text} />
          </InterweaveWrapper>
        </SharedComponents.Column>
        <SharedComponents.HorizontalBox width={16} />
        <div>
          <SharedComponents.Icons.IconArrowRight
            width={12}
            height={10.5}
            fill={"#808B9F"}
          />
        </div>
      </TranscriptItemWrapper>
    </Hover>
  );
};
