import { FC, useCallback } from "react";
import { useField, useFormikContext } from "formik";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import {
  SharedComponents,
  Pages,
  Types,
  Utils as SharedUtils,
  Types as SharedTypes,
} from "@shared";
import { VideoAnalyticsStore } from "@videoAnalytics";

import * as Utils from "../../utils";
import { IClip } from "../../types";

import * as PCComponents from "./components";
import { IChangeClipsOrder } from "./types";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

const renderItem =
  (onDelete: (idx: number) => void) => (item: IClip, index: number) =>
    (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <PCComponents.ItemWrapper
            isDragging={snapshot.isDragging}
            draggableStyle={provided.draggableProps.style}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <PCComponents.Image src={item.dataUri} />
            <SharedComponents.HorizontalBox width={16} />
            <SharedComponents.Column justifyContent={"center"}>
              <SharedComponents.Text text={item.clipName} type={"button"} />
              <SharedComponents.VerticalBox height={6} />
              <SharedComponents.Text text={item.videoName} type={"xSmall"} />
              <SharedComponents.VerticalBox height={8} />
              <SharedComponents.FlexContainer padding={0}>
                <SharedComponents.Text text={"From:"} type={"xSmallMedium"} />
                <SharedComponents.HorizontalBox width={8} />
                <SharedComponents.Text
                  text={Utils.formatTime(item.from)}
                  type={"xSmall"}
                />
                <SharedComponents.HorizontalBox width={16} />
                <SharedComponents.Text text={"To:"} type={"xSmallMedium"} />
                <SharedComponents.HorizontalBox width={8} />
                <SharedComponents.Text
                  text={Utils.formatTime(item.to)}
                  type={"xSmall"}
                />
              </SharedComponents.FlexContainer>
            </SharedComponents.Column>
            <SharedComponents.Column
              flex={0.2}
              padding={"20px 0"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              <SharedComponents.Box
                cursor={"pointer"}
                width={"fit-content"}
                onClick={() => onDelete(index)}
              >
                <SharedComponents.Icons.IconDelete width={13} height={15} />
              </SharedComponents.Box>
              <SharedComponents.Icons.IconDrag width={8} height={13} />
            </SharedComponents.Column>
          </PCComponents.ItemWrapper>
        )}
      </Draggable>
    );

export const ChangeClipsOrder: FC<IChangeClipsOrder> = observer(
  ({ projectId }) => {
    const navigate = useNavigate();
    const {
      data: { successes },
    } = VideoAnalyticsStore;
    const hasSuccess = SharedUtils.useQueryStatus(
      successes,
      Types.Endpoints.CREATE_CLIPS_VIDEO
    );
    const { handleSubmit } = useFormikContext();
    const [input, , helpers] = useField<Array<IClip>>("clips");
    const { setCurrentStep, currentStep } =
      SharedComponents.useStepperContext();
    const onPrev = useCallback(
      () => setCurrentStep(currentStep - 1),
      [setCurrentStep, currentStep]
    );
    const onNext = useCallback(() => handleSubmit(), [setCurrentStep]);
    const onDelete = useCallback(
      (index: number) => {
        helpers.setValue([
          ...input.value.filter((_, idx: number) => idx !== index),
        ]);
      },
      [helpers, input]
    );
    const onSuccess = useCallback(async () => {
      navigate(`${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}`);
    }, [navigate]);
    const onDragEnd = useCallback(
      (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        const newValue = [...input.value];
        const item = newValue.splice(result.source.index, 1)[0];
        newValue.splice(result.destination.index, 0, item);
        helpers.setValue(newValue);
      },
      [helpers, input]
    );
    return (
      <>
        <SharedComponents.Modal
          Icon={SharedComponents.Icons.IconSuccess}
          type={Types.ModalTypes.SUCCESS}
          isOpen={hasSuccess}
          onConfirm={onSuccess}
          confirmText={"GO TO PROJECT PAGE"}
          title={"The summary has been created"}
        />
        <Pages.AddProjectStepTemplate
          onPrev={onPrev}
          onNext={onNext}
          fields={[]}
        >
          <SharedComponents.Box>
            <SharedComponents.Text
              text={
                "This is a preview of all your clips. You can change the order of clips by dragging the videos’ thumbnails."
              }
              type={"h3"}
            />
            <SharedComponents.VerticalBox height={12} />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {input.value.map(renderItem(onDelete))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </SharedComponents.Box>
        </Pages.AddProjectStepTemplate>
      </>
    );
  }
);
