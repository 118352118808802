import styled from "styled-components";
import { Dialog as D } from "@material-ui/core";

export const Dialog = styled(D)`
  .MuiPaper-root {
    width: fit-content;
    height: fit-content;
    padding: 32px;
    display: flex;
    flex-direction: column;
  }
`;
