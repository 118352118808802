import styled from "styled-components";

import { ITSInputField } from "../types";
import { INPUT_HEIGHT, INPUT_WIDTH } from "../constants";

export const InputField = styled.input<ITSInputField>`
  width: ${INPUT_WIDTH};
  height: ${INPUT_HEIGHT};
  border: ${({ borderColor }: ITSInputField) => `1px solid ${borderColor}`};
  border-radius: ${({ borderRadius }: ITSInputField) => `${borderRadius}px`};
  padding: ${({ paddingHorizontal }: ITSInputField) =>
    `0 ${paddingHorizontal}px`};
  background: transparent;
`;
