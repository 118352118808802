import { Dispatch, FC, SetStateAction } from "react";

import * as TagOptionsLib from "./lib";
import { IOptions } from "./types";

const renderOption =
  (
    setOptionsVisibility: Dispatch<SetStateAction<boolean>>,
    onSelect: (text: string) => void
  ) =>
  (item: string) =>
    (
      <TagOptionsLib.ListItem
        key={item}
        title={item}
        setOptionsVisibility={setOptionsVisibility}
        onSelect={onSelect}
      />
    );

export const TagOptions: FC<IOptions> = ({
  data,
  setOptionsVisibility,
  onSelect,
  hasError,
}) => {
  return (
    <TagOptionsLib.OptionsWrapper hasError={hasError}>
      {data.map(renderOption(setOptionsVisibility, onSelect))}
    </TagOptionsLib.OptionsWrapper>
  );
};
