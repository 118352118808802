import { computed } from "mobx";

import { SharedStore, Types } from "@shared";

interface ILoadingStatus {
  hasError: boolean;
  hasLoading: boolean;
  hasSuccess: boolean;
}

export const useLoadingStatus = (endpoint: Types.IEndpoint): ILoadingStatus => {
  const {
    data: { loadings, errors, successes },
  } = SharedStore;
  const hasLoading = computed(() => loadings.get(endpoint)).get() || false;
  const hasError = computed(() => errors.get(endpoint)).get() || false;
  const hasSuccess = computed(() => successes.get(endpoint)).get() || false;
  return { hasError, hasLoading, hasSuccess };
};
