import { SVGProps } from "react";

export const IconImageRecognitionActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.003 3.5a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v15a3 3 0 0 1-3 3h-18a3 3 0 0 1-3-3v-15Zm1.5 13.5v1.5a1.5 1.5 0 0 0 1.5 1.5h18a1.5 1.5 0 0 0 1.5-1.5v-5.25l-5.666-2.92a.75.75 0 0 0-.865.139l-5.565 5.565-3.99-2.658a.75.75 0 0 0-.945.093L1.503 17Zm7.5-9.75a2.25 2.25 0 1 0-4.5 0 2.25 2.25 0 0 0 4.5 0Z"
      fill="#fff"
    />
  </svg>
);
