import { SVGProps } from "react";

export const IconVap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.5a3 3 0 0 1 3-3h11.25a3 3 0 0 1 2.974 2.607l4.666-2.073A1.5 1.5 0 0 1 24 6.404v11.192a1.5 1.5 0 0 1-2.109 1.37l-4.666-2.073A3 3 0 0 1 14.25 19.5H3a3 3 0 0 1-3-3v-9Zm17.25 7.762 5.25 2.334V6.404l-5.25 2.334v6.524ZM3 6a1.5 1.5 0 0 0-1.5 1.5v9A1.5 1.5 0 0 0 3 18h11.25a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5H3Z"
      fill="#fff"
    />
  </svg>
);
