import { FC } from "react";
import styled from "styled-components";

interface IHorizontalBox {
  width: number;
}

const Box = styled.div<IHorizontalBox>`
  width: ${({ width }) => `${width}px`};
`;

export const HorizontalBox: FC<IHorizontalBox> = ({ width }) => {
  return <Box width={width} />;
};
