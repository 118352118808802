import { useEffect } from "react";
import { observer } from "mobx-react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { SharedComponents, Types, SharedStore, ROUTES } from "@shared";
import { CommonPages } from "@modules";

import "./shared/css/toastify.css";

const renderRoute = ({ path, element }: Types.IRoute) => (
  <Route key={path} path={path} element={element} />
);

const App = () => {
  const {
    initPlatform,
    data: { hasInitialLoading, hasInitialError },
  } = SharedStore;

  useEffect(() => {
    initPlatform();
  }, []);

  if (hasInitialLoading) {
    return (
      <SharedComponents.MainContainer>
        <CommonPages.Landing />
        <ToastContainer limit={3} autoClose={3000} />
      </SharedComponents.MainContainer>
    );
  }

  if (hasInitialError) {
    return (
      <SharedComponents.MainContainer>
        <SharedComponents.ErrorIndicator
          title={"Error while getting user data"}
          onReload={initPlatform}
        />
        <ToastContainer limit={3} autoClose={3000} />
      </SharedComponents.MainContainer>
    );
  }

  return (
    <SharedComponents.MainContainer>
      <Router>
        <SharedComponents.Frame>
          <>
            <Routes>
              {ROUTES.map(renderRoute)}
              <Route path={"*"} element={<CommonPages.NotFound />} />
            </Routes>
          </>
        </SharedComponents.Frame>
      </Router>
      <ToastContainer limit={3} autoClose={3000} />
    </SharedComponents.MainContainer>
  );
};
export default observer(App);
