import { FC } from "react";
import { SharedComponents, Theme } from "@shared";
import * as SLLib from "./lib";
import { ISearchList } from "./types";

export const SearchList: FC<ISearchList> = ({ height, itemHeight, data }) => {
  const {
    spacing: { md },
  } = Theme.useStyledTheme();
  return (
    <SharedComponents.Column flex={3.5}>
      <SharedComponents.Text text={"Choose videos*"} type={"h3SemiBold"} />
      <SharedComponents.Text
        text={"Add videos you want to create clips from"}
        type={"h3"}
      />
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.FormikLib.Search
        placeholder={"Search video"}
        field={"search"}
      />
      <SharedComponents.VerticalBox height={md} />
      <SLLib.Videos data={data} itemHeight={itemHeight} height={height} />
    </SharedComponents.Column>
  );
};
