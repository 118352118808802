import { useEffect } from "react";
import { observer } from "mobx-react";

import {
  SharedStore,
  SharedComponents,
  Types as SharedTypes,
  Utils,
} from "@shared";
import { VideoAnalyticsStore, Types } from "@videoAnalytics";

interface IFileRoleController {
  children: React.ReactChildren | React.ReactChild;
  fileId: string;
  hasExternalLoading: boolean;
}

export const FileRoleController = observer(
  ({ children, fileId, hasExternalLoading }: IFileRoleController) => {
    const {
      data: { hasNonEmpRole, hasNoneRole, hasEBPRole, hasUndefinedRole },
    } = SharedStore;

    const {
      data: { isFileOwner, isFileMember, loadings },
      getEntityRole,
    } = VideoAnalyticsStore;

    const hasLoading = Utils.useQueryStatus(
      loadings,
      Types.Endpoints.USER_ROLE
    );

    useEffect(() => {
      getEntityRole(fileId, SharedTypes.EntityType.FILES);
    }, [fileId]);

    if (hasLoading || hasExternalLoading) {
      return <SharedComponents.LoadingIndicator />;
    }

    if (hasUndefinedRole) {
      return <SharedComponents.WaitingIndicator />;
    }

    if (
      hasNoneRole ||
      (hasEBPRole && !isFileOwner && !isFileMember) ||
      (hasNonEmpRole && !isFileOwner && !isFileMember)
    ) {
      return <SharedComponents.DeniedIndicator />;
    }

    return (
      <SharedComponents.Box width={"100%"}>{children}</SharedComponents.Box>
    );
  }
);
