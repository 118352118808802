import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { ROUTES } from "@shared";

function findPathnameInfo(pathname: string) {
  const info = ROUTES.find((route) => route.path.match(pathname));
  return info?.name || "Video analytics platform";
}

export const BrowserTabTitle = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [title, setTitle] = useState(findPathnameInfo(pathname));
  useEffect(() => {
    const newTitle = findPathnameInfo(pathname);
    if (newTitle !== title) {
      setTitle(newTitle);
    }
  }, [location, pathname, title]);
  return (
    <Helmet>
      <title>VAP - {title}</title>
    </Helmet>
  );
};
