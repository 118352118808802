import styled, { keyframes, css } from "styled-components";

export function withFadeIn() {
  const opacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
  return css`
    animation: ${opacity} 0.5s linear;
  `;
}

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  ${withFadeIn()}
`;
