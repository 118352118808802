import { SVGProps } from "react";

export const IconClp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.54 2.87.5 2a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 2h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 13H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31V2.87ZM2.19 3a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 3H2.19Zm4.69-1.707A1 1 0 0 0 6.172 1H2.5a1 1 0 0 0-1 .981l.006.139C1.72 2.042 1.95 2 2.19 2h5.396l-.707-.707h.001Z"
      fill="#808B9F"
    />
  </svg>
);
