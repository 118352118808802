import styled from "styled-components";

import { ITSTagWrapper } from "../types";

export const TagWrapper = styled.div<ITSTagWrapper>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-width;
  height: ${({ theme }: ITSTagWrapper) => `${theme.spacing.xl}px`};
  border-radius: ${({ theme }: ITSTagWrapper) => `${theme.spacing.md}px`};
  padding: ${({ theme }: ITSTagWrapper) => `0 ${theme.spacing.md}px`};
  border: ${({ theme }: ITSTagWrapper) =>
    `1px solid ${theme.palette.primaryBlue}`};
  margin-right: ${({ theme }: ITSTagWrapper) => `${theme.spacing.md}px`};
  cursor: pointer;
`;
