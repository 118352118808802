import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SharedComponents, Theme, Pages, Types as SharedTypes } from "@shared";
import { VideoAnalyticsStore } from "@videoAnalytics";
import { useFormikContext } from "formik";

interface IVideoDetailsPage {
  projectId: string;
}

export const VideoDetailsPage = observer(({ projectId }: IVideoDetailsPage) => {
  const { values } = useFormikContext<any>();
  const [isBackModalOpen, setBackModalOpen] = useState(false);
  const {
    spacing: { sm, lg },
  } = Theme.useStyledTheme();

  const {
    data: { videoDetails },
    updateVideo,
  } = VideoAnalyticsStore;

  const navigate = useNavigate();
  const onBack = useCallback(() => {
    navigate(
      `${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}/video/${videoDetails?.id}`
    );
  }, [navigate]);

  const onPrev = useCallback(() => {
    setBackModalOpen(true);
  }, [setBackModalOpen]);

  const onNext = useCallback(async () => {
    await updateVideo({
      id: videoDetails?.id || "",
      name: values?.name,
      tags: values?.tags,
    });
    navigate(
      `${SharedTypes.PATHS.VIDEO_ANALYTICS}/project/${projectId}/video/${videoDetails?.id}`
    );
  }, [values, videoDetails, updateVideo, projectId]);

  return (
    <Pages.AddProjectStepTemplate
      fields={["name", "tags", "id"]}
      nextTitle="Submit"
      onPrev={onPrev}
      onNext={onNext}
    >
      <SharedComponents.Box>
        <SharedComponents.Modal
          type={SharedTypes.ModalTypes.DEFAULT}
          cancelText={"NO"}
          confirmText={"YES"}
          title={
            "As you click the back button, the completed fields will be lost. Do you want to continue?"
          }
          hasConciergeNote={false}
          isOpen={isBackModalOpen}
          onConfirm={onBack}
          onCancel={() => setBackModalOpen(false)}
        />
        <SharedComponents.VerticalBox height={44} />
        <SharedComponents.Text text={"Video owner:"} type={"h3SemiBold"} />
        <SharedComponents.VerticalBox height={sm} />
        <SharedComponents.Text text={videoDetails?.ownerEmail} type={"h3"} />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.FormikLib.Field
          placeholder={"Enter a video name"}
          field="name"
          title="Video name*"
        />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.FormikLib.CustomTags
          title={"Video tags"}
          tagsField={"tags"}
          inputField={"tag"}
        />
      </SharedComponents.Box>
    </Pages.AddProjectStepTemplate>
  );
});
