export const createClipsVideo = /* GraphQL */ `
  mutation CreateClipsVideo($input: CreateClipsVideoInput!) {
    createClipsVideo(input: $input) {
      status
    }
  }
`;

export const createFile = /* GraphQL */ `
  mutation CreateFile($input: CreateFileInput!) {
    createFile(input: $input) {
      id
      rawPath
    }
  }
`;

export const createProject = /* GraphQL */ `
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      ownerEmail
      name
      updatedAt
      description
      active
    }
  }
`;

export const createVideo = /* GraphQL */ `
  mutation CreateVideo($input: CreateVideoInput!) {
    createVideo(input: $input) {
      rawPath
      id
    }
  }
`;

export const deleteFile = /* GraphQL */ `
  mutation deleteFile($input: DeleteVideoInput!) {
    deleteFile(input: $input) {
      id
    }
  }
`;

export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      id
    }
  }
`;

export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo($input: DeleteVideoInput!) {
    deleteVideo(input: $input) {
      id
    }
  }
`;

export const updateClipsVideo = /* GraphQL */ `
  mutation UpdateVideo($input: UpdateClipsVideoInput!) {
    updateClipsVideo(input: $input) {
      id
    }
  }
`;

export const updateProject = /* GraphQL */ `
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      ownerEmail
      name
      updatedAt
      description
      active
    }
  }
`;

export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo($input: UpdateVideoInput!) {
    updateVideo(input: $input) {
      id
    }
  }
`;
