import { SharedComponents, Theme, Types, Constants } from "@shared";

import "../text/index.css";
import styled from "styled-components";

export interface IErrorIndicator {
  onReload: () => void;
  title: string;
}

const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.primaryBlue};
`;

export const ErrorIndicator = ({ onReload, title }: IErrorIndicator) => {
  const {
    palette: { primaryRed },
    spacing: { md },
  } = Theme.useStyledTheme();
  return (
    <SharedComponents.Column
      alignItems={"center"}
      justifyContent={"center"}
      height={"100vh"}
      width={"100%"}
    >
      <SharedComponents.Text text={title} type={"h1"} color={primaryRed} />
      <p tabIndex={0} className="xSmallSemiBold">
        If you require any further information,{" "}
        <StyledLink
          className={"xSmallSemiBold"}
          href={Constants.MAIL_RECIPIENTS}
        >
          contact
        </StyledLink>{" "}
        support team
      </p>
      <SharedComponents.VerticalBox height={md} />
      <SharedComponents.Button
        type={Types.ButtonType.FILLED}
        text={"Try again"}
        onClick={onReload}
      />
    </SharedComponents.Column>
  );
};
