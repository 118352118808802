import { SharedComponents } from "@shared";
import { CircularProgress } from "@material-ui/core";

export const LoadingIndicator = () => {
  return (
    <SharedComponents.Column
      height={"189px"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress size={24} />
      <SharedComponents.VerticalBox height={16} />
      <SharedComponents.Text type={"h2"} text={"Please, wait..."} />
    </SharedComponents.Column>
  );
};
