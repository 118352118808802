import useBreadcrumbs, { BreadcrumbData } from "use-react-router-breadcrumbs";
import { ROUTES, SharedComponents, Theme } from "@shared";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

interface IText {
  active: boolean;
}

const Text = styled.p<IText>`
  font-size: 0.75rem;
  color: ${({ theme, active }) =>
    active ? theme.palette.primaryBlue : theme.palette.text};
  font-weight: ${({ theme, active }) => (active ? 600 : 400)};
  margin-right: 4px;
`;

export const Breadcrumbs = () => {
  const theme = Theme.useStyledTheme();

  const location = useLocation();

  const navigate = useNavigate();

  const breadcrumbs = useBreadcrumbs(ROUTES);

  const fBreadcrumbs = useMemo(
    () =>
      breadcrumbs.filter((r: BreadcrumbData) => {
        const arr = r.key.split("/");
        if (
          arr[arr.length - 1].toLowerCase() === "video" ||
          arr[arr.length - 1].toLowerCase() === "project" ||
          arr[arr.length - 1].toLowerCase() === "file" ||
          arr[arr.length - 1].toLowerCase() === "summary"
        )
          return false;
        return true;
      }),
    [breadcrumbs]
  );

  return (
    <SharedComponents.Row height={`${theme.spacing.lg}px`}>
      {fBreadcrumbs.map(({ match, breadcrumb }, index) => (
        <SharedComponents.Link to={match.pathname} key={match.pathname}>
          <Text
            onClick={() => navigate(match.pathname)}
            tabIndex={0}
            active={location.pathname === match.pathname}
          >
            {breadcrumb}
          </Text>
          {index !== fBreadcrumbs.length - 1 && (
            <Text active={false}>{">"}</Text>
          )}
        </SharedComponents.Link>
      ))}
    </SharedComponents.Row>
  );
};
