import { useNavigate } from "react-router-dom";
import { FC, useCallback, useEffect } from "react";
import { useField } from "formik";
import { observer } from "mobx-react";

import { SharedComponents, SharedStore, Theme, Pages, Types } from "@shared";
import { VideoAnalyticsStore } from "@videoAnalytics";

import { ITimelineItem } from "../../types";

import { ISummaryDetailsPage } from "./types";

export const SummaryDetailsPage: FC<ISummaryDetailsPage> = observer(
  ({ projectId }) => {
    const navigate = useNavigate();
    const {
      data: { userData },
    } = SharedStore;
    const {
      data: { videosDetails },
    } = VideoAnalyticsStore;

    const [, , helpers] = useField<Array<ITimelineItem>>("timelines");
    const {
      spacing: { sm, md, lg },
    } = Theme.useStyledTheme();
    const { setCurrentStep, currentStep } =
      SharedComponents.useStepperContext();
    const onBackHandler = useCallback(
      () => navigate(`${Types.PATHS.VIDEO_ANALYTICS}/project/${projectId}`),
      [navigate]
    );
    const onNextHandler = useCallback(
      () => setCurrentStep(currentStep + 1),
      [setCurrentStep]
    );

    useEffect(() => {
      const timelines: Array<ITimelineItem> =
        videosDetails?.map((video) => ({
          id: video.id,
          progress: 0,
          positionX: 0,
          progressFrom: 0,
          progressTo: 0,
        })) || [];
      helpers.setValue(timelines);
    }, [videosDetails]);

    return (
      <Pages.AddProjectStepTemplate
        fields={["name", "summary"]}
        onPrev={onBackHandler}
        onNext={onNextHandler}
      >
        <SharedComponents.Box>
          <SharedComponents.Text text={"Summary owner*"} type={"h3SemiBold"} />
          <SharedComponents.Text
            text={userData?.attributes?.email || ""}
            type={"h3"}
            margin={`${sm}px 0 ${lg}px 0`}
          />
          <SharedComponents.FormikLib.Field
            field={"name"}
            placeholder={"Enter a summary name"}
            title={"Clip name*"}
          />
          <SharedComponents.VerticalBox height={md} />
          <SharedComponents.FormikLib.Field
            field={"summary"}
            placeholder={"Enter a description"}
            title={"Clip summary*"}
            component="textarea"
            height={88}
          />
        </SharedComponents.Box>
      </Pages.AddProjectStepTemplate>
    );
  }
);
