import { FC } from "react";
import { IconButton } from "@material-ui/core";

import { SharedComponents, Theme } from "@shared";

import * as ModalLib from "./components";
import { IAssistanceModal } from "./types";

export const AssistanceModal: FC<IAssistanceModal> = ({
  isOpen = false,
  setOpen,
  modalText,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const {
    spacing: { md },
  } = Theme.useStyledTheme();
  return (
    <ModalLib.Wrapper open={isOpen} onClose={handleClose}>
      <SharedComponents.FlexContainer justifyContent={"flex-end"} padding={0}>
        <IconButton onClick={handleClose}>
          <SharedComponents.Icons.IconClose />
        </IconButton>
      </SharedComponents.FlexContainer>
      <SharedComponents.FlexContainer>
        <SharedComponents.Text text={modalText} type={"h3SemiBold"} />
        <SharedComponents.Text
          margin={`${md}px 0 0 0`}
          text={
            "If yes, please contact the DIY Concierge at saldarriaga.p@pg.com before creating the project"
          }
          type={"xSmall"}
        />
      </SharedComponents.FlexContainer>
      <SharedComponents.FlexContainer padding={0} justifyContent={"flex-end"}>
        <SharedComponents.Button
          text={"CREATE THE PROJECT"}
          onClick={handleClose}
        />
      </SharedComponents.FlexContainer>
    </ModalLib.Wrapper>
  );
};
