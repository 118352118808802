import styled from "styled-components";

export const TagWrapper = styled.div`
  height: ${({ theme }) => `${theme.spacing.xl}px`};
  border-radius: ${({ theme }) => `${theme.spacing.md}px`};
  border: 1px solid black;
  width: fit-content;
  padding: ${({ theme }) => `0 ${theme.spacing.md}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => `0 ${theme.spacing.sm}px`};
`;
