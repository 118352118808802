import styled from "styled-components";

import { HOVER_BUTTONS_CLASS_NAME } from "../constants";

export const Container = styled.div`
  width: 100%;
  height: 354px;
  transition: 0.3s ease-in-out;
  position: relative;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06), 0 3px 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  overflow: hidden;

  .${HOVER_BUTTONS_CLASS_NAME} {
    display: none;
  }

  :hover {
    .${HOVER_BUTTONS_CLASS_NAME} {
      display: flex;
    }
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0.1);

    img {
      -webkit-filter: brightness(50%);
    }
  }
`;
