import styled from "styled-components";

import { SharedComponents } from "@shared";

interface ISearchBar {
  onClick: () => void;
}

export const SearchBar = ({ onClick }: ISearchBar) => {
  return (
    <SearchBarWrapper onClick={onClick}>
      <SharedComponents.Text type={"small"} text={"Search..."} />
    </SearchBarWrapper>
  );
};

const SearchBarWrapper = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #808b9f;
  border-radius: 5px;
  padding: 8px 16px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 8px 24px 0 rgba(128, 139, 159, 0.2);
    -moz-box-shadow: 0 8px 24px 0 rgba(128, 139, 159, 0.2);
    box-shadow: 0 8px 24px 0 rgba(128, 139, 159, 0.2);
  }
`;
