import React from "react";
import { Theme, Types, SharedComponents } from "@shared";

import { NAVBAR_ROUTES } from "../../../../routing";

import { NavbarWrapper } from "./navbar.wrapper";
import { MenuItem } from "./navbar.menu.item";

export const Navbar = () => {
  const {
    palette: { primaryWhite },
    spacing: { sm },
  } = Theme.useStyledTheme();

  const generateNavSectionHeader = (title: string, key: number) => {
    return (
      <>
        <SharedComponents.Text
          key={key}
          text={title}
          type={"xSmall"}
          fontSize={`${sm}px`}
          textAlign={"center"}
          color={primaryWhite}
          borderBottom={"1px solid white"}
          width={"100%"}
          margin={"0 0 10px"}
        />
        <SharedComponents.VerticalBox height={5} />
      </>
    );
  };

  const renderNavSection = (
    navSection: Types.INavbarSectionConfig,
    index: number
  ) => (
    <React.Fragment key={index}>
      {navSection.title && generateNavSectionHeader(navSection.title, index)}
      {navSection.data.map((item: Types.IRoute) => (
        <MenuItem key={item.path} item={item} />
      ))}
    </React.Fragment>
  );

  return <NavbarWrapper>{NAVBAR_ROUTES.map(renderNavSection)}</NavbarWrapper>;
};

export default React.memo(Navbar);
